import { Box, CircularProgress } from '@mui/material';

import './tipsLoading.scss';

function TipsLoading(props) {

    return (
        <Box className='tips-loading'>
            <CircularProgress />
        </Box>
    );
};

TipsLoading.propTypes = {};

TipsLoading.defaultProps = {};

export default TipsLoading;