import { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  Collapse,
  Button,
  Stack,
} from '@mui/material';
import PropTypes from 'prop-types';

import './details.scss';

const truncateDetail = (detail, limit = 100) => {
  if (detail.length <= limit) return detail;
  const subString = detail.slice(0, limit - 1);
  return `${subString.slice(0, subString.lastIndexOf(' '))}...`;
};

export const renderDetail = (detail) => (
  <Grid container columnSpacing={2} key={detail[0]} className='detail'>
    <Grid item xs={12} sm={4} className='detail-key'>
      <Typography noWrap variant='subtitle2'>
        {detail[0]}
      </Typography>
    </Grid>
    <Grid item xs={12} sm={8} className='detail-value'>
      <Typography>
        {detail[1] ? truncateDetail(String(detail[1])) : '--'}
      </Typography>
    </Grid>
  </Grid>
);

// TODO look into using this in Device Detail on map
function Details({ primaryDetails, secondaryDetails, actions, ...props }) {
  const [showSecondaryDetails, setShowSecondaryDetails] = useState(false);

  const handleToggleSecondaryDetails = async (event) => {
    setShowSecondaryDetails((prevState) => !prevState);
  };

  return (
    <Box className='details-container'>
      <Paper elevation={3} sx={{ p: 2 }}>
        {primaryDetails.map(renderDetail)}
        {secondaryDetails && (
          <>
            <Collapse in={showSecondaryDetails}>
              <div className='secondary-details'>
                {secondaryDetails.map(renderDetail)}
              </div>
            </Collapse>
            <Button onClick={handleToggleSecondaryDetails}>
              {showSecondaryDetails ? 'Show less' : 'Show More'}
            </Button>
          </>
        )}
      </Paper>
      {actions && (
        <Stack width='100%' spacing={1} mt={1}>
          {actions}
        </Stack>
      )}
    </Box>
  );
}

Details.propTypes = {
  primaryDetails: PropTypes.array.isRequired,
  secondaryDetails: PropTypes.array,
  actions: PropTypes.object,
};

Details.defaultProps = {};

export default Details;
