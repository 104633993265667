import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './pulseDouble.scss';

function DoublePulseAnimation({ color1, color2, ...props }) {

    return (
        <>
            <Box
                className='double-pulse-animation animation-root'
                sx={{ borderColor: color1 }}
                {...props}
            />
            <Box
                className='double-pulse-animation-inverse animation-root'
                sx={{ borderColor: color2 }}
                {...props}
            />
        </>
    );
}

DoublePulseAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

DoublePulseAnimation.defaultProps = {}

export default DoublePulseAnimation;
