import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import './addresses.scss';
import mapAPIService from '../../../services/map';
import SidebarListView from '../../sidebar/listView/listView';
import AddressGroupField from '../../forms/fields/addressGroup/addressGroup';
import {
  setAddressFilters,
  toggleShowAll,
  updateAddress,
} from '../../../store/slices/map';
import {
  selectAuthUserPermissions,
  selectFilteredAddresses,
  selectMapAddressFilters,
  selectMapFollowedMarker,
} from '../../../store/selectors';

const fields = {
  query: 'query',
  group: 'group',
};

function AddressSidebar() {
  const dispatch = useDispatch();
  const addresses = useSelector(selectFilteredAddresses);
  const followedMarker = useSelector(selectMapFollowedMarker);
  const userPermissions = useSelector(selectAuthUserPermissions);
  const filters = useSelector(selectMapAddressFilters);
  const form = useForm({
    defaultValues: {
      [fields.group]: filters.group,
    },
  });

  const addressArray = Object.values(addresses);

  const filterRowsFunction = (query, address) =>
    address.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;

  const handleChange = (addressID) => async (event) => {
    const isVisible = !addresses[addressID].visible;
    dispatch(
      updateAddress({ id: addressID, properties: { visible: isVisible } })
    );
    if (isVisible && !followedMarker) return mapAPIService.resetView();
  };

  const handleAllChange = async (event) => {
    const addressIDs = addressArray.map((address) => address.id);
    dispatch(
      toggleShowAll({
        type: 'addresses',
        show: event.target.checked,
        ids: addressIDs,
      })
    );
    if (event.target.checked && !followedMarker)
      return mapAPIService.resetView();
  };

  const handleGroupChange = async (event, data) => {
    form.setValue(fields.group, data);
    form.setValue(fields.query, '');
    if (data) {
      dispatch(
        setAddressFilters({ filters: { group: data }, updateVisibility: true })
      );
      if (!followedMarker) return mapAPIService.resetView();
    }
  };

  const formFields = (
    <Grid item xs={12}>
      <AddressGroupField
        name={fields.group}
        label='Show Group'
        onChange={handleGroupChange}
        includeAll
      />
    </Grid>
  );

  return (
    <FormProvider {...form}>
      <SidebarListView
        rows={addressArray}
        getRowPrimaryText={(address) => address.name}
        filterRows={filterRowsFunction}
        sortRows={(a, b) => a.name.localeCompare(b.name)}
        emptyMessage='No Addresses Found'
        canManage={userPermissions.addresses.manage}
        manageURL={'manage/addresses'}
        onChange={handleChange}
        onAllChange={handleAllChange}
        formFields={formFields}
      />
    </FormProvider>
  );
}

AddressSidebar.propTypes = {};

AddressSidebar.defaultProps = {};

export default AddressSidebar;
