import { useState } from 'react';
import { Button, Dialog, DialogContent, Grow } from '@mui/material';
import PropTypes from 'prop-types';

import './deviceControlHOC.scss';
import DeviceControl from '../../../../deviceControl/deviceControl';
import USFTDialogTitle from '../../../../dialog/dialogTitle/dialogTitle';

function DeviceControlHOC({ deviceID, commands }) {
    const [showControls, setShowControls] = useState(false);

    const handleClick = async (event) => {
        setShowControls(true);
    };

    const onClose = async (event) => {
        setShowControls(false);
    };

    return (
        <>
            <Button variant='contained' onClick={handleClick}>
                Control
            </Button>
            <Dialog
                open={showControls}
                TransitionComponent={Grow}
                onClose={onClose}
                fullWidth
                maxWidth='sm'
            >
                <USFTDialogTitle title='Device Controls' onClose={onClose} />
                <DialogContent dividers>
                    <DeviceControl deviceID={deviceID} commands={commands} />
                </DialogContent>
            </Dialog>
        </>
    );
};

DeviceControlHOC.propTypes = {
    deviceID: PropTypes.number.isRequired,
    commands: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DeviceControlHOC.defaultProps = {};

export default DeviceControlHOC;
