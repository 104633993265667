import { createElement, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Box } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import PropTypes from 'prop-types';

import './deviceMarker.scss';
import mapAPIService from '../../../services/map';
import DeviceDetail from './details/deviceDetail';
import MarkerPopper from '../common/popper/markerPopper';
import { getDeviceRingColor } from '../common';
import { alertAnimationMap } from '../animations';
import {
  selectMapActiveDetailPopUp,
  selectMapFollowedMarker,
  selectMapHeading,
  selectMapMode,
  selectMapSettings,
} from '../../../store/selectors';
import {
  clearDetailPopUp,
  setActiveDetailPopUp,
} from '../../../store/slices/map';
import defaultDeviceIcon from '../../../static/images/icons/device/default_cropped.png';
import HistoryPointDetails from '../history/point/details/pointDetails';

function DeviceMarker({ device }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const activeDetailPopUp = useSelector(selectMapActiveDetailPopUp);
  const followedMarker = useSelector(selectMapFollowedMarker);
  const heading = useSelector(selectMapHeading);
  const { smallIcons, deviceLabels: labels } = useSelector(selectMapSettings);
  const mapMode = useSelector(selectMapMode);

  useEffect(() => {
    return () => {
      dispatch(clearDetailPopUp(device.id));
    };
  }, [device.id, dispatch]);

  const handleAnchor = (node) => {
    if (node !== null) {
      setAnchorEl(node);
    }
  };

  const onClickHandler = async (event) => {
    event.stopPropagation();
    if (!followedMarker)
      await mapAPIService.center({
        latitude: device.currentLocation.latitude,
        longitude: device.currentLocation.longitude,
      });

    const handler = () => {
      mapAPIService.map.off('moveend', handler);
      dispatch(setActiveDetailPopUp(device.id));
    };
    mapAPIService.map.on('moveend', handler);
  };

  const onCloseDetail = (event) => {
    dispatch(setActiveDetailPopUp(null));
  };

  let ringColor = getDeviceRingColor(device);

  const labelOptions = useMemo(() => {
    if (device.map.backgroundColor)
      return {
        sx: {
          backgroundColor: device.map.backgroundColor,
          color: (t) => t.palette.getContrastText(device.map.backgroundColor),
        },
      };
    else return { color: 'primary' };
  }, [device.map.backgroundColor]);

  const icon =
    device.map.icon ===
    'https://static.usfleettracking.com/img/icons/vehicles/default.png'
      ? defaultDeviceIcon
      : device.map.icon || defaultDeviceIcon;
  const label = device.name;

  const isStaticIcon = icon.includes('no-rotate'); // TODO find better way

  const details =
    mapMode === mapAPIService.modes.live ? (
      <DeviceDetail device={device} />
    ) : (
      <HistoryPointDetails
        point={{ ...device, ...device.location }}
        onClose={onCloseDetail}
      />
    );

  return (
    <Box className={`device-marker-container${smallIcons ? ' small' : ''}`}>
      {device.activeInput && (
        <Box className='animation-container'>
          {createElement(
            alertAnimationMap[device.activeInput.type],
            device.activeInput
          )}
        </Box>
      )}
      <Box className='offset'>
        <Box className='device-marker'>
          <Box
            tabIndex='0'
            className='icon'
            sx={{
              transform: isStaticIcon
                ? 'unset'
                : `rotate(${device.currentLocation.heading - heading}deg)`,
              color: ringColor,
              opacity: device.outOfService ? 0.5 : 1,
            }}
            ref={handleAnchor}
            onClick={onClickHandler}
          >
            <Box
              className='border'
              sx={{
                borderColor: (theme) => theme.palette.success.contrastText,
              }}
            >
              <Box className='border inner' sx={{ borderColor: ringColor }}>
                <Box
                  className='border'
                  sx={{
                    borderColor: (theme) => theme.palette.success.contrastText,
                  }}
                >
                  <img src={icon} alt={`${label}'s icon`} />
                </Box>
              </Box>
            </Box>
            {!isStaticIcon && (
              <ArrowDropUpIcon
                className='direction'
                sx={{ color: ringColor }}
              />
            )}
          </Box>
          {labels ? (
            <Chip
              label={label}
              className='label'
              clickable={false}
              onClick={onClickHandler}
              {...labelOptions}
            />
          ) : null}
        </Box>
      </Box>
      <MarkerPopper
        open={activeDetailPopUp === device.id}
        onClose={onCloseDetail}
        title='Device Details'
        details={details}
        anchor={anchorEl}
      />
    </Box>
  );
}

DeviceMarker.propTypes = {
  device: PropTypes.object.isRequired,
};

DeviceMarker.defaultProps = {};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.device.name === nextProps.device.name &&
    prevProps.device.currentLocation.heading ===
      nextProps.device.currentLocation.heading &&
    prevProps.device.currentLocation.velocity ===
      nextProps.device.currentLocation.velocity &&
    prevProps.device.location.address === nextProps.device.location.address &&
    prevProps.device.location.streetViewURL ===
      nextProps.device.location.streetViewURL &&
    prevProps.device.ignition === nextProps.device.ignition &&
    prevProps.device.map.backgroundColor ===
      nextProps.device.map.backgroundColor &&
    prevProps.device.map.icon === nextProps.device.map.icon &&
    prevProps.device.activeInput?.type === nextProps.device.activeInput?.type &&
    prevProps.small === nextProps.small &&
    prevProps.showLabel === nextProps.showLabel &&
    prevProps.followedMarker === nextProps.followedMarker &&
    prevProps.heading === nextProps.heading &&
    (prevProps.activeDetailPopUp === nextProps.activeDetailPopUp ||
      (prevProps.activeDetailPopUp !== prevProps.device.id &&
        nextProps.activeDetailPopUp !== nextProps.device.id))
  );
};

export default memo(DeviceMarker, arePropsEqual);
