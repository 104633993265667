import HistoryPlayback from '../../components/historyPlayback/historyPlayback';
import RoutesSidebar from '../../components/routes/sidebar/routes';
import KMLSidebar from '../../components/kmls/sidebar/kmls';
import DevicesSidebar from '../../components/devices/sidebar/devices';
import DriversSidebar from '../../components/drivers/sidebar/drivers';
import AddressSidebar from '../../components/addresses/sidebar/addresses';
import GeofencesSidebar from '../../components/geofences/sidebar/geofences';
import serviceCallsSidebar from '../serviceCalls/sidebar/serviceCalls';

const options = {
  addresses: {
    title: 'Addresses',
    component: AddressSidebar,
  },
  history: {
    title: 'History Playback',
    component: HistoryPlayback,
  },
  routes: {
    title: 'Routes',
    component: RoutesSidebar,
  },
  kmls: {
    title: 'KMLs / KMZs',
    component: KMLSidebar,
  },
  geofences: {
    title: 'Geofences',
    component: GeofencesSidebar,
  },
  devices: {
    title: 'Devices',
    component: DevicesSidebar,
  },
  drivers: {
    title: 'Drivers',
    component: DriversSidebar,
  },
  serviceCalls: {
    title: 'Service Calls',
    component: serviceCallsSidebar,
  },
};

export default options;
