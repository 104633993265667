import { ListItemButton, ListItemIcon, ListItemText, Icon, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './menuItem.scss';
import featureService from '../../../services/feature';

function MenuItem({ featureID, features, ...props }) {

    const handleClick = (e) => {
        if (featureID)
            featureService.view(featureID)
        if (!props.to)
            props.onClick()
    }

    return (
        <ListItemButton
            disabled={props.disabled}
            onClick={handleClick}
            component={props.to ? Link : undefined}
            to={props.to}
            className='menu-item'
        >
            {props.icon && (
                <ListItemIcon>
                    <Icon component={props.icon} />
                </ListItemIcon>
            )}
            <ListItemText primary={props.label} />
            {props.to && <ChevronRightIcon />}
            {featureID && features[featureID] && <Box bgcolor='primary.main' className='new' />}
        </ListItemButton>
    );
}

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    features: PropTypes.object.isRequired,
    icon: PropTypes.object,
    to: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    featureID: PropTypes.string,
}

MenuItem.defaultProps = {
    disabled: false
}

const mapStateToProps = (state) => {
    return {
        features: state.app.features,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);
