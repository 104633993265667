import { Grid, Box, Paper, Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import './webSearchDetails.scss';
import StreetViewPreview from '../../../streetViewPreview/streetViewPreview';
import AddAddressHOC from './addAddressHOC/addAddressHOC';
import { selectAuthUser } from '../../../../store/selectors';

function WebSearchDetails({ address }) {
  const user = useSelector(selectAuthUser);

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };
  const handleOnClick = (event) => {
    event.stopPropagation();
  };

  const renderDetail = (detail) => (
    <Grid container columnSpacing={2} key={detail[0]} className='detail'>
      <Grid item xs={12} sm={4} className='detail-key'>
        <Typography noWrap variant='subtitle2'>
          {detail[0]}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} className='detail-value'>
        {detail[1] ? detail[1] : '--'}
      </Grid>
    </Grid>
  );

  const details = [
    ['Name', address.name],
    ['Address', address.address],
    ['Latitude', address.latitude.toFixed(6)],
    ['Longitude', address.longitude.toFixed(6)],
  ].map(renderDetail);

  return (
    <Box
      className='address-detail'
      onMouseDown={handleMouseDown}
      onClick={handleOnClick}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Paper className='details' elevation={3} sx={{ p: 2 }}>
            {details}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className='streetview-container'>
            <StreetViewPreview
              latitude={address.latitude}
              longitude={address.longitude}
              heading={0}
            />
          </div>
          <Stack width='100%' spacing={1} mt={1}>
            {user.permissions.addresses.manage && (
              <AddAddressHOC address={address} />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

WebSearchDetails.propTypes = {
  address: PropTypes.object.isRequired,
};

WebSearchDetails.defaultProps = {};

export default WebSearchDetails;
