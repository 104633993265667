import { useState } from 'react';
import { Button, Dialog, DialogContent, Grow } from '@mui/material';
import PropTypes from 'prop-types';

import './videoPlayback.scss';
import USFTDialogTitle from '../../../../dialog/dialogTitle/dialogTitle';
import VideoPlayback from '../../../../videoPlayback/videoPlayback';

function VideoPlaybackAction({ event, device }) {
    const [showVideo, setShowVideo] = useState(false);

    const handleClick = async (e) => {
        setShowVideo(true);
    };

    const onClose = async (e) => {
        setShowVideo(false);
    };

    return (
        <>
            <Button variant='contained' onClick={handleClick} fullWidth>
                View Video
            </Button>
            <Dialog
                open={showVideo}
                TransitionComponent={Grow}
                onClose={onClose}
                fullWidth
                maxWidth='md'
            >
                <USFTDialogTitle title='Camera' onClose={onClose} />
                <DialogContent dividers>
                    <VideoPlayback device={device} urls={event.urls} />
                </DialogContent>
            </Dialog>
        </>
    );
};

VideoPlaybackAction.propTypes = {
    event: PropTypes.object.isRequired,
};

VideoPlaybackAction.defaultProps = {};

export default VideoPlaybackAction;
