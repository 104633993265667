import { Typography, Link } from "@mui/material";
import { DateTime } from "luxon";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from 'prop-types';

// TODO add popup with change log?
/* 
    NOTE process.env.REACT_APP_VERSION will pull package.json version 
    while running locally, but will pull $BUDDY_EXECUTION_TAG during pipeline
*/
function Copyright({ disableLink, ...props }) {
    const currentDateTime = DateTime.now();

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`Copyright © ${currentDateTime.year} - rev `}
            {disableLink ? process.env.REACT_APP_VERSION : (
                <Link
                    component={RouterLink}
                    to='/manage/client-info'
                    underline='none'
                    color='inherit'

                >
                    {process.env.REACT_APP_VERSION}
                </Link>
            )}
        </Typography>
    );
};

Copyright.propTypes = {
    disableLink: PropTypes.bool.isRequired
};

Copyright.defaultProps = {
    disableLink: false
};

export default Copyright;