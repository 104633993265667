import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

import './wave.scss';

function WaveAnimation({ color1, color2, ...props }) {
    const wave = keyframes`
        0% {
            height: 30%;
            width: 30%;
            border-width: 2px;
            opacity: 0;
            border-color: ${color1};
        }
        30% {
            opacity: 1;
        }
        100% {
            height: 100%;
            width: 100%;
            border-width: 8px;
            opacity: 0;
            border-color: ${color2};
        }
    `;

    return (
        <Box
            className='wave-animation animation-root'
            sx={{ animation: `${wave} 1s infinite` }}
            {...props}
        />
    );
}

WaveAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

WaveAnimation.defaultProps = {}

export default WaveAnimation;
