import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Box, Grid, IconButton, Slider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { findNearest } from 'geolib';
import L from 'leaflet';

import './historyPlayback.scss';
import mapAPIService from '../../services/map';
import RequestVideoButton from '../requestVideos/button/requestVideoButton';
import FormSection from '../forms/section/section';
import HistoryPlaybackForm from './form/historyPlaybackForm';
import {
  selectAuthUser,
  selectHistory,
  selectMapPOI,
} from '../../store/selectors';
import {
  setDevices,
  setEvents,
  setPOI,
  updateDevice,
  updateDeviceLocation,
  updateMapMode,
} from '../../store/slices/map';
import { resetPlayerData } from '../../store/slices/historyPlayback';

const playRateMap = [
  { speed: 4, interval: 125, direction: -1 },
  { speed: 3, interval: 250, direction: -1 },
  { speed: 2, interval: 500, direction: -1 },
  { speed: 1, interval: 1000, direction: -1 },
  { speed: 1, interval: 1000, direction: 1 },
  { speed: 2, interval: 500, direction: 1 },
  { speed: 3, interval: 250, direction: 1 },
  { speed: 4, interval: 125, direction: 1 },
];
const defaultPlayerOptions = {
  isPlaying: false,
  updatedBySlider: false,
  position: 0,
  maxPosition: 0,
  playRate: 4,
};
const decrement = -1;
const increment = 1;
const defaultTrailColor = '#000';

// TODO if last point of playback is event, open details automatically
function HistoryPlayback({ cleanUpFunction }) {
  const [player, setPlayer] = useState(defaultPlayerOptions);

  const polyline = useMemo(
    () =>
      L.polyline([], {
        color: defaultTrailColor,
        opacity: 0.7,
        weight: 6,
      }).addTo(mapAPIService.map),
    []
  );

  const dispatch = useDispatch();
  const POI = useSelector(selectMapPOI);
  const playerData = useSelector(selectHistory);
  const user = useSelector(selectAuthUser);

  const { enqueueSnackbar } = useSnackbar();

  // Handle Mount / Unmount
  useEffect(() => {
    dispatch(updateMapMode(mapAPIService.modes.historyPlayback));
    cleanUpFunction.current = () => {
      console.debug('Unmounting History Playback');
      polyline.remove();
      dispatch(resetPlayerData());
      dispatch(setDevices([]));
      dispatch(setEvents([]));
      dispatch(setPOI(null));
      /* 
                TODO look into only running this when "X" close button is clicked
                Due to possible other modes besides "live" and "history playback"
                And also going straight to management. Cleanup already happens in Map
            */
      dispatch(updateMapMode(mapAPIService.modes.live));
    };
  }, [polyline, dispatch, cleanUpFunction]);

  // Handle Polyline listener
  useEffect(() => {
    const clickListener = (event) => {
      const nearestPoint = findNearest(
        { latitude: event.latlng.lat, longitude: event.latlng.lng },
        playerData.points
      );
      dispatch(setPOI(nearestPoint));
    };
    polyline.on('click', clickListener);
    return () => {
      polyline.off('click', clickListener);
    };
  }, [polyline, playerData.points, dispatch]);

  // Watch for "unmount" of POI
  useEffect(() => {
    if (POI && player.position <= POI.index) dispatch(setPOI(null));
  }, [player.position, POI, dispatch]);

  // Watch position update
  useEffect(() => {
    if (playerData.coordinates.length === 0) return;
    const handlePlayerChange = () => {
      polyline.setLatLngs(playerData.coordinates.slice(0, player.position + 1));
      const point = playerData.points[player.position];
      const device = playerData.devices[point.device];
      if (point.device === playerData.currentDevice) {
        dispatch(
          updateDevice({
            device: device.id,
            properties: point,
            moveDirect: true,
          })
        );
        dispatch(
          updateDeviceLocation({
            id: device.id,
            location: point,
            moveDirect: true,
          })
        );
      } else {
        dispatch(
          setDevices([
            {
              ...device,
              location: point,
              airbag: point.airbag,
              ignition: point.ignition,
              seatbelt: point.seatbelt,
            },
          ])
        );
      }

      // Calculate visible events
      let eventIndex;
      playerData.events.every((event, index) => {
        if (point.time >= event.time) return true;
        else eventIndex = index;
        return false;
      });

      dispatch(
        setEvents([
          ...playerData.points
            .slice(0, player.position + 1)
            .filter((point) => point.type === 'stop'),
          ...playerData.events.slice(0, eventIndex),
        ])
      );
    };
    // If slider is being dragged
    if (player.updatedBySlider) {
      const timeout = setTimeout(handlePlayerChange, 100);
      return () => {
        clearTimeout(timeout);
      };
    } else handlePlayerChange();
  }, [player.position, player.updatedBySlider, playerData, polyline, dispatch]);

  // Watch position update (center on map)
  useEffect(() => {
    if (playerData.coordinates.length === 0) return;
    const handlePlayerChange = () => {
      const coords = playerData.coordinates[player.position];
      mapAPIService.center({
        latitude: coords.lat,
        longitude: coords.lng,
        animate: false,
      });
    };
    // If slider is being dragged
    if (player.updatedBySlider) {
      const timeout = setTimeout(handlePlayerChange, 100);
      return () => {
        clearTimeout(timeout);
      };
    } else handlePlayerChange();
  }, [player.position, player.updatedBySlider, playerData.coordinates]);

  // Play / Play Rate watch
  useEffect(() => {
    if (!player.isPlaying) return;
    const playRate = playRateMap[player.playRate];
    let interval;
    interval = setInterval(() => {
      setPlayer((prevState) => {
        if (
          (playRate.direction === 1 &&
            prevState.position < prevState.maxPosition) ||
          (playRate.direction === -1 && prevState.position > 0)
        ) {
          const newPosition = prevState.position + playRate.direction;
          return {
            ...prevState,
            isPlaying:
              (playRate.direction === 1 &&
                newPosition < prevState.maxPosition) ||
              (playRate.direction === -1 && newPosition > 0),
            position: newPosition,
          };
        } else
          return {
            ...prevState,
            isPlaying: false,
          };
      });
    }, playRate.interval);
    return () => {
      clearInterval(interval);
    };
  }, [player.isPlaying, player.playRate]);

  const handleSubmit = async (data) => {
    polyline.remove();
    dispatch(resetPlayerData());
    dispatch(setDevices([]));
    dispatch(setEvents([]));
    dispatch(setPOI(null));
    setPlayer(defaultPlayerOptions);
  };

  const handleFetch = async (data) => {
    if (data.points.length === 0) {
      enqueueSnackbar('No history found for the selected time', {
        variant: 'info',
      });
      return;
    }
    const initialPoint = data.points[0];
    const device = data.devices[initialPoint.device];
    const points = [];
    const coordinates = [];
    data.points.forEach((point, index) => {
      points.push({
        ...point,
        index,
      });
      coordinates.push({
        lat: point.latitude,
        lng: point.longitude,
      });
    });
    setPlayer({
      ...defaultPlayerOptions,
      maxPosition: data.points.length - 1, // Helper to not have to do so many calcs later
    });
    dispatch(
      setDevices([
        {
          ...device,
          location: initialPoint,
          airbag: initialPoint.airbag,
          ignition: initialPoint.ignition,
          seatbelt: initialPoint.seatbelt,
        },
      ])
    );
    dispatch(
      resetPlayerData({
        currentDevice: device.id,
        devices: data.devices,
        coordinates,
        points,
        events: data.events,
      })
    );
    polyline.setStyle({
      color: device.map.vaporTrailColor || defaultTrailColor,
    });
    polyline.addTo(mapAPIService.map);
  };

  const togglePlaying = (event) => {
    setPlayer((prevState) => ({
      ...prevState,
      isPlaying: !prevState.isPlaying,
    }));
  };

  const skipToStart = (event) => {
    console.debug('Skipping to start');
    setPlayer((prevState) => ({
      ...prevState,
      isPlaying: false,
      updatedBySlider: false,
      position: 0,
    }));
  };

  const skipToEnd = (event) => {
    console.debug('Skipping to end');
    setPlayer((prevState) => ({
      ...prevState,
      isPlaying: false,
      updatedBySlider: false,
      position: player.maxPosition,
    }));
  };

  const handleSliderChange = (event) => {
    const newPosition = Number(event.target.value);
    if (newPosition !== player.position)
      setPlayer((prevState) => ({
        ...prevState,
        position: newPosition,
        isPlaying: false,
        updatedBySlider: true,
      }));
  };

  const changeFrame = (modifier) => (event) => {
    setPlayer((prevState) => {
      if (
        (modifier === decrement && prevState.position > 0) ||
        (modifier === increment && prevState.position < prevState.maxPosition)
      ) {
        return {
          ...prevState,
          isPlaying: false,
          updatedBySlider: false,
          position: prevState.position + modifier,
        };
      } else return prevState;
    });
  };

  const changePlayRate = (modifier) => (event) => {
    setPlayer((prevState) => {
      if (
        (modifier === increment &&
          prevState.playRate < playRateMap.length - 1) ||
        (modifier === decrement && prevState.playRate > 0)
      ) {
        return {
          ...prevState,
          updatedBySlider: false,
          playRate: prevState.playRate + modifier,
        };
      } else return prevState;
    });
  };

  const showRequestVideoButton =
    Object.values(playerData.devices).some((device) => device.camera) &&
    user.permissions.videos;

  const playRate = playRateMap[player.playRate];

  return (
    <Box className='history-playback' p={2}>
      <HistoryPlaybackForm onSubmit={handleSubmit} onFetch={handleFetch} />
      {playerData.points.length > 0 && (
        <>
          <FormSection spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h6' align='center'>
                {DateTime.fromISO(
                  playerData.points[player.position].time
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </Typography>
            </Grid>
            <Grid item xs={12} className='slider-container'>
              {/* MUI doesn't support max === min */}
              <Slider
                value={player.maxPosition !== 0 ? player.position : 1}
                disabled={player.maxPosition === 0}
                aria-label='Default'
                valueLabelDisplay='off'
                max={player.maxPosition || 1}
                min={0}
                step={1}
                onChange={handleSliderChange}
              />
            </Grid>
            <Grid item xs={12} className='playback-buttons'>
              <IconButton
                onClick={skipToStart}
                color='inherit'
                aria-label='Skip to start'
                size='large'
                disabled={player.position <= 0}
              >
                <SkipPreviousIcon fontSize='inherit' />
              </IconButton>
              <IconButton
                onClick={changeFrame(decrement)}
                color='inherit'
                aria-label='Advance backward'
                size='large'
                disabled={player.position <= 0}
              >
                <KeyboardArrowLeftIcon fontSize='inherit' />
              </IconButton>
              <IconButton
                onClick={togglePlaying}
                color='inherit'
                aria-label={player.isPlaying ? 'Pause' : 'Play'}
                size='large'
                disabled={
                  (player.position >= player.maxPosition &&
                    playRate.direction === 1) ||
                  (player.position <= 0 && playRate.direction === -1)
                }
              >
                {player.isPlaying ? (
                  <PauseIcon fontSize='inherit' />
                ) : (
                  <PlayArrowIcon fontSize='inherit' />
                )}
              </IconButton>
              <IconButton
                onClick={changeFrame(increment)}
                color='inherit'
                aria-label='Advance forward'
                size='large'
                disabled={player.position >= player.maxPosition}
              >
                <KeyboardArrowRightIcon fontSize='inherit' />
              </IconButton>
              <IconButton
                onClick={skipToEnd}
                color='inherit'
                aria-label='Skip to end'
                size='large'
                disabled={player.position >= player.maxPosition}
              >
                <SkipNextIcon fontSize='inherit' />
              </IconButton>
            </Grid>
            <Grid item xs={12} className='playback-buttons'>
              <IconButton
                onClick={changePlayRate(decrement)}
                color='inherit'
                aria-label='Decrease Playback Speed'
                size='large'
                disabled={player.playRate === 0}
              >
                <FastRewindIcon fontSize='inherit' />
              </IconButton>
              <Typography width={10} align='center'>
                {playRate.direction < 0 ? '<' : ' '}
              </Typography>
              <Typography>{playRate.speed}X</Typography>
              <Typography width={10} align='center'>
                {playRate.direction > 0 ? '>' : ' '}
              </Typography>
              <IconButton
                onClick={changePlayRate(increment)}
                color='inherit'
                aria-label='Increase Playback Speed'
                size='large'
                disabled={player.playRate === playRateMap.length - 1}
              >
                <FastForwardIcon fontSize='inherit' />
              </IconButton>
            </Grid>
          </FormSection>
          {showRequestVideoButton && (
            <Box display='flex' justifyContent='center'>
              <RequestVideoButton
                device={playerData.devices[playerData.currentDevice]}
                time={playerData.points[player.position].time}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

HistoryPlayback.propTypes = {};

HistoryPlayback.defaultProps = {};

export default HistoryPlayback;
