import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet } from 'react-router-dom';

import './basicLayout.scss';
import Sidebar from './sidebar/sidebar';
import Notifications from '../../components/notifications/notifications';
import AccountMenu from '../common/accountMenu/accountMenu';
import { appModes } from '../../store/utility';
import ManagementLoading from '../../components/loading/management/managementLoading';
import { setAppMode } from '../../store/slices/app';

const drawerWidth = 280;

function BasicLayout() {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    dispatch(setAppMode(appModes.manage));
  }, [dispatch]);

  const toggleOpenMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Box className='basic-layout' sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          color: 'text.primary',
          backgroundColor: 'background.default',
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar className='navbar'>
          <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }}>
            <IconButton onClick={toggleOpenMenu} color='inherit'>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box className='navbar-buttons'>
            <Notifications />
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        {isMobile ? (
          <Drawer
            variant='temporary'
            open={menuOpen}
            onClose={handleCloseMenu}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
          >
            <Sidebar onClose={handleCloseMenu} />
          </Drawer>
        ) : (
          <Drawer
            variant='permanent'
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
              },
            }}
            open
          >
            <Sidebar />
          </Drawer>
        )}
      </Box>
      <Box
        component='main'
        className='main-container'
        sx={{ width: { md: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Suspense fallback={<ManagementLoading />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
}

BasicLayout.propTypes = {};

BasicLayout.defaultProps = {};

export default BasicLayout;
