import { DateTime } from 'luxon';
import { createSlice } from '@reduxjs/toolkit';
import {
  allAddressesOption,
  allDevicesOption,
  statusAllDevices,
} from '../../components/forms/fields/helpers';
import { deviceVisibiltyFunctions } from '../../utilities/helpers';
import mapAPIService from '../../services/map';

// TODO reduce number of reducers (can be abstracted)
const initialState = {
  addresses: {},
  addressGroups: {},
  devices: {},
  deviceGroups: {},
  drivers: {},
  events: {},
  routes: {},
  kmls: {},
  geofences: {},
  history: {},
  serviceCalls: {},
  appointments: {},
  deviceFilters: {
    group: allDevicesOption,
    status: statusAllDevices,
  },
  addressFilters: {
    group: allAddressesOption,
  },
  serviceCallFilters: {
    priorities: [],
    statuses: [],
    territories: [],
    technicians: [],
  },
  appointmentFilters: {
    date: DateTime.now().toISO(),
  },
  mode: 'live', // TODO make const options
  streetViewActive: false, // TODO look into moving external
  heading: 0,
  POI: null,
  webSearch: null,
  activeDetailPopUp: null,
  followedMarker: null,
  settings: {
    type: 'roadmap',
    smallIcons: false,
    addressLabels: true,
    deviceLabels: true,
    groupMarkers: true,
    routeWaypoints: false,
    traffic: false,
    weather: false,
    counties: false,
    autoZoom: false,
    liveVapor: false,
  },
  liveVaporSettings: {
    range: 1,
    mode: 'normal',
  },
  loading: true,
};

//TODO move this logic out
const _isVisible = (state, key, id, defaultValue = true) => {
  let isVisible = defaultValue;
  if (state[key][id] !== undefined) isVisible = state[key][id].visible;
  else if (mapAPIService.prevMapState && mapAPIService.prevMapState[key])
    isVisible = mapAPIService.prevMapState[key].includes(id);
  return isVisible;
};

const _setMapObjects = (state, objects, key, defaultVisible = false) => {
  const value = {};
  objects.forEach((obj) => {
    obj.visible = _isVisible(state, key, obj.id, defaultVisible);
    value[obj.id] = obj;
  });
  state[key] = value;
};

// TODO refactor out isVisible to action callers
/* 
  forEach instead of reduce 
  https://www.measurethat.net/Benchmarks/Show/11057/0/new-object-from-reduce-vs-foreach
*/
const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setDevices: (state, action) => {
      const devices = {};
      action.payload.forEach((device) => {
        devices[device.id] = {
          ...device,
          currentLocation: device.prevLocation || device.location,
          animationLoop: null,
          visible: _isVisible(state, 'devices', device.id),
        };
      });
      state.devices = devices;
    },
    setDeviceGroups: (state, action) => {
      const deviceGroups = {};
      action.payload.forEach((group) => (deviceGroups[group.id] = group));
      state.deviceGroups = deviceGroups;
    },
    setEvents: (state, action) => {
      const events = {};
      action.payload.forEach((event) => (events[event.id] = event));
      state.events = events;
    },
    setPOI: (state, action) => {
      state.POI = action.payload;
    },
    setWebSearch: (state, action) => {
      state.webSearch = action.payload;
    },
    setDeviceHistory: (state, action) => {
      state.history = action.payload;
    },
    setDeviceTags: (state, action) => {
      const devices = {};
      Object.values(state.devices).forEach((device) => {
        devices[device.id] = {
          ...device,
          searchTags: action.payload[device.id] || [],
        };
      });
      state.devices = devices;
    },
    updateAddress: (state, action) => {
      state.addresses[action.payload.id] = {
        ...state.addresses[action.payload.id],
        ...action.payload.properties,
      };
    },
    updateKML: (state, action) => {
      state.kmls[action.payload.id] = {
        ...state.kmls[action.payload.id],
        ...action.payload.properties,
      };
    },
    updateGeofence: (state, action) => {
      state.geofences[action.payload.id] = {
        ...state.geofences[action.payload.id],
        ...action.payload.properties,
      };
    },
    updateDevice: (state, action) => {
      state.devices[action.payload.id] = {
        ...state.devices[action.payload.id],
        ...action.payload.properties,
      };
    },
    updateDeviceLocationCache: (state, action) => {
      state.devices[action.payload.id] = {
        ...state.devices[action.payload.id],
        location: {
          ...state.devices[action.payload.id].location,
          address: action.payload.address,
          streetViewURL: action.payload.url,
        },
      };
    },
    updateDeviceLocation: (state, action) => {
      const device = state.devices[action.payload.id];
      state.devices[action.payload.id] = {
        ...device,
        location: action.payload.location,
        currentLocation: action.payload.moveDirect
          ? action.payload.location
          : device.location,
        prevLocation: device.location,
        lastMoved:
          action.payload.location.velocity > 0
            ? action.payload.location.lastUpdated
            : device.lastMoved,
      };
    },
    updateDeviceCurrentLocation: (state, action) => {
      state.devices[action.payload.id].currentLocation =
        action.payload.location;
    },
    updateDeviceHistory: (state, action) => {
      state.history[action.payload.id] = action.payload.history;
    },
    updateRoute: (state, action) => {
      state.routes[action.payload.id] = {
        ...state.routes[action.payload.id],
        ...action.payload.properties,
      };
    },
    updateServiceCall: (state, action) => {
      state.serviceCalls[action.payload.id] = {
        ...state.serviceCalls[action.payload.id],
        ...action.payload.properties,
      };
    },
    finishDeviceAnimation: (state, action) => {
      const device = state.devices[action.payload];
      state.devices[action.payload] = {
        ...device,
        currentLocation: device.location,
        animationLoop: null,
      };
    },
    setActiveDetailPopUp: (state, action) => {
      state.activeDetailPopUp = action.payload;
    },
    clearDetailPopUp: (state, action) => {
      if (state.activeDetailPopUp === action.payload)
        state.activeDetailPopUp = null;
    },
    setServiceCalls: (state, action) => {
      _setMapObjects(state, action.payload, 'serviceCalls');
      state.activeDetailPopUp = Object.keys(state.serviceCalls).includes(
        String(state.activeDetailPopUp)
      )
        ? null
        : state.activeDetailPopUp;
    },
    setServiceModuleAppointments: (state, action) => {
      _setMapObjects(state, action.payload, 'appointments');
      // state.activeDetailPopUp = Object.keys(state.serviceCalls).includes(
      //   String(state.activeDetailPopUp)
      // )
      //   ? null
      //   : state.activeDetailPopUp;
    },
    setDrivers: (state, action) =>
      _setMapObjects(state, action.payload, 'drivers'),
    setAddresses: (state, action) =>
      _setMapObjects(state, action.payload, 'addresses'),
    setAddressGroups: (state, action) =>
      _setMapObjects(state, action.payload, 'addressGroups'),
    setRoutes: (state, action) =>
      _setMapObjects(state, action.payload, 'routes'),
    setKMLs: (state, action) => _setMapObjects(state, action.payload, 'kmls'),
    setGeofences: (state, action) =>
      _setMapObjects(state, action.payload, 'geofences'),
    setfollowedMarker: (state, action) => {
      state.followedMarker = action.id;
    },
    updateSettings: (state, action) => {
      state.settings = {
        ...state.settings,
        ...action.payload,
      };
    },
    updateLiveVaporSettings: (state, action) => {
      state.liveVaporSettings = action.payload;
      state.settings.liveVapor = true;
    },
    updateMapMode: (state, action) => {
      state.mode = action.payload;
    },
    setStreetView: (state, action) => {
      state.streetViewActive = action.payload;
    },
    loadSuccess: (state, action) => {
      state.loading = false;
      state.settings.type = action.payload;
    },
    setDeviceFilters: (state, action) => {
      const filters = {
        ...state.deviceFilters,
        ...action.payload.filters,
      };
      if (action.payload.updateVisibility) {
        let group;
        if (filters.group) group = state.deviceGroups[filters.group.id];
        const devices = {};
        Object.values(state.devices).forEach((device) => {
          let visible = true;

          if (group) {
            visible = group.members.includes(device.id);
          }
          if (visible && filters.status) {
            const vFunc = deviceVisibiltyFunctions[filters.status];
            visible = vFunc ? vFunc(device) : true;
          }

          devices[device.id] = {
            ...device,
            visible,
          };
        });

        state.devices = devices;
      }

      state.deviceFilters = filters;
    },
    // TODO Setup Device Filters like Service Calls
    setServiceCallFilters: (state, action) => {
      const filters = {
        ...state.serviceCallFilters,
        ...action.payload,
      };
      const calls = {};
      Object.values(state.serviceCalls)
        .map((call) => {
          let visible = true;

          if (filters.priorities.length > 0) {
            visible = filters.priorities.includes(call.priority);
          }
          if (visible && filters.statuses.length > 0) {
            visible = filters.statuses.includes(call.status);
          }
          if (visible && filters.territories.length > 0) {
            visible = filters.territories.includes(call.location.territory);
          }
          if (visible && filters.technicians.length > 0) {
            visible = filters.technicians.includes(call.technician);
          }

          return {
            ...call,
            visible,
          };
        })
        .forEach((call) => (calls[call.id] = call));

      state.serviceCallFilters = filters;
      state.serviceCalls = calls;
    },
    setAddressFilters: (state, action) => {
      const filters = {
        ...state.addressFilters,
        ...action.payload.filters,
      };
      if (action.payload.updateVisibility) {
        let group;
        if (filters.group) group = state.addressGroups[filters.group.id];
        const addresses = {};
        Object.values(state.addresses).forEach((address) => {
          let visible = true;

          if (group) {
            visible = group.members.includes(address.id);
          }

          addresses[address.id] = {
            ...address,
            visible,
          };
        });

        state.addresses = addresses;
      }

      state.addressFilters = filters;
    },
    setHeading: (state, action) => {
      state.heading = action.payload;
    },
    toggleShowAll: (state, action) => {
      let mapFunction;
      if (action.payload.ids)
        mapFunction = (marker) => {
          if (action.payload.ids.includes(marker.id))
            return { ...marker, visible: action.payload.show };
          else return marker;
        };
      else
        mapFunction = (marker) => ({ ...marker, visible: action.payload.show });
      const markers = {};
      Object.values(state[action.payload.type])
        .map(mapFunction)
        .forEach((marker) => (markers[marker.id] = marker));

      state[action.payload.type] = markers;
    },
    resetMap: (state, action) => ({
      ...initialState,
      mode: state.mode,
    }),
  },
});

export const {
  setDevices,
  setDeviceGroups,
  setEvents,
  setPOI,
  setWebSearch,
  setDeviceHistory,
  setDeviceTags,
  updateAddress,
  updateKML,
  updateGeofence,
  updateDevice,
  updateDeviceLocationCache,
  updateDeviceLocation,
  updateDeviceCurrentLocation,
  updateDeviceHistory,
  updateRoute,
  updateServiceCall,
  finishDeviceAnimation,
  setActiveDetailPopUp,
  clearDetailPopUp,
  setServiceCalls,
  setServiceModuleAppointments,
  setDrivers,
  setAddresses,
  setAddressGroups,
  setRoutes,
  setKMLs,
  setGeofences,
  setfollowedMarker,
  updateSettings,
  updateLiveVaporSettings,
  updateMapMode,
  setStreetView,
  loadSuccess,
  setDeviceFilters,
  setServiceCallFilters,
  setAddressFilters,
  setHeading,
  toggleShowAll,
  resetMap,
} = mapSlice.actions;

export default mapSlice.reducer;
