import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';

import './login.scss';
import Copyright from '../copyright/copyright';
import Logo from '../logo/logo';
import LoginForm from './form/loginForm';
import { getDealer, useTitleHook } from '../../utilities/helpers';

function Login(props) {
  const dealer = useMemo(getDealer, []);
  useTitleHook('Login');

  useEffect(() => {
    if (dealer) document.title = dealer.title;
  }, [dealer]);

  let logo = <Logo height={45} maxWidth={300} />;
  if (dealer)
    logo = (
      <Box className='dealer-logo'>
        <img src={dealer.logo} alt={dealer.title} />
      </Box>
    );

  return (
    <Box className='login-container'>
      {logo}
      <LoginForm />
      {/* <Box className='login-actions'>
                <Link component={RouterLink} to="/reset-password" variant="body2" underline="hover">
                    Forgot password?
                </Link>
                <Link component={RouterLink} to="#" variant="body2" underline="hover">
                    Don't have an account?
                </Link>
            </Box> */}
      <Copyright sx={{ mt: 3 }} disableLink />
    </Box>
  );
}

Login.propTypes = {};

Login.defaultProps = {};

export default Login;
