import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './defaultAlt.scss';

function DefaultAltAnimation({ color1, color2, ...props }) {

    return (
        <>
            <Box
                className='default-alt-animation-inner animation-root'
                sx={{
                    borderTopColor: color2,
                    borderBottomColor: color2,
                }}
                {...props}
            />
            <Box
                className='default-alt-animation-outer animation-root'
                sx={{
                    borderTopColor: color1,
                    borderBottomColor: color1,
                    boxShadow: `0 2px 1px ${color1}, 0 -2px 1px ${color1}`
                }}
                {...props}
            />
        </>
    );
}

DefaultAltAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

DefaultAltAnimation.defaultProps = {}

export default DefaultAltAnimation;
