import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Drawer, Grid, IconButton, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import EventNoteIcon from '@mui/icons-material/EventNote';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import DirectionsIcon from '@mui/icons-material/Directions';
import LogoutIcon from '@mui/icons-material/Logout';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import LayersIcon from '@mui/icons-material/Layers';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

import './menu.scss';
import MenuItem from './menuItem/menuItem';
import Copyright from '../copyright/copyright';
import Logo from '../logo/logo';
import ThemeModeField from '../forms/fields/themeMode/themeMode';
import FormDialog from '../crud/formDialog/formDialog';
import DispatchForm from '../dispatch/form/dispatchForm';
import Feedback from '../feedback/feedback';
import TipsAndTricks from '../tipsAndTricks/tipsAndTricks';
import featureService from '../../services/feature';
import {
  selectAppMenuVisible,
  selectAuthUserPermissions,
} from '../../store/selectors';
import { setMenuVisible, updateSidebar } from '../../store/slices/app';
import { getDealer } from '../../utilities/helpers';

function Menu() {
  const [showDispatch, setShowDispatch] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showTips, setShowTips] = useState(false);

  const dealer = useMemo(getDealer, []);
  const dispatch = useDispatch();
  const permissions = useSelector(selectAuthUserPermissions);
  const showMenu = useSelector(selectAppMenuVisible);

  const handleClick = (option) => () => {
    switch (option) {
      case 'dispatch':
        setShowDispatch(true);
        break;
      case 'feedback':
        setShowFeedback(true);
        break;
      case 'tips':
        setShowTips(true);
        break;
      default:
        dispatch(
          updateSidebar({
            show: true,
            option: option,
          })
        );
        dispatch(setMenuVisible(false));
    }
  };

  const hideMenu = (event) => {
    dispatch(setMenuVisible(false));
  };

  const handleDispatchClose = () => {
    setShowDispatch(false);
  };

  const handleFeedbackClose = (event) => {
    setShowFeedback(false);
  };

  const handleTipsClose = (event) => {
    setShowTips(false);
  };

  const menuComponents = [
    { divider: true, id: 1 },
    {
      label: 'Devices',
      value: 'devices',
      icon: LocalShippingIcon,
      permission: permissions.devices.view,
    },
    {
      label: 'Drivers',
      value: 'drivers',
      icon: PeopleAltIcon,
      permission: permissions.contacts.view,
    },
    {
      label: 'Geofences',
      value: 'geofences',
      icon: SelectAllIcon,
      permission: permissions.geofences.view,
    },
    {
      label: 'Addresses',
      value: 'addresses',
      icon: MapsHomeWorkIcon,
      permission: permissions.addresses.view,
    },
    {
      label: 'Routes',
      value: 'routes',
      icon: AltRouteIcon,
      permission: permissions.routes.view,
    },
    {
      label: 'Dispatch',
      value: 'dispatch',
      icon: DirectionsIcon,
      permission: permissions.dispatch.manage,
    },
    {
      label: 'Service Calls',
      value: 'serviceCalls',
      icon: PhoneInTalkIcon,
      permission: !permissions.serviceModule,
    },
    {
      label: 'Service Calls',
      value: 'serviceModuleCalls',
      icon: PhoneInTalkIcon,
      permission: !dealer && permissions.serviceModule === true,
    },
    { label: 'KMLs / KMZs', value: 'kmls', icon: LayersIcon },
    {
      label: 'Historical Playback',
      value: 'history',
      icon: EventNoteIcon,
      permission: permissions.historyPlayback,
    },
    { divider: true, id: 2 },
    { label: 'Management', to: '/manage', icon: DashboardIcon },
    {
      label: 'Service Module',
      to: '/service/calls',
      icon: HomeRepairServiceIcon,
      permission: false,
      // permission: permissions.serviceModule === true,
    },
    { divider: true, id: 3 },
    {
      label: 'Tips and Tricks',
      value: 'tips',
      icon: TipsAndUpdatesIcon,
      featureID: featureService.IDs.timeZone,
    },
    { label: 'Feedback', value: 'feedback', icon: FeedbackIcon },
    // { label: 'Language', icon: TranslateIcon, disabled: true },
    { label: 'Logout', to: '/logout', icon: LogoutIcon },
  ].map(({ divider, permission, value, ...item }) => {
    if (permission !== undefined && !permission) return null;
    else if (divider) return <Divider key={item.id} />;
    else
      return (
        <MenuItem
          key={item.value || item.label}
          onClick={value ? handleClick(value) : undefined}
          {...item}
        />
      );
  });

  return (
    <>
      <Drawer anchor='left' open={showMenu} onClose={hideMenu}>
        <Box role='presentation' className='map-menu'>
          <Box>
            {!dealer && (
              <Grid container p={2}>
                <Grid item xs={9}>
                  <Logo />
                </Grid>
                <Grid item xs sx={{ flexGrow: 1 }} />
                <Grid item xs>
                  <IconButton aria-label='close' onClick={hideMenu}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            <List>{menuComponents}</List>
          </Box>
          <Box>
            <Grid container p={2} spacing={2}>
              <Grid item xs={12} className='theme-mode-container'>
                <ThemeModeField />
              </Grid>
              <Grid item xs={12}>
                <Copyright />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {permissions.dispatch.manage && (
        <FormDialog
          open={showDispatch}
          onClose={handleDispatchClose}
          onSave={handleDispatchClose}
          form={DispatchForm}
          title='Dispatch'
        />
      )}
      <TipsAndTricks open={showTips} onClose={handleTipsClose} />
      <Feedback
        open={showFeedback}
        onClose={handleFeedbackClose}
        onSave={handleFeedbackClose}
      />
    </>
  );
}

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
