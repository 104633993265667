import { createElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import './sidebar.scss';
import { selectAppSidebar } from '../../store/selectors';
import { setMenuVisible, updateSidebar } from '../../store/slices/app';
import options from './options';

const sidebarWidth = 350;

function Sidebar() {
  const [minimized, setMinimized] = useState(true);

  const dispatch = useDispatch();
  const { show, option } = useSelector(selectAppSidebar);
  const cleanUpFunction = useRef(null);

  useEffect(() => {
    setMinimized(!Boolean(option));
  }, [option]);

  const showMenu = (event) => {
    dispatch(setMenuVisible(true));
  };

  const toggleHide = (event) => {
    setMinimized((prevValue) => !prevValue);
  };

  const handleClose = async (event) => {
    dispatch(updateSidebar({ show: false }));
    if (typeof cleanUpFunction.current === 'function')
      cleanUpFunction.current();
  };

  const handleAfterClose = async (event) => {
    if (!show) dispatch(updateSidebar({ option: null }));
  };

  const { component, title } = options[option] || {};

  return (
    <Drawer
      className='usft-sidebar'
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidebarWidth,
          visibility: show ? 'visible !important' : undefined,
        },
      }}
      variant='persistent'
      anchor='left'
      open={show && !minimized}
      SlideProps={{ onExited: handleAfterClose }}
    >
      <AppBar position='relative'>
        <Toolbar>
          <Tooltip title='Menu' enterDelay={300}>
            <IconButton
              onClick={showMenu}
              edge='start'
              color='inherit'
              aria-label='menu'
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography flexGrow={1} align='center' variant='button'>
            {title}
          </Typography>
          <IconButton
            onClick={handleClose}
            edge='end'
            color='inherit'
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Button variant='contained' className='handle' onClick={toggleHide}>
        <ArrowLeftIcon
          sx={{ transform: minimized ? 'rotate(180deg)' : undefined }}
        />
      </Button>
      <Box className='component-container'>
        {component && createElement(component, { cleanUpFunction })}
      </Box>
    </Drawer>
  );
}

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
