import { memo } from 'react';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { Box, styled } from '@mui/material';
import PropTypes from 'prop-types';

import './videoRequest.scss';
import EventMarker from '../eventMarker';
import VideoRequestDetails from './details/videoRequestDetails';

const VideoRequestIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '> svg': {
    fill: theme.palette.primary.contrastText,
  },
}));

function VideoRequestMarker({ event, ...props }) {
  return (
    <EventMarker
      title='Video Request'
      icon={
        <VideoRequestIcon className='event-marker-icon'>
          <VideoCameraBackIcon />
        </VideoRequestIcon>
      }
      details={<VideoRequestDetails event={event} />}
      {...props}
    />
  );
}

VideoRequestMarker.propTypes = {
  event: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

VideoRequestMarker.defaultProps = {};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.event.id === nextProps.event.id;
}

export default memo(VideoRequestMarker, arePropsEqual);
