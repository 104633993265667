import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './default.scss';

function DefaultAnimation({ color1, color2, ...props }) {

    return (
        <Box
            className='default-animation animation-root'
            sx={{ boxShadow: `0 8px 10px ${color1}, 0 -8px 10px ${color2}` }}
            {...props}
        />
    );
}

DefaultAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

DefaultAnimation.defaultProps = {}

export default DefaultAnimation;
