import { Badge, Box, Button, Paper, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import './image.scss';

// TODO replace with file input when available https://mui.com/material-ui/discover-more/roadmap/#new-components
// Currently using recommendation from https://mui.com/material-ui/react-button/#upload-button
function ImageField({
  name,
  label,
  rules,
  helperText,
  alt,
  width,
  height,
  circle,
  ...props
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      //   validate: fileValidation,
      ...rules,
    },
    defaultValue: null,
    shouldUnregister: true,
  });

  const clearImage = (event) => {
    event.stopPropagation();
    field.onChange(null);
  };

  const handleInputChange = (event) => {
    if (event.target.files) field.onChange(event.target.files);
  };

  const fieldID = `${name}-${uuidv4()}`;

  let url = null;
  if (field.value instanceof FileList && field.value.length > 0)
    url = URL.createObjectURL(field.value[0]);
  else if (typeof field.value === 'string' && field.value.length > 0)
    url = field.value;

  const clearButton = (
    <Button
      variant='contained'
      color='error'
      onClick={clearImage}
      className='clear-image-button'
    >
      <CloseIcon />
    </Button>
  );

  return (
    <Box className='image-input-container' height={height} width={width}>
      <label
        htmlFor={fieldID}
        className={props.disabled ? 'Mui-disabled' : undefined}
      >
        <input
          id={fieldID}
          type='file'
          onChange={handleInputChange}
          accept='.jpg,.jpeg,.png'
          {...props}
        />
        <Badge badgeContent={clearButton} invisible={!url}>
          <Paper
            className='preview'
            elevation={3}
            sx={{
              borderRadius: circle ? '50%' : '4px',
              width,
              height,
            }}
          >
            {url ? (
              <img src={url} alt={alt} width='100%' height='100%' />
            ) : (
              <Box className='preview-label'>
                <ImageIcon sx={{ width: '25%', height: '25%' }} />
                <Typography variant='button'>{label}</Typography>
              </Box>
            )}
            <Box className='preview-hover' />
            {url ? (
              <EditIcon className='edit-icon' />
            ) : (
              <AddIcon className='edit-icon' />
            )}
          </Paper>
        </Badge>
        {(error || helperText) && (
          <Typography color={error ? 'error' : undefined} mt={1}>
            {error?.message || helperText}
          </Typography>
        )}
      </label>
    </Box>
  );
}

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  helperText: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
};

ImageField.defaultProps = {};

export default ImageField;
