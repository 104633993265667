import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import FeedbackIcon from '@mui/icons-material/Feedback';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './accountMenu.scss';
import Feedback from '../../../components/feedback/feedback';
import { getDealer } from '../../../utilities/helpers';

function AccountMenu({ user, ...props }) {
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);

  const dealer = useMemo(getDealer, []);

  const accountOpen = Boolean(accountAnchorEl);

  const handleFeedbackClick = (event) => {
    setShowFeedback(true);
  };

  const handleFeedbackClose = (event) => {
    setShowFeedback(false);
  };

  const handleAccountClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };

  const avatar = (
    <Avatar src={user.logo}>
      {user.firstName ? user.firstName[0].toUpperCase() : 'A'}
    </Avatar>
  );

  return (
    <>
      <Tooltip title='Account'>
        <IconButton
          onClick={handleAccountClick}
          aria-controls='account-menu'
          aria-haspopup='true'
          aria-expanded={accountOpen ? 'true' : undefined}
        >
          {avatar}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={accountAnchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={accountOpen}
        onClose={handleAccountClose}
        onClick={handleAccountClose}
        className='account-menu'
      >
        <Box
          className='account-details'
          onClick={(event) => event.stopPropagation()}
        >
          <ListItemIcon>{avatar}</ListItemIcon>
          <ListItemText>
            <Typography>{`${user.firstName}${
              user.lastName ? ` ${user.lastName}` : ''
            }`}</Typography>
            <Typography variant='caption'>{user.email}</Typography>
          </ListItemText>
        </Box>
        <MenuItem component={Link} to='/account'>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to='/account/settings'>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        {!dealer && (
          <MenuItem
            component={Link}
            to='/account/users'
            disabled={!user.selfService}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText>Manage Users</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleFeedbackClick}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText>Feedback</ListItemText>
        </MenuItem>
        <MenuItem component={Link} to='/logout'>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
      <Feedback
        open={showFeedback}
        onClose={handleFeedbackClose}
        onSave={handleFeedbackClose}
      />
    </>
  );
}

AccountMenu.propTypes = {
  user: PropTypes.object.isRequired,
};

AccountMenu.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(AccountMenu);
