import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './deviceControlButton.scss';
import APIService from '../../../services/api';

function DeviceControlButton({ deviceID, command }) {
    const [loading, setLoading] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleOnClick = (commandID) => async (event) => {
        setLoading(true)
        APIService.post('devices/commands/send', { params: { deviceID: deviceID, commandID: commandID } })
            .then((res) => {
                enqueueSnackbar('Command sent successfully', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar('Unable to send command. Try again later.', { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <LoadingButton loading={loading} fullWidth variant='contained' p={3} onClick={handleOnClick(command.id)}>
            {command.name}
        </LoadingButton>
    );
};

DeviceControlButton.propTypes = {
    deviceID: PropTypes.number.isRequired,
    command: PropTypes.object.isRequired,
};

DeviceControlButton.defaultProps = {};

export default DeviceControlButton;
