import { DateTimePicker } from '@mui/x-date-pickers';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import './dateTime.scss';

function USFTDateTimeField({
  name,
  label,
  defaultValue,
  rules,
  helperText,
  textFieldProps,
  ...props
}) {
  let validateRules;
  if (typeof rules?.validate === 'function')
    validateRules = { custom: rules.validate };
  else if (typeof rules?.validate === 'object') validateRules = rules.validate;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      ...rules,
      validate: {
        isvalidDateTime: (value) => value.isValid || 'Invalid date/time',
        ...validateRules,
      },
    },
    defaultValue,
    shouldUnregister: true,
  });

  return (
    <DateTimePicker
      {...field}
      label={label && rules?.required ? `${label} *` : label}
      slotProps={{
        field: {
          fullWidth: true,
        },
      }}
      variant='outlined'
      error={error !== undefined}
      helperText={error?.message || helperText}
      {...props}
    />
  );
}

USFTDateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.object,
  rules: PropTypes.object,
  helperText: PropTypes.string,
  label: PropTypes.string,
};

USFTDateTimeField.defaultProps = {};

export default USFTDateTimeField;
