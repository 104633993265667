import APIService from './api';

/** Class for Tracking Analytics */
class AnalyticsService {
  track = async (action, label) => {
    console.debug('Tracking', action, label);
    if (process.env.NODE_ENV === 'production')
      return APIService.post('/users/analytics/track', { action, label })
        .then((response) => {
          console.debug('Analytics successfully sent');
        })
        .catch((error) => {
          console.warn('Unable to send analytics', error);
        });
  };
}

const analyticsService = new AnalyticsService();

export default analyticsService;
