import { lazy, Suspense } from 'react';
import { Dialog } from '@mui/material';
import PropTypes from 'prop-types';

import './tipsAndTricks.scss';
import USFTDialogTitle from '../dialog/dialogTitle/dialogTitle';
import TipsLoading from '../loading/tips/tipsLoading';

const Tips = lazy(() => import('./content/content'));

// swipable views had issues
// https://github.com/oliviertassinari/react-swipeable-views/issues/659
// TODO get tips from Hades with Markdown
function TipsAndTricks({ open, onClose, ...props }) {

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='md' className='tips-and-tricks' TransitionProps={{ unmountOnExit: true }}>
            <USFTDialogTitle title='Tips & Tricks' onClose={onClose} />
            <Suspense fallback={<TipsLoading />}>
                <Tips />
            </Suspense>
        </Dialog>
    );
}

TipsAndTricks.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

TipsAndTricks.defaultProps = {};

export default TipsAndTricks;