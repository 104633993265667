import DefaultAnimation from './default/default';
import PulseAnimation from './pulse/pulse';
import SpinAnimation from './spin/spin';
import WaveAnimation from './wave/wave';
import DoubleWaveAnimation from './waveDouble/waveDouble';
import DoublePulseAnimation from './pulseDouble/pulseDouble';
import DoubleSpinAnimation from './spinDouble/spinDouble';
import DefaultAltAnimation from './defaultAlt/defaultAlt';

export const alertAnimationMap = {
    0: DefaultAnimation,
    1: SpinAnimation,
    2: PulseAnimation,
    3: WaveAnimation,
    4: DoubleSpinAnimation,
    5: DoubleWaveAnimation,
    6: DoublePulseAnimation,
    7: DefaultAltAnimation,
};