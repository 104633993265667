import { createSlice } from '@reduxjs/toolkit';
import { appModes } from '../utility';

const initialState = {
  mode: appModes.map,
  showMenu: false,
  showLiveChat: false,
  sidebar: {
    show: false,
    option: null,
  },
  loading: true,
  preferences: {
    theme: {
      mode: 'auto',
      colors: {
        light: {
          primary: null,
          secondary: null,
        },
        dark: {
          primary: null,
          secondary: null,
        },
      },
    },
    dashboard: null,
    timeZone: null,
  },
  features: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAppMode: (state, action) => {
      state.mode = action.payload;
    },
    setThemeMode: (state, action) => {
      state.preferences.theme.mode = action.payload;
    },
    setMenuVisible: (state, action) => {
      state.showMenu = action.payload;
    },
    updateSidebar: (state, action) => {
      state.sidebar = {
        ...state.sidebar,
        ...action.payload,
      };
    },
    closeSidebar: (state, action) => {
      state.sidebar = initialState.sidebar;
    },
    setPreferredColors: (state, action) => {
      state.preferences.theme.colors = action.payload;
    },
    setPreferredTimeZone: (state, action) => {
      state.preferences.timeZone = action.payload;
    },
    setPreferences: (state, action) => {
      state.preferences = {
        theme: {
          mode:
            localStorage.getItem('themeMode') || state.preferences.theme.mode,
          colors: {
            light: {
              primary: action.payload.preferences.colors[0],
              secondary: action.payload.preferences.colors[1],
            },
            dark: {
              primary: action.payload.preferences.colors[2],
              secondary: action.payload.preferences.colors[3],
            },
          },
        },
        dashboard: action.payload.preferences.dashboard,
      };
    },
    updateDashboardPreferences: (state, action) => {
      state.preferences.dashboard = [...action.payload];
    },
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
  },
});

export const {
  setLoading,
  setAppMode,
  setThemeMode,
  setMenuVisible,
  updateSidebar,
  closeSidebar,
  setPreferredColors,
  setPreferredTimeZone,
  setPreferences,
  updateDashboardPreferences,
  setFeatures,
} = appSlice.actions;

export default appSlice.reducer;
