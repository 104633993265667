import { useState } from 'react';
import { Box, Divider, Grid, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import './dispatchForm.scss';
import APIService from '../../../services/api';
import { filterFormKeyPress } from '../../../utilities/helpers';
import { latitudeRules, longitudeRules } from '../../forms/fields/helpers';
import USFTSelect from '../../forms/fields/select/select';
import USFTCheckbox from '../../forms/fields/checkbox/checkbox';
import USFTAddressField from '../../forms/fields/address/address';
import USFTTextField from '../../forms/fields/text/text';
import DeviceField from '../../forms/fields/device/device';
import USFTCustomAddressField from '../../forms/fields/customAddress/customAddress';
import ContactField from '../../forms/fields/contact/contact';

const linkTypes = {
  device: 'Device Location',
  address: 'Address Marker',
  customAddress: 'Custom Address',
  customLatLng: 'Custom Lat/Long',
};

function DispatchForm(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasPresetDevice = Boolean(props.device);

  const form = useForm({
    defaultValues: {
      device: props.device
        ? { id: props.device.id, name: props.device.name }
        : null,
      includeLink: hasPresetDevice,
      linkType: linkTypes.device,
    },
  });
  const includeLink = form.watch('includeLink'); // Watch includeLink input since form is "uncontrolled"
  const linkType = form.watch('linkType');
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    if (!props.useCustomButton) setIsSubmitting(true);
    if (typeof props.onSubmit === 'function') props.onSubmit();
    return APIService.post(`/contacts/dispatch`, {
      ...data,
      contact: data.contact.details.id,
      useEmail: data.contact.useEmail,
      useSMS: data.contact.useSMS,
      message: data.message,
      device: data.device?.id,
      address: data.address?.id,
      latitude: data.latitude ? Number(data.latitude) : undefined,
      longitude: data.longitude ? Number(data.longitude) : undefined,
    })
      .then((response) => {
        enqueueSnackbar('Successfully dispatched message', {
          variant: 'success',
        });
        if (typeof props.onSave === 'function') props.onSave();
      })
      .catch((error) => {
        enqueueSnackbar('Unable to dispatch message. Try again later.', {
          variant: 'error',
        });
        if (!props.useCustomButton) setIsSubmitting(false);
        if (typeof props.onError === 'function') props.onError(error);
      });
  };

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        id={props.formID}
        onSubmit={form.handleSubmit(onSubmit)}
        onKeyPress={filterFormKeyPress}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <USFTTextField
              name='message'
              label='Message'
              multiline
              placeholder='A short, helpful dispatch message.'
            />
          </Grid>
          <Grid item xs={12}>
            <ContactField
              name='contact'
              label='Send To'
              rules={{
                required: 'Please select a contact',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <USFTCheckbox
              name='includeLink'
              label='Include a Google Maps location'
              inputProps={{
                disabled: hasPresetDevice,
              }}
            />
          </Grid>
          {includeLink && (
            <>
              <Grid item xs={12} sm={6}>
                <USFTSelect
                  label='Type'
                  name='linkType'
                  disabled={hasPresetDevice}
                >
                  {Object.entries(linkTypes).map(([value, label]) => (
                    <MenuItem value={label} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </USFTSelect>
              </Grid>
              {linkType === linkTypes.device && (
                <Grid item xs={12} sm={6}>
                  <DeviceField
                    name='device'
                    label='Select Device'
                    rules={{ required: 'Required' }}
                    disabled={hasPresetDevice}
                  />
                </Grid>
              )}
              {linkType === linkTypes.address && (
                <Grid item xs={12} sm={6}>
                  <USFTAddressField
                    name='address'
                    label='Select Address'
                    rules={{ required: 'Required' }}
                  />
                </Grid>
              )}
              {linkType === linkTypes.customAddress && (
                <Grid item xs={12} sm={6}>
                  <USFTCustomAddressField
                    name='customAddress'
                    label='Street Address'
                    rules={{ required: 'Required' }}
                  />
                </Grid>
              )}
              {linkType === linkTypes.customLatLng && (
                <>
                  <Grid item xs={false} sm></Grid>
                  <Grid item xs={12} sm={6}>
                    <USFTTextField
                      name='latitude'
                      label='Latitude'
                      rules={{
                        required: 'Required',
                        ...latitudeRules,
                      }}
                      type='number'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <USFTTextField
                      name='longitude'
                      label='Longitude'
                      rules={{
                        required: 'Required',
                        ...longitudeRules,
                      }}
                      type='number'
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
        {!props.useCustomButton && (
          <Box display='flex' flexDirection='row-reverse'>
            <LoadingButton
              loading={isSubmitting}
              variant='contained'
              color='success'
              type='submit'
            >
              Save
            </LoadingButton>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
}

DispatchForm.propTypes = {
  useCustomButton: PropTypes.bool.isRequired,
  device: PropTypes.object,
  onSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func,
};

DispatchForm.defaultProps = {
  useCustomButton: false,
};

export default DispatchForm;
