import { createElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MarkerLayer } from 'react-leaflet-marker';

import { selectMapEvents, selectMapPOI } from '../../../store/selectors';
import HistoryPointMarker from '../../markers/history/point/pointMarker';
import StopMarker from '../../markers/history/event/stop/stopMarker';
import AlertMarker from '../../markers/history/event/alert/alertMarker';
import VideoRequestMarker from '../../markers/history/event/videoRequest/videoRequest';

const eventMap = {
  videoRequest: VideoRequestMarker,
  alert: AlertMarker,
  stop: StopMarker,
  point: HistoryPointMarker,
};

const HistoryEventsLayer = () => {
  const events = useSelector(selectMapEvents);
  const poi = useSelector(selectMapPOI);

  const markers = useMemo(
    () =>
      Object.entries(events).map(([eventID, event]) =>
        createElement(eventMap[event.type], {
          key: event.id,
          lat: event.latitude,
          lng: event.longitude,
          event: event,
        })
      ),
    [events]
  );

  const activePoint = poi ? (
    createElement(eventMap[poi.type], {
      key: poi.id,
      event: poi,
      point: poi,
    })
  ) : (
    <></>
  );

  return (
    <MarkerLayer pane='markerPane'>
      {markers}
      {activePoint}
    </MarkerLayer>
  );
};

HistoryEventsLayer.propTypes = {};

HistoryEventsLayer.defaultProps = {};

export default HistoryEventsLayer;
