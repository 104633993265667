import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PropTypes from 'prop-types';

import './webSearch.scss';
import WebSearchDetails from './details/webSearchDetails';
import MarkerPopper from '../common/popper/markerPopper';
import { setWebSearch } from '../../../store/slices/map';

function WebSearchMarker({ searchResult }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const onCloseDetail = (event) => {
    dispatch(setWebSearch(null));
  };

  return (
    <Box className='web-search-marker-container'>
      <Box className='marker-icon-container'>
        <LocationOnIcon
          ref={setAnchorEl}
          tabIndex='0'
          className='marker-icon'
          color='primary'
        />
      </Box>
      <MarkerPopper
        open={true}
        onClose={onCloseDetail}
        title='Address Details'
        details={<WebSearchDetails address={{ ...searchResult, id: null }} />}
        anchor={anchorEl}
      />
    </Box>
  );
}

WebSearchMarker.propTypes = {
  searchResult: PropTypes.object.isRequired,
};

WebSearchMarker.defaultProps = {};

export default WebSearchMarker;
