import { useRef, useState } from 'react';
import {
  Fab,
  useTheme,
  useMediaQuery,
  Box,
  Drawer,
  Tooltip,
  Popover,
} from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';

import './mapControls.scss';
import MapSettings from './mapSettings/mapSettings';

function MapControls() {
  const [open, setOpen] = useState(false);

  const anchorEl = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDrawer = (event) => {
    setOpen((prevState) => !prevState);
  };

  const common = (
    <Box className='map-settings-container'>
      <MapSettings />
    </Box>
  );

  return (
    <>
      <Tooltip title='Map Settings' placement='bottom-end' enterDelay={500}>
        <Fab
          ref={anchorEl}
          className='map-controls-button'
          color='primary'
          aria-label='change layers'
          onClick={toggleDrawer}
        >
          <LayersIcon />
        </Fab>
      </Tooltip>
      {isMobile ? (
        <Drawer anchor='bottom' open={open} onClose={toggleDrawer}>
          {common}
        </Drawer>
      ) : (
        <Popover
          open={open}
          onClose={toggleDrawer}
          anchorEl={anchorEl.current}
          anchorOrigin={{
            vertical: 70,
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className='map-controls-popover'>{common}</Box>
        </Popover>
      )}
    </>
  );
}

MapControls.propTypes = {};

MapControls.defaultProps = {};

export default MapControls;
