import { useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import './liveVaporForm.scss';
import FormSection from '../../forms/section/section';
import { filterFormKeyPress } from '../../../utilities/helpers';
import { positiveWholeNumberRules } from '../../forms/fields/helpers';
import USFTTextField from '../../forms/fields/text/text';
import USFTSelect from '../../forms/fields/select/select';
import USFTCheckbox from '../../forms/fields/checkbox/checkbox';
import mapAPIService from '../../../services/map';

const fields = {
  range: 'range',
  mode: 'mode',
  direction: 'direction',
};
const vaporModes = {
  normal: 'Normal',
  activeInputs: 'Active Inputs',
};

function LiveVaporForm({ showLiveVapor, liveVaporSettings, ...props }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm({ defaultValues: liveVaporSettings });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    return mapAPIService
      .enableLiveVapor(data.mode, data.range, data.direction)
      .catch((e) => {
        enqueueSnackbar('Unable to enable Live Vapor. Try again later.', {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const disableLiveVapor = (event) => {
    return mapAPIService.disableLiveVapor();
  };

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        onSubmit={form.handleSubmit(onSubmit)}
        onKeyPress={filterFormKeyPress}
      >
        <FormSection>
          <Grid item xs={12}>
            <USFTTextField
              name={fields.range}
              label='Time Range (Hours)'
              type='number'
              rules={{
                required: true,
                ...positiveWholeNumberRules,
                max: { value: 24, message: 'Maximum is 24 hours' },
              }}
              helperText='Up to 24 Hours'
              disabled={isSubmitting || showLiveVapor}
            />
          </Grid>
          <Grid item xs={12}>
            <USFTSelect
              name={fields.mode}
              label='Vapor Mode'
              rules={{
                required: true,
              }}
              disabled={isSubmitting || showLiveVapor}
            >
              {Object.entries(vaporModes).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </USFTSelect>
          </Grid>
          <Grid item xs={12}>
            <USFTCheckbox
              name={fields.direction}
              label='Show Direction'
              disabled={isSubmitting || showLiveVapor}
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={showLiveVapor}
              exclusive
              aria-label='text alignment'
              fullWidth
            >
              <ToggleButton
                value={false}
                disabled={isSubmitting}
                onClick={disableLiveVapor}
              >
                OFF
              </ToggleButton>
              <ToggleButton value={true} disabled={isSubmitting} type='submit'>
                {isSubmitting ? <CircularProgress size={20} /> : 'ON'}
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </FormSection>
      </Box>
    </FormProvider>
  );
}

LiveVaporForm.propTypes = {};

LiveVaporForm.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    showLiveVapor: state.map.settings.liveVapor,
    liveVaporSettings: state.map.liveVaporSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveVaporForm);
