// import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Container, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './routes.scss';
// import mapAPIService from '../../../services/map';

function RoutesSidebar({ routes, followedMarker, canManage }) {
  // const form = useForm();

  // const filterRowsFunction = (query, route) =>
  //   route.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;

  // const handleChange = (routeID) => (event) => {
  //   if (!routes[routeID].visible)
  //     mapAPIService.showRoute(routeID, !followedMarker);
  //   else mapAPIService.hideRoute(routeID);
  // };

  // const handleAllChange = async (event) => {
  //   if (event.target.checked)
  //     Object.values(routes).forEach((route) =>
  //       mapAPIService.showRoute(route.id)
  //     );
  //   else
  //     Object.values(routes).forEach((route) =>
  //       mapAPIService.hideRoute(route.id)
  //     );
  // };

  return (
    <Container>
      <Typography mt={1}>NOT IMPLEMENTED</Typography>
      <Typography>
        Unabled to find usable plugin that is not outdated
      </Typography>
      <Link href='https://leafletjs.com/plugins.html#routing'>
        https://leafletjs.com/plugins.html#routing
      </Link>
    </Container>
    // <FormProvider {...form} >
    //     <SidebarListView
    //         rows={Object.values(routes)}
    //         getRowPrimaryText={(route) => route.name}
    //         filterRows={filterRowsFunction}
    //         sortRows={(a, b) => a.name.localeCompare(b.name)}
    //         emptyMessage='No Routes Found'
    //         canManage={canManage}
    //         manageURL={'manage/routes'}
    //         onChange={handleChange}
    //         onAllChange={handleAllChange}
    //     />
    // </FormProvider>
  );
}

RoutesSidebar.propTypes = {
  routes: PropTypes.object.isRequired,
  canManage: PropTypes.bool.isRequired,
  followedMarker: PropTypes.number,
};

RoutesSidebar.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    routes: state.map.routes,
    followedMarker: state.map.followedMarker,
    canManage: state.auth.user.permissions.routes.manage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesSidebar);
