import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './logout.scss';
import authService from '../../services/auth';
import mapAPIService from '../../services/map';

function Logout({ dealer }) {
  const navigate = useNavigate();

  useEffect(() => {
    mapAPIService
      .clearMapState()
      .then(authService.logout)
      .then(() => {
        let url = '/login';
        if (dealer) url = url + `?cid=${dealer}`;
        window.location = url;
      });
  }, [navigate, dealer]);

  return (
    <Box className='logout'>
      <Typography>Logging Out...</Typography>
    </Box>
  );
}

Logout.propTypes = {
  dealer: PropTypes.string,
};

Logout.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    dealer: state.auth.user?.dealer,
  };
};

export default connect(mapStateToProps)(Logout);
