import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './spinDouble.scss';

function DoubleSpinAnimation({ color1, color2, ...props }) {

    return (
        <>
            <Box
                className='double-spin-animation-outer animation-root'
                sx={{
                    borderTopColor: color1,
                    borderBottomColor: color1,
                    boxShadow: `0 2px 1px ${color1}, 0 -2px 1px ${color1}`
                }}
                {...props}
            />
            <Box
                className='double-spin-animation-inner animation-root'
                sx={{
                    borderLeftColor: color2,
                    borderRightColor: color2,
                    boxShadow: `0 0 0 2px ${color2}`
                }}
                {...props}
            />
        </>
    );
}

DoubleSpinAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

DoubleSpinAnimation.defaultProps = {}

export default DoubleSpinAnimation;
