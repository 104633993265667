import { forwardRef, useEffect, useState } from 'react';
import { Box, CircularProgress, IconButton, Stack } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CachedIcon from '@mui/icons-material/Cached';
import PropTypes from 'prop-types';

import './videoPlayer.scss';

/**
 * Checks if a video element has an audio track
 * @param {Element} video - The video element to check
 * @returns {Boolean}
 */
const hasAudio = (video) => {
    // console.debug('Checking if video has audio', video.webkitAudioDecodedByteCount, video.mozHasAudio)
    if (typeof video.webkitAudioDecodedByteCount !== "undefined")
        return video.webkitAudioDecodedByteCount > 0
    else if (typeof video.mozHasAudio !== "undefined")
        return video.mozHasAudio
    else
        console.debug("can't tell if video has audio")
    return false
}

function VideoPlayerFunction({ volume, onLoad, index, ...props }, ref) {
    const [isLoading, setIsLoading] = useState(true);
    const [audio, setAudio] = useState(false);
    const [muted, setMuted] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [rotation, setRotation] = useState(0);

    useEffect(() => {
        if (ref.current)
            ref.current.volume = volume;
        setMuted(volume === 0);
    }, [volume, ref]);

    useEffect(() => {
        if (ref.current === undefined)
            return;
        ref.current.onloadeddata = async () => {
            console.debug('Video has loaded');
            // buffer for video info to be populated otherwise hadAudio returns false
            await new Promise(r => setTimeout(r, 250));
            setAudio(hasAudio(ref.current));
            setIsLoading(false);
            if (typeof onLoad === 'function')
                onLoad(index);
        };
    }, [ref, onLoad, index]);

    const handleToggleMute = (event) => {
        setMuted((muted) => !muted);
    };

    const handleRotate = (event) => {
        setRotation(prevState => prevState ? 0 : 180);
    };

    const handleToggleFullscreen = (event) => {
        if (document.fullscreenElement !== ref.current.parentElement)
            ref.current.parentElement.requestFullscreen()
                .then(() => {
                    setFullscreen(true);
                })
                .catch(err => {
                    alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                });
        else {
            document.exitFullscreen();
            setFullscreen(false);
        }
    };

    return (
        <Box className='video-player'>
            {isLoading && (
                <Box className='video-loading'>
                    <CircularProgress color='inherit' />
                </Box>
            )}
            <video ref={ref} {...props} muted={muted} className='video' style={{ transform: `rotate(${rotation}deg)` }}>
                Your browser does not support the video tag.
            </video>
            {!props.controls && (
                <Box className='controls' sx={{ background: t => `linear-gradient(to bottom, transparent, 10%, ${t.palette.common.black})` }}>
                    <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-end'
                        sx={{
                            '> .MuiIconButton-root': { color: 'common.white' },
                            '> .Mui-disabled': { color: 'grey.700' }
                        }}
                    >
                        <IconButton onClick={handleToggleMute} disabled={!audio}>
                            {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                        </IconButton>
                        <IconButton onClick={handleRotate}>
                            <CachedIcon />
                        </IconButton>
                        <IconButton onClick={handleToggleFullscreen}>
                            {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                        </IconButton>
                    </Stack>
                </Box>
            )}
        </Box>
    );
};

const VideoPlayer = forwardRef(VideoPlayerFunction);

VideoPlayer.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onLoad: PropTypes.func,
    masterMuted: PropTypes.bool,
    type: PropTypes.string
};

VideoPlayer.defaultProps = {
    muted: false,
    width: '100%',
    height: '100%',
};

export default VideoPlayer;
