import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import './viewOnly.scss';
import Logo from '../../components/logo/logo';
import SearchBar from '../../components/search/search';
import ViewOnlyMenu from '../../components/menu/viewOnly/viewOnlyMenu';
import { setMenuVisible } from '../../store/slices/app';
import MapControls from '../../components/mapControls/mapControls';

function ViewOnlylayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setMenuVisible(false));
    };
  }, [dispatch]);

  return (
    <Box className='view-only-layout'>
      <Grid
        container
        spacing={2}
        p={2}
        direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        className='overlay'
      >
        <Grid item>
          <Grid container spacing={2} className='toolbar'>
            <Grid item xs={12} sm='auto' className='left visible'>
              <SearchBar />
            </Grid>
            <Grid item xs={12} sm='auto' className='right visible'>
              <MapControls />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} className='lower'>
            <Grid
              item
              xs={12}
              sm='auto'
              className='visible'
              sx={{ pointerEvents: 'none', ml: { xs: 0, sm: 6 } }}
            >
              <Logo className='watermark ' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Outlet />
      <ViewOnlyMenu />
    </Box>
  );
}

ViewOnlylayout.propTypes = {};

ViewOnlylayout.defaultProps = {};

export default ViewOnlylayout;
