import { useSnackbar } from 'notistack';
import { ListItemText, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import './device.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';

const multipleRenderOption = (props, option, { selected }) => (
  <li {...props} key={option.id}>
    <Checkbox style={{ marginRight: 8 }} checked={selected} />
    <ListItemText primary={option.name} secondary={option.serial} />
  </li>
);

const singleRenderOption = (props, option) => (
  <li {...props} key={option.id}>
    <ListItemText primary={option.name} secondary={option.serial} />
  </li>
);

function DeviceField({ filterOptions, filterFunction, ...props }) {
  const { enqueueSnackbar } = useSnackbar();

  const getOptions = async (event) => {
    return APIService.get('/devices/options', { params: filterOptions })
      .then((response) => {
        let vehicles = response.data.vehicles;
        if (typeof filterFunction === 'function')
          vehicles = vehicles.filter(filterFunction);
        return vehicles
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
              ...option,
              firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            };
          });
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Unable to get vehicle data. Try again later.', {
          variant: 'error',
        });
      });
  };

  const deviceFilter = (options, state) =>
    options.filter((option) =>
      (option.name.toLowerCase() + option.serial).includes(
        state.inputValue.toLowerCase()
      )
    );

  return (
    <USFTAutocomplete
      getOptions={getOptions}
      groupBy={(option) => option.firstLetter}
      filterOptions={deviceFilter}
      renderOption={props.multiple ? multipleRenderOption : singleRenderOption}
      {...props}
    />
  );
}

DeviceField.propTypes = {
  filterOptions: PropTypes.object,
  filterFunction: PropTypes.func,
};

DeviceField.defaultProps = {};

export default DeviceField;
