import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './addressGroup.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';
import { allAddressesOption } from '../helpers';

function AddressGroupField({ includeAll, ...props }) {
    const { enqueueSnackbar } = useSnackbar();

    const getOptions = async (event) => {
        return APIService.get('/addresses/groups/options')
            .then(response => {
                const options = response.data.sort((a, b) => a.name.localeCompare(b.name));
                if (includeAll)
                    options.splice(0, 0, allAddressesOption);
                return options;
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Unable to get Address Group data. Try again later.', { variant: 'error' });
            });
    };

    return (
        <USFTAutocomplete
            getOptions={getOptions}
            defaultValue={includeAll ? allAddressesOption : undefined}
            {...props}
        />
    );
};

AddressGroupField.propTypes = {
    includeAll: PropTypes.bool.isRequired,
};

AddressGroupField.defaultProps = {
    includeAll: false,
};

export default AddressGroupField;