import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/Clear';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import PropTypes from 'prop-types';

import './listView.scss';
import USFTTextField from '../../forms/fields/text/text';
import USFTCheckbox from '../../forms/fields/checkbox/checkbox';

const fields = {
  query: 'query',
  toggleAll: 'toggleAll',
};

// TODO update onAllChange to send visible rows for faster/more predictable filtering
function SidebarListView({
  rows,
  getRowPrimaryText,
  getRowSecondaryText,
  getRowExtra,
  filterRows,
  sortRows,
  emptyMessage,
  extraMessage,
  manageURL,
  onChange,
  onAllChange,
  formFields,
  canManage,
  ...props
}) {
  const { setValue, watch } = useFormContext();
  const query = watch(fields.query);

  let filteredRows = query
    ? rows.filter((row) => filterRows(query, row))
    : rows;

  if (typeof sortRows === 'function')
    filteredRows = filteredRows.sort(sortRows);

  const handleQueryClear = (event) => {
    setValue(fields.query, '');
  };

  const row = ({ index, style }) => {
    const row = filteredRows[index];
    return (
      <ListItem
        key={row.id}
        style={style}
        className='sidebar-row'
        component='div'
        dense={typeof getRowSecondaryText === 'function'}
        disablePadding
      >
        <ListItemButton
          role={undefined}
          onClick={onChange(row.id)}
          disabled={row.disabled || false}
        >
          <ListItemIcon>
            {row.visible ? (
              <VisibilityIcon color='primary' />
            ) : (
              <VisibilityOffIcon />
            )}
          </ListItemIcon>
          <ListItemText
            id={row.id}
            primary={getRowPrimaryText(row)}
            secondary={
              typeof getRowSecondaryText === 'function'
                ? getRowSecondaryText(row)
                : undefined
            }
            sx={{ overflowWrap: 'anywhere' }}
          />
          {typeof getRowExtra === 'function' ? getRowExtra(row) : null}
        </ListItemButton>
      </ListItem>
    );
  };

  const numOfVisibleDevices = filteredRows.filter(
    (row) => !row.disabled && row.visible
  ).length;
  const indeterminate =
    numOfVisibleDevices > 0 && numOfVisibleDevices !== filteredRows.length;

  return (
    <Box p={2} className='sidebar-list-view'>
      <Grid container spacing={2}>
        {formFields}
        <Grid item xs={12}>
          <USFTTextField
            name={fields.query}
            label='Search'
            className='sidebar-search'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' className='clear-sidebar-search'>
                  <ClearIcon onClick={handleQueryClear} />
                </InputAdornment>
              ),
              'data-lpignore': true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid item xs='auto'>
              <USFTCheckbox
                name={fields.toggleAll}
                label='Show All'
                checked={
                  numOfVisibleDevices > 0 &&
                  numOfVisibleDevices === filteredRows.length
                }
                indeterminate={indeterminate}
                color={indeterminate ? 'default' : 'primary'}
                onChange={onAllChange}
                disabled={filteredRows.length === 0}
              />
            </Grid>
            <Grid item xs='auto'>
              <Typography>
                {numOfVisibleDevices}/{filteredRows.length} Showing
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className='sidebar-list'>
        {filteredRows.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                itemCount={filteredRows.length}
                itemSize={typeof getRowSecondaryText === 'function' ? 60 : 48}
                width={width}
              >
                {row}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <>
            <Typography align='center'>{emptyMessage}</Typography>
            {extraMessage && (
              <Box className='extra-message'>{extraMessage}</Box>
            )}
          </>
        )}
      </Box>
      {manageURL && canManage && (
        <Box className='sidebar-manage-container'>
          <Button component={Link} variant='contained' to={manageURL}>
            Manage
          </Button>
        </Box>
      )}
    </Box>
  );
}

SidebarListView.propTypes = {
  rows: PropTypes.array.isRequired,
  getRowPrimaryText: PropTypes.func.isRequired,
  filterRows: PropTypes.func.isRequired,
  sortRows: PropTypes.func,
  emptyMessage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAllChange: PropTypes.func.isRequired,
  extraMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  canManage: PropTypes.bool,
  getRowSecondaryText: PropTypes.func,
  getRowExtra: PropTypes.func,
  formFields: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  manageURL: PropTypes.string,
};

SidebarListView.defaultProps = {
  canManage: false,
};

export default SidebarListView;
