import { Box, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './feedbackForm.scss';
import { filterFormKeyPress } from '../../../utilities/helpers';
import USFTRatingField from '../../forms/fields/rating/rating';
import FormSection from '../../forms/section/section';
import USFTTextField from '../../forms/fields/text/text';
import APIService from '../../../services/api';

const fields = {
    rating: 'rating',
    comments: 'comments'
}

function FeedbackForm(props) {

    const form = useForm();
    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async (data) => {
        if (typeof props.onSubmit === 'function')
            props.onSubmit();
        return APIService.post(`/users/feedback`, data)
            .then((response) => {
                enqueueSnackbar('Thanks for the feedback!', { variant: 'success' });
                if (typeof props.onSave === 'function')
                    props.onSave();
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 429:
                        enqueueSnackbar('Too much feedback sent. Please wait a while and try again', { variant: 'warning' });
                        break;
                    default:
                        enqueueSnackbar('Something went wrong. Try again later.', { variant: 'error' });
                }
                if (typeof props.onError === 'function')
                    props.onError(error);
            });
    };

    return (
        <FormProvider {...form} >
            <Box component='form' id={props.formID} onSubmit={form.handleSubmit(onSubmit)} onKeyPress={filterFormKeyPress}>
                <FormSection>
                    <Grid item xs={12} display='flex' justifyContent='center'>
                        <USFTRatingField
                            name={fields.rating}
                            defaultValue={0}
                            rules={{
                                required: 'Required',
                                min: { value: 1, message: 'Please give us a rating' },
                            }}
                            size='large'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <USFTTextField
                            name={fields.comments}
                            label='Comments'
                            placeholder='Give it to us straight!'
                            multiline
                            minRows={3}
                            maxRows={9}
                            rules={{
                                maxLength: { value: 1024, message: "That's too much feedback!" },
                            }}
                        />
                    </Grid>
                </FormSection>
            </Box>
        </FormProvider>
    );
};

FeedbackForm.propTypes = {
    formID: PropTypes.string,
    onSubmit: PropTypes.func,
    onSave: PropTypes.func,
    onError: PropTypes.func,
};

FeedbackForm.defaultProps = {};

export default FeedbackForm;
