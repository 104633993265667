import { forwardRef, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  TextField,
} from '@mui/material';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import './autocomplete.scss';
import { VirtualizedListboxComponent, StyledPopper } from './virtualization';
import { useMountedRef } from '../../../../utilities/helpers';

const multipleRenderOption = (props, option, { selected }) => (
  <li {...props} key={option.id}>
    <Checkbox style={{ marginRight: 8 }} checked={selected} />
    {option.name}
  </li>
);

function USFTAutocomplete({
  name,
  label,
  defaultValue,
  rules,
  helperText,
  options,
  optionsRef,
  getOptions,
  groupBy,
  disableCache,
  forceGroups,
  controllerProps,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(options || []);
  const [isFocused, setIsFocused] = useState(false);

  const isMounted = useMountedRef();
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    defaultValue: defaultValue
      ? defaultValue
      : props.multiple
      ? []
      : defaultValue,
    rules,
    shouldUnregister: true,
    ...controllerProps,
  });

  useEffect(() => {
    if (optionsRef) optionsRef.current = [currentOptions, setCurrentOptions];
  }, [currentOptions, optionsRef]);

  const handleOpen = async (event) => {
    if (
      typeof getOptions === 'function' &&
      !loading &&
      (disableCache || currentOptions.length === 0)
    ) {
      setLoading(true);
      getOptions()
        .then((newOptions) => {
          if (isMounted.current) {
            if (newOptions) setCurrentOptions(newOptions);
          }
        })
        .finally(() => {
          if (isMounted.current) setLoading(false);
        });
    }
  };

  const handleFocus = async (event) => {
    setIsFocused(true);
  };

  const handleBlur = async (event) => {
    setIsFocused(false);
  };

  const renderScrollableTags = (value, getTagProps) => (
    <Box className='autocomplete-overflow'>
      {value.map((option, index) => (
        <Chip {...getTagProps({ index })} key={index} label={option.name} />
      ))}
    </Box>
  );

  const handleSelectAll = (event) => {
    const options =
      field.value.length === currentOptions.length ? [] : currentOptions;
    props.onChange ? props.onChange(options) : onChange(options);
  };

  let listboxComponent = 'ul';

  // Needs Virtualization?
  let vProps = {};
  if (currentOptions.length > 1000) {
    listboxComponent = props.multiple
      ? forwardRef(({ children, ...props }, ref) => (
          <VirtualizedListboxComponent {...props} ref={ref}>
            {children.toSpliced(
              0,
              0,
              <li
                key='select-all'
                className='MuiAutocomplete-option'
                onClick={handleSelectAll}
              >
                <Checkbox
                  indeterminate={
                    !!field.value.length &&
                    field.value.length !== currentOptions.length
                  }
                  checked={field.value.length === currentOptions.length}
                  style={{ marginRight: 8 }}
                />
                Select All
              </li>,
              <Divider key='select-all-divider' />
            )}
          </VirtualizedListboxComponent>
        ))
      : VirtualizedListboxComponent;
    vProps = {
      disableListWrap: true,
      PopperComponent: StyledPopper,
      renderGroup: (params) => params,
    };
  } else if (props.multiple) {
    listboxComponent = forwardRef(({ children, ...props }, ref) => (
      <ul {...props} ref={ref}>
        <li className='MuiAutocomplete-option' onClick={handleSelectAll}>
          <Checkbox
            indeterminate={
              !!field.value.length &&
              field.value.length !== currentOptions.length
            }
            checked={field.value.length === currentOptions.length}
            style={{ marginRight: 8 }}
          />
          Select All
        </li>
        <Divider />
        {children}
      </ul>
    ));
  }

  return (
    <Autocomplete
      {...field}
      options={currentOptions}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(event, data) => onChange(data)}
      onOpen={handleOpen}
      onFocus={handleFocus}
      onBlur={handleBlur}
      getOptionLabel={(option) => option.name}
      groupBy={forceGroups || currentOptions.length > 15 ? groupBy : undefined}
      renderOption={props.multiple ? multipleRenderOption : undefined}
      renderTags={isFocused ? renderScrollableTags : undefined}
      ListboxComponent={listboxComponent}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant='outlined'
          error={error !== undefined}
          helperText={error?.message || helperText}
          label={label && rules?.required ? `${label} *` : label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...vProps}
      {...props}
    />
  );
}

USFTAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disableCache: PropTypes.bool.isRequired,
  forceGroups: PropTypes.bool.isRequired,
  options: PropTypes.array,
  getOptions: PropTypes.func,
  groupBy: PropTypes.func,
  rules: PropTypes.object,
  controllerProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  helperText: PropTypes.string,
};

USFTAutocomplete.defaultProps = {
  options: null,
  defaultValue: null,
  disableCache: false,
  forceGroups: false,
};

export default USFTAutocomplete;
