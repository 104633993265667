import { useState } from 'react';
import { Box, ButtonBase, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './fancyCheckbox.scss';
import { useMountedRef } from '../../../../utilities/helpers';

// TODO give better name
function FancyCheckbox({ onClick, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useMountedRef();

    const handleClick = async (event) => {
        setIsLoading(true);
        await onClick();
        if (isMounted.current)
            setIsLoading(false);
    };

    return (
        <ButtonBase onClick={handleClick} disabled={isLoading} className='fancy-button' sx={{ p: 1 }} disableRipple>
            <Box className='icon-border' sx={{ borderColor: props.checked ? (t) => t.palette.primary.main : 'transparent' }}>
                <Box className='icon-container'>
                    {isLoading && (
                        <Box className='loading-container'>
                            <CircularProgress className='loading' color="inherit" />
                        </Box>
                    )}
                    <img className='icon' alt={props.label} src={props.icon} />
                </Box>
            </Box>
            <Typography mt={1}>{props.label}</Typography>
        </ButtonBase >
    );
}

FancyCheckbox.propTypes = {
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};

FancyCheckbox.defaultProps = {};

export default FancyCheckbox;
