import L from 'leaflet';

import { sleep } from '../utilities/helpers';
import APIService, { GlobalAPIService } from './api';

/** Weather Layer */
// TODO look into Baron weather
class WeatherLayer {
  // Argon
  NEXRAD_URL = `https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0q.cgi`;
  NEXRAD_LAYER = `nexrad-n0q-900913`;

  // Velocity
  product = 'C39-0x0302-0';
  // This configuration splits the different types of precipitation
  // into rain/mixed/snow instead of having it all merged as one
  configuration = 'Mask1-Mercator';
  apiKey = process.env.REACT_APP_WEATHER_API_KEY;
  apiURI = process.env.REACT_APP_WEATHER_API_URI;

  numberOfFrames = 10; // Number of frames in the loop we'll receive, each frame is spaced by about 2.5 minutes of time
  signature = null;
  loopSpeed = 1000;
  layerOpacity = 0.6;
  events = {
    frameUpdate: 'frameUpdate',
    ready: 'ready',
  };

  constructor() {
    console.debug('building weather layer');
    this.fetchInterval = null;
    this.frameInterval = null;
    this.frameIndex = 0;
    this.frames = [];
    this.eventTarget = new EventTarget();
    this.enabled = false;
    this.ready = false;

    this.buildVelocity();
  }

  buildVelocity = async () => {
    await this.updateSignature();
    const response = await GlobalAPIService.get(
      `${this.apiURI}/${this.apiKey}/meta/tiles/product-instances/${this.product}/${this.configuration}.jsonp?${this.signature}&page_size=${this.numberOfFrames}&callback=?`
    );
    // Strip off bad JSON data, parses it and reverses it so oldest data is first
    this.frames = JSON.parse(response.data.slice(2).slice(0, -2))
      .reverse()
      .map((frame) => {
        const layer = L.tileLayer(
          `${this.apiURI}/${this.apiKey}/tms/1.0.0/${this.product}+${this.configuration}+${frame.time}/{z}/{x}/{y}.png?${this.signature}`,
          {
            minZoom: 4,
            maxNativeZoom: 10,
            tileSize: 256,
            opacity: 0,
            tms: true,
            updateWhenIdle: true,
            updateWhenZooming: false,
            className: 'weather',
          }
        );
        return {
          timestamp: frame.time,
          layer: layer,
        };
      });

    this.ready = true;
    this.eventTarget.dispatchEvent(new CustomEvent(this.events.ready));
  };

  onReady = async () => {
    if (this.ready) return;
    return new Promise((resolve, reject) => {
      const callback = () => {
        console.log('layers ready');
        this.eventTarget.removeEventListener(this.events.ready, callback);
        this.ready = true;
        resolve();
      };
      this.eventTarget.addEventListener(this.events.ready, callback);
    });
  };

  addTo = (map) => {
    this.frames.forEach((frame, index) => {
      frame.layer.addTo(map);
      frame.layer.setOpacity(index === 0 ? this.layerOpacity : 0);
    });

    // this.fetchInterval = setInterval(this.refreshData, 300000); // 5 minutes
    this.frameInterval = setInterval(this.advanceFrame, this.loopSpeed);
    console.log('frameInt', this.frameInterval);

    return this;
  };

  removeFrom = (map) => {
    console.log('removeFrom', map);
    this.frames.forEach((frame, index) => {
      frame.layer.removeFrom(map);
    });
    console.log('frameInt', this.frameInterval);
    // clearInterval(this.fetchInterval);
    clearInterval(this.frameInterval);
    return this;
  };

  remove = () => {
    this.frames.forEach((frame, index) => {
      frame.layer.remove();
    });
    // clearInterval(this.fetchInterval);
    clearInterval(this.frameInterval);
    return this;
  };

  // _getURL = () =>
  //   `${this.apiURI}/${this.apiKey}/tms/1.0.0/${this.product}+${
  //     this.configuration
  //   }+${this.frames[this.frameIndex].time}/{z}/{x}/{y}.png?${this.signature}`;

  _buildLayers = async () => {
    if (!this.map) throw new Error('Service has not been assigned map');
    this.layers.forEach((layer) => {
      layer.remove();
    });
    console.debug('Building Weather layers');
    this.layers = this.frames.map((frame, index) => {
      return L.tileLayer(
        `${this.apiURI}/${this.apiKey}/tms/1.0.0/${this.product}+${this.configuration}+${frame.time}/{z}/{x}/{y}.png?${this.signature}`,
        {
          minZoom: 4,
          maxNativeZoom: 10,
          tileSize: 256,
          // opacity: 0,
          opacity: index === 0 ? this.layerOpacity : 0,
          tms: true,
          updateWhenIdle: true,
          updateWhenZooming: false,
          className: 'weather',
        }
      ).addTo(this.map);
    });
    // this.layers.forEach((layer, index) =>
    //   setTimeout(() => layer.addTo(this.map), index * this.loopSpeed)
    // );
  };

  advanceFrame = async () => {
    const prevIndex = Number(this.frameIndex);
    if (this.frameIndex < this.frames.length - 1) this.frameIndex++;
    else {
      clearInterval(this.frameInterval);
      await sleep(2000);
      this.frameIndex = 0;
      this.frameInterval = setInterval(this.advanceFrame, this.loopSpeed);
    }

    // const bufferIndex =
    //   this.frameIndex < this.frames.length - 1 ? this.frameIndex + 1 : 0;

    this.eventTarget.dispatchEvent(
      new CustomEvent(this.events.frameUpdate, {
        detail: {
          index: this.frameIndex,
          timestamp: this.frames[this.frameIndex].timestamp,
        },
      })
    );

    // this.layer.setUrl(this._getURL());

    console.log('advance frame', this.frames[this.frameIndex].timestamp);
    this.frames[this.frameIndex].layer.setOpacity(this.layerOpacity);
    this.frames[prevIndex].layer.setOpacity(0);

    // console.log(
    //   'advance',
    //   prevIndex,
    //   this.frameIndex,
    //   bufferIndex,
    //   this.layers
    // );
    // this.layers[this.frameIndex].setOpacity(this.layerOpacity);
    // if (this.frameIndex !== prevIndex)
    //   this.layers[prevIndex].remove().setOpacity(0);
    // if (this.frameIndex !== bufferIndex)
    //   this.layers[bufferIndex].addTo(this.map);
  };

  // /** Fetches the signature for the weather API. */
  updateSignature = async () => {
    console.debug('Updating Weather signature');
    const response = await APIService.get('map/weather/signature');
    this.signature = response.data;
  };

  // /** Fetches current frames for animation. */
  // fetchFrames = async () => {
  //   console.debug('Updating Weather frames');
  //   const response = await GlobalAPIService.get(
  //     `${this.apiURI}/${this.apiKey}/meta/tiles/product-instances/${this.product}/${this.configuration}.jsonp?${this.signature}&page_size=${this.numberOfFrames}&callback=?`
  //   );
  //   // Strip off bad JSON data, parses it and reverses it so oldest data is first
  //   this.frames = JSON.parse(response.data.slice(2).slice(0, -2)).reverse();
  //   this.frameIndex = 0;
  // };

  // refreshData = async () => {
  //   await this.updateSignature();
  //   await this.fetchFrames();
  //   await this._buildLayers();
  // };

  // /** Enables the weather layer */
  // enable = async (map) => {
  //   if (this.enabled) {
  //     console.warn('Weather Service already enabled');
  //     return;
  //   }
  //   this.map = map;

  //   await this.refreshData();

  //   // this.layer.setUrl(this._getURL());
  //   // this.layer.addTo(this.map);

  //   // this.layers[0].addTo(this.map);
  //   // this.layers[0].addTo(this.map);

  //   this.fetchInterval = setInterval(this.refreshData, 300000); // 5 minutes
  //   this.frameInterval = setInterval(this.advanceFrame, this.loopSpeed);

  //   this.enabled = true;
  // };

  // /** Disables the weather layer */
  // disable = async () => {
  //   this.frames = [];
  //   this.frameIndex = 0;
  //   clearInterval(this.fetchInterval);
  //   clearInterval(this.frameInterval);
  //   // this.layer?.remove();
  //   this.layers.forEach((layer) => {
  //     layer.remove();
  //   });
  //   this.layers = [];
  //   this.enabled = false;
  // };

  addEventListener = (...args) => {
    this.eventTarget.addEventListener(...args);
  };

  removeEventListener = (...args) => {
    this.eventTarget.removeEventListener(...args);
  };
}

export default WeatherLayer;
