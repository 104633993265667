import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

function MapTilerLayer(props) {
  const map = useMap();

  useEffect(() => {
    console.log('MAPQUEST', process.env.REACT_APP_MAPQUEST_KEY);
    if (window.L.mapquest) {
      const baseLayer = window.L.mapquest.tileLayer('map');
      map.addLayer(baseLayer);

      return () => {
        map.removeLayer(baseLayer);
      };
    }
    // eslint-disable-next-line
  }, [map, window.L.mapquest]);

  return null;
}

MapTilerLayer.propTypes = {};

MapTilerLayer.defaultProps = {};

export default MapTilerLayer;
