import { useState } from 'react';
import { Button, Dialog, DialogContent, Grow } from '@mui/material';
import PropTypes from 'prop-types';

import './cameraHOC.scss';
import USFTDialogTitle from '../../../../dialog/dialogTitle/dialogTitle';
import LiveView from '../../../../liveView/liveView';

function CameraHOC({ device, offline }) {
    const [showLiveVideo, setShowLiveVideo] = useState(false);

    const handleClick = async (e) => {
        setShowLiveVideo(true)
    }

    const onClose = async (e) => {
        setShowLiveVideo(false)
    }

    return (
        <>
            <Button variant='contained' onClick={handleClick} disabled={offline}>
                Live View
            </Button>
            <Dialog
                open={showLiveVideo}
                TransitionComponent={Grow}
                onClose={onClose}
                fullWidth
                maxWidth='md'
            >
                <USFTDialogTitle title='Live View' onClose={onClose} />
                <DialogContent dividers>
                    <LiveView device={device} />
                </DialogContent>
            </Dialog>
        </>
    );
}

CameraHOC.propTypes = {
    device: PropTypes.object.isRequired,
    offline: PropTypes.bool.isRequired,
}

CameraHOC.defaultProps = {}

export default CameraHOC;
