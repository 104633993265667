import { useCallback, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import {
  useTheme,
  Paper,
  Box,
  Grid,
  Typography,
  styled,
  IconButton,
  Avatar,
  Tooltip,
  useMediaQuery,
  Drawer,
  Popper,
  Fab,
  Badge,
  Grow,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import './chat.scss';
import newMessage from '../../static/audio/newMessage.mp3';
import supportLogo from '../../static/images/logo/chat_logo.jpg';
import { capitalizeFirstLetter } from '../../utilities/_algorithms';
import LiveChatInput from './chatInput/chatInput';
import titleService from '../../services/title';
import { socketManager } from '../../services/socket';

const LiveChatHeader = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
}));

const LiveChatLogs = styled(Box)(({ theme }) => {
  if (theme.palette.mode === 'light')
    return {
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(1),
      boxShadow: `inset 0 11px 8px -10px ${theme.palette.grey[400]}, inset 0 -11px 8px -10px ${theme.palette.grey[400]}`,
    };
  else
    return {
      backgroundColor: theme.palette.grey[800],
      padding: theme.spacing(1),
      boxShadow: `inset 0 11px 8px -10px ${theme.palette.grey[900]}, inset 0 -11px 8px -10px ${theme.palette.grey[900]}`,
    };
});

const newMessageAudio = new Audio(newMessage);
newMessageAudio.volume = 0.5;

// TODO add new message line
// "new messages" FAB like Slack
// react-window for chat
// OR!!!! use a pre-built solution
function LiveChat({ user, token, streetViewActive, ...props }) {
  const [logs, setLogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [unread, setUnread] = useState(0);

  const theme = useTheme();
  const anchorEl = useRef(null);
  const logsRef = useRef(null);
  const socket = useRef(null);
  const isOpen = useRef(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const addMessage = useCallback(
    (data) => {
      const fData = {
        ...data,
        createdAt: DateTime.fromISO(data.createdAt),
      };
      setLogs((prevLogs) => {
        let prevSections = prevLogs.slice(0, -1) || [];
        let lastSection = prevLogs.slice(-1)[0] || [];
        if (lastSection.length > 0) {
          if (lastSection[0].author === fData.author)
            return [...prevSections, [...lastSection, fData]];
          return [...prevSections, lastSection, [fData]];
        } else return [...prevSections, [fData]];
      });
      if (!isOpen.current) {
        setUnread((prev) => prev + 1);
        newMessageAudio.play();
      }
      logsRef.current.scrollTop = logsRef.current?.scrollHeight || 0;
    },
    [isOpen]
  );

  useEffect(() => {
    console.debug('Setting up Chat Socket');
    const _socket = socketManager.socket('/chat', {
      auth: { token },
    });
    _socket.on('message', addMessage);
    _socket.connect();
    socket.current = _socket;

    return () => {
      console.debug('Tearing down Chat Socket');
      _socket.off('message', addMessage);
      _socket.disconnect();
    };
  }, [token, socket, addMessage]);

  useEffect(() => {
    if (open) {
      setUnread(0);
      logsRef.current.scrollTop = logsRef.current.scrollHeight;
    }
  }, [open]);

  useEffect(() => {
    if (unread > 0 && !document.hasFocus()) {
      let title;
      if (unread === 1) title = 'NEW MESSAGE';
      else title = `${unread} NEW MESSAGES`;
      titleService.cycle(title);
      return () => {
        titleService.reset();
      };
    }
  }, [unread]);

  useEffect(() => {
    if (streetViewActive) {
      setOpen(false);
      isOpen.current = false;
    }
  }, [streetViewActive]);

  const toggleChat = (event) => {
    setOpen((prevState) => {
      if (logs.length > 0)
        socket.current.emit(
          'message',
          `*** User has ${prevState ? 'minimized' : 'opened'} the window. ***`
        );
      isOpen.current = !prevState;
      return !prevState;
    });
  };

  const sendMessage = (message) => {
    if (message.length > 0) socket.current.emit('message', message, addMessage);
  };

  const downloadChat = (event) => {
    const blobString = logs
      .flat()
      .map(
        (log) =>
          `[${log.createdAt.toLocaleString(
            DateTime.DATETIME_FULL_WITH_SECONDS
          )}] ${
            log.author === 'user'
              ? user.username
              : capitalizeFirstLetter(log.author)
          }: ${log.text}\n`
      );
    const blob = new Blob(blobString, { type: 'text/plain' });
    fileDownload(
      blob,
      `${DateTime.now()
        .toFormat('yyyy_MM_dd')
        .toLocaleString()}_usft_chat_log.txt`,
      'text/plain'
    );
  };

  const generateChatLog = useCallback(
    (logSection, index) => {
      let avatarAlt, avatarSrc, chatColor, chatBackgroundColor, flexDirection;
      if (logSection[0].author === 'support') {
        avatarAlt = 'Support';
        avatarSrc = supportLogo;
      } else {
        avatarAlt = 'User';
        avatarSrc = user.logo;
        flexDirection = 'row-reverse';
        chatColor = theme.palette.secondary.contrastText;
        chatBackgroundColor = theme.palette.primary.main;
      }

      return (
        <Grid
          item
          xs={12}
          key={`section-${index}`}
          className='chat-log-section'
        >
          <Grid container flexDirection={flexDirection}>
            <Grid item xs={2} className='avatar'>
              <Avatar alt={avatarAlt} src={avatarSrc} />
            </Grid>
            <Grid item xs={8}>
              {logSection.map((log) => (
                <Paper
                  key={log.id}
                  elevation={2}
                  className='chat-log'
                  sx={{
                    backgroundColor: chatBackgroundColor,
                    color: chatColor,
                  }}
                >
                  <Typography>{log.text}</Typography>
                  <Typography variant='caption' align='right'>
                    {log.createdAt.toLocaleString(DateTime.TIME_SIMPLE)}
                  </Typography>
                </Paper>
              ))}
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>
      );
    },
    [theme, user.logo]
  );

  const chat = (
    <Box className='live-chat'>
      <LiveChatHeader container>
        <Grid item xs className='live-chat-title'>
          <Typography variant='button' align='justify'>
            Live Chat
          </Typography>
        </Grid>
        <Grid item xs='auto'>
          <Tooltip title='Download Chat' placement='bottom-end'>
            <span>
              <IconButton
                color='inherit'
                onClick={downloadChat}
                disabled={logs.length === 0}
              >
                <DownloadIcon />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton color='inherit' onClick={toggleChat}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </LiveChatHeader>
      <LiveChatLogs ref={logsRef} className='chat-logs'>
        <Grid container>
          {logs.length === 0 && (
            <Grid item xs={12}>
              <Typography className='welcome-message'>
                Thank you for contacting our Live Support department.
                <br />
                Office hours are 7 a.m. to 7 p.m. Central Time, Monday through
                Friday, and 9 a.m. to 4 p.m. on Saturdays.
                <br />
                How may we help you today?
              </Typography>
            </Grid>
          )}
          {logs.map(generateChatLog)}
        </Grid>
      </LiveChatLogs>
      <LiveChatInput onSubmit={sendMessage} />
    </Box>
  );

  const id = open ? 'liveChat' : undefined;

  if (streetViewActive) return null;

  return (
    <Box className='live-chat-container'>
      <Tooltip title='Live Chat' placement='top-end' enterDelay={500}>
        <Badge
          badgeContent={unread}
          color='primary'
          overlap='circular'
          max={99}
        >
          <Fab
            ref={anchorEl}
            className='chat-button'
            color='secondary'
            aria-label='Live Chat'
            onClick={toggleChat}
            {...props}
          >
            <ChatIcon />
          </Fab>
        </Badge>
      </Tooltip>
      {isMobile ? (
        <Drawer
          anchor='bottom'
          open={open}
          onClose={toggleChat}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box className='live-chat-mobile'>{chat}</Box>
        </Drawer>
      ) : (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl.current}
          className='live-chat-popover'
          placement='top'
          keepMounted
          transition
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: 'viewport',
                padding: 8,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={250}>
              <Paper elevation={3} className='live-chat-paper'>
                {chat}
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Box>
  );
}

LiveChat.propTypes = {
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  streetViewActive: PropTypes.bool.isRequired,
};

LiveChat.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    streetViewActive: state.map.streetViewActive,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
