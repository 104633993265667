import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './oneTime.scss';
import authService from '../../../services/auth';
import AppLoading from '../../loading/app/app';
import { setLoading } from '../../../store/slices/app';

function OnetimeLogin() {
  const dispatch = useDispatch();
  let { loginID, username, token } = useParams();

  useEffect(() => {
    authService.oneTimeLogin({ loginID, username, token }).then(() => {
      return dispatch(setLoading(false));
    });
  }, [loginID, username, token, dispatch]);

  return <AppLoading />;
}

OnetimeLogin.propTypes = {};

OnetimeLogin.defaultProps = {};

export default OnetimeLogin;
