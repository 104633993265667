import { lazy, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import './App.scss';
import Login from './components/login/login';
import BasicLayout from './layouts/basic/basicLayout';
import SplitLayout from './layouts/split/split';
import MapLayout from './layouts/map/map';
import ViewOnlyLayout from './layouts/viewOnly/viewOnly';
import ServiceModuleLayout from './layouts/serviceModule/serviceModule';
import ThemeManager from './components/themeManager/themeManager';
import Map from './components/map/map';
import Logout from './components/logout/logout';
import Chat from './components/chat/chat';
import {
  setLVfavicon,
  dealers,
  getDealer,
  handleAppUnload,
  usePageViews,
} from './utilities/helpers';
import { useTimeZoneHook } from './utilities/timeZones';
import NormalLogin from './components/login/normal/normal';
import OnetimeLogin from './components/login/oneTime/oneTime';
import SharedViewLogin from './components/login/sharedView/sharedView';
import DemoLogin from './components/login/demo/demo';
import ResetPasswordLogin from './components/login/resetPassword/resetPassword';
import FriendlyMessage from './components/friendlyMessage/friendlyMessage';
import USFTSnackbarProvider from './components/snackbarProvider/snackbarProvider';
import Playground from './components/playground/playground';

const ClientInfo = lazy(() => import('./components/clientInfo/clientInfo'));
const AccountManagement = lazy(() => import('./components/account/account'));
const Addresses = lazy(() => import('./components/addresses/addresses'));
const AddressGroups = lazy(() =>
  import('./components/addressGroups/addressGroups')
);
const Alerts = lazy(() => import('./components/alerts/alerts'));
const Contacts = lazy(() => import('./components/contacts/contacts'));
// const Dashboard = lazy(() => import('./components/dashboard/dashboard'));
const DataManagement = lazy(() =>
  import('./components/dataManagement/dataManagement')
);
const Devices = lazy(() => import('./components/devices/devices'));
const DeviceGroups = lazy(() =>
  import('./components/deviceGroups/deviceGroups')
);
const DeviceMaintenance = lazy(() =>
  import('./components/deviceMaintenance/deviceMaintenance')
);
const DriverScorecards = lazy(() =>
  import('./components/driverScorecards/driverScorecards')
);
const FOBs = lazy(() => import('./components/fobs/fobs'));
const EmailSignature = lazy(() =>
  import('./components/emailSignature/emailSignature')
);
const KMLs = lazy(() => import('./components/kmls/kmls'));
const ManagementHome = lazy(() => import('./components/management/management'));
const NotificationsPOC = lazy(() =>
  import('./components/notifications/poc/notificationsPOC')
);
const Reports = lazy(() => import('./components/reports/reports'));
const ReportSchedules = lazy(() =>
  import('./components/reportSchedules/reportSchedules')
);
const ReportWizard = lazy(() =>
  import('./components/reports/wizard/reportWizard')
);
const RequestVideos = lazy(() =>
  import('./components/requestVideos/requestVideos')
);
const USFTRoutes = lazy(() => import('./components/routes/routes'));
const ResetPassword = lazy(() =>
  import('./components/resetPassword/resetPassword')
);
// const RequestPasswordReset = lazy(() => import('./components/resetPassword/request/requestPasswordReset'));

// Service Module
const ServiceConfig = lazy(() =>
  import('./components/serviceModule/config/config')
);
const ServiceCalls = lazy(() =>
  import('./components/serviceModule/callScreen/callScreen')
);
const ServiceJobDetail = lazy(() =>
  import('./components/serviceModule/jobs/detail/jobDetail')
);
const EstimateDetail = lazy(() =>
  import('./components/serviceModule/estimates/detail/estimateDetail')
);
const InvoiceDetail = lazy(() =>
  import('./components/serviceModule/invoices/detail/invoiceDetail')
);
const JobCalendar = lazy(() =>
  import('./components/serviceModule/jobCalendar/jobCalendar')
);
const TechCalendar = lazy(() =>
  import('./components/serviceModule/techCalendar/techCalendar')
);
const Payments = lazy(() =>
  import('./components/serviceModule/payments/payments')
);
const Clients = lazy(() =>
  import('./components/serviceModule/clients/clients')
);
const Dispatch = lazy(() =>
  import('./components/serviceModule/dispatch/dispatch')
);
const AccountingBatching = lazy(() =>
  import('./components/serviceModule/accounting/batching/batching')
);
const AccountsReceivable = lazy(() =>
  import('./components/serviceModule/accounting/accountsReceivable/accounts')
);
const ClientDetail = lazy(() =>
  import('./components/serviceModule/clients/detail/clientDetail')
);

// TODO make seperate sockets service
// TODO FOR THE LOVE OF GOD IMPLEMENT TESTS!!!!!
// TODO localization awareness
// TODO consider custom logging package
// TODO adjust imports for tree shaking -- Maybe already does?
// TODO setup ARIA https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA
// TODO Add autocomplete attributes https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
// TODO build out Pauseable intervals / timeouts https://gist.github.com/ncou/3a0a1f89c8e22416d0d607f621a948a9
// TODO make sure Proptypes are extensive https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
// TODO Upgrade https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
function App({ user, isAuthenticated, isLoading, timeZoneID, viewOnly }) {
  let [searchParams] = useSearchParams();
  usePageViews(user);
  useTimeZoneHook(timeZoneID);

  const dealer = useMemo(getDealer, []);

  useEffect(() => {
    if (user) {
      window.addEventListener('beforeunload', handleAppUnload);
    }
  }, [user]);

  useEffect(() => {
    if (dealer?.id === dealers.LiveViewGPS) {
      setLVfavicon();
    }
  }, [dealer]);

  let routes;
  const redirect = searchParams.get('redirect');

  if (isLoading)
    routes = (
      <Routes>
        <Route path='/reset/:loginID/:token' element={<ResetPasswordLogin />} />
        <Route
          path='/onetime/:loginID/:username/:token'
          element={<OnetimeLogin />}
        />
        <Route path='/view/:token' element={<SharedViewLogin />} />
        <Route path='/demo' element={<DemoLogin />} />
        <Route path='*' element={<NormalLogin />} />
      </Routes>
    );
  else if (!isAuthenticated) {
    routes = (
      <Routes>
        <Route path='/' element={<SplitLayout />}>
          <Route index element={<Navigate to='login' replace />} />
          <Route path='login' element={<Login />} />
          <Route path='reset/:loginID/:token' element={<ResetPassword />} />
          {/* <Route path="reset-password" element={<RequestPasswordReset />} /> */}
        </Route>
        <Route path='*' element={<Navigate to='login' replace />} />
      </Routes>
    );
  } else if (viewOnly) {
    routes = (
      <>
        <Routes>
          <Route path='/view/:token' element={<ViewOnlyLayout />}>
            <Route index element={<Map />} />
          </Route>
          <Route path='logout' element={<Logout />} />
        </Routes>
      </>
    );
  } else if (redirect) routes = <Navigate to={redirect} replace />;
  else {
    const managementRoutes = [
      { permission: true, index: true, element: <ManagementHome /> },
      // { permission: true, path: 'dashboard', element: <Dashboard /> },
      {
        permission: true,
        path: 'notifications/test',
        element: <NotificationsPOC />,
      },
      {
        permission: user.permissions.videos,
        path: 'video-requests',
        element: <RequestVideos />,
      },
      {
        permission: !dealer && user.admin,
        path: 'data',
        element: <DataManagement />,
      },
      {
        permission: user.permissions.addresses.manage,
        path: 'addresses',
        element: <Addresses />,
      },
      {
        permission: user.permissions.addresses.manage,
        path: 'addresses/groups',
        element: <AddressGroups />,
      },
      {
        permission: user.permissions.alerts.manage,
        path: 'alerts',
        element: <Alerts />,
      },
      {
        permission: user.permissions.contacts.manage,
        path: 'contacts',
        element: <Contacts />,
      },
      {
        permission: user.permissions.devices.manage,
        path: 'devices',
        element: <Devices />,
      },
      {
        permission: user.permissions.devices.manage,
        path: 'devices/groups',
        element: <DeviceGroups />,
      },
      {
        permission: user.permissions.devices.manage,
        path: 'devices/maintenance',
        element: <DeviceMaintenance />,
      },
      { permission: user.admin, path: 'fobs', element: <FOBs /> },
      { permission: !dealer && user.admin, path: 'kmls', element: <KMLs /> },
      {
        permission: user.permissions.reports.manage,
        path: 'reports',
        element: <Reports />,
      },
      {
        permission: user.permissions.reports.manage,
        path: 'reports/run',
        element: <ReportWizard />,
      },
      {
        permission: user.permissions.reports.manage,
        path: 'reports/schedules',
        element: <ReportSchedules />,
      },
      {
        permission: user.permissions.reports.manage,
        path: 'scorecards',
        element: <DriverScorecards />,
      },
      {
        permission: user.permissions.routes.manage,
        path: 'routes',
        element: <USFTRoutes />,
      },
      {
        permission: true,
        path: 'email/generate',
        element: <EmailSignature />,
      },
    ]
      .filter((route) => route.permission)
      .map(({ permission, ...route }) => (
        <Route key={route.path || 'index'} {...route} />
      ));

    const serviceModuleRoutes = [
      {
        permission: user.serviceModuleAdmin,
        path: 'config',
        element: <ServiceConfig />,
      },
      {
        permission: user.serviceModuleAdmin || user.serviceModuleDispatch,
        path: 'calls',
        element: <ServiceCalls />,
      },
      {
        permission: user.serviceModule,
        path: 'jobs/:jobID',
        element: <ServiceJobDetail />,
      },
      {
        permission: user.serviceModule,
        path: 'estimates/:estimateID',
        element: <EstimateDetail />,
      },
      {
        permission: user.serviceModuleAdmin || user.serviceModuleDispatch,
        path: 'calendars/job',
        element: <JobCalendar />,
      },
      {
        permission: user.serviceModuleAdmin || user.serviceModuleDispatch,
        path: 'calendars/tech',
        element: <TechCalendar />,
      },
      {
        permission: user.serviceModuleAdmin || user.serviceModuleDispatch,
        path: 'dispatch',
        element: <Dispatch />,
      },
      {
        permission: user.serviceModuleAdmin,
        path: 'accounting/accounts-receivable',
        element: <AccountsReceivable />,
      },
      {
        permission: user.serviceModuleAdmin,
        path: 'accounting/invoicing/batching',
        element: <AccountingBatching />,
      },
      {
        permission: user.serviceModuleAdmin,
        path: 'accounting/payments',
        element: <Payments />,
      },
      {
        permission: user.serviceModule,
        path: 'invoices/:invoiceID',
        element: <InvoiceDetail />,
      },
      {
        permission: user.serviceModuleAdmin,
        path: 'clients/',
        element: <Clients />,
      },
      {
        permission: user.serviceModule,
        path: 'clients/:clientID',
        element: <ClientDetail />,
      },
    ]
      .filter((route) => route.permission)
      .map(({ permission, ...route }) => (
        <Route key={route.path || 'index'} {...route} />
      ));

    routes = (
      <>
        <Routes>
          <Route path='/' element={<MapLayout />}>
            <Route index element={<Map />} />
          </Route>
          <Route path='manage' element={<BasicLayout />}>
            {managementRoutes}
            <Route index path='client-info' element={<ClientInfo />} />
            <Route path='*' element={<Navigate to='' replace />} />
          </Route>
          <Route path='service' element={<ServiceModuleLayout />}>
            {serviceModuleRoutes}
            <Route path='*' element={<Navigate to='calls' replace />} />
          </Route>
          <Route path='account' element={<BasicLayout />}>
            <Route index path='*' element={<AccountManagement />} />
          </Route>
          <Route path='internal/playground' element={<Playground />} />
          <Route path='logout' element={<Logout />} />
          <Route path='*' element={<Navigate to='' replace />} />
        </Routes>
        <Chat />
      </>
    );
  }
  return (
    <ThemeManager>
      <USFTSnackbarProvider>{routes}</USFTSnackbarProvider>
      {user && user.messages?.friendly && (
        <FriendlyMessage message={user.messages.friendly} />
      )}
    </ThemeManager>
  );
}

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

App.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    isLoading: state.app.loading,
    timeZoneID: state.app.preferences.timeZone,
    user: state.auth.user,
    viewOnly: state.auth.viewOnly,
  };
};

export default connect(mapStateToProps)(App);
