import APIService from '../../services/api';
import mapAPIService from '../../services/map';
import store from '../../store';
import {
  setActiveDetailPopUp,
  setAddressFilters,
  setDeviceFilters,
  setDeviceTags,
  setWebSearch,
  updateAddress,
  updateDevice,
} from '../../store/slices/map';
import { allAddressesOption, statusAllDevices } from '../forms/fields/helpers';
import { allDevicesOption } from '../forms/fields/helpers';

const maxSize = 3;
const zoomLevel = mapAPIService.maxClusterZoom + 1;

const _deviceAction = async (device) => {
  return Promise.all([
    mapAPIService.disableAutoZoom(),
    mapAPIService.unfollow(),
    store.dispatch(
      updateDevice({ id: device.id, properties: { visible: true } })
    ),
    store.dispatch(
      setDeviceFilters({
        filters: { group: allDevicesOption, status: statusAllDevices },
        updateVisibility: false,
      })
    ),
    mapAPIService.center({
      latitude: device.location.latitude,
      longitude: device.location.longitude,
      zoomLevel,
    }),
    store.dispatch(setActiveDetailPopUp(device.id)),
  ]).catch((error) => {
    console.error(error);
  });
};

const deviceAction = (deviceID) => async (event) => {
  const device = store.getState().map.devices[deviceID];
  return _deviceAction(device);
};

const driverAction = (driver) => async (event) => {
  const device = Object.values(store.getState().map.devices).find(
    (device) => device.driverID === driver.id
  );
  return _deviceAction(device);
};

const addressAction = (address) => async (event) => {
  return Promise.all([
    mapAPIService.disableAutoZoom(),
    mapAPIService.unfollow(),
    store.dispatch(
      updateAddress({ id: address.id, properties: { visible: true } })
    ),
    store.dispatch(
      setAddressFilters({
        filters: { group: allAddressesOption },
        updateVisibility: false,
      })
    ),
    mapAPIService.center({
      latitude: address.latitude,
      longitude: address.longitude,
      zoomLevel,
    }),
    store.dispatch(setActiveDetailPopUp(address.id)),
  ]);
};

const geofenceAction = (geofence) => async (event) => {
  await mapAPIService.disableAutoZoom();
  return mapAPIService.showGeofence(geofence.id, true);
};

const webSearchAction = (place) => async (event) => {
  const latitude = place.geometry.location.lat();
  const longitude = place.geometry.location.lng();
  return Promise.all([
    mapAPIService.disableAutoZoom(),
    mapAPIService.unfollow(),
    store.dispatch(
      setWebSearch({
        id: place.place_id,
        name: place.name,
        address: place.formatted_address,
        latitude,
        longitude,
      })
    ),
    mapAPIService.center({ latitude, longitude, zoomLevel }),
  ]);
};

// TODO look into better algorithm
export const search = async (searchText) => {
  console.debug('Searching', searchText);
  let state = store.getState();

  if (Object.values(state.map.devices)[0].searchTags === undefined)
    await APIService.get('/devices/search/tags').then(async (response) => {
      await store.dispatch(setDeviceTags(response.data));
      state = store.getState();
    });

  const { user, viewOnly } = state.auth;
  const searchRegex = new RegExp(searchText.trim(), 'i');
  let devices = [],
    drivers = [],
    addresses = [],
    geofences = [],
    pages = [];

  if (viewOnly || user.permissions.devices.view) {
    const _devices = Object.values(state.map.devices).filter(
      (device) =>
        (
          device.name +
          device.serial +
          device.searchTags.map((tag) => tag.Value).join('')
        ).search(searchRegex) >= 0
    );

    devices = [
      _devices.slice(0, maxSize).map((device) => ({
        id: device.id,
        label: `${device.name} (${device.serial})`,
        tags: device.searchTags
          .map((tag) => (tag.Value.search(searchRegex) >= 0 ? tag : null))
          .filter((tag) => tag),
        action: deviceAction(device.id),
      })),
      _devices.length,
    ];
  }

  if (viewOnly)
    return {
      devices,
      drivers,
      addresses,
      geofences,
      pages,
    };

  if (user.permissions.contacts.view) {
    const assignedDrivers = Object.values(state.map.devices).map(
      (device) => device.driverID
    );
    const _drivers = Object.values(state.map.drivers).filter(
      (driver) => driver.name.search(searchRegex) >= 0
    );

    drivers = [
      _drivers.slice(0, maxSize).map((driver) => ({
        id: driver.id,
        label: driver.name,
        action: driverAction(driver),
        disabled: !assignedDrivers.includes(driver.id),
      })),
      _drivers.length,
    ];
  }

  if (user.permissions.addresses.view) {
    const _addresses = Object.values(state.map.addresses).filter(
      (address) =>
        (address.name + (address.address || '')).search(searchRegex) >= 0
    );

    addresses = [
      _addresses.slice(0, maxSize).map((address) => ({
        id: address.id,
        label: address.name,
        action: addressAction(address),
      })),
      _addresses.length,
    ];
  }

  if (user.permissions.geofences.view) {
    const _geofences = Object.values(state.map.geofences).filter(
      (fence) => fence.name.search(searchRegex) >= 0
    );

    geofences = [
      _geofences.slice(0, maxSize).map((fence) => ({
        id: fence.id,
        label: fence.name,
        action: geofenceAction(fence),
      })),
      _geofences.length,
    ];
  }

  const _pages = [
    {
      id: 1,
      label: 'Addresses',
      to: '/manage/addresses',
      show: user.permissions.addresses.manage,
    },
    {
      id: 2,
      label: 'Alerts',
      to: '/manage/alerts',
      show: user.permissions.alerts.manage,
    },
    {
      id: 3,
      label: 'Contacts',
      to: '/manage/contacts',
      show: user.permissions.contacts.manage,
    },
    { id: 4, label: 'KMLs / KMZs', to: '/manage/KMLs', show: user.admin },
    {
      id: 5,
      label: 'Devices',
      to: '/manage/devices',
      show: user.permissions.devices.manage,
    },
    {
      id: 6,
      label: 'Device Groups',
      to: '/manage/devices/groups',
      show: user.permissions.devices.manage,
    },
    {
      id: 7,
      label: 'Maintenance',
      to: '/manage/devices/maintenance',
      show: user.permissions.devices.manage,
    },
    {
      id: 8,
      label: 'Reports',
      to: '/manage/reports',
      show: user.permissions.reports.manage,
    },
    {
      id: 9,
      label: 'Routing',
      to: '/manage/routes',
      show: user.permissions.routes.manage,
    },
    { id: 10, label: 'Account', to: '/account', show: true },
  ].filter((page) => page.show && page.label.search(searchRegex) >= 0);

  pages = [
    _pages.slice(0, maxSize).map((page) => ({
      id: page.id,
      label: page.label,
      to: page.to,
    })),
    _pages.length,
  ];

  return {
    devices,
    drivers,
    addresses,
    geofences,
    pages,
  };
};

export const webSearch = async (searchText) => {
  return mapAPIService
    .searchPlaces(searchText)
    .then((places) => {
      return places.slice(0, maxSize).map((place) => ({
        id: place.place_id,
        label: place.name || place.formatted_address,
        action: webSearchAction(place),
      }));
    })
    .catch((error) => []);
};
