import { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Slider, Typography, Paper, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PropTypes from 'prop-types';

import './videoPlayback.scss';
import VideoPlayer from './videoPlayer/videoPlayer';
import { formatDuration } from '../../utilities/_algorithms';
import VolumeControl from './volumeControl/volumeControl';

function VideoPlayback({ device, urls, ...props }) {
    const [playing, setPlaying] = useState(false);
    const [masterVolume, setMasterVolume] = useState(1);
    const [masterFullscreen, setMasterFullscreen] = useState(false);
    const [playPosition, setPlayPosition] = useState(0);
    const [videoRefs] = useState([...Array(urls?.length)].map(() => createRef()));
    const [videoLoadStatuses, setVideoLoadStatuses] = useState([...Array(urls?.length)].map(() => false));
    const masterRef = useRef();

    const { enqueueSnackbar } = useSnackbar();

    const proxyPlayer = videoRefs[0].current;
    const isLoading = !videoLoadStatuses.every(loaded => loaded);

    useEffect(() => {
        if (proxyPlayer) {
            proxyPlayer.ontimeupdate = () => {
                setPlayPosition(Math.floor(proxyPlayer.currentTime))
            }
            proxyPlayer.onended = () => {
                setPlaying(false)
            }
        }
    }, [proxyPlayer]);

    const onVideoLoad = useCallback((videoIndex) => {
        setVideoLoadStatuses((oldStatuses) => {
            oldStatuses[videoIndex] = true
            return [...oldStatuses]
        })
    }, []);

    const togglePlaying = (event) => {
        videoRefs.forEach(playing ? async (ref) => ref.current.pause() : async (ref) => ref.current.play());
        setPlaying((prev) => !prev);
    };

    const handleToggleMute = (event) => {
        setMasterVolume(volume => volume ? 0 : 1);
    };

    const handleVolumeChange = (event, newValue) => {
        setMasterVolume(newValue);
    };

    const handleToggleFullscreen = (event) => {
        if (!document.fullscreenElement)
            masterRef.current.parentElement.requestFullscreen()
                .then(() => {
                    setMasterFullscreen(true);
                })
                .catch(err => {
                    enqueueSnackbar(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`, { variant: 'error' });
                });
        else {
            document.exitFullscreen();
            setMasterFullscreen(false);
        }
    };

    const handleSeek = (e, value) => {
        if (value !== playPosition) {
            console.debug('Seeking', value);
            setPlayPosition(value);
            videoRefs.forEach((ref) => {
                ref.current.currentTime = value
            });
        }
    };

    return (
        <Box ref={masterRef} className='video-playback'>
            <Grid container spacing={1}>
                {urls.map((url, index) => (
                    <Grid item xs={12} sm={urls.length === 1 ? 12 : 6} key={index}>
                        <VideoPlayer
                            src={url}
                            type="video/mp4"
                            volume={masterVolume}
                            ref={videoRefs[index]}
                            index={index}
                            onLoad={onVideoLoad}
                        />
                    </Grid>
                ))}
            </Grid>
            <Paper elevation={3} sx={{ mt: 1, p: 1 }}>
                <Grid container>
                    <Grid item xs={12} md={true}>
                        <Stack
                            spacing={1}
                            direction='row'
                            alignItems='center'
                        >
                            <IconButton onClick={togglePlaying} disabled={isLoading}>
                                {playing ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <Typography ml={1}>
                                {isLoading ? '--:--' : formatDuration(proxyPlayer.currentTime)}
                            </Typography>
                        </Stack>
                    </Grid>
                    {device?.name && (
                        <Grid item md={6} display={{ xs: 'none', md: 'flex' }} justifyContent='center' alignItems='center'>
                            <Typography noWrap>
                                {device.name}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} md={true}>
                        <Stack
                            spacing={1}
                            direction='row'
                            alignItems='center'
                            justifyContent='flex-end'
                        >
                            <VolumeControl
                                onChange={handleVolumeChange}
                                onMuteToggle={handleToggleMute}
                                volume={masterVolume}
                                disabled={isLoading}
                            />
                            <IconButton onClick={handleToggleFullscreen}>
                                {masterFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                            </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
                <Box p={2} display='flex' alignItems='center'>
                    <Slider
                        defaultValue={0}
                        min={0}
                        max={proxyPlayer?.duration ? proxyPlayer.duration : 0}
                        step={1}
                        value={playPosition}
                        onChange={handleSeek}
                        disabled={isLoading}
                        getAriaLabel={() => 'Group Video Slider'}
                    />
                </Box>
            </Paper>
        </Box>
    );
};

VideoPlayback.propTypes = {
    device: PropTypes.object,
    urls: PropTypes.arrayOf(PropTypes.string),
    muted: PropTypes.bool,
};

VideoPlayback.defaultProps = {};

export default VideoPlayback;
