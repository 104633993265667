import { memo } from 'react';
import { alpha } from '@mui/material';
import PropTypes from 'prop-types';

import './addressCluster.scss';
import ClusterMarker from '../clusterMarker';

function AddressClusterMarker(props) {

    const innerProps = {
        color: 'primary.contrastText',
        bgcolor: t => alpha(t.palette.primary.main, 0.8),
    };

    return (
        <ClusterMarker innerProps={innerProps} {...props} />
    );
};

AddressClusterMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    cluster: PropTypes.object.isRequired,
    supercluster: PropTypes.object.isRequired,
};

AddressClusterMarker.defaultProps = {};

const arePropsEqual = (prevProps, nextProps) => {
    return (
        prevProps.lat === nextProps.lat &&
        prevProps.lng === nextProps.lng &&
        prevProps.cluster.properties.point_count === nextProps.cluster.properties.point_count
    );
};

export default memo(AddressClusterMarker, arePropsEqual);