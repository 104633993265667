import { configureStore } from '@reduxjs/toolkit';

import appReducer from './slices/app';
import authReducer from './slices/auth';
import mapReducer from './slices/map';
import historyReducer from './slices/historyPlayback';

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    map: mapReducer,
    history: historyReducer,
  },
});

export default store;
