import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './waveDouble.scss';

function DoubleWaveAnimation({ color1, color2, ...props }) {
    return (
        <>
            <Box
                className='double-wave-animation animation-root'
                sx={{ borderColor: color1 }}
                {...props}
            />
            <Box
                className='double-wave-animation animation-root'
                sx={{ animationDelay: '.25s', borderColor: color2 }}
                {...props}
            />
        </>
    );
}

DoubleWaveAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

DoubleWaveAnimation.defaultProps = {}

export default DoubleWaveAnimation;
