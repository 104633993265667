import { memo } from 'react';
import { Box, styled } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import PropTypes from 'prop-types';

import './alertMarker.scss';
import EventMarker from '../eventMarker';
import AlertDetails from './details/alertDetails';

const AlertIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  '> svg': {
    fill: theme.palette.error.contrastText,
  },
}));

function AlertMarker({ event, ...props }) {
  let icon =
    event.urls.length > 0 ? (
      <VideoCameraBackIcon />
    ) : (
      <PriorityHighIcon sx={{ fontSize: '32px' }} />
    );

  return (
    <EventMarker
      title={`${event.alertType} Alert`}
      icon={<AlertIcon className='event-marker-icon'>{icon}</AlertIcon>}
      details={<AlertDetails event={event} />}
      {...props}
    />
  );
}

AlertMarker.propTypes = {
  event: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

AlertMarker.defaultProps = {};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.event.id === nextProps.event.id;
}

export default memo(AlertMarker, arePropsEqual);
