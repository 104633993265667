import { useSnackbar } from 'notistack';

import './addressIcon.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';
import IconOption from '../options/iconOption/iconOption';

function AddressIconField(props) {
    const { enqueueSnackbar } = useSnackbar();

    const getOptions = async (event) => {
        return APIService.get('/addresses/icons')
            .then(response => response.data.sort((a, b) => a.name.localeCompare(b.name)))
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Unable to get icon data. Try again later.', { variant: 'error' });
            });
    };

    return (
        <USFTAutocomplete
            getOptions={getOptions}
            renderOption={(props, option) => <IconOption {...props} option={option} />}
            {...props}
        />
    );
};

AddressIconField.propTypes = {};

AddressIconField.defaultProps = {};

export default AddressIconField;