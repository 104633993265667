import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import './index.scss';
import store from './store';
import reportWebVitals from './reportWebVitals';
import ThemeManager from './components/themeManager/themeManager';
import USFTSnackbarProvider from './components/snackbarProvider/snackbarProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeManager>
            <USFTSnackbarProvider>
              <App />
            </USFTSnackbarProvider>
          </ThemeManager>
        </BrowserRouter>
      </Provider>
    </LocalizationProvider>
  </StrictMode>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
