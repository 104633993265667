import { useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, useMap } from 'react-leaflet';
import MapTilerLayer from '../map/layers/tile';

function TrafficLayer() {
  const map = useMap();

  console.debug('Render Traffic Layer', window.L, L, map);

  useEffect(() => {
    const trafficLayer = window.L.mapquest.trafficLayer();
    // const incidentsLayer = window.L.mapquest.incidentsLayer();
    map.addLayer(trafficLayer);
    // map.addLayer(incidentsLayer);

    return () => {
      map.removeLayer(trafficLayer);
      // map.removeLayer(incidentsLayer);
    };
  }, [map]);

  return null;
}

function Playground(props) {
  console.debug('Render Traffic Layer', window.L.version, L.version);

  // useEffect(() => {
  //   var map = window.L.mapquest.map('map', {
  //     center: [34.0522, -118.2437],
  //     layers: window.L.mapquest.tileLayer('map'),
  //     zoom: 12,
  //   });

  //   map.addLayer(window.L.mapquest.trafficLayer());
  //   map.addLayer(window.L.mapquest.incidentsLayer());

  //   return () => {
  //     map.remove();
  //   };
  // }, []);

  // useEffect(() => {
  //   var map = window.L.map('map', {
  //     center: [34.0522, -118.2437],
  //     layers: window.L.mapquest.tileLayer('map'),
  //     zoom: 14,
  //   });

  //   map.addLayer(window.L.mapquest.trafficLayer());
  //   map.addLayer(window.L.mapquest.incidentsLayer());

  //   return () => {
  //     map.remove();
  //   };
  // }, []);

  // return <div id='map'></div>;
  return (
    <MapContainer center={[34.0522, -118.2437]} zoom={14}>
      <MapTilerLayer />
      <TrafficLayer />
    </MapContainer>
  );
}

Playground.propTypes = {};

Playground.defaultProps = {};

export default Playground;
