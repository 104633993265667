import { useCallback, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import useSupercluster from 'use-supercluster';
import { shallowEqual, useSelector } from 'react-redux';
import { Marker, MarkerLayer } from 'react-leaflet-marker';

import { clusterOptions } from './common';
import mapAPIService from '../../../services/map';
import AddressMarker from '../../markers/address/addressMarker';
import {
  selectMapSettings,
  selectVisibleAddresses,
} from '../../../store/selectors';
import AddressCluster from '../../markers/cluster/address/addressCluster';

const AddressLayer = () => {
  const map = useMap();

  const [bounds, setBounds] = useState(map.getBounds());
  const [zoom, setZoom] = useState(map.getZoom());

  const addresses = useSelector(selectVisibleAddresses, shallowEqual);
  const { groupMarkers: useGroups } = useSelector(selectMapSettings);

  useMapEvent('moveend', (event) => {
    setBounds(event.target.getBounds());
    setZoom(event.target.getZoom());
  });

  const addressPoints = Object.values(addresses).map((address) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      address,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(address.longitude),
        parseFloat(address.latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: addressPoints,
    bounds: [
      bounds._southWest.lng,
      bounds._southWest.lat,
      bounds._northEast.lng,
      bounds._northEast.lat,
    ],
    zoom: zoom,
    options: {
      ...clusterOptions,
      maxZoom: useGroups ? mapAPIService.maxClusterZoom : 0,
    },
  });

  const renderClusterMarker = useCallback(
    (cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      return (
        <Marker
          key={`cluster-${cluster.id}`}
          position={[latitude, longitude]}
          size={[50, 50]}
        >
          <AddressCluster
            key={`cluster-${cluster.id}`}
            lat={latitude}
            lng={longitude}
            cluster={cluster}
            supercluster={supercluster}
          />
        </Marker>
      );
    },
    [supercluster]
  );

  const renderAddressMarker = (address) => (
    <Marker
      key={address.id}
      position={[address.latitude, address.longitude]}
      size={[50, 50]}
    >
      <AddressMarker address={address} />
    </Marker>
  );

  const addressMarkers = [],
    clusterMarkers = [];

  clusters.forEach((cluster) => {
    if (cluster.properties.cluster)
      clusterMarkers.push(renderClusterMarker(cluster));
    else if (cluster.properties.address)
      addressMarkers.push(renderAddressMarker(cluster.properties.address));
    else console.warn('Invalid Cluster marker');
  });

  // console.debug('Render Address Layer');

  return (
    <MarkerLayer pane='markerPane'>
      {clusterMarkers}
      {addressMarkers}
    </MarkerLayer>
  );
};

AddressLayer.propTypes = {};

AddressLayer.defaultProps = {};

export default AddressLayer;
