import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, IconButton, List, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CallIcon from '@mui/icons-material/Call';
import TodayIcon from '@mui/icons-material/Today';
// import WorkIcon from '@mui/icons-material/Work';
// import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PeopleIcon from '@mui/icons-material/People';
// import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';

import './sidebar.scss';
import SidebarSection from '../../common/sidebarSection/sidebarSection';
import SidebarItem from '../../common/sidebarItem/sidebarItem';
import Copyright from '../../../components/copyright/copyright';
import ThemeModeField from '../../../components/forms/fields/themeMode/themeMode';
import { getDealer } from '../../../utilities/helpers';
import lightLogo from '../../../static/images/logo/servApp_light.svg';
import darkLogo from '../../../static/images/logo/servApp_dark.svg';

function Sidebar({ user, onClose, ...props }) {
  const dealer = useMemo(getDealer, []);
  const theme = useTheme();

  const handleClick = (event) => {
    if (typeof onClose === 'function') onClose();
  };

  const menuSections = [
    {
      title: 'Service Module',
      permission: true,
      options: [
        {
          label: 'Configuration',
          icon: NoteAltIcon,
          to: '/service/config',
          disabled: !user.serviceModuleAdmin,
        },
        {
          label: 'Calls',
          icon: CallIcon,
          to: '/service/calls',
          disabled: !user.serviceModuleAdmin && !user.serviceModuleDispatch,
        },
        {
          label: 'Job Calendar',
          icon: TodayIcon,
          to: '/service/calendars/job',
          disabled: !user.serviceModuleAdmin && !user.serviceModuleDispatch,
        },
        {
          label: 'Tech Calendar',
          icon: TodayIcon,
          to: '/service/calendars/tech',
          disabled: !user.serviceModuleAdmin && !user.serviceModuleDispatch,
        },
        {
          label: 'Dispatch',
          icon: LocalShippingIcon,
          to: '/service/dispatch',
          disabled: !user.serviceModuleAdmin && !user.serviceModuleDispatch,
        },
        {
          label: 'Accounting',
          icon: AccountBalanceWalletIcon,
          to: '/service/accounting/invoicing/batching',
          disabled: !user.serviceModuleAdmin,
        },
        {
          label: 'AR Management',
          icon: AccountBalanceWalletIcon,
          to: '/service/accounting/accounts-receivable',
          disabled: !user.serviceModuleAdmin,
        },
        {
          label: 'Payments',
          icon: AccountBalanceWalletIcon,
          to: '/service/accounting/payments',
          disabled: !user.serviceModuleAdmin,
        },
        {
          label: 'Clients',
          icon: PeopleIcon,
          to: '/service/clients',
          disabled: !user.serviceModuleAdmin,
        },
      ],
    },
    // {
    //   title: 'DEMOs',
    //   permission: true,
    //   options: [
    //     {
    //       label: 'Job',
    //       icon: WorkIcon,
    //       to: '/service/jobs/71',
    //     },
    //     {
    //       label: 'Estimate',
    //       icon: AssignmentIcon,
    //       to: '/service/estimates/19',
    //     },
    //     {
    //       label: 'Invoice',
    //       icon: ReceiptIcon,
    //       to: '/service/invoices/6',
    //     },
    //     {
    //       label: 'Client',
    //       icon: PersonIcon,
    //       to: '/service/clients/41',
    //     },
    //   ],
    // },
  ]
    .filter((section) => section.permission)
    .map((section) => {
      const filteredOptions = section.options.filter(
        (option) => option.hide === undefined || !option.hide
      );
      return (
        <SidebarSection
          key={section.title}
          title={section.title}
          options={filteredOptions}
          onClick={handleClick}
        />
      );
    });

  return (
    <Box component='nav' className='sidebar'>
      <Box>
        {!dealer && (
          <Grid container p={2}>
            <Grid item xs={9} md={12}>
              <Box
                display='flex'
                justifyContent='center'
                height='100%'
                width='100%'
                {...props}
              >
                <img
                  src={theme.palette.mode === 'light' ? lightLogo : darkLogo}
                  alt='USFT ServApp logo'
                  className='usft-logo'
                />
              </Box>
            </Grid>
            <Grid
              item
              xs
              sx={{ display: { sm: 'block', md: 'none' }, flexGrow: 1 }}
            />
            <Grid item xs sx={{ display: { sm: 'block', md: 'none' } }}>
              <IconButton aria-label='close' onClick={handleClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <List>
          <SidebarItem
            label='Return To Map'
            icon={MapIcon}
            to='/'
            onClick={handleClick}
          />
          <SidebarItem
            label='Management'
            icon={DashboardIcon}
            to='/manage'
            onClick={handleClick}
          />
          {menuSections}
        </List>
      </Box>
      <Box>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12} className='theme-mode-container'>
            <ThemeModeField />
          </Grid>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

Sidebar.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
