import { useEffect, useRef, useState } from 'react';
import { Marker } from 'react-leaflet-marker';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';

import './pointMarker.scss';
import HistoryPointDetails from './details/pointDetails';
import MarkerPopper from '../../common/popper/markerPopper';
import { setPOI } from '../../../../store/slices/map';

function HistoryPointMarker({ point }) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // Here to give ref chance to populate
    setIsOpen(true);
  }, []);

  const handleClose = () => {
    dispatch(setPOI(null));
  };

  return (
    <Marker position={[point.latitude, point.longitude]}>
      <Box className='point-marker'>
        <Box className='history-point-icon-container' ref={anchorEl}>
          <CircleIcon />
        </Box>
        {anchorEl.current && (
          <MarkerPopper
            open={isOpen}
            onClose={handleClose}
            title='Historical Data'
            details={
              <HistoryPointDetails point={point} onClose={handleClose} />
            }
            anchor={anchorEl}
          />
        )}
      </Box>
    </Marker>
  );
}

HistoryPointMarker.propTypes = {
  point: PropTypes.object.isRequired,
};

HistoryPointMarker.defaultProps = {};

export default HistoryPointMarker;
