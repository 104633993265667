import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grow,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import './search.scss';
import { search, webSearch } from './common';
import featureService from '../../services/feature';
import { setMenuVisible } from '../../store/slices/app';
import { selectAppFeatures, selectAuth } from '../../store/selectors';
import { dealers, getDealer } from '../../utilities/helpers';

let searchTimeout;
const sectionMap = {
  devices: 'Devices',
  drivers: 'Drivers',
  addresses: 'Addresses',
  geofences: 'Geofences',
  pages: 'Pages',
  webSearch: 'Web Search',
};
const resultsMax = 3;

// TODO consider https://github.com/moroshko/autosuggest-highlight
function SearchBar() {
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState({});
  const [showResults, setShowResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [showWebResults, setShowWebResults] = useState(false);
  const [isWebSearching, setIsWebSearching] = useState(true);

  const dispatch = useDispatch();
  const features = useSelector(selectAppFeatures);
  const { viewOnly } = useSelector(selectAuth);

  const anchorEl = useRef(null);
  const dealer = useMemo(getDealer, []);

  const handleSearch = (query) => async () => {
    const timeout = setTimeout(() => setIsSearching(true), 200);
    const _results = await search(query);
    clearTimeout(timeout);
    setIsSearching(false);
    setResults(_results);
    setShowResults(true);
    setShowWebResults(false);
  };

  const handleWebSearch = async () => {
    setIsWebSearching(true);
    setShowWebResults(true);
    const _results = await webSearch(searchText).catch((error) => []);
    setResults((prevValue) => ({
      ...prevValue,
      webSearch: [_results, _results.length],
    }));
    setIsWebSearching(false);
  };

  const handleChange = (event) => {
    clearTimeout(searchTimeout);
    setSearchText(event.target.value);
    if (event.target.value === '') setShowResults(false);
    else searchTimeout = setTimeout(handleSearch(event.target.value), 300);
  };

  const clearSearch = (event) => {
    clearTimeout(searchTimeout);
    setShowResults(false);
    setSearchText('');
  };

  const showMenu = (event) => {
    dispatch(setMenuVisible(true));
  };

  const buildResult = (result, section) => {
    return (
      <ListItemButton
        key={`${section}-${result.id}`}
        onClick={result.action}
        component={section === 'pages' ? Link : undefined}
        to={result.to}
        disabled={result.disabled || false}
      >
        <ListItemText primary={result.label} />
      </ListItemButton>
    );
  };

  const buildDeviceResult = (device, section) => {
    let secondary = null;
    if (device.tags.length)
      secondary = device.tags.map((tag) => (
        <Typography key={tag.Name} className='secondary'>
          {tag.Name} - {tag.Value}
        </Typography>
      ));
    return (
      <ListItemButton key={`${section}-${device.id}`} onClick={device.action}>
        <ListItemText disableTypography>
          <Typography>{device.label}</Typography>
          {secondary}
        </ListItemText>
      </ListItemButton>
    );
  };

  const buildResultSection = (section, [_results, length]) => {
    let buildFunction = buildResult;
    if (section === 'devices') buildFunction = buildDeviceResult;

    return (
      <Box className='search-section' key={section}>
        <Typography className='section-header'>
          {sectionMap[section]}
        </Typography>
        {length ? (
          <List>{_results.map(buildFunction)}</List>
        ) : (
          <Typography align='center'>No results found</Typography>
        )}
        {length > resultsMax && (
          <Typography className='more-results' color='text.secondary'>
            + {length - resultsMax} results
          </Typography>
        )}
      </Box>
    );
  };

  const resultSections = Object.entries(results)
    .filter(([section, data]) => data[1] > 0 || section === 'webSearch')
    .map(([section, data]) => buildResultSection(section, data));

  let webResultSection;
  if (showWebResults && isWebSearching)
    webResultSection = (
      <Box className='web-search-loading'>
        <CircularProgress size={30} />
      </Box>
    );
  else if (!showWebResults)
    webResultSection = (
      <Button fullWidth onClick={handleWebSearch}>
        Search Web
      </Button>
    );

  let placeholder = 'Search US Fleet Tracking';
  if (dealer)
    switch (dealer.id) {
      case dealers.LiveViewGPS:
        placeholder = 'Search LiveViewGPS Tracking';
        break;
      default:
        break;
    }

  const showFeatureBadge = features[featureService.IDs.timeZone] ?? false;

  return (
    <ClickAwayListener onClickAway={clearSearch}>
      <Box className='search-container' ref={anchorEl}>
        <Paper
          className='search-bar'
          sx={{ width: { xs: '100%', sm: '350px' } }}
        >
          <Tooltip title='Menu' enterDelay={300}>
            <IconButton
              className='menu-btn'
              aria-label='menu'
              onClick={showMenu}
            >
              <Badge
                color='primary'
                variant='dot'
                invisible={!showFeatureBadge}
              >
                <MenuIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <InputBase
            id='usft-search'
            placeholder={placeholder}
            inputProps={{
              'aria-label': placeholder,
              'aria-autocomplete': false,
              'data-lpignore': true,
            }}
            onChange={handleChange}
            value={searchText}
            endAdornment={
              <>
                {isSearching && (
                  <InputAdornment
                    position='end'
                    sx={{ display: searchText ? 'flex' : 'none' }}
                  >
                    <CircularProgress color='inherit' size={20} />
                  </InputAdornment>
                )}
                <InputAdornment
                  position='end'
                  sx={{ display: searchText ? 'flex' : 'none' }}
                >
                  <IconButton
                    className='menu-btn'
                    aria-label='menu'
                    onClick={clearSearch}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              </>
            }
          />
          <IconButton
            disabled
            role={undefined}
            className='search-btn'
            aria-label='search'
            disableFocusRipple
          >
            <SearchIcon />
          </IconButton>
        </Paper>
        <Popper
          id={'test'}
          open={Boolean(searchText && showResults)}
          anchorEl={anchorEl.current}
          className='search-results-popover'
          placement='bottom-start'
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={250} unmountOnExit>
              <Paper className='search-results-container'>
                <Box className='search-results'>
                  {resultSections.length > 0 ? (
                    resultSections
                  ) : (
                    <Typography>No results found</Typography>
                  )}
                  {!viewOnly && webResultSection}
                </Box>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

SearchBar.propTypes = {};

SearchBar.defaultProps = {};

export default SearchBar;
