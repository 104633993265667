import { Box, Button, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import './tags.scss';
import USFTTextField from '../text/text';

export const blankTag = {
    id: null,
    name: '',
    value: ''
}

function TagsField({ name, ...props }) {
    const { fields, append, remove } = useFieldArray({ name: name, keyName: "tagKey" })

    return (
        <Box>
            {fields.map((field, index) => (
                <Grid container spacing={2} mb={1} key={field.tagKey}>
                    <Grid item xs={5}>
                        <USFTTextField
                            name={`${name}.${index}.name`}
                            rules={{
                                maxLength: {
                                    value: 50,
                                    message: '50 character limit'
                                }
                            }}
                            placeholder='Category'
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <USFTTextField
                            name={`${name}.${index}.value`}
                            rules={{
                                maxLength: {
                                    value: 50,
                                    message: '50 character limit'
                                }
                            }}
                            placeholder='Tag'
                        />
                    </Grid>
                    <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
                        <IconButton aria-label="delete" color='error' onClick={(e) => remove(index)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Box display='flex' justifyContent='center' mt={2}>
                <Button onClick={(e) => append(blankTag)}>Add Tag</Button>
            </Box>
        </Box>
    );
}

TagsField.propTypes = {
    name: PropTypes.string.isRequired
}

TagsField.defaultProps = {
    name: 'tags'
}

export default TagsField;
