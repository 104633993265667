import {
  allAddressesOption,
  allDevicesOption,
  statusAllDevices,
} from '../components/forms/fields/helpers';
import { deviceVisibiltyFunctions } from '../utilities/helpers';

export const selectAppMode = (state) => state.app.mode;
export const selectAppMenuVisible = (state) => state.app.showMenu;
export const selectAppChatVisible = (state) => state.app.showLiveChat;
export const selectAppSidebar = (state) => state.app.sidebar;
export const selectAppLoading = (state) => state.app.loading;
export const selectAppPreferences = (state) => state.app.preferences;
export const selectAppDashboardPreferences = (state) =>
  state.app.preferences.dashboard;
export const selectAppThemePreferences = (state) => state.app.preferences.theme;
export const selectAppTimeZonePreference = (state) =>
  state.app.preferences.timeZone;
export const selectAppFeatures = (state) => state.app.features;

export const selectAuth = (state) => state.auth;
export const selectAuthUser = (state) => state.auth.user;
export const selectAuthToken = (state) => state.auth.token;
export const selectAuthUserPermissions = (state) => state.auth.user.permissions;
export const selectAuthLoading = (state) => state.auth.loading;

export const selectMapAddresses = (state) => state.map.addresses;
export const selectMapAddressGroups = (state) => state.map.addressGroups;
export const selectMapDevices = (state) => state.map.devices;
export const selectMapDevicesWithDrivers = (state) =>
  Object.fromEntries(
    Object.entries(state.map.devices).filter(([_, device]) => device.driverID)
  );
export const selectMapDeviceGroups = (state) => state.map.deviceGroups;
export const selectMapDrivers = (state) => state.map.drivers;
export const selectMapEvents = (state) => state.map.events;
export const selectMapRoutes = (state) => state.map.routes;
export const selectMapKMLs = (state) => state.map.kmls;
export const selectMapGeofences = (state) => state.map.geofences;
export const selectMapHistory = (state) => state.map.history;
export const selectMapServiceCalls = (state) => state.map.serviceCalls;
export const selectMapDeviceFilters = (state) => state.map.deviceFilters;
export const selectMapAddressFilters = (state) => state.map.addressFilters;
export const selectMapServiceCallFilters = (state) =>
  state.map.serviceCallFilters;
export const selectMapMode = (state) => state.map.mode;
export const selectMapHeading = (state) => state.map.heading;
export const selectMapPOI = (state) => state.map.POI;
export const selectMapWebSearch = (state) => state.map.webSearch;
export const selectMapActiveDetailPopUp = (state) =>
  state.map.activeDetailPopUp;
export const selectMapFollowedMarker = (state) => state.map.followedMarker;
export const selectMapSettings = (state) => state.map.settings;
export const selectMapLiveVaporSettings = (state) =>
  state.map.liveVaporSettings;
export const selectMapLoading = (state) => state.map.loading;

export const selectHistory = (state) => state.history;
export const selectHistoryCurrentDevice = (state) =>
  state.history.currentDevice;
export const selectHistoryDevices = (state) => state.history.devices;
export const selectHistoryPoints = (state) => state.history.points;
export const selectHistoryEvents = (state) => state.history.events;
export const selectHistoryCoordinates = (state) => state.history.coordinates;

const _selectFilteredDevices = (state, onlyVisible) => {
  let filteredDevices = Object.entries(state.map.devices).filter(
    ([_, device]) => device.location?.latitude !== undefined
  );

  if (state.map.deviceFilters.group.id !== allDevicesOption.id) {
    const group = state.map.deviceGroups[state.map.deviceFilters.group.id];
    filteredDevices = filteredDevices.filter(([_, device]) =>
      group?.members.includes(device.id)
    );
  }

  if (state.map.deviceFilters.status !== statusAllDevices)
    filteredDevices = filteredDevices.filter(
      deviceVisibiltyFunctions[state.map.deviceFilters.status]
    );

  if (onlyVisible)
    filteredDevices = filteredDevices.filter(([_, device]) => device.visible);

  return Object.fromEntries(filteredDevices);
};
export const selectFilteredDevices = (state) =>
  _selectFilteredDevices(state, false);
export const selectVisibleDevices = (state) =>
  _selectFilteredDevices(state, true);

export const _selectFilteredAddresses = (state, onlyVisible) => {
  let filteredAddresses = Object.entries(state.map.addresses).filter(
    ([_, address]) => address.latitude !== undefined
  );

  if (state.map.addressFilters.group.id !== allAddressesOption.id) {
    const group = state.map.addressGroups[state.map.addressFilters.group.id];
    filteredAddresses = filteredAddresses.filter(([_, address]) =>
      group?.members.includes(address.id)
    );
  }

  if (onlyVisible)
    filteredAddresses = filteredAddresses.filter(
      ([_, address]) => address.visible
    );

  return Object.fromEntries(filteredAddresses);
};
export const selectFilteredAddresses = (state) =>
  _selectFilteredAddresses(state, false);
export const selectVisibleAddresses = (state) =>
  _selectFilteredAddresses(state, true);

export const _selectFilteredServiceCalls = (state, onlyVisible) => {
  let filteredAddresses = Object.entries(state.map.addresses).filter(
    ([_, address]) => address.latitude !== undefined
  );

  if (state.map.addressFilters.group.id !== allAddressesOption.id) {
    const group = state.map.addressGroups[state.map.addressFilters.group.id];
    filteredAddresses = filteredAddresses.filter(([_, address]) =>
      group?.members.includes(address.id)
    );
  }

  if (onlyVisible)
    filteredAddresses = filteredAddresses.filter(
      ([_, address]) => address.visible
    );

  return Object.fromEntries(filteredAddresses);
};
export const selectFilteredServiceCalls = (state) =>
  _selectFilteredServiceCalls(state, false);
export const selectVisibleServiceCalls = (state) =>
  _selectFilteredServiceCalls(state, true);
