import mapAPIService from '../../../services/map';

export const clusterOptions = {
  minPoints: mapAPIService.minClusterPoints,
  radius: mapAPIService.clusterRadius,
  maxZoom: mapAPIService.maxClusterZoom,
  map: (props) => ({
    hasAlert: (props.device && props.device.activeInput !== null) || false,
  }),
  reduce: (acc, props) => {
    if (!acc.hasAlert) acc.hasAlert = props.hasAlert;
  },
};
