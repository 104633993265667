import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

import './dialogTitle.scss';

function USFTDialogTitle({ title, onClose, ...props }) {

    return (
        <DialogTitle>
            {title}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{ color: (theme) => theme.palette.grey[500] }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};

USFTDialogTitle.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

USFTDialogTitle.defaultProps = {};

export default USFTDialogTitle;
