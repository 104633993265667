import { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, CircularProgress, Grow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import './map.scss';
import mapAPIService from '../../services/map';
// import StopMarker from '../markers/event/stop/stopMarker';
// import AlertMarker from '../markers/event/alert/alertMarker';
// import VideoRequestMarker from '../markers/event/videoRequest/videoRequest';
// import HistoryMarker from '../markers/history/historyMarker';
import Loading from '../loading/loading';
import {
  getDealer,
  useMountedRef,
  useTitleHook,
} from '../../utilities/helpers';
import { setDevices } from '../../store/slices/map';
import LeafletMap from '../leaflet/leaflet';
import { selectMapMode, selectMapSettings } from '../../store/selectors';
import TrafficLayer from './layers/traffic';
import DeviceLayer from './layers/device';
import AddressLayer from './layers/addresses';
import WebSearchLayer from './layers/webSearch';
import HistoryEventsLayer from './layers/event';

function Map() {
  const [dataLoading, setDataLoading] = useState(true);
  const [map, setMap] = useState(null);

  const dispatch = useDispatch();
  const mapMode = useSelector(selectMapMode);
  const settings = useSelector(selectMapSettings);

  const dealer = useMemo(getDealer, []);
  const needFullLoad = useRef(true); // TODO make sure this works
  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useMountedRef();
  useTitleHook('US Fleet Tracking', false);

  let title = 'US Fleet Tracking';
  if (dealer) title = dealer.title;

  useTitleHook(title, false);

  // const [serviceCalls, serviceCallClusters] = useServiceCallLayer({ serviceCalls: props.serviceCalls, settings: props.settings, clusterState });

  useEffect(() => {
    return () => {
      mapAPIService.unsubscribe().then(mapAPIService.cleanUp);
    };
  }, []);

  useEffect(() => {
    if (map) {
      mapAPIService.setMap(map);

      if (mapMode === mapAPIService.modes.live) {
        const handleOnChange = () =>
          mapAPIService.handleBoundsChanged(map.getZoom(), map.getBounds());

        handleOnChange();
        map.on('moveend', handleOnChange);

        return () => {
          map.off('moveend', handleOnChange);
        };
      }
    }
  }, [map, mapMode]);

  useEffect(() => {
    if (!map) return;

    switch (mapMode) {
      case mapAPIService.modes.live:
        if (!needFullLoad.current) mapAPIService.resetView();
        let isLoaded = false;
        setDataLoading(true);
        mapAPIService
          .loadMap()
          .then(async () => {
            if (isMounted.current) {
              isLoaded = true;
              setDataLoading(false);
              return Promise.all([
                mapAPIService.subscribe(),
                needFullLoad.current ? mapAPIService.resetView() : null,
              ]).then(() => {
                needFullLoad.current = false;
              });
            }
          })
          .catch((error) => {
            if (error.message !== 'Map Unmounted') console.error(error);
          });
        // TODO move visibility timeout to map service
        /** Handles visibility changes with tab */
        let visibilityTimeout;
        const handleVisibilityChange = async () => {
          console.debug('Map visibility is', document.visibilityState);
          if (document.visibilityState === 'hidden') {
            visibilityTimeout = setTimeout(async () => {
              console.debug('Handling visibility timeout');
              mapAPIService.unsubscribe();
            }, 5000);
          } else {
            clearTimeout(visibilityTimeout);
            if (mapAPIService.socket?.disconnected) {
              setDataLoading(true);
              return mapAPIService
                .updateMap()
                .then(mapAPIService.subscribe)
                .then(() => setDataLoading(false))
                .catch((e) => {
                  enqueueSnackbar('Failed to update Map', { variant: 'error' });
                });
            }
          }
        };

        const handleMapUnload = (event) => {
          if (isLoaded) mapAPIService.saveMapState();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleMapUnload);

        return () => {
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange
          );
          window.removeEventListener('beforeunload', handleMapUnload);
          if (isLoaded) mapAPIService.saveMapState();
        };

      case mapAPIService.modes.historyPlayback:
        setDataLoading(false);
        mapAPIService.unsubscribe().then(() => dispatch(setDevices([])));
        needFullLoad.current = true;
        break;
      default:
        console.warn('Default case hit', mapMode);
    }
  }, [needFullLoad, isMounted, map, mapMode, enqueueSnackbar, dispatch]);

  //   useEffect(() => {
  //     if (!mapLoading) {
  //       // Heading Listener
  //       const headingListener = mapAPIService.map.addListener(
  //         'heading_changed',
  //         () => {
  //           console.debug('Heading changed');
  //           setHeading(mapAPIService.map.getHeading());
  //         }
  //       );
  //       // Setup Street View Listener
  //       let thePanorama = mapAPIService.map.getStreetView();
  //       const listenerID = mapAPIService.maps.event.addListener(
  //         thePanorama,
  //         'visible_changed',
  //         () => {
  //           if (thePanorama.getVisible()) setStreetViewActive(true);
  //           else setStreetViewActive(false);
  //         }
  //       );
  //       return () => {
  //         headingListener.remove();
  //         mapAPIService.maps.event.removeListener(listenerID);
  //         setStreetViewActive(false);
  //       };
  //     }
  //   }, [mapLoading, setStreetViewActive, setHeading]);

  // const events = useMemo(() => Object.entries(props.events)
  //     .map(([eventID, event]) => createElement(eventMap[event.type], {
  //         key: event.id,
  //         lat: event.latitude,
  //         lng: event.longitude,
  //         event: event
  //     }))
  //     , [props.events]);

  //   const POI = useMemo(
  //     () =>
  //       props.POI
  //         ? createElement(eventMap[props.POI.type], {
  //             key: props.POI.id,
  //             lat: props.POI.latitude,
  //             lng: props.POI.longitude,
  //             event: props.POI,
  //           })
  //         : undefined,
  //     [props.POI]
  //   );

  console.log('Render Map', settings, map?.getZoom());

  return (
    <>
      <LeafletMap ref={setMap}>
        {settings.traffic && <TrafficLayer />}
        <WebSearchLayer />
        <AddressLayer />
        <DeviceLayer />
        <HistoryEventsLayer />
      </LeafletMap>
      {/* <GoogleMapReact
                bootstrapURLKeys={mapAPIService.mapOptions}
                defaultCenter={mapAPIService.defaults.center}
                defaultZoom={mapAPIService.defaults.zoom}
                options={mapAPIService.createMapOptions}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleAPILoaded}
                onChange={handleOnChange}
            >
                {events}
                {POI}
                {webSearch}
                {addressClusters}
                {serviceCallClusters}
                {addresses}
                {serviceCalls}
                {deviceClusters}
                {devices}
            </GoogleMapReact> */}
      <Box
        className='map-loading-container'
        sx={{ bottom: { xs: 100, sm: '1rem' } }}
      >
        <Grow
          in={dataLoading && !needFullLoad.current}
          style={{
            transitionDelay:
              dataLoading && !needFullLoad.current ? '1000ms' : '0ms',
          }}
          unmountOnExit
        >
          <Alert
            severity='info'
            color='secondary'
            variant='filled'
            className='map-loading-alert'
            icon={<CircularProgress size='1rem' color='inherit' />}
          >
            Loading Map Data
          </Alert>
        </Grow>
      </Box>
      {dataLoading && needFullLoad.current && <Loading />}
    </>
  );
}

Map.propTypes = {};

Map.defaultProps = {};

export default Map;
