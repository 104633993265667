import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './directions.scss';

function DirectionsAction({ location }) {
  const handleClick = async (event) => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${location.latitude}%2C${location.longitude}`,
      '_blank'
    );
  };

  return (
    <Button variant='contained' onClick={handleClick}>
      Get Directions
    </Button>
  );
}

DirectionsAction.propTypes = {
  location: PropTypes.object.isRequired,
};

DirectionsAction.defaultProps = {};

export default DirectionsAction;
