import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './editAddressHOC.scss';
import FormDialog from '../../../../crud/formDialog/formDialog';
import AddressForm from '../../../../addresses/form/addressForm';
import { updateAddress } from '../../../../../store/slices/map';

function EditAddressHOC({ id }) {
  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleSave = (address) => {
    dispatch(updateAddress({ id, properties: address }));
    handleClose();
  };

  return (
    <>
      <Button variant='contained' onClick={handleClick}>
        Edit
      </Button>
      <FormDialog
        open={showDialog}
        onClose={handleClose}
        onSave={handleSave}
        form={AddressForm}
        model='address'
        formProps={{ id }}
        size='lg'
      />
    </>
  );
}

EditAddressHOC.propTypes = {
  id: PropTypes.number.isRequired,
};

EditAddressHOC.defaultProps = {};

export default EditAddressHOC;
