import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  autocompleteClasses,
  ListItemText,
  ListSubheader,
  Popper,
  styled,
} from '@mui/material';
import { VariableSizeList } from 'react-window';

// https://v5-0-6.mui.com/components/autocomplete/#virtualization

const LISTBOX_PADDING = 8; // px

function renderRow({ data, index, style }) {
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component='div' style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <span {...dataSet[0]} style={inlineStyle}>
      {dataSet}
    </span>
  );
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const VirtualizedListboxComponent = forwardRef(
  ({ children, ...props }, ref) => {
    const itemData = [];
    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSize = 44;

    // TODO better dynamically get height
    const getChildSize = (child) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      } else if (child.key === 'select-all-divider') return 1;
      else if (
        (Array.isArray(child.props.children) &&
          child.props.children.some(
            (c) => c.type === ListItemText && c.props.secondary
          )) ||
        (typeof child.props.children === 'object' &&
          child.props.children.type === ListItemText &&
          child.props.children.props.secondary)
      )
        return 68;

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={props}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

export const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
      li: {
        height: 'inherit',
      },
    },
  },
});
