import { useEffect, useRef } from 'react';
import {
  Grow,
  Dialog,
  DialogContent,
  useTheme,
  useMediaQuery,
  Paper,
  Popper,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import L from 'leaflet';
import PropTypes from 'prop-types';

import './markerPopper.scss';
import USFTDialogTitle from '../../../dialog/dialogTitle/dialogTitle';

function MarkerPopper({
  open,
  anchor,
  details,
  title,
  onClose,
  dialogProps,
  popperProps,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const disableScrollPropagation = L.DomEvent.disableScrollPropagation;
      const disableClickPropagation = L.DomEvent.disableClickPropagation;
      disableScrollPropagation(ref.current);
      disableClickPropagation(ref.current);
    }
  }, [ref.current]);

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  const common = (
    <>
      <USFTDialogTitle title={title} onClose={onClose} />
      <DialogContent dividers>{details}</DialogContent>
    </>
  );

  const anchorEl = anchor?.current || anchor;

  if (isMobile)
    return (
      <Dialog
        open={open}
        TransitionComponent={Grow}
        onClose={onClose}
        fullWidth
        maxWidth='sm'
        ref={ref}
        {...dialogProps}
      >
        {common}
      </Dialog>
    );
  else
    return (
      <Popper
        id={uuidv4()}
        disablePortal
        open={open && anchorEl !== null}
        anchorEl={anchorEl}
        ref={ref}
        placement='right'
        className='detail-container'
        transition
        modifiers={[
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'viewport',
              padding: 8,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ]}
        {...popperProps}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={250}>
            <Paper onMouseDown={handleMouseDown} onClick={handleClick}>
              {common}
            </Paper>
          </Grow>
        )}
      </Popper>
    );
}

MarkerPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.object,
  details: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dialogProps: PropTypes.object,
  popperProps: PropTypes.object,
};

MarkerPopper.defaultProps = {};

export default MarkerPopper;
