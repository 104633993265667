import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Link, Skeleton, Typography } from '@mui/material';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import L from 'leaflet';

import './addressForm.scss';
import CRUDForm from '../../crud/form/form';
import CRUDField from '../../crud/field/field';
import { latitudeRules, longitudeRules } from '../../forms/fields/helpers';
import mapAPIService from '../../../services/map';
import USFTTextField from '../../forms/fields/text/text';
import USFTCustomAddressField from '../../forms/fields/customAddress/customAddress';
import AddressGroupField from '../../forms/fields/addressGroup/addressGroup';
import FormSection from '../../forms/section/section';
import AddressIconField from '../../forms/fields/addressIcon/addressIcon';
import tileSets from '../../leaflet/tileSets';

const fields = {
  name: 'name',
  groups: 'groups',
  address: 'address',
  latitude: 'latitude',
  longitude: 'longitude',
  icon: 'icon',
};

function AddressForm(props) {
  const form = useForm(props.formProps);
  const icon = form.watch(fields.icon);
  const latitude = form.watch(fields.latitude);
  const longitude = form.watch(fields.longitude);

  const marker = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (marker.current && icon) {
      marker.current.setIcon(L.icon({ iconUrl: icon.url, iconSize: [50, 50] }));
    }
  }, [icon, marker]);

  useEffect(() => {
    if (latitude && latitude && map.current && marker.current) {
      map.current.panTo([latitude, longitude]);
      marker.current.setLatLng([latitude, longitude]);
    }
  }, [latitude, longitude, marker, map]);

  const handlePlaceChange = async (place) => {
    form.setValue(fields.latitude, place.latitude, {
      shouldDirty: true,
      shouldTouch: true,
    });
    form.setValue(fields.longitude, place.longitude, {
      shouldDirty: true,
      shouldTouch: true,
    });

    if (map.current) {
      map.current.setZoom(mapAPIService.maxZoom - 3);
    }
  };

  const handleLatLngChange = async () => {
    if ([latitude, longitude].includes('')) return;

    return mapAPIService
      .getLocation({
        latitude: Number(latitude),
        longitude: Number(longitude),
      })
      .then((location) => {
        form.current.setValue(fields.address, location.formatted_address, {
          shouldDirty: true,
          shouldTouch: true,
        });
      })
      .catch((error) => {
        console.warn('No location found to sync');
        form.setValue(fields.address, '');
      });
  };

  const formatSubmitData = (data) => ({
    ...data,
    id: props.id,
    icon: data.icon?.id,
    address: data.address.address,
    latitude: data.latitude ? Number(data.latitude) : undefined,
    longitude: data.longitude ? Number(data.longitude) : undefined,
    groups: data[fields.groups].map((group) => group.id),
  });

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        if (marker.current != null) {
          const position = marker.current.getLatLng();
          form.setValue(fields.latitude, position.lat);
          form.setValue(fields.longitude, position.lng);
          form.setValue(fields.address, null);
        }
      },
    }),
    [form]
  );

  const position = [
    latitude || mapAPIService.defaults.center.lat,
    longitude || mapAPIService.defaults.center.lng,
  ];

  return (
    <CRUDForm
      form={form}
      fetchURL='/addresses/get'
      updateURL='/addresses/update'
      submitDataFormatter={formatSubmitData}
      className='address-form'
      {...props}
    >
      <FormSection>
        <Grid container item spacing={2} xs={12} md={6}>
          <CRUDField xs={12}>
            <USFTTextField
              name={fields.name}
              label='Name'
              rules={{ required: 'Required' }}
            />
          </CRUDField>
          <CRUDField xs={12}>
            <AddressGroupField
              name={fields.groups}
              label='Groups'
              multiple
              disableCloseOnSelect
              limitTags={3}
            />
          </CRUDField>
          <CRUDField xs={12}>
            <USFTCustomAddressField
              name={fields.address}
              label='Address'
              onPlaceChange={handlePlaceChange}
            />
          </CRUDField>
          <CRUDField xs={12} sm={6}>
            <USFTTextField
              name={fields.latitude}
              label='Latitude'
              rules={{
                required: 'Required',
                ...latitudeRules,
              }}
              type='number'
              onBlur={handleLatLngChange}
            />
          </CRUDField>
          <CRUDField xs={12} sm={6}>
            <USFTTextField
              name={fields.longitude}
              label='Longitude'
              rules={{
                required: 'Required',
                ...longitudeRules,
              }}
              type='number'
              onBlur={handleLatLngChange}
            />
          </CRUDField>
          <CRUDField xs={12}>
            <AddressIconField
              name={fields.icon}
              label='Icon'
              rules={{ required: 'Required' }}
            />
          </CRUDField>
          <CRUDField xs={12}>
            <Typography>
              Icons need to be given sizing in Hades. Small icons will just look
              blurry. Some icons will be squished.
            </Typography>
            <Link href='https://leafletjs.com/reference.html#icon'>
              https://leafletjs.com/reference.html#icon
            </Link>
          </CRUDField>
        </Grid>
        <CRUDField
          xs={12}
          md={6}
          className='address-map-container'
          loadingComponent={<Skeleton variant='rectangle' height={340} />}
        >
          <MapContainer
            {...mapAPIService.mapOptions}
            center={position}
            zoom={10}
            ref={map}
          >
            <TileLayer {...tileSets.osm} />
            <Marker
              draggable
              ref={marker}
              position={position}
              eventHandlers={eventHandlers}
            />
          </MapContainer>
        </CRUDField>
      </FormSection>
    </CRUDForm>
  );
}

AddressForm.propTypes = {};

AddressForm.defaultProps = {};

export default AddressForm;
