import * as materialColors from '@mui/material/colors';

const colors = [
  materialColors.indigo,
  materialColors.blue,
  materialColors.lightBlue,
  materialColors.cyan,
  materialColors.teal,
  materialColors.blueGrey,
];

const randomColors = Object.values(colors)
  .map((color) =>
    Object.entries(color)
      .filter(([key]) => !key.includes('A'))
      .map(([key, value]) => value)
  )
  .flat();

/**
 * Shuffles an array. Based off https://stackoverflow.com/a/12646864.
 * @param {Array} array - The array to shuffle
 * @returns {Array} The shuffled array
 */
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

/**
 * Formats seconds into human readable
 * @param {Number} value - Seconds of video left
 * @returns {String} Formatted time
 */
export const formatDuration = (value) => {
  const minute = Math.floor(value / 60);
  const secondLeft = Math.floor(value - minute * 60);
  return `${minute}:${secondLeft <= 9 ? `0${secondLeft}` : secondLeft}`;
};

/**
 * Formats Temperature
 * @param {Number} value - Temperature in Celcius
 * @returns {String} Formatted Temperature
 */
export const formatTemperature = (value) => {
  return `${Math.round(value * 1.8 + 32)}° F / ${value}° C`;
};

/**
 * Formats phone number into human readable
 * @param {string} value - Raw phone number
 * @returns {String} Formatted phone number
 */
export const formatPhoneNumber = (value) => {
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
};

/**
 * Formats a number into Human readable string
 * @param {Number} value - Amount
 * @returns {String} Number
 */
export const formatNumber = (value) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Formats currency into Human readable string
 * @param {Number} value - Amount
 * @returns {String} Currency
 */
export const formatCurrency = (value) =>
  value
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * Formats address into human readable
 * @param {string} address1 - First line of address
 * @param {string} address2 - Second line of address
 * @param {string} city - City name
 * @param {string} state - State name
 * @param {number} zip - ZIP code
 * @returns {String} Formatted address
 */
export const formatAddress = ({ address1, address2, city, state, zip }) => {
  return `${address1}${
    address2 ? ` ${address2}` : ''
  }, ${city}, ${state} ${zip}`;
};

/**
 * Formats name parts into single string
 * @param {string} first - First Name
 * @param {string} middle - Middle Name
 * @param {string} last - Last Name
 * @returns {String} Formatted Name
 */
export const formatFullName = (first, middle, last) =>
  `${first}${middle ? ` ${middle}` : ''}${last ? ` ${last}` : ''}`;

/**
 * Capitalizes the first letter of a string
 * @param {string} value - Raw string
 * @returns {String} Formatted string
 */
export const capitalizeFirstLetter = (string) => {
  if (string.length === 0) return string;
  else return string[0].toUpperCase() + string.slice(1);
};

/**
 * Capitalizes the first letter of each word in a string
 * @param {string} value - Raw string
 * @returns {String} Formatted string
 */
export const capitalize = (string) =>
  string
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Generates a color based off string
 * @param {string} value - Raw string
 * @returns {String} Formatted string
 */
export const stringToColor = (string) => {
  let sum = 0;
  for (let i = 0; i < string.length; i++) {
    sum += string.charCodeAt(i);
  }
  let result = sum % randomColors.length;
  return randomColors[result];
};

/**
 * Returns a random Integer between 2 numbers
 * @param {Number} min - Minimum number
 * @param {Number} max - Maximum number
 * @returns {Number} Random Integer
 */
export const getRandomInt = (min = 1, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

/**
 * Returns a URL friendly array (Mainly used for Notification subscriptions)
 * @param {String} base64String - String to be converted
 * @returns {String} Uint8 Array
 */
export const urlBase64ToUint8Array = (base64String) => {
  let padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  let base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  let rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

/**
 * Creates a 2 way map for an object
 * @param {Object} obj - Object to map
 * @returns {Object} Static 2 way mapped object
 */
export const create2WayMap = (obj) => {
  const map = { ...obj };
  Object.entries(obj).forEach(([key, value]) => (map[value] = key));
  return map;
};

/**
 * Creates FormData for Hades
 *
 * This JSON stringifies data to be easily consumed in the backend since FormData only supports strings.
 * This should mainly be used for forms with file uploads.
 *
 * @param {Object} data - Raw form data (from React Hook Form)
 * @param {Object} overrides - Data to override raw
 * @returns {FormData}
 */
export const createFormData = (data, overrides = {}) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined) formData.set(key, JSON.stringify(value));
  });
  Object.entries(overrides).forEach(([key, value]) => {
    if (value !== undefined) formData.set(key, JSON.stringify(value));
  });
  return formData;
};

/**
 * Maps Service Calls into Service Locations
 * @param {Array} serviceCalls - Service Calls
 * @returns {Array} Service Locations
 */
export const BuildServiceLocations = (serviceCalls) => {
  const locations = serviceCalls.reduce((prevValue, call) => {
    const key = `${call.location.latitude}${call.location.longitude}`;
    if (!prevValue[key])
      prevValue[key] = {
        id: key,
        calls: [],
        ...call.location,
      };
    prevValue[key].calls.push({ ...call, location: undefined });
    return prevValue;
  }, {});
  return Object.values(locations);
};

/**
 * Maps Service Calls into Service Locations
 * @param {Array} serviceCalls - Service Calls
 * @returns {Array} Service Locations
 */
export const loadCDNPackage = async (url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.addEventListener('load', resolve);
    document.body.appendChild(script);
  });
};
