import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './iconOption.scss';

function IconOption({ option, ...props }) {
    return (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <img
                loading="lazy"
                width="30"
                src={option.url}
                srcSet={option.url}
                alt={`${option.name}'s icon`}
            />
            {option.name}
        </Box>
    );
}

IconOption.propTypes = {
    option: PropTypes.object.isRequired,
}

IconOption.defaultProps = {}

export default IconOption;
