import { LayerGroup } from 'react-leaflet';
import { Marker } from 'react-leaflet-marker';
import { useSelector } from 'react-redux';

import WebSearchMarker from '../../markers/webSearch/webSearch';
import { selectMapWebSearch } from '../../../store/selectors';

function WebSearchLayer() {
  const webSearchResult = useSelector(selectMapWebSearch);

  // console.debug('Render Web Search Layer', webSearchResult);

  if (webSearchResult)
    return (
      <LayerGroup>
        <Marker
          position={[webSearchResult.latitude, webSearchResult.longitude]}
        >
          <WebSearchMarker searchResult={webSearchResult} />
        </Marker>
      </LayerGroup>
    );
}

WebSearchLayer.propTypes = {};

WebSearchLayer.defaultProps = {};

export default WebSearchLayer;
