import { useSnackbar } from 'notistack';

import './driver.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';

function DriverField(props) {
    const { enqueueSnackbar } = useSnackbar();

    const getOptions = async (event) => {
        return APIService.get('/drivers/options')
            .then(response => response.data.sort((a, b) => -b.name.localeCompare(a.name)))
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Unable to get driver data. Try again later.', { variant: 'error' });
            });
    };

    return (
        <USFTAutocomplete
            getOptions={getOptions}
            {...props}
        />
    );
};

DriverField.propTypes = {};

DriverField.defaultProps = {};

export default DriverField;