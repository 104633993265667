import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';

import './map.scss';
import Logo from '../../components/logo/logo';
import Menu from '../../components/menu/menu';
import Sidebar from '../../components/sidebar/sidebar';
import SearchBar from '../../components/search/search';
import MapControls from '../../components/mapControls/mapControls';
import { appModes } from '../../store/utility';
import Notifications from '../../components/notifications/notifications';
// import DashboardSidebar from '../../components/dashboard/sidebar/dashboard';
import {
  closeSidebar,
  setAppMode,
  setMenuVisible,
} from '../../store/slices/app';
import { getDealer } from '../../utilities/helpers';
import WeatherStatus from '../../components/weatherStatus/weatherStatus';
import { selectMapSettings } from '../../store/selectors';

function MapLayout() {
  const dealer = useMemo(getDealer, []);
  const dispatch = useDispatch();

  const { weather: weatherActive } = useSelector(selectMapSettings);

  useEffect(() => {
    dispatch(setAppMode(appModes.map));

    return () => {
      dispatch(setMenuVisible(false));
      dispatch(closeSidebar());
    };
  }, [dispatch]);

  return (
    <Box className='map-layout'>
      <Grid
        container
        spacing={2}
        p={2}
        direction='column'
        justifyContent='space-between'
        alignItems='stretch'
        className='overlay'
      >
        <Grid
          item
          container
          spacing={2}
          justifyContent='space-between'
          alignItems='center'
          className='toolbar'
        >
          <Grid
            item
            container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
            className='toolbar'
          >
            <Grid item xs={12} sm='auto' className='left visible'>
              <SearchBar />
            </Grid>
            <Grid item xs={12} sm='auto' className='right visible'>
              <Grid container spacing={2} justifyContent='space-between'>
                <Grid item xs>
                  {weatherActive && <WeatherStatus />}
                </Grid>
                <Grid item>
                  <Stack direction='row' spacing={2}>
                    <Notifications useFAB />
                    <MapControls />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2} className='lower'>
          {!dealer && (
            <Grid
              item
              xs={12}
              sm='auto'
              className='visible'
              sx={{ pointerEvents: 'none', ml: { xs: 0, sm: 6 } }}
            >
              <Logo className='watermark ' />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Outlet />
      <Sidebar />
      {/* <DashboardSidebar /> */}
      <Menu />
    </Box>
  );
}

MapLayout.propTypes = {};

MapLayout.defaultProps = {};

export default MapLayout;
