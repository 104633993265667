import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { createElement } from 'react';

import './animationOption.scss';

function AnimationOption({ option, color1, color2, ...props }) {
    return (
        <Box component="li" className='animation-option' {...props}>
            <Box className='animation-option-container'>
                {createElement(option.animation, { color1, color2 })}
            </Box>
            {option.name}
        </Box>
    );
};

AnimationOption.propTypes = {
    option: PropTypes.object.isRequired,
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
};

AnimationOption.defaultProps = {};

export default AnimationOption;
