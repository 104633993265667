import { icon } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';

import './traffic.scss';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';

function TrafficMarker({ lat, lng, ...props }) {
  const mIcon = icon({
    iconUrl: props.iconURL.slice(0, 4) + 's' + props.iconURL.slice(4),
    iconSize: [29, 29],
    popupAnchor: [0, -10],
  });

  return (
    <Marker position={[lat, lng]} icon={mIcon} pane='overlayPane'>
      <Popup className='traffic-popup'>
        <Typography fontWeight='bold'>{props.shortDesc}</Typography>
        <Typography>{props.fullDesc}</Typography>
        <Box className='traffic-times'>
          <Typography m={0}>
            <b>Start</b>:{' '}
            {DateTime.fromISO(props.startTime, { zone: 'utc' }).toLocaleString(
              DateTime.DATETIME_MED
            )}
            <br />
            <b>End</b>: Expected{' '}
            {DateTime.fromISO(props.endTime, { zone: 'utc' }).toLocaleString(
              DateTime.DATETIME_MED
            )}
          </Typography>
          <Typography variant='caption'>(All Times Estimated)</Typography>
        </Box>
      </Popup>
    </Marker>
  );
}

TrafficMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  iconURL: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  shortDesc: PropTypes.string.isRequired,
  fullDesc: PropTypes.string.isRequired,
};

TrafficMarker.defaultProps = {};

export default TrafficMarker;
