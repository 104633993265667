import './feedback.scss';
import FeedbackForm from './form/feedbackForm';
import FormDialog from '../crud/formDialog/formDialog';

function Feedback(props) {

    return (
        <FormDialog
            title='Leave Us Feedback'
            form={FeedbackForm}
            submitButtonText='Send'
            {...props}
        />
    );
};

Feedback.propTypes = {};

Feedback.defaultProps = {};

export default Feedback;
