import { MenuItem } from '@mui/material';

import './deviceStatus.scss';
import { statusAllDevices, statusIdle, statusMoving, statusStopped, statusIgnitionOn, statusIgnitionOff, statusOffline } from '../helpers';
import USFTSelect from '../select/select';

const filterOptions = {
    [statusAllDevices]: 'All',
    [statusMoving]: 'Moving',
    [statusIdle]: 'Idling',
    [statusStopped]: 'Stopped',
    [statusIgnitionOn]: 'Ignition ON',
    [statusIgnitionOff]: 'Ignition OFF',
    [statusOffline]: 'Offline',
};

function DeviceStatusField(props) {

    return (
        <USFTSelect
            defaultValue={statusAllDevices}
            {...props}
        >
            {Object.entries(filterOptions).map(([value, label]) => (
                <MenuItem value={value} key={value}>{label}</MenuItem>
            ))}
        </USFTSelect>
    );
};

DeviceStatusField.propTypes = {};

DeviceStatusField.defaultProps = {};

export default DeviceStatusField;
