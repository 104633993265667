import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import './normal.scss';
import AppLoading from '../../loading/app/app';
import authService from '../../../services/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLoading } from '../../../store/slices/app';

const loginPathname = '/login';

function NormalLogin() {
  const dispatch = useDispatch();

  const hasTriedAuth = useRef(false);
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!hasTriedAuth.current)
      authService.autoLogin().then((successful) => {
        hasTriedAuth.current = true;
        if (
          !successful &&
          !['/', '/logout', loginPathname].includes(location.pathname)
        )
          navigate({
            pathname: loginPathname,
            search: `${location.search ? location.search + '&' : ''}redirect=${
              location.pathname
            }`,
          });
        dispatch(setLoading(false));
      });
  }, [location, navigate, hasTriedAuth, dispatch]);

  return <AppLoading />;
}

NormalLogin.propTypes = {};

NormalLogin.defaultProps = {};

export default NormalLogin;
