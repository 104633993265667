import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import './deviceControl.scss';
import DeviceControlButton from './deviceControlButton/deviceControlButton';

function DeviceControl({ deviceID, commands }) {

    return (
        <Grid container spacing={2}>
            {commands.map(command => (
                <Grid item xs={12} sm={4} key={command.id}>
                    <DeviceControlButton
                        deviceID={deviceID}
                        command={command}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

DeviceControl.propTypes = {
    deviceID: PropTypes.number.isRequired,
    commands: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DeviceControl.defaultProps = {};

export default DeviceControl;
