import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Box } from '@mui/material';
import PropTypes from 'prop-types';

import './addressMarker.scss';
import mapAPIService from '../../../services/map';
import AddressDetails from './details/addressDetails';
import MarkerPopper from '../common/popper/markerPopper';
import {
  selectMapActiveDetailPopUp,
  selectMapFollowedMarker,
  selectMapSettings,
} from '../../../store/selectors';
import {
  clearDetailPopUp,
  setActiveDetailPopUp,
} from '../../../store/slices/map';

function AddressMarker({ address }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const activeDetailPopUp = useSelector(selectMapActiveDetailPopUp);
  const followedMarker = useSelector(selectMapFollowedMarker);
  const { smallIcons, addressLabels: labels } = useSelector(selectMapSettings);

  useEffect(() => {
    return () => {
      if (activeDetailPopUp === address.id)
        dispatch(clearDetailPopUp(address.id));
    };
  }, [activeDetailPopUp, address.id, dispatch]);

  const handleAnchor = (node) => {
    if (node !== null) {
      setAnchorEl(node);
    }
  };

  const onClickHandler = async (event) => {
    event.stopPropagation();
    if (!followedMarker)
      await mapAPIService.center({
        latitude: address.latitude,
        longitude: address.longitude,
      });
    const handler = () => {
      mapAPIService.map.off('idle', handler);
      dispatch(setActiveDetailPopUp(address.id));
    };
    mapAPIService.map.on('idle', handler);
  };

  const onCloseDetail = (event) => {
    setActiveDetailPopUp(null);
  };

  return (
    <Box className={`address-marker-container${smallIcons ? ' small' : ''}`}>
      <Box className='marker-icon-container'>
        <Box
          ref={handleAnchor}
          tabIndex='0'
          className='marker-icon'
          onClick={onClickHandler}
        >
          <img src={address.icon} alt={`${address.name}'s icon`} />
        </Box>
        {labels ? (
          <Chip
            label={address.name}
            color='primary'
            className='marker-label'
            clickable={false}
            onClick={onClickHandler}
          />
        ) : null}
      </Box>
      <MarkerPopper
        open={activeDetailPopUp === address.id}
        onClose={onCloseDetail}
        title='Address Details'
        details={<AddressDetails addressID={address.id} />}
        anchor={anchorEl}
      />
    </Box>
  );
}

AddressMarker.propTypes = {
  address: PropTypes.object.isRequired,
};

AddressMarker.defaultProps = {};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.address.name === nextProps.address.name &&
    prevProps.address.icon === nextProps.address.icon
  );
}

export default memo(AddressMarker, arePropsEqual);
