import { memo } from 'react';
import { Box, styled } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import PropTypes from 'prop-types';

import './stopMarker.scss';
import EventMarker from '../eventMarker';
import StopDetails from './details/stopDetails';

const StopMarkerIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  '> svg': {
    fill: theme.palette.error.contrastText,
    fontSize: '32px',
  },
}));

function StopMarker({ event, ...props }) {
  console.log('StopMarker');
  return (
    <EventMarker
      title='History Stop Event'
      icon={
        <StopMarkerIcon className='event-marker-icon'>
          <StopIcon />
        </StopMarkerIcon>
      }
      details={<StopDetails event={event} />}
      {...props}
    />
  );
}

StopMarker.propTypes = {
  event: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

StopMarker.defaultProps = {};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.event.id === nextProps.event.id;
}

export default memo(StopMarker, arePropsEqual);
