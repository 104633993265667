import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import './pointDetails.scss';
import { formatHeading, useAddress } from '../../../../../utilities/helpers';
import Details from '../../../common/details/details';
import RequestVideoDirect from '../requestVideo/requestVideo';
import { selectMapDevices } from '../../../../../store/selectors';

function HistoryPointDetails({ point, onClose, ...props }) {
  const address = useAddress(point.latitude, point.longitude);

  const devices = useSelector(selectMapDevices);

  console.log('point', point);

  const device = devices[point.device];
  const details = [
    [
      'Timestamp',
      point.time?.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    ],
    ['Address', address],
    ['Serial', point.serial],
    ['Driver', point.driver || point.fob],
    ['Speed', point.velocity > 0 ? `${point.velocity} mph` : 'Stopped'],
    ['Heading', formatHeading(point.heading)],
    ['Latitude', point.latitude],
    ['Longitude', point.longitude],
    ['Satellites', point.satellites],
    [
      'Ignition',
      typeof point.ignition === 'boolean'
        ? point.ignition
          ? 'On'
          : 'Off'
        : null,
    ],
    [
      'Airbag',
      typeof point.airbag === 'boolean' ? (point.airbag ? 'On' : 'Off') : null,
    ],
    [
      'Seatbelt',
      typeof point.seatbelt === 'boolean'
        ? point.seatbelt
          ? 'On'
          : 'Off'
        : null,
    ],
  ];

  let actions;

  // TODO get more camera details instead of boolean
  if (device?.camera)
    actions = <RequestVideoDirect point={point} onClose={onClose} />;

  return <Details primaryDetails={details} actions={actions} />;
}

HistoryPointDetails.propTypes = {
  point: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

HistoryPointDetails.defaultProps = {};

export default HistoryPointDetails;
