import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, Link, Typography } from '@mui/material';

import './serviceCalls.scss';
import mapAPIService from '../../../services/map';
import SidebarListView from '../../sidebar/listView/listView';
import APIService from '../../../services/api';
import { useMountedRef } from '../../../utilities/helpers';
import USFTAutocomplete from '../../forms/fields/autocomplete/autocomplete';
import {
  selectAuthUserPermissions,
  selectMapActiveDetailPopUp,
  selectMapFollowedMarker,
  selectMapServiceCallFilters,
  selectMapServiceCalls,
} from '../../../store/selectors';
import {
  setActiveDetailPopUp,
  setServiceCallFilters,
  setServiceCalls,
  toggleShowAll,
  updateServiceCall,
} from '../../../store/slices/map';

const fields = {
  query: 'query',
  priorities: 'priorities',
  statuses: 'statuses',
  territories: 'territories',
  technicians: 'technicians',
};

const buildFilterFields = (calls, onChange) => {
  const options = {
    priorityOptions: new Set(),
    statusOptions: new Set(),
    territoryOptions: new Set(),
    technicianOptions: new Set(),
  };

  Object.values(calls).forEach((call) => {
    options.priorityOptions.add(call.priority);
    options.statusOptions.add(call.status);
    if (call.location.territory)
      options.territoryOptions.add(call.location.territory);
    if (call.technician) options.technicianOptions.add(call.technician);
  });

  Object.entries(options).forEach(([key, set]) => {
    options[key] = [...set]
      .map((value) => ({ id: value, name: value }))
      .sort((a, b) => a.id.localeCompare(b.id));
  });

  return [
    {
      label: 'Filter by Priority',
      name: fields.priorities,
      options: options.priorityOptions,
    },
    {
      label: 'Filter by Status',
      name: fields.statuses,
      options: options.statusOptions,
    },
    {
      label: 'Filter by Territory',
      name: fields.territories,
      options: options.territoryOptions,
    },
    {
      label: 'Filter by Technician',
      name: fields.technicians,
      options: options.technicianOptions,
    },
  ].map((field) => (
    <Grid item xs={12} key={field.name}>
      <USFTAutocomplete
        name={field.name}
        label={field.label}
        onChange={onChange(field.name)}
        options={field.options}
        multiple
        disableCloseOnSelect
        limitTags={1}
      />
    </Grid>
  ));
};

function ServiceCallsSidebar() {
  const serviceCalls = useSelector(selectMapServiceCalls);

  const [isLoading, setIsLoading] = useState(
    Object.keys(serviceCalls).length === 0
  );

  const dispatch = useDispatch();
  const permissions = useSelector(selectAuthUserPermissions);
  const followedMarker = useSelector(selectMapFollowedMarker);
  const activeDetailPopUp = useSelector(selectMapActiveDetailPopUp);
  const filters = useSelector(selectMapServiceCallFilters);

  const form = useForm({
    defaultValues: Object.entries(filters).reduce(
      (defaultValues, [field, values]) => ({
        ...defaultValues,
        [field]: values.map((value) => ({ id: value, name: value })),
      }),
      {}
    ),
  });
  const isMounted = useMountedRef();

  const handleFilterChange = useCallback(
    (type) => async (event, newValue) => {
      form.setValue(type, newValue);
      form.setValue(fields.query, '');
      if (newValue)
        return dispatch(
          setServiceCallFilters({ [type]: newValue.map((filter) => filter.id) })
        );
    },
    [form, dispatch]
  );

  useEffect(() => {
    APIService.get('map/service-calls').then((response) => {
      if (isMounted.current) {
        dispatch(setServiceCalls(response.data));
        setIsLoading(false);
      }
    });
  }, [dispatch, isMounted]);

  // TODO optimize filtering
  let filteredCalls = Object.values(serviceCalls);
  if (filters.priorities.length > 0)
    filteredCalls = filteredCalls.filter((call) =>
      filters.priorities.includes(call.priority)
    );
  if (filters.statuses.length > 0)
    filteredCalls = filteredCalls.filter((call) =>
      filters.statuses.includes(call.status)
    );
  if (filters.territories.length > 0)
    filteredCalls = filteredCalls.filter((call) =>
      filters.territories.includes(call.location.territory)
    );
  if (filters.technicians.length > 0)
    filteredCalls = filteredCalls.filter((call) =>
      filters.technicians.includes(call.technician)
    );

  const filterRowsFunction = (query, serviceCall) =>
    (serviceCall.workOrder + serviceCall.customer.name + serviceCall.technician)
      .toLocaleLowerCase()
      .indexOf(query.toLocaleLowerCase()) >= 0;

  const handleChange = (serviceCallID) => async (event) => {
    const isVisible = !serviceCalls[serviceCallID].visible;
    dispatch(updateServiceCall(serviceCallID, { visible: isVisible }));
    if (isVisible && !followedMarker)
      return mapAPIService.center({
        latitude: serviceCalls[serviceCallID].location.latitude,
        longitude: serviceCalls[serviceCallID].location.longitude,
        zoomLevel: 14,
      });

    if (!isVisible && activeDetailPopUp === serviceCallID)
      dispatch(setActiveDetailPopUp(null));
  };

  const handleAllChange = async (event) => {
    const callIDs = filteredCalls.map((call) => call.id);
    dispatch(toggleShowAll('serviceCalls', event.target.checked, callIDs));
    if (event.target.checked && !followedMarker)
      return mapAPIService.resetView();
    if (
      !event.target.checked &&
      Object.keys(serviceCalls).includes(String(activeDetailPopUp))
    )
      dispatch(setActiveDetailPopUp(null));
  };

  let formFields = null;
  if (Object.keys(serviceCalls).length > 0)
    formFields = buildFilterFields(serviceCalls, handleFilterChange);

  let ctaMessage;
  if (!permissions.serviceCalls.view)
    ctaMessage = (
      <Typography align='center' fontSize='.8em'>
        CONTACT SUPPORT TO INQUIRE HOW YOU CAN HAVE YOUR SERVICE CALLS IMPORTED
        FROM YOUR BACKEND SYSTEM TO OURS!
        <br />
        Call <Link href='tel:4057269900'>(405) 726-9900</Link> or use the
        built-in support chat in lower right corner.
      </Typography>
    );

  return (
    <FormProvider {...form}>
      {isLoading ? (
        <Box className='service-call-loading'>
          <CircularProgress color='inherit' size={60} />
        </Box>
      ) : (
        <SidebarListView
          rows={filteredCalls}
          getRowPrimaryText={(serviceCall) => serviceCall.customer.name}
          getRowSecondaryText={(serviceCall) => serviceCall.workOrder}
          filterRows={filterRowsFunction}
          sortRows={(a, b) => b.workOrder.localeCompare(a.workOrder)}
          emptyMessage='No Service Calls Found'
          extraMessage={ctaMessage}
          onChange={handleChange}
          onAllChange={handleAllChange}
          formFields={formFields}
        />
      )}
    </FormProvider>
  );
}

ServiceCallsSidebar.propTypes = {};

ServiceCallsSidebar.defaultProps = {};

export default ServiceCallsSidebar;
