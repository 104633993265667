import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { Box, Grid, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';

import './stopDetails.scss';
import mapAPIService from '../../../../../../services/map';
import { useMountedRef } from '../../../../../../utilities/helpers';
import { formatAddress } from '../../../../../../utilities/_algorithms';

function StopDetails({ event }) {
  const [address, setAddress] = useState(null);

  const isMounted = useMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    mapAPIService
      .getLocation({ latitude: event.latitude, longitude: event.longitude })
      .then((location) => {
        if (isMounted.current) setAddress(formatAddress(location));
      })
      .catch((e) => {
        console.error(e);
        enqueueSnackbar(
          'Unable to fetch Google Street View. Try again later.',
          { variant: 'error' }
        );
      });
  }, [event, enqueueSnackbar, isMounted]);

  const startDateTime = DateTime.fromISO(event.startDateTime);
  const endDateTime = DateTime.fromISO(event.endDateTime);
  let durationUnit;
  let duration = endDateTime
    .diff(startDateTime, ['hours', 'minutes'])
    .toObject();
  if (duration.hours >= 1) {
    durationUnit = duration.hours === 1 ? 'Hour' : 'Hours';
    duration = duration.hours;
  } else {
    durationUnit = 'MIN';
    duration = Math.round(duration.minutes);
  }

  return (
    <Box className='history-stop-event-details'>
      <Grid container spacing={1} className='duration'>
        <Grid item xs={12}>
          <Typography align='center' mb={1}>
            {address || 'Loading...'}
            {/* {address ? address.split(',')[0] : 'Loading...'} */}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} className='left-column'>
          <Typography variant='h3'>{duration}</Typography>
          <Typography>{durationUnit}</Typography>
        </Grid>
        <Grid item xs={12} sm={9} className='right-column'>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs='auto' className='timeline'>
              <CircleIcon color='error' />
              |
              <CircleIcon color='success' />
            </Grid>
            <Grid item xs='auto'>
              <Typography mb={2}>
                {startDateTime.toLocaleString(DateTime.DATETIME_MED)}
              </Typography>
              <Typography>
                {endDateTime.toLocaleString(DateTime.DATETIME_MED)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

StopDetails.propTypes = {
  event: PropTypes.object.isRequired,
};

StopDetails.defaultProps = {};

export default StopDetails;
