import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './deviceGroup.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';
import { allDevicesOption } from '../helpers';

function DeviceGroupField({ includeAll, filterFunction, ...props }) {
  const { enqueueSnackbar } = useSnackbar();

  const getOptions = async (event) => {
    return APIService.get('/devices/groups/options')
      .then((response) => {
        let options = response.data;
        if (typeof filterFunction === 'function')
          options = options.filter(filterFunction);
        options = options.sort((a, b) => a.name.localeCompare(b.name));
        if (includeAll) options.splice(0, 0, allDevicesOption);
        return options;
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Unable to get device group data. Try again later.', {
          variant: 'error',
        });
      });
  };

  return (
    <USFTAutocomplete
      getOptions={getOptions}
      defaultValue={includeAll ? allDevicesOption : undefined}
      {...props}
    />
  );
}

DeviceGroupField.propTypes = {
  includeAll: PropTypes.bool.isRequired,
  filterFunction: PropTypes.func,
};

DeviceGroupField.defaultProps = {
  includeAll: false,
};

export default DeviceGroupField;
