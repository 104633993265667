import { DateTime } from 'luxon';

// Cheating here for History playback marker
export const isRecentlyUpdated = (device) => {
  if (device.location.lastUpdated)
    return (
      DateTime.now()
        .diff(DateTime.fromISO(device.location.lastUpdated))
        .as('days') < 2
    );
  else return true;
};

/**
 *
 * @param {Object} device - Device to get ring color for
 * @returns {String} - MUI theme color or hex value
 */
export const getDeviceRingColor = (device) => {
  let ringColor = 'error.main';
  if (
    (typeof device.power === 'number' && device.power === 0) ||
    !isRecentlyUpdated(device)
  )
    ringColor = 'grey.800';
  else if (device.currentLocation.velocity > 0) ringColor = 'success.main';
  else if (device.ignition) ringColor = 'warning.main';

  return ringColor;
};
