import { ListItemText, Checkbox } from '@mui/material';
import { useSnackbar } from 'notistack';

import './address.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';

const multipleRenderOption = (props, option, { selected }) => (
  <li {...props} key={option.id}>
    <Checkbox style={{ marginRight: 8 }} checked={selected} />
    <ListItemText
      primary={option.name}
      secondary={option.address || `${option.latitude} / ${option.longitude}`}
    />
  </li>
);

const singleRenderOption = (props, option) => (
  <li {...props} key={option.id}>
    <ListItemText
      primary={option.name}
      secondary={option.address || `${option.latitude} / ${option.longitude}`}
    />
  </li>
);

function USFTAddressField(props) {
  const { enqueueSnackbar } = useSnackbar();

  const getOptions = async (event) => {
    return APIService.get('/addresses/options')
      .then((response) =>
        response.data
          .sort((a, b) => -b.name.localeCompare(a.name))
          .map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
              ...option,
              firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            };
          })
      )
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('Unable to get Address data. Try again later.', {
          variant: 'error',
        });
      });
  };

  return (
    <USFTAutocomplete
      getOptions={getOptions}
      groupBy={(option) => option.firstLetter}
      renderOption={props.multiple ? multipleRenderOption : singleRenderOption}
      {...props}
    />
  );
}

USFTAddressField.propTypes = {};

USFTAddressField.defaultProps = {};

export default USFTAddressField;
