import { DateTime } from 'luxon';
import store from '../store';
import { setFeatures } from '../store/slices/app';

// This is used to help display NEW dots for components and features
export class FeatureService {
  localStorageKey = 'features';
  current = {};
  IDs = {
    timeZone: '6c46e3ed-9f93-4a4a-a13c-7ae01757fbfe',
  };
  features = {
    [this.IDs.timeZone]: {
      name: 'Time Zone Tip',
      expires: DateTime.fromISO('2023-04-07T08:00:00'),
    },
  };

  constructor() {
    const cache = localStorage.getItem(this.localStorageKey);
    const currentValues = cache ? JSON.parse(cache) : {};
    this.current = Object.entries(this.features)
      .filter(([key, feature]) => feature.expires.diffNow() > 0)
      .reduce(
        (prev, [key, feature]) => ({
          ...prev,
          [key]: currentValues[key] ?? true,
        }),
        {}
      );
    this.save();
  }

  view = async (id) => {
    console.debug('Viewing Feature', id);
    if (this.current[id]) {
      this.current[id] = false;
      return this.save();
    }
  };

  save = async () => {
    store.dispatch(setFeatures(this.current));
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.current));
  };
}

const featureService = new FeatureService();

export default featureService;
