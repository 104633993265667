import APIService from './api';
import { urlBase64ToUint8Array } from '../utilities/_algorithms';

/** Class for Notification actions */
class NotificationService {
  unsubscribe = async (updateBackend = true) => {
    console.debug('Clearing subscriptions');
    let promises = [];
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      promises = registrations.map(async (registration) => {
        const subscription = await registration.pushManager.getSubscription();
        if (subscription) {
          const unsubscribed = await subscription.unsubscribe();
          if (unsubscribed && updateBackend)
            return APIService.post('notifications/subscriptions/unset', {
              endpoint: subscription.endpoint,
            }).catch((error) => console.error(error));
          return null;
        }
      });
    }
    return Promise.all(promises);
  };
  subscribe = async () => {
    const result = await Notification.requestPermission();
    if (result === 'granted') {
      console.info('Subscribing to notifications');
      const registration = await navigator.serviceWorker.ready;
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_VAPID_PUBLIC_KEY
        ),
      };
      return registration.pushManager
        .subscribe(subscribeOptions)
        .then(async (pushSubscription) => {
          return APIService.post('notifications/subscriptions/set', {
            subscription: pushSubscription,
          }).catch((error) => {
            pushSubscription.unsubscribe();
            throw error;
          });
        });
    } else throw Error('Permission not granted');
  };
}

const notificationsService = new NotificationService();

export default notificationsService;
