import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import './drivers.scss';
import mapAPIService from '../../../services/map';
import SidebarListView from '../../sidebar/listView/listView';
import {
  selectAuthUserPermissions,
  selectMapActiveDetailPopUp,
  selectMapDevicesWithDrivers,
  selectMapDrivers,
  selectMapFollowedMarker,
} from '../../../store/selectors';
import {
  setActiveDetailPopUp,
  toggleShowAll,
  updateDevice,
} from '../../../store/slices/map';

function DriversSidebar() {
  const dispatch = useDispatch();
  const devices = useSelector(selectMapDevicesWithDrivers);
  const drivers = useSelector(selectMapDrivers);
  const activeDetailPopUp = useSelector(selectMapActiveDetailPopUp);
  const followedMarker = useSelector(selectMapFollowedMarker);
  const permissions = useSelector(selectAuthUserPermissions);
  const form = useForm();

  const visibleDriverIDs = [];
  const assignedDrivers = Object.values(devices).map((device) => {
    if (device.visible) visibleDriverIDs.push(device.driverID);
    return device.driverID;
  });

  let filteredDrivers = Object.values(drivers).map((driver) => ({
    ...driver,
    disabled: !assignedDrivers.includes(driver.id),
    visible: visibleDriverIDs.includes(driver.id),
  }));

  const filterRowsFunction = (query, driver) =>
    driver.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;

  const handleChange = (driverID) => async (event) => {
    const isVisible = !visibleDriverIDs.includes(driverID);
    const device = Object.values(devices).find(
      (device) => device.driverID === driverID
    );
    dispatch(
      updateDevice({ id: device.id, properties: { visible: isVisible } })
    );
    if (isVisible && !followedMarker) {
      await mapAPIService.disableAutoZoom();
      return mapAPIService.center({
        latitude: device.location.latitude,
        longitude: device.location.longitude,
        zoomLevel: mapAPIService.maxClusterZoom + 1,
      });
    }
    if (!isVisible) {
      if (activeDetailPopUp === device.id) dispatch(setActiveDetailPopUp(null));
      if (followedMarker === device.id) mapAPIService.unfollow();
    }
  };

  const handleAllChange = async (event) => {
    const deviceIDs = Object.values(devices).map((device) => device.id);
    dispatch(
      toggleShowAll({
        type: 'devices',
        show: event.target.checked,
        ids: deviceIDs,
      })
    );
    if (event.target.checked && !followedMarker)
      return mapAPIService.resetView();

    if (!event.target.checked) mapAPIService.unfollow();
    if (!event.target.checked && deviceIDs.includes(activeDetailPopUp))
      dispatch(setActiveDetailPopUp(null));
  };

  return (
    <FormProvider {...form}>
      <SidebarListView
        rows={filteredDrivers}
        getRowPrimaryText={(driver) => driver.name}
        filterRows={filterRowsFunction}
        sortRows={(a, b) => a.name.localeCompare(b.name)}
        emptyMessage='No Drivers Found'
        canManage={permissions.contacts.manage}
        manageURL={'manage/contacts'}
        onChange={handleChange}
        onAllChange={handleAllChange}
      />
    </FormProvider>
  );
}

DriversSidebar.propTypes = {};

DriversSidebar.defaultProps = {};

export default DriversSidebar;
