import { useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, LinearProgress, Typography, useMediaQuery } from '@mui/material';

import './app.scss';
import { getDealer, themeModes } from '../../../utilities/helpers';
import usftLightLogo from '../../../static/images/logo/usft_tech_light.svg';
import usftDarkLogo from '../../../static/images/logo/usft_tech_dark.svg';
import servAppLightLogo from '../../../static/images/logo/servApp_light.svg';
import servAppDarkLogo from '../../../static/images/logo/servApp_dark.svg';
import usftFavicon from '../../../static/images/icons/favicon.png';

function AppLoading({ themeMode }) {
  const dealer = useMemo(getDealer, []);
  const location = useLocation();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true,
  });

  let mode;
  if (themeMode === themeModes.auto)
    mode = prefersDarkMode ? themeModes.dark : themeModes.light;
  else mode = themeMode;

  let appLogo,
    parentLogo,
    height = 100;
  if (dealer) {
    appLogo = dealer.logo;
  } else if (location.pathname.includes('/service')) {
    height = 70;
    appLogo = mode === themeModes.dark ? servAppDarkLogo : servAppLightLogo;
    parentLogo = mode === themeModes.dark ? usftDarkLogo : usftLightLogo;
  } else {
    appLogo = usftFavicon;
    parentLogo = mode === themeModes.dark ? usftDarkLogo : usftLightLogo;
  }

  return (
    <Box className='app-loading-container'>
      <LinearProgress />
      <img src={appLogo} alt='app logo' className='app-logo' height={height} />
      {parentLogo && (
        <Box className='parent-logo'>
          <Typography variant='button' align='center' fontSize={12}>
            Powered by
          </Typography>
          <img src={parentLogo} alt='parent company logo' width={100} />
        </Box>
      )}
    </Box>
  );
}

AppLoading.propTypes = {};

AppLoading.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    themeMode: state.app.preferences.theme.mode,
  };
};

export default connect(mapStateToProps)(AppLoading);
