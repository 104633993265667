export const darkTheme = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#6ec6f7',
    },
    secondary: {
      main: '#7b97c2',
    },
  },
};
export const lightTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#4D738A',
    },
    secondary: {
      main: '#2F4C5D',
    },
    success: {
      main: '#66842F',
    },
    error: {
      main: '#D8262F',
    },
  },
};

// Hobby Lobby
// $primary: #B53315;
// $secondary: #0848ab;

// Chesapeake Energy
// $primary: #8DC63F;
// $secondary: #0B336B;

// Salvation Army
// $primary: #ed1c2b;
// $secondary: #2a313f;

// Amazon
// $primary: #FFD814;
// $secondary: #131921;

// AMD
// $primary: #dd0031;
// $secondary: #000;

// Nvidia
// $primary: #76b900;
// $secondary: #000;

// Continental
// $primary: #EAAA00;
// $secondary: #393A3A;
