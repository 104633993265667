import axios from "axios";

// TODO implement retry logic
/** API to talk to Webapp/Slayer. */
const APIService = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    // timeout: 4000, // TODO reduce after optimize
});
/** API to use for any external endpoint. */
const GlobalAPIService = axios.create();

export default APIService;
export { GlobalAPIService };