import { useContext } from 'react';
import { Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

import './field.scss';
import { LoadingContext } from '../common/context';

function CRUDField({ loadingComponent, children, ...props }) {
  const loading = useContext(LoadingContext);

  let field;
  if (loading)
    field = loadingComponent || <Skeleton variant='text' height={56} />;
  else field = children;

  return (
    <Grid item {...props}>
      {field}
    </Grid>
  );
}

CRUDField.propTypes = {
  loadingComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

CRUDField.defaultProps = {};

export default CRUDField;
