import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import './expandableTextCell.scss';

// https://github.com/mui/mui-x/issues/1040#issuecomment-1361092416
const ExpandableTextCell = ({ value }) => {
  const [isOverflowed, setIsOverflow] = useState(false);

  const textElementRef = useRef(null);

  useEffect(() => {
    if (textElementRef.current) {
      const checkOverflow = () => {
        // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
        const clientWidth =
          textElementRef.current.getBoundingClientRect().width;

        textElementRef.current.style.overflow = 'visible';
        const contentWidth =
          textElementRef.current.getBoundingClientRect().width;
        textElementRef.current.style.overflow = 'hidden';

        setIsOverflow(contentWidth > clientWidth);
      };
      checkOverflow();
      window.addEventListener('resize', checkOverflow);
      return () => {
        window.removeEventListener('resize', checkOverflow);
      };
    }
  }, []);

  const text = value.length > 0 ? value : '--';

  return (
    <Tooltip title={text} disableHoverListener={!isOverflowed}>
      <span ref={textElementRef} className='expandable-text-cell'>
        {text}
      </span>
    </Tooltip>
  );
};

ExpandableTextCell.propTypes = {
  value: PropTypes.any.isRequired,
};

ExpandableTextCell.defaultProps = {};

export default ExpandableTextCell;
