import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import './requestVideo.scss';
import APIService from '../../../../../services/api';
import { useMountedRef } from '../../../../../utilities/helpers';
import { updatePlayerData } from '../../../../../store/slices/historyPlayback';
import { selectHistoryEvents } from '../../../../../store/selectors';

function RequestVideoDirect({ point, onClose }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useMountedRef();
  const dispatch = useDispatch();
  const events = useSelector(selectHistoryEvents);

  const handleClick = async (clickEvent) => {
    setIsSubmitting(true);
    return APIService.post(`/history/videos/request`, {
      device: point.device,
      time: point.time,
    })
      .then((response) => {
        enqueueSnackbar('Video has been requested', { variant: 'success' });
        // TODO remove and use data back from request
        const newEvent = {
          id: uuidv4(),
          type: 'videoRequest',
          time: point.time,
          status: 'Requested',
          device: point.device,
          latitude: point.latitude,
          longitude: point.longitude,
          heading: point.heading,
        };
        let insertIndex;
        const time = DateTime.fromISO(point.time);
        events.every((event, index) => {
          const eventTime = DateTime.fromISO(event.time);
          if (time > eventTime) return true;
          else insertIndex = index;
          return false;
        });
        const newEvents = [...events];
        newEvents.splice(insertIndex, 0, newEvent);
        dispatch(updatePlayerData({ events: newEvents }));
        onClose();
      })
      .catch((error) => {
        console.error('Error', error);
        enqueueSnackbar(
          error.response?.data.error ||
            'Unable to Request Video. Try again later.',
          { variant: 'error' }
        );
      })
      .finally(() => {
        if (isMounted.current) {
          setIsSubmitting(false);
        }
      });
  };

  return (
    <>
      <LoadingButton
        variant='contained'
        fullWidth
        onClick={handleClick}
        loading={isSubmitting}
      >
        Request Video
      </LoadingButton>
    </>
  );
}

RequestVideoDirect.propTypes = {
  point: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

RequestVideoDirect.defaultProps = {};

export default RequestVideoDirect;
