import { createElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';
import PropTypes from 'prop-types';

import './themeMode.scss';
import { themeModes } from '../../../../utilities/helpers';
import mapAPIService from '../../../../services/map';
import { selectAppThemePreferences } from '../../../../store/selectors';
import { setThemeMode } from '../../../../store/slices/app';

const themeMap = {
  [themeModes.light]: { label: 'Light', icon: LightModeIcon },
  [themeModes.auto]: { label: 'Auto', icon: BrightnessMediumIcon },
  [themeModes.dark]: { label: 'Dark', icon: DarkModeIcon },
};

function ThemeModeField({ expanded }) {
  const dispatch = useDispatch();
  const preferences = useSelector(selectAppThemePreferences);

  const handleThemeChange = (mode) => {
    localStorage.setItem('themeMode', mode);
    dispatch(setThemeMode(mode));
    mapAPIService.updateMapTheme(mode);
  };

  let buildOption;

  if (expanded)
    buildOption = (mode) => (
      <ToggleButton
        key={mode}
        value={mode}
        aria-label={mode}
        onClick={() => handleThemeChange(mode)}
      >
        {createElement(themeMap[mode].icon)}
        <Typography ml={0.5}>{themeMap[mode].label}</Typography>
      </ToggleButton>
    );
  else
    buildOption = (mode) => (
      <Tooltip
        title={themeMap[mode].label}
        placement='top'
        key={mode}
        value={mode}
      >
        <ToggleButton
          value={mode}
          aria-label={mode}
          onClick={() => handleThemeChange(mode)}
        >
          {createElement(themeMap[mode].icon)}
        </ToggleButton>
      </Tooltip>
    );

  return (
    <ToggleButtonGroup
      exclusive
      value={preferences.mode}
      color='primary'
      aria-label='theme mode'
      className='theme-mode-select'
    >
      {Object.values(themeModes).map(buildOption)}
    </ToggleButtonGroup>
  );
}

ThemeModeField.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

ThemeModeField.defaultProps = {
  expanded: false,
};

export default ThemeModeField;
