import { useState } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './requestVideoButton.scss';
import FormDialog from '../../crud/formDialog/formDialog';
import RequestVideoForm from '../form/requestVideoForm';

function RequestVideoButton({ device, time, ...props }) {
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = (e) => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleSave = () => {
    handleClose();
  };

  return (
    <>
      <Button variant='contained' onClick={handleClick}>
        Request Video
      </Button>
      <FormDialog
        open={showDialog}
        onClose={handleClose}
        onSave={handleSave}
        form={RequestVideoForm}
        model='device'
        title='Request Video'
        submitButtonText='Request'
        formProps={{ device, time }}
        dialogProps={{ TransitionProps: { unmountOnExit: true } }}
      />
    </>
  );
}

RequestVideoButton.propTypes = {
  device: PropTypes.object.isRequired,
  time: PropTypes.string,
};

RequestVideoButton.defaultProps = {};

export default RequestVideoButton;
