import { Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './contact.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';
import USFTCheckbox from '../checkbox/checkbox';
import { formatPhoneNumber } from '../../../../utilities/_algorithms';

function ContactField({ name, ...props }) {
    const { getValues, watch } = useFormContext();
    const { enqueueSnackbar } = useSnackbar();

    const contact = watch(`${name}.details`)

    const getContacts = async () => {
        return APIService.get('/contacts/')
            .then(response => response.data.contacts
                .sort((a, b) => -b.firstName.localeCompare(a.firstName))
                .map(option => ({
                    ...option,
                    name: `${option.firstName} ${option.lastName || ''}`.trim(),
                }))
            )
            .catch(e => {
                console.error(e)
                enqueueSnackbar('Unable to get contact data. Try again later.', { variant: 'error' })
            })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <USFTAutocomplete
                    getOptions={getContacts}
                    {...props}
                    name={`${name}.details`}
                    rules={{
                        validate: value => (value && (getValues(`${name}.useEmail`) || getValues(`${name}.useSMS`))) || 'Please select either Email or SMS',
                        ...props.rules,
                    }}
                />

            </Grid>
            {contact && (
                <>
                    {contact.email && (
                        <Grid item xs={12}>
                            <USFTCheckbox
                                name={`${name}.useEmail`}
                                label={`Send email to ${contact.email}`}
                                rules={{
                                    deps: [name]
                                }}
                            />
                        </Grid>
                    )}
                    {contact.sms && (
                        <Grid item xs={12}>
                            <USFTCheckbox
                                name={`${name}.useSMS`}
                                label={`Send SMS to ${formatPhoneNumber(contact.sms)}`}
                                rules={{
                                    deps: [name]
                                }}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

ContactField.propTypes = {
    name: PropTypes.string.isRequired,
}

ContactField.defaultProps = {}

export default ContactField;
