import { Box, Rating, Typography } from '@mui/material';
import { useController } from "react-hook-form";
import PropTypes from 'prop-types';

import './rating.scss';

function USFTRatingField({ name, label, defaultValue, rules, helperText, ...props }) {
    const { field, fieldState: { error } } = useController({ name, rules, defaultValue, shouldUnregister: true });

    return (
        <Box>
            <Typography component='legend'>
                {(label && rules?.required) ? `${label} *` : label}
            </Typography>
            <Rating
                {...field}
                onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                {...props}
            />
            {(helperText || error) && (
                <Typography variant='caption' display="block" color={error !== undefined ? 'error' : null}>
                    {error?.message || helperText}
                </Typography>
            )}
        </Box>
    );
};

USFTRatingField.propTypes = {
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    rules: PropTypes.object,
    helperText: PropTypes.string,
};

USFTRatingField.defaultProps = {
    defaultValue: 5
};

export default USFTRatingField;