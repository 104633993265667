import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import './select.scss';

// TODO refactor to support "multiple" prop better
function USFTSelect({
  name,
  label: rawLabel,
  defaultValue,
  rules,
  helperText: rawHelperText,
  children,
  options,
  controllerProps,
  ...props
}) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules,
    defaultValue,
    shouldUnregister: true,
    ...controllerProps,
  });

  const labelId = `${name}-label`;
  const label = rawLabel && rules?.required ? `${rawLabel} *` : rawLabel;
  const helperText = error?.message || rawHelperText;

  return (
    <FormControl fullWidth>
      <InputLabel id={labelId} error={error !== undefined}>
        {label}
      </InputLabel>
      <Select
        {...field}
        labelId={labelId}
        label={label}
        error={error !== undefined}
        {...props}
      >
        {children ||
          (options
            ? options.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))
            : null)}
      </Select>
      {helperText && (
        <FormHelperText error={error !== undefined}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

USFTSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rules: PropTypes.object,
  controllerProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  helperText: PropTypes.string,
  children: PropTypes.array,
  options: PropTypes.array,
};

USFTSelect.defaultProps = {
  defaultValue: '',
};

export default USFTSelect;
