import { Accordion, AccordionDetails, AccordionSummary, Paper } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';

import './sidebarSection.scss';
import SidebarItem from '../sidebarItem/sidebarItem';

function SidebarSection({ title, options, onClick, ...props }) {
    return (
        <Accordion elevation={0} disableGutters square defaultExpanded sx={{ '&:before': { display: 'none' } }}>
            <AccordionSummary component={Paper} square elevation={1} expandIcon={<ExpandMore />}>
                {title}
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
                {options.map((props, index) => <SidebarItem {...props} key={index} onClick={onClick} />)}
            </AccordionDetails>
        </Accordion>
    );
};

SidebarSection.propTypes = {
    title: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onClick: PropTypes.func,
};

SidebarSection.defaultProps = {};

export default SidebarSection;
