import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentDevice: null,
  devices: {},
  points: [],
  events: [],
  coordinates: [],
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    updatePlayerData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetPlayerData: (state, action) => {
      return {
        ...initialState,
        ...action.payload,
      };
    },
  },
});

export const { updatePlayerData, resetPlayerData } = historySlice.actions;

export default historySlice.reducer;
