import { createElement, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import './formDialog.scss';
import USFTDialogTitle from '../../dialog/dialogTitle/dialogTitle';
import { capitalize } from '../../../utilities/_algorithms';

// TODO Abstract form base
function FormDialog(props) {
  const [submitting, setSubmitting] = useState(false);

  const formID = `${uuidv4()}-${props.model}-form`;

  const handleSubmit = () => {
    setSubmitting(true);
  };

  const handleFormError = (error) => {
    setSubmitting(false);
  };

  const handleClose = (event) => {
    setSubmitting(false);
    if (typeof props.onClose === 'function') props.onClose(event);
  };

  const handleSave = (object) => {
    setSubmitting(false);
    if (typeof props.onSave === 'function') props.onSave(object);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      fullWidth
      maxWidth={props.size}
      {...props.dialogProps}
    >
      <USFTDialogTitle
        title={
          props.title ||
          `${
            props.formProps.id && !props.formProps.duplicate ? 'Edit' : 'Create'
          } ${capitalize(props.model)}`
        }
        onClose={handleClose}
      />
      <DialogContent dividers>
        {createElement(props.form, {
          ...props.formProps,
          onSubmit: handleSubmit,
          onSave: handleSave,
          onError: handleFormError,
          useCustomButton: true,
          formID,
          model: props.model,
        })}
      </DialogContent>
      <DialogActions>
        {props.actions}
        <LoadingButton
          variant='contained'
          color='success'
          loading={submitting}
          form={formID}
          type='submit'
        >
          {props.submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = {
  form: PropTypes.elementType.isRequired,
  title: PropTypes.string,
  model: PropTypes.string,
  size: PropTypes.string,
  submitButtonText: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  formProps: PropTypes.object,
  dialogProps: PropTypes.object,
};

FormDialog.defaultProps = {
  open: false,
  size: 'sm',
  submitButtonText: 'Save',
  formProps: {},
};

export default FormDialog;
