import { ListItemButton, ListItemIcon, ListItemText, Icon } from '@mui/material';
import { NavLink, useLocation, useResolvedPath } from 'react-router-dom';
import PropTypes from 'prop-types';

import './sidebarItem.scss';

function SidebarItem(props) {
    const location = useLocation();
    const path = useResolvedPath(props.to);

    const locationPathname = location.pathname.toLowerCase();
    const toPathname = path.pathname.toLowerCase();

    return (
        <ListItemButton
            disabled={props.disabled}
            onClick={props.onClick}
            selected={locationPathname === toPathname}
            component={NavLink}
            to={props.to}
            end
        >
            {props.icon && (
                <ListItemIcon>
                    <Icon component={props.icon} />
                </ListItemIcon>
            )}
            <ListItemText primary={props.label} />
        </ListItemButton>
    );
};

SidebarItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    icon: PropTypes.object,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

SidebarItem.defaultProps = {
    disabled: false
};

export default SidebarItem;
