import { Suspense } from 'react';
import { Grid, Paper } from '@mui/material';
import { Outlet } from 'react-router-dom';

import './split.scss';
import SplitLoading from '../../components/loading/split/splitLoading';
import loginBackground from '../../static/images/login/background.jpg';

function SplitLayout(props) {

    return (
        <Grid container className='split-layout'>
            <Grid item xs={false} md={8} className='background-container' sx={{ display: { xs: 'none', md: 'block' } }}>
                <img src={loginBackground} alt="" />
            </Grid>
            <Grid item xs={12} md={4} component={Paper} elevation={6} square className='component-container'>
                <Suspense fallback={<SplitLoading />}>
                    <Outlet />
                </Suspense>
            </Grid>
        </Grid >
    );
};

SplitLayout.propTypes = {};

SplitLayout.defaultProps = {};

export default SplitLayout;