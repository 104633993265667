import store from '../store';
import APIService from './api';
import analyticsService from './analytics';
import notificationsService from './notifications';
import mapAPIService from './map';
import { authFail, authSuccess, setUser } from '../store/slices/auth';

/** Class for Authenication actions */
class AuthService {
  handleAuthSuccess = async (token) => {
    APIService.defaults.headers['Authorization'] = `Bearer ${token}`;
    await this.loadUser();
    const currentUserID = store.getState().auth.user?.id;
    const lastLoginID = localStorage.getItem('lastLoginID');
    if (!lastLoginID || currentUserID !== Number(lastLoginID)) {
      localStorage.removeItem(mapAPIService.localStorageKey);
      localStorage.setItem('lastLoginID', currentUserID);
      notificationsService.unsubscribe(false);
    }
    return store.dispatch(authSuccess({ token }));
  };
  handleAuthFailure = async (errorMessage) => {
    APIService.defaults.headers['Authorization'] = undefined;
    localStorage.removeItem('token');
    return store.dispatch(authFail(errorMessage));
  };
  demoLogin = async () => {
    localStorage.clear();
    return APIService.post('auth/login/demo')
      .then(async (response) => {
        await this.handleAuthSuccess(response.data.token);
        analyticsService.track('login', 'demo');
      })
      .catch(async (error) => {
        return this.handleAuthFailure();
      });
  };
  oneTimeLogin = async (params) => {
    localStorage.clear();
    return APIService.post('auth/login/onetime', params)
      .then(async (response) => {
        await this.handleAuthSuccess(response.data.token);
        analyticsService.track('login', 'oneTime');
      })
      .catch(async (error) => {
        return this.handleAuthFailure();
      });
  };
  sharedViewLogin = async (params) => {
    localStorage.clear();
    return APIService.post('auth/login/shared-view', params)
      .then(async (response) => {
        APIService.defaults.headers[
          'Authorization'
        ] = `Bearer ${response.data.token}`;
        notificationsService.unsubscribe();
        store.dispatch(
          authSuccess({ token: response.data.token, viewOnly: true })
        );
        analyticsService.track('login', 'shared-view');
      })
      .catch(async (error) => {
        return this.handleAuthFailure('Link unavailable');
      });
  };
  /**
   * Attempts a login request.
   * @param {Dict} params - POST data to be sent. (Username, Password, etc.)
   * @returns {Promise} API Promise
   */
  login = async (params) => {
    return APIService.post('auth/login', params)
      .then(async (response) => {
        if (params.rememberMe)
          localStorage.setItem('token', response.data.token);
        await this.handleAuthSuccess(response.data.token);
        analyticsService.track('login', 'form');
      })
      .catch(async (error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          throw new Error(error.response.data.error);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          throw new Error('Network Error');
        } else {
          // Something happened in setting up the request that triggered an Error
          throw new Error(error.message);
        }
      });
  };
  /**
   * Tries to login using token if available
   * @returns {Boolean} Successful Login
   */
  autoLogin = async () => {
    const token = localStorage.token || null;
    if (token !== null) {
      console.debug('Token found');
      return this.handleAuthSuccess(token)
        .then(async () => {
          analyticsService.track('login', 'rememberMe');
          return true;
        })
        .catch(async (error) => {
          return this.handleAuthFailure().then(() => false);
        });
    } else {
      console.debug('No token found');
      return this.handleAuthFailure().then(() => false);
    }
  };
  /* Loads a user's information */
  loadUser = async () => {
    console.debug('Loading User');
    return APIService.get('users/me').then(async (response) => {
      return store.dispatch(setUser(response.data));
    });
  };
  /* Logs out the user */
  logout = async () => {
    console.debug('Logging out');
    await notificationsService.unsubscribe();
    localStorage.removeItem('token');
    // TODO make API call to blacklist token
  };
}

const authService = new AuthService();

export default authService;
