import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Button, CircularProgress, Divider, FormControlLabel, Link, List, Paper, Switch, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './notificationCenter.scss';
import NotificationItem from '../notificationItem/notificationItem';
import NotificationCTA from '../cta/notificationCTA';
import APIService from '../../../services/api';
import { useMountedRef } from '../../../utilities/helpers';

const fetchLimit = 5;

// TODO cache calls
// TODO get End Of List marker from slayer so we don't make blank call
function NotificationCenter({ socket, unreadNotifications, setUnreadNotifications, onClose, canManage, ...props }) {
    const [notifications, setNotifications] = useState([]);
    const [endOfList, setEndOfList] = useState(false);
    const [hasAlertsSetup, setHasAlertsSetup] = useState(true);
    const [onlyShowUnread, setOnlyShowUnread] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSeeMore, setIsLoadingSeeMore] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const isMounted = useMountedRef();

    useEffect(() => {
        if (socket && onlyShowUnread) {
            console.debug('Watching Notification Details');
            const handleNotifications = async (data) => {
                setNotifications(prevState => [
                    {
                        ...data,
                        unread: true
                    },
                    ...prevState
                ]);
            };
            socket.on('notifications', handleNotifications);
            return () => {
                console.debug('Unwatching Notification Details');
                socket.off('notifications', handleNotifications);
            };
        }
    }, [socket, onlyShowUnread]);

    useEffect(() => {
        setIsLoading(true);
        let stillNeeded = true;
        APIService.get('/notifications', { params: { unread: onlyShowUnread, limit: fetchLimit } })
            .then(response => {
                if (isMounted.current && stillNeeded) {
                    setNotifications(response.data.notifications);
                    setEndOfList(response.data.notifications.length < fetchLimit);
                    setUnreadNotifications(response.data.unread);
                    setHasAlertsSetup(response.data.alertsSetup);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Unable to get Notifications. Try again later.', { variant: 'error' });
            });
        return () => {
            stillNeeded = false;
        };
    }, [onlyShowUnread, isMounted, setUnreadNotifications, enqueueSnackbar]);

    const seeMore = (event) => {
        setIsLoadingSeeMore(true);
        APIService.get('/notifications', { params: { unread: onlyShowUnread, limit: fetchLimit, timestamp: notifications.at(-1).id } })
            .then(response => {
                if (isMounted.current) {
                    setNotifications(prevValue => [
                        ...prevValue,
                        ...response.data.notifications
                    ]);
                    setEndOfList(response.data.notifications.length < fetchLimit);
                }
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Unable to get Notifications. Try again later.', { variant: 'error' });
            })
            .finally(() => {
                setIsLoadingSeeMore(false);
            });
    };

    const handleRead = (notificationID) => (event) => {
        APIService.post('/notifications/read', { id: notificationID }).then(response => {
            setNotifications(prevState => prevState.map(notification => {
                if (notification.id === notificationID)
                    return {
                        ...notification,
                        unread: false,
                    };
                else
                    return notification;
            }));
            setUnreadNotifications(prevState => prevState - 1);
        })
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Server Unavailable. Try again later.', { variant: 'error' });
            });
    };

    const handleAllRead = (event) => {
        APIService.post('/notifications/read', { id: 0 }).then(response => {
            setNotifications(prevState => prevState.map(notification => ({
                ...notification,
                unread: false,
            })));
            setUnreadNotifications(0);
        })
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Server Unavailable. Try again later.', { variant: 'error' });
            });
    };

    const toggleOnlyShowUnread = (event) => {
        setOnlyShowUnread(event.target.checked);
    };

    const renderNotification = (notification) => (
        <NotificationItem key={notification.id} {...notification} onChange={handleRead(notification.id)} />
    );

    const unreadSwitch = (
        <Switch
            size='small'
            color='secondary'
            checked={onlyShowUnread}
            onChange={toggleOnlyShowUnread}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );

    return (
        <Box className='notification-center'>
            <Box className='notifications-header' color='primary.contrastText' bgcolor='primary.main'>
                <Typography variant='h6'>Notifications</Typography>
                <NotificationsIcon />
            </Box>
            <NotificationCTA onClick={onClose} />
            <Box className='notifications-actions' p={2}>
                <FormControlLabel control={unreadSwitch} label='Unread' />
                <Button
                    size='small'
                    onClick={handleAllRead}
                    disabled={!onlyShowUnread || !unreadNotifications}
                >
                    Mark all as read
                </Button>
            </Box>
            <Divider />
            <Paper square elevation={0} className='notifications-list-container'>
                {isLoading ? (
                    <Box className='loading-container'>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <List className='notifications-list'>
                            {notifications.map(renderNotification)}
                        </List>
                        {endOfList ? (
                            <Box className='notifications-end' p={2}>
                                <NotificationsIcon />
                                {notifications.length > 0 ? (
                                    <Typography fontSize='1em' align='center'>
                                        That's all your notifications<br />from the last 30 days.
                                    </Typography>
                                ) : (
                                    <Typography fontSize='1em' align='center'>
                                        Looks like you're all caught up!
                                    </Typography>
                                )}
                            </Box>
                        ) : (
                            <Box className='notifications-see-more' p={2}>
                                <LoadingButton onClick={seeMore} loading={isLoadingSeeMore}>See More</LoadingButton>
                            </Box>
                        )}
                    </>
                )}
            </Paper>
            {!hasAlertsSetup && canManage && (
                <>
                    <Divider />
                    <Box p={2}>
                        <Typography fontSize='1em' align='center'>
                            You don't have any alert notifications setup<br />
                            <Link component={RouterLink} to='/manage/alerts' onClick={onClose}>Go to Alerts</Link>
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};

NotificationCenter.propTypes = {
    unreadNotifications: PropTypes.number.isRequired,
    setUnreadNotifications: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    canManage: PropTypes.bool.isRequired,
    socket: PropTypes.object,
};

NotificationCenter.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        canManage: state.auth.user.permissions.alerts.manage,
    };
};

export default connect(mapStateToProps)(NotificationCenter);