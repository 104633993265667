import { forwardRef } from 'react';
import { Box } from '@mui/material';
import { MapContainer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'; // <-- IMPORTANT
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import './leaflet.scss';
import mapAPIService from '../../services/map';
import MapTilerLayer from '../map/layers/tile';

// Fixes broken default Icon
// https://github.com/PaulLeCam/react-leaflet/issues/808#issuecomment-977109769
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
});

// Build Toggle Zoom control
L.Control.ToggleZoom = L.Control.extend({
  onAdd: function (map) {
    let text;
    let container = L.DomUtil.create('div', 'leaflet-bar');
    let btn = L.DomUtil.create('a', 'toggle-map-zoom-btn', container);

    btn.type = 'button';
    btn.textContent = 'enable zoom';
    btn.addEventListener('click', (event) => {
      if (map.scrollWheelZoom.enabled()) {
        map.scrollWheelZoom.disable();
        text = 'enable';
      } else {
        map.scrollWheelZoom.enable();
        text = 'disable';
      }
      btn.textContent = `${text} zoom`;
    });

    return container;
  },

  onRemove: function (map) {
    // Nothing to do here
  },
});
// Toggle Zoom factory
L.control.toggleZoom = function (opts) {
  return new L.Control.ToggleZoom(opts);
};

const LeafletMap = forwardRef((props, ref) => {
  return (
    <Box className='leaflet-container'>
      <MapContainer
        // {...mapAPIService.mapOptions}
        center={mapAPIService.defaults.center}
        zoom={mapAPIService.defaults.zoom}
        className='leaflet-map'
        ref={ref}
        {...props}
      >
        <MapTilerLayer />
        {props.children}
      </MapContainer>
    </Box>
  );
});

LeafletMap.propTypes = {};

LeafletMap.defaultProps = {};

export default LeafletMap;
