import { useCallback } from 'react';
import { Grid, InputAdornment, Skeleton } from '@mui/material';
import { useForm } from 'react-hook-form';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import PropTypes from 'prop-types';

import './deviceForm.scss';
import ColorPickerField from '../../forms/fields/colorPicker/colorPicker';
import CRUDForm from '../../crud/form/form';
import CRUDField from '../../crud/field/field';
import TagsField, { blankTag } from '../../forms/fields/tags/tags';
import FormSection from '../../forms/section/section';
import USFTCheckbox from '../../forms/fields/checkbox/checkbox';
import USFTTextField from '../../forms/fields/text/text';
import DeviceGroupField from '../../forms/fields/deviceGroup/deviceGroup';
import InputRulesField from '../../forms/fields/inputs/inputs';
import { animationOptions } from '../../forms/fields/helpers';
import DeviceIconField from '../../forms/fields/deviceIcon/deviceIcon';
import ImageField from '../../forms/fields/image/image';
import { createFormData } from '../../../utilities/_algorithms';

const fields = {
  photo: 'photo',
  serial: 'serial',
  groups: 'groups',
  name: 'name',
  vin: 'vin',
  fuelCardNumber: 'fuelCardNumber',
  outOfService: 'outOfService',
  backgroundColor: 'backgroundColor',
  vaporTrailColor: 'vaporTrailColor',
  icon: 'icon',
  tags: 'tags',
  inputWires: 'inputWires',
  inputRules: 'inputRules',
};

// TODO add device rollover sensitivity field
function DeviceForm(props) {
  const form = useForm();
  const inputWires = form.watch(fields.inputWires);

  const formatFetchData = useCallback(
    (data) => ({
      ...data,
      [fields.tags]: [...data.tags, blankTag],
      [fields.inputRules]: data.inputRules?.map((rule) => ({
        ...rule,
        animation: animationOptions[rule.animation],
      })),
    }),
    []
  );

  const formatSubmitData = (data) => {
    const formData = createFormData(data, {
      [fields.icon]: data.icon.id,
      [fields.groups]: data[fields.groups].map((group) => group.id),
      [fields.tags]: data.tags.filter(
        (tag) => tag.name !== '' && tag.value !== ''
      ), // TODO see if optimized check
    });

    if (data[fields.inputRules])
      formData.set(
        fields.inputRules,
        data[fields.inputRules].map((rule) => ({
          ...rule,
          animation: rule.animation.id,
        }))
      );

    if (data.photo instanceof FileList)
      formData.set(fields.photo, data.photo[0]);

    return formData;
  };

  const tagsLoadingComponent = [...Array(2)].map((_, index) => (
    <Grid item xs={12} key={index}>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={5}>
          <Skeleton variant='text' height={56} />
        </Grid>
        <Grid item xs={5}>
          <Skeleton variant='text' height={56} />
        </Grid>
      </Grid>
    </Grid>
  ));

  const photoSize = 200;

  return (
    <CRUDForm
      form={form}
      fetchURL='/devices/get'
      updateURL='/devices/update'
      fetchDataFormatter={formatFetchData}
      submitDataFormatter={formatSubmitData}
      {...props}
    >
      <FormSection title='Basic Information'>
        <CRUDField
          xs={12}
          className='device-photo-container'
          loadingComponent={
            <Skeleton
              variant='rectangular'
              height={photoSize}
              width={photoSize}
            />
          }
        >
          <ImageField
            name={fields.photo}
            label='Photo'
            height={photoSize}
            width={photoSize}
          />
        </CRUDField>
        <CRUDField xs={12}>
          <USFTTextField name={fields.serial} label='Serial' disabled={true} />
        </CRUDField>
        <CRUDField xs={12}>
          <DeviceGroupField
            name={fields.groups}
            label='Groups'
            multiple
            disableCloseOnSelect
            limitTags={3}
          />
        </CRUDField>
        <CRUDField xs={12}>
          <USFTTextField name={fields.name} label='Name' />
        </CRUDField>
        <CRUDField xs={12} sm={6}>
          <USFTTextField name={fields.vin} label='VIN' />
        </CRUDField>
        <CRUDField xs={12} sm={6}>
          <USFTTextField name={fields.fuelCardNumber} label='Fuel Card' />
        </CRUDField>
        <CRUDField xs={12} sm={6}>
          <USFTCheckbox name={fields.outOfService} label='Out of Service' />
        </CRUDField>
      </FormSection>
      <FormSection title='Marker'>
        <CRUDField xs={12} sm={6}>
          <ColorPickerField
            name={fields.backgroundColor}
            label='Label Color'
            rules={{
              required: 'Required',
            }}
          />
        </CRUDField>
        <CRUDField xs={12} sm={6}>
          <ColorPickerField
            name={fields.vaporTrailColor}
            label='Vapor Trail Color'
          />
        </CRUDField>
        <CRUDField xs={12} sm={6}>
          <DeviceIconField
            name={fields.icon}
            label='Icon'
            rules={{ required: 'Required' }}
          />
        </CRUDField>
      </FormSection>
      <FormSection title='Tags'>
        <CRUDField xs={12} loadingComponent={tagsLoadingComponent}>
          <TagsField name={fields.tags} />
        </CRUDField>
      </FormSection>
      {inputWires && (
        <>
          <FormSection title='Input Labels'>
            {inputWires.map((wire, index) => (
              <CRUDField xs={12} sm={6} key={wire.id}>
                <USFTTextField
                  name={`${fields.inputWires}.${index}.label`}
                  label={`Wire ${wire.id}`}
                  placeholder={`Wire ${wire.id}`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SettingsInputComponentIcon htmlColor={wire.color} />
                      </InputAdornment>
                    ),
                  }}
                />
              </CRUDField>
            ))}
          </FormSection>
          <FormSection title='Input Rules'>
            <CRUDField xs={12}>
              <InputRulesField name={fields.inputRules} wires={inputWires} />
            </CRUDField>
          </FormSection>
        </>
      )}
    </CRUDForm>
  );
}

DeviceForm.propTypes = {
  useCustomButton: PropTypes.bool.isRequired,
  id: PropTypes.number,
  onSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func,
};

DeviceForm.defaultProps = {
  useCustomButton: false,
};

export default DeviceForm;
