import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import './kmls.scss';
import mapAPIService from '../../../services/map';
import SidebarListView from '../../sidebar/listView/listView';
import { selectAuthUser, selectMapKMLs } from '../../../store/selectors';

function KMLSidebar() {
  const form = useForm();
  const KMLs = useSelector(selectMapKMLs);
  const user = useSelector(selectAuthUser);

  const filterRowsFunction = (query, kml) =>
    kml.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0;

  const handleChange = (kmlID) => (event) => {
    if (!KMLs[kmlID].visible) mapAPIService.showKML(kmlID);
    else mapAPIService.hideKML(kmlID);
  };

  const handleAllChange = async (event) => {
    if (event.target.checked)
      Object.values(KMLs).forEach((kml) => mapAPIService.showKML(kml.id));
    else Object.values(KMLs).forEach((kml) => mapAPIService.hideKML(kml.id));
  };

  return (
    <FormProvider {...form}>
      <SidebarListView
        rows={Object.values(KMLs)}
        getRowPrimaryText={(kml) => kml.name}
        filterRows={filterRowsFunction}
        sortRows={(a, b) => a.name.localeCompare(b.name)}
        emptyMessage='No KMLs Found'
        canManage={user.admin}
        manageURL={'manage/kmls'}
        onChange={handleChange}
        onAllChange={handleAllChange}
      />
    </FormProvider>
  );
}

KMLSidebar.propTypes = {};

KMLSidebar.defaultProps = {};

export default KMLSidebar;
