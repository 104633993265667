import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';

import './legend.scss';

function DeviceLegend(props) {

    const theme = useTheme();

    const entries = [
        { title: 'Moving', color: theme.palette.success.main },
        { title: 'Idle', color: theme.palette.warning.main },
        { title: 'Stopped', color: theme.palette.error.main },
        { title: 'No Power', color: theme.palette.grey[800] },
    ];

    return (
        <Paper className='map-legend' elevation={3}>
            <Grid container spacing={1}>
                {entries.map(entry => (
                    <Grid item key={entry.title} xs={6} className='legend-entry'>
                        <Box className='entry-color' sx={{ backgroundColor: entry.color }}></Box>
                        <Typography>{entry.title}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

DeviceLegend.propTypes = {};

DeviceLegend.defaultProps = {};

export default DeviceLegend;
