import { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageOverlay, LayerGroup, useMap, useMapEvents } from 'react-leaflet';

import APIService from '../../../services/api';
import { zoomMap } from '../../../utilities/helpers';
import TrafficMarker from '../../markers/traffic/traffic';

function TrafficLayer() {
  const [data, setData] = useState({
    url: null,
    events: [],
    bounds: null,
  });

  const map = useMap();

  const fetchTrafficData = useCallback(() => {
    console.debug('Fetching Traffic Data');
    const center = map.getCenter();
    const zoom = map.getZoom();
    const bounds = map.getBounds();

    if (zoomMap[zoom]) {
      APIService.get('https://www.mapquestapi.com/traffic/v2/incidents', {
        params: {
          key: process.env.REACT_APP_MAPQUEST_KEY,
          boundingBox: `${bounds.getNorth()},${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()}`,
        },
      }).then((response) => {
        setData({
          url: `https://www.mapquestapi.com/traffic/v2/flow?key=${process.env.REACT_APP_MAPQUEST_KEY}&mapLat=${center.lat}&mapLng=${center.lng}&mapHeight=${window.visualViewport.height}&mapWidth=${window.visualViewport.width}&mapScale=${zoomMap[zoom]}&imageType=png`,
          events: response.data.incidents,
          bounds,
        });
      });
    } else {
      setData({
        url: null,
        events: [],
        bounds,
      });
    }
  }, [map]);

  useMapEvents({
    moveend: () => {
      setData((prevState) => ({
        ...prevState,
        url: null,
      }));
      fetchTrafficData();
    },
  });

  useEffect(fetchTrafficData, [fetchTrafficData]);

  const trafficMarkers = useMemo(
    () =>
      data.events.map((event) => <TrafficMarker {...event} key={event.id} />),
    [data.events]
  );

  console.debug('Render Traffic Layer');

  return (
    <LayerGroup>
      {data.url && (
        <ImageOverlay url={data.url} bounds={data.bounds} opacity={0.8} />
      )}
      {trafficMarkers}
    </LayerGroup>
  );
}

TrafficLayer.propTypes = {};

TrafficLayer.defaultProps = {};

export default TrafficLayer;
