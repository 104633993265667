import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Drawer, Grid, IconButton, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';

import './viewOnlyMenu.scss';
import MenuItem from '../menuItem/menuItem';
import Copyright from '../../copyright/copyright';
import Logo from '../../logo/logo';
import ThemeModeField from '../../forms/fields/themeMode/themeMode';
import { selectAppMenuVisible } from '../../../store/selectors';
import { setMenuVisible } from '../../../store/slices/app';

function ViewOnlyMenu() {
  const dispatch = useDispatch();
  const showMenu = useSelector(selectAppMenuVisible);

  const hideMenu = (event) => {
    dispatch(setMenuVisible(false));
  };

  return (
    <Drawer anchor='left' open={showMenu} onClose={hideMenu}>
      <Box role='presentation' className='map-menu'>
        <Box>
          <Grid container p={2}>
            <Grid item xs={9}>
              <Logo />
            </Grid>
            <Grid item xs sx={{ flexGrow: 1 }} />
            <Grid item xs>
              <IconButton aria-label='close' onClick={hideMenu}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <List>
            <Divider />
            <MenuItem label='Logout' to='/logout' icon={LogoutIcon} />
          </List>
        </Box>
        <Box>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12} className='theme-mode-container'>
              <ThemeModeField />
            </Grid>
            <Grid item xs={12}>
              <Copyright disableLink={true} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}

ViewOnlyMenu.propTypes = {};

ViewOnlyMenu.defaultProps = {};

export default ViewOnlyMenu;
