import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Skeleton,
  IconButton,
  Typography,
  Paper,
  Stack,
  Fade,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PropTypes from 'prop-types';

import './liveView.scss';
import { useMountedRef } from '../../utilities/helpers';
import APIService from '../../services/api';

const screenshotDelay = 800; // ms

function LiveView({ device, ...props }) {
  const [screenshotURLs, setScreenshotURLs] = useState([
    ...Array(device.camera.cameras),
  ]);
  const [isFetching, setIsFetching] = useState(true);
  const [masterFullscreen, setMasterFullscreen] = useState(false);

  const masterRef = useRef();
  const isMounted = useMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchScreenshots = () => {
      console.debug('Fetching Screenshots');
      const timestamp = DateTime.now();
      const fetchTimeout = setTimeout(() => setIsFetching(true), 2000);
      APIService.get('cameras/previews/', {
        params: { deviceID: device.camera.esn },
      })
        .then((response) => {
          clearTimeout(fetchTimeout);
          if (isMounted.current) {
            setIsFetching(false);
            if (response.status === 200)
              setScreenshotURLs((prevState) => {
                return prevState.map((url, index) => {
                  const preview = response.data.previews[index];
                  if (preview && preview.camera === index + 1)
                    return preview.url;
                  else return url;
                });
              });
            else if (response.status === 202)
              enqueueSnackbar('Camera is offline', { variant: 'warning' });
            const diff = Math.abs(timestamp.diffNow().toMillis());
            if (diff >= timestamp) fetchScreenshots();
            else setTimeout(fetchScreenshots, screenshotDelay - diff);
          }
        })
        .catch((e) => {
          console.error(e);
          if (isMounted.current)
            enqueueSnackbar('Unable to reach SmartWitness', {
              variant: 'error',
            });
        });
    };
    fetchScreenshots();
  }, [device.camera, enqueueSnackbar, isMounted]);

  const handleToggleFullscreen = (event) => {
    if (!document.fullscreenElement)
      masterRef.current.parentElement
        .requestFullscreen()
        .then(() => {
          setMasterFullscreen(true);
        })
        .catch((err) => {
          enqueueSnackbar(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
            { variant: 'error' }
          );
        });
    else {
      document.exitFullscreen();
      setMasterFullscreen(false);
    }
  };

  return (
    <Box ref={masterRef} className='live-view'>
      <Grid container spacing={1}>
        {screenshotURLs.map((url, index) => (
          <Grid
            item
            xs={12}
            sm={screenshotURLs.length === 1 ? 12 : 6}
            key={index}
          >
            {url ? (
              <Box className='screenshot-container'>
                <img
                  className='screenshot'
                  src={url}
                  alt={`Live View ${index}`}
                />
                <Fade in={isFetching}>
                  <CircularProgress className='fetch-loading' color='inherit' />
                </Fade>
              </Box>
            ) : (
              <div className='loading-container'>
                <Skeleton variant='rectangle' width='100%' height='100%' />
              </div>
            )}
          </Grid>
        ))}
      </Grid>
      <Paper elevation={3} sx={{ mt: 1, p: 1 }}>
        <Grid container>
          <Grid item xs={6} md={3}></Grid>
          <Grid
            item
            md={6}
            display={{ xs: 'none', md: 'flex' }}
            justifyContent='center'
            alignItems='center'
          >
            <Typography noWrap>{device.name}</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Stack
              spacing={1}
              direction='row'
              alignItems='center'
              justifyContent='flex-end'
            >
              <Tooltip title='Toggle Fullscreen' enterDelay={300}>
                <IconButton onClick={handleToggleFullscreen}>
                  {masterFullscreen ? (
                    <FullscreenExitIcon />
                  ) : (
                    <FullscreenIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

LiveView.propTypes = {
  device: PropTypes.object.isRequired,
};

LiveView.defaultProps = {};

export default LiveView;
