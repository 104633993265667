import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import './historyPlaybackForm.scss';
import DeviceField from '../../forms/fields/device/device';
import USFTDateTimeField from '../../forms/fields/dateTime/dateTime';
import { filterFormKeyPress, useMountedRef } from '../../../utilities/helpers';
import USFTSelect from '../../forms/fields/select/select';
import DriverField from '../../forms/fields/driver/driver';
import APIService from '../../../services/api';
import FormSection from '../../forms/section/section';
import USFTTextField from '../../forms/fields/text/text';
import { positiveWholeNumberRules } from '../../forms/fields/helpers';

const playbackOptions = {
  device: { label: 'Device', active: true },
  driver: { label: 'Driver (Coming Soon)', active: false },
};
const fields = {
  playbackType: 'playbackType',
  device: 'device',
  driver: 'driver',
  startDateTime: 'startDateTime',
  endDateTime: 'endDateTime',
  stopTime: 'stopTime',
  showArrows: 'showArrows',
};

function HistoryPlaybackForm({
  onSubmit,
  onFetch,
  onToggleDirection,
  ...props
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isMounted = useMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm({
    defaultValues: {
      startDateTime: DateTime.now().set({ hour: 0, minute: 0, second: 0 }),
      endDateTime: DateTime.now().set({ hour: 23, minute: 59, second: 59 }),
      stopTime: 3,
      playbackType: fields.device,
    },
  });
  const playbackType = form.watch(fields.playbackType);

  const handleTimeSpanChange = (span) => async () => {
    let startDateTime, endDateTime;
    switch (span) {
      case 'today':
        startDateTime = DateTime.now().set({ hour: 0, minute: 0, second: 0 });
        endDateTime = DateTime.now().set({ hour: 23, minute: 59, second: 59 });
        break;
      case '24':
        endDateTime = DateTime.now();
        startDateTime = endDateTime.minus({ days: 1 });
        break;
      case '48':
        endDateTime = DateTime.now();
        startDateTime = endDateTime.minus({ days: 2 });
        break;
      default:
        console.warn('Invalid option');
        return;
    }

    form.setValue(fields.startDateTime, startDateTime);
    form.setValue(fields.endDateTime, endDateTime);
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    onSubmit(data);
    return APIService.get(`/history`, {
      params: {
        ...data,
        device: data.device?.id,
        driver: data.driver?.id,
        startDateTime: data.startDateTime.toISO(),
        endDateTime: data.endDateTime.toISO(),
      },
    })
      .then((response) => onFetch(response.data))
      .catch((e) => {
        console.error(e);
        enqueueSnackbar('Unable to fetch history data. Try again later.', {
          variant: 'error',
        });
      })
      .finally(() => {
        if (isMounted.current) setIsSubmitting(false);
      });
  };

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        id={props.formID}
        onSubmit={form.handleSubmit(handleSubmit)}
        onKeyDown={filterFormKeyPress}
      >
        <FormSection mt={0}>
          <Grid item xs={12}>
            <Typography align='center'>
              Select up to a 48 hour time span
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            flexDirection='column'
            alignItems='center'
          >
            <ButtonGroup variant='contained'>
              <Button onClick={handleTimeSpanChange('today')}>Today</Button>
              <Button onClick={handleTimeSpanChange('24')}>Last 24h</Button>
              <Button onClick={handleTimeSpanChange('48')}>Last 48h</Button>
            </ButtonGroup>
          </Grid>
        </FormSection>
        <FormSection className='THIS'>
          <Grid item xs={12}>
            <USFTDateTimeField
              name={fields.startDateTime}
              label='Start Time'
              rules={{
                required: 'Required',
                validate: (value) => {
                  const endDateTime = form.getValues(fields.endDateTime);
                  return (
                    (endDateTime &&
                      endDateTime.diff(value, 'hours').as('hours') <= 48) ||
                    'Selected date range is more than 48 hours'
                  );
                },
                deps: [fields.endDateTime],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <USFTDateTimeField
              name={fields.endDateTime}
              label='End Time'
              rules={{
                required: 'Required',
                validate: (value) => {
                  const startDateTime = form.getValues(fields.startDateTime);
                  return (
                    (startDateTime &&
                      startDateTime.diff(value, 'seconds').as('seconds') <=
                        0) ||
                    'End Time must be after Start Time'
                  );
                },
                deps: [fields.startDateTime],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <USFTSelect
              name={fields.playbackType}
              label='Show playback for'
              rules={{ required: 'Required' }}
            >
              {Object.entries(playbackOptions).map(([value, option]) => (
                <MenuItem value={value} key={value} disabled={!option.active}>
                  {option.label}
                </MenuItem>
              ))}
            </USFTSelect>
          </Grid>
          <Grid item xs={12}>
            {playbackType === fields.device && (
              <DeviceField
                name={fields.device}
                label='Device'
                rules={{ required: 'Required' }}
              />
            )}
            {playbackType === fields.driver && (
              <DriverField
                name={fields.driver}
                label='Driver'
                rules={{ required: 'Required' }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <USFTTextField
              name={fields.stopTime}
              label='Minimum Stop Time'
              type='number'
              helperText='The Minimum Stop Time value determines when a STOP marker is shown on the history playback route'
              rules={{
                required: 'Required',
                ...positiveWholeNumberRules,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>Minutes</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs display='flex' flexDirection='row-reverse'>
            <LoadingButton
              variant='contained'
              color='success'
              type='submit'
              loading={isSubmitting}
            >
              Playback
            </LoadingButton>
          </Grid>
        </FormSection>
      </Box>
    </FormProvider>
  );
}

HistoryPlaybackForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
};

HistoryPlaybackForm.defaultProps = {};

export default HistoryPlaybackForm;
