import { useState } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import './loginForm.scss';
import authService from '../../../services/auth';
import USFTCheckbox from '../../forms/fields/checkbox/checkbox';
import USFTTextField from '../../forms/fields/text/text';

const fields = {
  username: 'username',
  password: 'password',
  rememberMe: 'rememberMe',
};

function LoginForm({ authError, ...props }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(authError);

  const form = useForm({
    defaultValues: {
      [fields.rememberMe]: true,
    },
  });

  const onSubmit = async (data) => {
    setError('');
    setIsSubmitting(true);
    return authService
      .login({
        username: data.username,
        password: data.password,
        rememberMe: data.rememberMe,
      })
      .catch((error) => {
        setError(error.message);
        setIsSubmitting(false);
      });
  };

  return (
    <FormProvider {...form}>
      <Box
        component='form'
        id='login-form'
        className='login-form'
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Grid container spacing={2} my={2}>
          <Grid item xs={12}>
            <USFTTextField
              name={fields.username}
              label='Username'
              autoComplete='username'
              rules={{ required: 'Required' }}
              overrideLabel
            />
          </Grid>
          <Grid item xs={12}>
            <USFTTextField
              name={fields.password}
              label='Password'
              type='password'
              autoComplete='current-password'
              rules={{ required: 'Required' }}
              overrideLabel
            />
          </Grid>
          <Grid item xs={12}>
            <USFTCheckbox name={fields.rememberMe} label='Remember Me' />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              type='submit'
              fullWidth
              variant='contained'
              loading={isSubmitting}
            >
              Login
            </LoadingButton>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography align='center' color='error' mb={1}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </FormProvider>
  );
}

LoginForm.propTypes = {
  authError: PropTypes.string,
};

LoginForm.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.error,
  };
};

export default connect(mapStateToProps)(LoginForm);
