import { useSnackbar } from 'notistack';

import './deviceIcon.scss';
import USFTAutocomplete from '../autocomplete/autocomplete';
import APIService from '../../../../services/api';
import IconOption from '../options/iconOption/iconOption';

function DeviceIconField(props) {
    const { enqueueSnackbar } = useSnackbar();

    const getOptions = async (event) => {
        return APIService.get('/devices/icons')
            .then(response => response.data.icons.sort((a, b) => {
                if (a.category === b.category)
                    return a.name.localeCompare(b.name);
                return a.category - b.category;
            }))
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Unable to get icon data. Try again later.', { variant: 'error' });
            });
    };

    return (
        <USFTAutocomplete
            getOptions={getOptions}
            groupBy={(option) => option.categoryName}
            renderOption={(props, option) => <IconOption {...props} option={option} />}
            {...props}
        />
    );
};

DeviceIconField.propTypes = {};

DeviceIconField.defaultProps = {};

export default DeviceIconField;