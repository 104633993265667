import { useEffect } from 'react';
import { Settings } from 'luxon';

// TODO move to Luxon natively
const timeZones = {
  93: {
    id: 93,
    name: 'Hawaii Standard Time',
    abv: 'HST',
    offset: -10,
    supportDST: false,
    order: 1,
  },
  92: {
    id: 92,
    name: 'Alaska Standard Time',
    abv: 'AKST',
    offset: -9,
    supportDST: true,
    order: 2,
  },
  91: {
    id: 91,
    name: 'Pacific Standard Time',
    abv: 'PST',
    offset: -8,
    supportDST: true,

    order: 3,
  },
  89: {
    id: 89,
    name: 'Mountain Standard Time',
    abv: 'MST',
    offset: -7,
    supportDST: true,
    order: 4,
  },
  87: {
    id: 87,
    name: 'Arizona Time',
    abv: 'MST',
    offset: -7,
    supportDST: false,
    order: 5,
  },
  84: {
    id: 84,
    name: 'Central Standard Time',
    abv: 'CST',
    offset: -6,
    supportDST: true,
    order: 6,
  },
  81: {
    id: 81,
    name: 'Eastern Standard Time',
    abv: 'EST',
    offset: -5,
    supportDST: true,
    order: 7,
  },
  75: {
    id: 75,
    name: 'Atlantic Standard Time',
    abv: 'AST',
    offset: -4,
    supportDST: true,
    order: 8,
  },
  1: {
    id: 1,
    name: 'Coordinated Universal Time',
    abv: 'UTC',
    offset: 0,
    supportDST: false,
    order: 9,
  },
  8: { id: 8, name: 'UTC +1', offset: 1, supportDST: false, order: 10 },
  18: { id: 18, name: 'UTC +2', offset: 2, supportDST: false, order: 11 },
  22: { id: 22, name: 'UTC +3', offset: 3, supportDST: false, order: 12 },
  28: { id: 28, name: 'UTC +4', offset: 4, supportDST: false, order: 13 },
  32: { id: 32, name: 'UTC +5', offset: 5, supportDST: false, order: 14 },
  38: { id: 38, name: 'UTC +6', offset: 6, supportDST: false, order: 15 },
  41: { id: 41, name: 'UTC +7', offset: 7, supportDST: false, order: 16 },
  47: { id: 47, name: 'UTC +8', offset: 8, supportDST: false, order: 17 },
  50: { id: 50, name: 'UTC +9', offset: 9, supportDST: false, order: 18 },
  57: { id: 57, name: 'UTC +10', offset: 10, supportDST: false, order: 19 },
  58: { id: 58, name: 'UTC +11', offset: 11, supportDST: false, order: 20 },
  62: { id: 62, name: 'UTC +12', offset: 12, supportDST: false, order: 21 },
  63: { id: 63, name: 'UTC +13', offset: 13, supportDST: false, order: 22 },
  65: { id: 65, name: 'UTC -1', offset: -1, supportDST: false, order: 23 },
  67: { id: 67, name: 'UTC -2', offset: -2, supportDST: false, order: 24 },
  72: { id: 72, name: 'UTC -3', offset: -3, supportDST: false, order: 25 },
  78: { id: 78, name: 'UTC -4', offset: -4, supportDST: false, order: 26 },
  82: { id: 82, name: 'UTC -5', offset: -5, supportDST: false, order: 27 },
  86: { id: 86, name: 'UTC -6', offset: -6, supportDST: false, order: 28 },
  95: { id: 95, name: 'UTC -11', offset: -11, supportDST: false, order: 29 },
  96: { id: 96, name: 'UTC -12', offset: -12, supportDST: false, order: 30 },
};

// TODO Update to support Luxon DateTime
const isDST = (d = new Date()) => {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
};

export function useTimeZoneHook(timeZoneID) {
  useEffect(() => {
    if (timeZoneID) {
      const timeZone = timeZones[timeZoneID];
      if (timeZone) {
        let offset =
          timeZone.supportDST && isDST()
            ? timeZone.offset + 1
            : timeZone.offset;
        Settings.defaultZone = `UTC${offset >= 0 ? '+' : ''}${offset}`;
        console.debug('Time Zone Changed', timeZone.name, offset);
      }
    }
  }, [timeZoneID]);
}

export default timeZones;
