import { useState } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './dispatchHOC.scss';
import DispatchForm from '../../../../dispatch/form/dispatchForm';
import FormDialog from '../../../../crud/formDialog/formDialog';

function DispatchHOC(props) {
    const [showDialog, setShowDialog] = useState(false);

    const handleClick = (e) => {
        setShowDialog(true)
    }

    const handleClose = () => {
        setShowDialog(false)
    }

    const handleSave = (device) => {
        handleClose()
    }

    return (
        <>
            <Button variant='contained' onClick={handleClick}>
                Dispatch
            </Button>
            <FormDialog
                open={showDialog}
                onClose={handleClose}
                onSave={handleSave}
                form={DispatchForm}
                title='Dispatch'
                formProps={{ device: props.device }}
            />
        </>
    );
}

DispatchHOC.propTypes = {
    device: PropTypes.object.isRequired
}

DispatchHOC.defaultProps = {}

export default DispatchHOC;
