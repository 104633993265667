import { useRef } from 'react';
import { Slide, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

import './snackbarProvider.scss';

function USFTSnackbarProvider({ children, ...props }) {
    const snackbarRef = useRef(null);

    return (
        <SnackbarProvider
            ref={snackbarRef}
            maxSnack={4}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            TransitionComponent={Slide}
            autoHideDuration={3000}
            iconVariant={{
                success: <CheckIcon className='snackbarIcon' />,
                error: <ErrorIcon className='snackbarIcon' />,
                warning: <WarningIcon className='snackbarIcon' />,
                info: <InfoIcon className='snackbarIcon' />,
            }}
            action={(snackbarID) => (
                <IconButton
                    aria-label="Close notification"
                    onClick={() => snackbarRef.current.closeSnackbar(snackbarID)}
                >
                    <CloseIcon />
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};

USFTSnackbarProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

USFTSnackbarProvider.defaultProps = {};

export default USFTSnackbarProvider;
