import { useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Marker } from 'react-leaflet-marker';
import PropTypes from 'prop-types';

import './eventMarker.scss';
import MarkerPopper from '../../common/popper/markerPopper';

function EventMarker({ icon, title, details, onClose, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  const handleOpenEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    if (typeof onClose === 'function') onClose();
  };

  return (
    <Marker position={[props.lat, props.lng]}>
      <Box className='event-marker-container'>
        <Box
          className='event-marker'
          onClick={handleOpenEvent}
          onTouchStart={handleMouseDown}
          ref={anchorEl}
        >
          {icon}
        </Box>
        <MarkerPopper
          open={isOpen}
          onClose={handleClose}
          title={title}
          details={details}
          anchor={anchorEl}
        />
      </Box>
    </Marker>
  );
}

EventMarker.propTypes = {
  icon: PropTypes.element.isRequired,
  details: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

EventMarker.defaultProps = {};

export default EventMarker;
