import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';

import store from '../store';
import mapAPIService from '../services/map';
import analyticsService from '../services/analytics';
import titleService from '../services/title';
import {
  statusIdle,
  statusIgnitionOff,
  statusIgnitionOn,
  statusMoving,
  statusOffline,
  statusStopped,
} from '../components/forms/fields/helpers';
import { isRecentlyUpdated } from '../components/markers/common';
import { statusAllDevices } from '../components/forms/fields/helpers';
import liveViewLogo from '../static/images/dealers/liveViewGPS.png';
import ExpandableTextCell from '../components/tables/expandableTextCell/expandableTextCell';
import { formatAddress, formatPhoneNumber } from './_algorithms';

export const zoomMap = {
  // 10: 433343,
  // 11: 216671, // MapQuest is returning a 400 for 11
  12: 108335,
  13: 54167,
  14: 27083,
  15: 13541,
  16: 6770,
  17: 3385,
  18: 1692,
};

export const sleep = async (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

export const setLVfavicon = async () => {
  console.debug('Changing favicons for LiveView');
  document.querySelector(
    '#favicon32'
  ).href = `${process.env.PUBLIC_URL}/lv-favicon-32x32.png`;
  document.querySelector(
    '#favicon16'
  ).href = `${process.env.PUBLIC_URL}/lv-favicon-16x16.png`;
};

export const handleAppUnload = async (event) => {
  analyticsService.track('unload');
};

export function useTitleHook(title, prefix) {
  useEffect(() => {
    titleService.set(title, prefix);
  }, [title, prefix]);
}

export function usePageViews(user) {
  let location = useLocation();
  useEffect(() => {
    if (user) analyticsService.track('page_view', location.pathname);
  }, [location, user]);
}

// TODO implement across all components using Async functions in useEffect
export function useMountedRef() {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  return mounted;
}

export function useAddress(latitude, longitude) {
  const [address, setAddress] = useState('Loading...');

  const isMounted = useMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    mapAPIService
      .getLocation({ latitude, longitude })
      .then((location) => {
        if (isMounted.current) setAddress(formatAddress(location));
      })
      .catch((e) => {
        console.error(e);
        if (isMounted.current) {
          enqueueSnackbar('Unable to fetch address. Try again later.', {
            variant: 'error',
          });
          setAddress('Unknown');
        }
      });
  }, [latitude, longitude, enqueueSnackbar, isMounted]);

  return address;
}

// Handles visibility changes with tab
export function useVisibilityChange(func) {
  useEffect(() => {
    document.addEventListener('visibilitychange', func);

    return () => {
      document.removeEventListener('visibilitychange', func);
    };
  }, [func]);
}

// Use this function to help trace why components re-render
export const useTraceUpdate = (props) => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
};

export const isPushNotificationsSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

export const filterFormKeyPress = (event) => {
  if (event.key === 'Enter') event.preventDefault();
};

export const stopPropagation = async (event) => {
  event.stopPropagation();
};

const headingArray = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];

export const formatHeading = (heading) => {
  // This function is needed due to how the animation function in Map service
  // modifies the current location headings for "correct" turning
  let headingIndex = 0;
  heading = Math.round(heading);
  if (heading > 360) heading -= 360;
  else if (heading < 0) heading += 360;

  if (heading !== 0) headingIndex = Math.round(heading / 45);

  return `${heading}° / ${headingArray[headingIndex]}`;
};

export const deviceVisibiltyFunctions = {
  [statusAllDevices]: () => true,
  [statusMoving]: ([_, device]) =>
    device.location.velocity > 0 && isRecentlyUpdated(device),
  [statusIdle]: ([_, device]) =>
    device.location.velocity === 0 &&
    device.ignition &&
    isRecentlyUpdated(device),
  [statusStopped]: ([_, device]) =>
    device.location.velocity === 0 &&
    !device.ignition &&
    isRecentlyUpdated(device),
  [statusIgnitionOn]: ([_, device]) =>
    device.ignition === true && isRecentlyUpdated(device),
  [statusIgnitionOff]: (_, device) =>
    device.ignition === false && isRecentlyUpdated(device),
  [statusOffline]: ([_, device]) =>
    (typeof device.power === 'number' && device.power === 0) ||
    !isRecentlyUpdated(device),
};

// TODO refactor out of app
export const dealers = {
  LiveViewGPS: 'LiveViewGPS',
  'Spy-Hawk-GPS': 'Spy-Hawk-GPS',
  'Compliance-Assurance-Services': 'Compliance-Assurance-Services',
  'Motus-Tracking-Solutions': 'Motus-Tracking-Solutions',
};
const dealerSearchKeys = ['ref', 'REF', 'Ref', 'cid', 'CID', 'Cid'];
const dealerMap = {
  [dealers.LiveViewGPS]: {
    id: dealers.LiveViewGPS,
    prefix: 'LVGPS',
    title: 'Live View GPS',
    logo: liveViewLogo,
  },
  [dealers['Spy-Hawk-GPS']]: {
    id: dealers['Spy-Hawk-GPS'],
    prefix: 'SHGPS',
    title: 'Spy Hawk GPS',
    logo: 'https://static.usfleettracking.com/img/logos/Spy-Hawk-GPS.png',
  },
  [dealers['Compliance-Assurance-Services']]: {
    id: dealers['Compliance-Assurance-Services'],
    prefix: 'CAS',
    title: 'Compliance-Assurance-Services',
    logo: 'https://static.usfleettracking.com/img/logos/Compliance-Assurance-Services.png',
  },
  [dealers['Motus-Tracking-Solutions']]: {
    id: dealers['Motus-Tracking-Solutions'],
    prefix: 'MTS',
    title: 'Motus',
    logo: 'https://static.usfleettracking.com/img/logos/Motus-Tracking-Solutions.png',
  },
};

// TODO move domain check to ENV var or make a dedicated var in Redux
export const getDealer = () => {
  switch (window.location.hostname) {
    case 'www.lvgps.net':
    case 'lvgps.net':
      return dealerMap[dealers.LiveViewGPS];
    default:
      break;
  }

  if (store.getState().auth.user?.dealer)
    return dealerMap[store.getState().auth.user.dealer];

  let params = new URLSearchParams(document.location.search);

  for (let i = 0; i < dealerSearchKeys.length; i++) {
    const value = params.get(dealerSearchKeys[i]);
    if (value && dealerMap[value]) return dealerMap[value];
  }

  return null;
};

export const themeModes = {
  light: 'light',
  auto: 'auto',
  dark: 'dark',
};

export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
export const hexRegex = /^#[0-9a-fA-F]{6}$/;
export const vinRegex = /^[(A-H|J-N|P|R-Z|0-9)]{17}$/;
export const phoneRegex = /^\d{10}$/;
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// TODO support more than USD
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// MUI Data Grid Columns
export const usdPriceColumn = {
  flex: 1,
  minWidth: 120,
  type: 'number',
  valueFormatter: (params) => currencyFormatter.format(params.value),
};

export const phoneNumberColumn = {
  flex: 1,
  minWidth: 160,
  valueFormatter: (params) => {
    if (params.value)
      if (params.value.length === 10) return formatPhoneNumber(params.value);
      else return params.value;
    else return '--';
  },
};

export const dateTimeColumn = {
  minWidth: 200,
  type: 'dateTime',
  valueGetter: (params) => DateTime.fromISO(params.value),
  valueFormatter: (params) =>
    params.value.toLocaleString(DateTime.DATETIME_MED),
};

export const addressColumn = {
  minWidth: 200,
  valueGetter: (params) => formatAddress(params.value),
  renderCell: (params) => <ExpandableTextCell {...params} />,
};

export const textBlockColumn = {
  minWidth: 200,
  renderCell: (params) => <ExpandableTextCell {...params} />,
};
