import { Grid, IconButton, ListItem, Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import './notificationItem.scss';

function NotificationItem({ title, body, timestamp, unread, onChange, ...props }) {

    return (
        <ListItem className={`notification${unread ? ' unread' : ''}`} {...props}>
            <Grid container>
                <Grid item xs className='notification-details'>
                    <Typography variant='subtitle2'>
                        {title}
                    </Typography>
                    <Typography variant='body2'>
                        {body}
                    </Typography>
                    <Typography variant='caption'>
                        {DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)}
                    </Typography>
                </Grid>
                <Grid item xs='auto' className='unread-container'>
                    {unread && (
                        <IconButton color='primary' onClick={onChange}>
                            <VisibilityIcon />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </ListItem >
    );
};

NotificationItem.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,  // ISO
    unread: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

NotificationItem.defaultProps = {};

export default NotificationItem;
