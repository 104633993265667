import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';

import './mapSettings.scss';
import mapAPIService from '../../../services/map';
import FancyCheckbox from './fancyCheckbox/fancyCheckbox';
import SmallIcon from '../../../static/images/icons/map/small_icon.jpg';
import DeviceLabelIcon from '../../../static/images/icons/map/device_label.png';
import AddressLabelIcon from '../../../static/images/icons/map/address_label.png';
import GroupIcon from '../../../static/images/icons/map/group_marker.jpg';
import WeatherIcon from '../../../static/images/icons/map/weather.jpg';
import LiveVaporIcon from '../../../static/images/icons/map/live_vapor.png';
import SureFitIcon from '../../../static/images/icons/map/sure_fit.png';
import LiveVaporForm from '../../liveVapor/form/liveVaporForm';
import USFTDialogTitle from '../../dialog/dialogTitle/dialogTitle';
import analyticsService from '../../../services/analytics';
import { selectMapMode, selectMapSettings } from '../../../store/selectors';
import { updateSettings } from '../../../store/slices/map';

function MapSettings() {
  const [showLiveVaporDialog, setShowLiveVaporDialog] = useState(false);

  const dispatch = useDispatch();
  const mapMode = useSelector(selectMapMode);
  const mapSettings = useSelector(selectMapSettings);

  const settings = {
    types: [
      // {
      //   label: 'Default',
      //   icon: 'https://maps.gstatic.com/tactile/layerswitcher/ic_default-2x.png',
      //   value: mapAPIService.maps.MapTypeId.ROADMAP,
      // },
      // {
      //   label: 'Satellite',
      //   icon: 'https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-2x.png',
      //   value: mapAPIService.maps.MapTypeId.HYBRID,
      // },
    ],
    details: [
      {
        label: 'Small Icons',
        icon: SmallIcon,
        value: 'smallIcons',
        callback: () => handleMapSettingsChange('smallIcons'),
      },
      {
        label: 'Device Labels',
        icon: DeviceLabelIcon,
        value: 'deviceLabels',
        callback: () => handleMapSettingsChange('deviceLabels'),
      },
      {
        label: 'Location Labels',
        icon: AddressLabelIcon,
        value: 'addressLabels',
        callback: () => handleMapSettingsChange('addressLabels'),
      },
      {
        label: 'Group Markers',
        icon: GroupIcon,
        value: 'groupMarkers',
        callback: () => handleMapSettingsChange('groupMarkers'),
      },
      {
        label: 'Traffic',
        icon: 'https://maps.gstatic.com/tactile/layerswitcher/ic_traffic-2x.png',
        value: 'traffic',
        callback: async () => {
          analyticsService.track('map_settings_traffic', !mapSettings.traffic);
          return mapAPIService.toggleTrafficLayer();
        },
      },
      {
        label: 'Weather',
        icon: WeatherIcon,
        value: 'weather',
        callback: mapAPIService.toggleWeatherLayer,
      },
      {
        label: 'Counties',
        icon: 'https://maps.gstatic.com/tactile/layerswitcher/ic_terrain-2x.png',
        value: 'counties',
        callback: mapAPIService.toggleCountyLayer,
      },
      {
        label: 'Sure Fit',
        icon: SureFitIcon,
        value: 'autoZoom',
        callback: mapAPIService.toggleAutoZoom,
      },
      {
        label: 'Live Vapor',
        icon: LiveVaporIcon,
        value: 'liveVapor',
        callback: () => setShowLiveVaporDialog(true),
      },
    ],
  };

  const handleMapSettingsChange = async (setting) => {
    return dispatch(updateSettings({ [setting]: !mapSettings[setting] }));
  };

  const closeLiveVaporDialog = (event) => {
    setShowLiveVaporDialog(false);
  };

  return (
    <Box className='map-settings'>
      <Typography variant='h5' mb={2}>
        Map Type
      </Typography>
      <Grid container>
        {/* {settings.types.map((type) => (
          <Grid item key={type.value} xs={true} className='setting'>
            <FancyCheckbox
              label={type.label}
              icon={type.icon}
              checked={mapSettings.type === type.value}
              onClick={(e) => mapAPIService.setMapType(type.value)}
            />
          </Grid>
        ))} */}
        TODO - decide on tile sets
      </Grid>
      {!mapSettings.viewOnly &&
        mapMode !== mapAPIService.modes.historyPlayback && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant='h5' mb={true}>
              Map Options
            </Typography>
            <Grid container>
              {settings.details.map((type) => (
                <Grid item key={type.value} xs={4} className='setting'>
                  <FancyCheckbox
                    label={type.label}
                    checked={mapSettings[type.value]}
                    icon={type.icon}
                    onClick={type.callback}
                  />
                </Grid>
              ))}
            </Grid>
            <Dialog
              open={showLiveVaporDialog}
              onClose={closeLiveVaporDialog}
              fullWidth
            >
              <USFTDialogTitle
                title='Live Vapor'
                onClose={closeLiveVaporDialog}
              />
              <DialogContent dividers>
                <LiveVaporForm />
              </DialogContent>
              <DialogActions>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={closeLiveVaporDialog}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
    </Box>
  );
}

MapSettings.propTypes = {};

MapSettings.defaultProps = {};

export default MapSettings;
