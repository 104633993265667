import { Box, CircularProgress } from '@mui/material';

import './managementLoading.scss';

function ManagementLoading(props) {

    return (
        <Box className='management-loading'>
            <CircularProgress />
        </Box>
    );
};

ManagementLoading.propTypes = {};

ManagementLoading.defaultProps = {};

export default ManagementLoading;