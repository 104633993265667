import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  user: null,
  error: null,
  viewOnly: false,
  loading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authSuccess: (state, action) => ({
      ...state,
      token: action.payload.token,
      viewOnly: action.payload.viewOnly || false,
      error: null,
      loading: false,
    }),
    authFail: (state, action) => ({
      error: action.payload,
      user: null,
      token: null,
      loading: false,
      viewOnly: false,
    }),
    authLogout: (state, action) => {
      state.user = null;
      state.token = null;
    },
    setUser: (state, action) => {
      state.user = {
        ...action.payload,
        preferences: undefined, // TODO check if needed
      };
      state.error = null;
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
  },
});

export const { authSuccess, authFail, authLogout, setUser, updateUser } =
  authSlice.actions;

export default authSlice.reducer;
