import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';
import PropTypes from 'prop-types';

import './pulse.scss';

function PulseAnimation({ color1, color2, ...props }) {
    const initialSize = '75%';
    const pulse = keyframes`
        0% {
            height: ${initialSize};
            width: ${initialSize};
            border-color: ${color1};
        }
        50% {
            height: 100%;
            width: 100%;
            border-color: ${color1};
        }
        51% {
            height: 100%;
            width: 100%;
            border-color: ${color2};
        }
        100% {
            height: ${initialSize};
            width: ${initialSize};
            border-color: ${color2};
        }
    `;

    return (
        <Box
            className='pulse-animation animation-root'
            sx={{ animation: `${pulse} 1s ease infinite` }}
            {...props}
        />
    );
}

PulseAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

PulseAnimation.defaultProps = {}

export default PulseAnimation;
