import { getDealer } from '../utilities/helpers';

/** Class for Title actions */
class TitleService {
  defaultTitle = 'US Fleet Tracking';
  previousTitle = null;
  cycleInterval = null;

  cycle = (newTitle, speed = 2000) => {
    console.debug('Cycling Document title', newTitle, speed);
    this.previousTitle = `${document.title}`;
    this.reset();
    let cycleCount = 0;
    const cycleTitles = () => {
      document.title = cycleCount % 2 ? this.previousTitle : newTitle;
      cycleCount++;
    };
    this.cycleInterval = setInterval(cycleTitles, speed);
    cycleTitles();
  };

  reset = () => {
    console.debug('Resetting Document title');
    clearInterval(this.cycleInterval);
    this.cycleInterval = null;
    document.title = this.previousTitle;
  };

  set = (newTitle, usePrefix = true) => {
    if (usePrefix) {
      const dealer = getDealer();
      let prefix = 'USFT';
      if (dealer) prefix = dealer.prefix;
      let title = `${prefix} - ${newTitle}`;
      this.cycleInterval
        ? (this.previousTitle = title)
        : (document.title = title);
    } else {
      this.cycleInterval
        ? (this.previousTitle = newTitle)
        : (document.title = newTitle);
    }
  };
}

const titleService = new TitleService();

export default titleService;
