import { Box, Button, Grid, IconButton, Paper, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import './inputs.scss';
import USFTCheckbox from '../checkbox/checkbox';
import ColorPickerField from '../colorPicker/colorPicker';
import USFTAutocomplete from '../autocomplete/autocomplete';
import AnimationOption from '../options/animationOption/animationOption';
import { animationOptions } from '../helpers';

function InputRulesField({ name, wires, rules, ...props }) {
    const { formState } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        name: name,
        keyName: "inputRulesKey",
        rules: {
            validate: {
                noDuplicates: (values) => {
                    const cache = {};
                    let isValid = true;
                    values.every((value, index) => {
                        const key = value.inputs.join('');
                        if (key in cache) {
                            isValid = false;
                            return false;
                        }
                        cache[key] = null;
                        return true;
                    });
                    return isValid || 'You can not save duplicate rules';
                },
                noBlankRules: (values) => {
                    return values.every(value => value.inputs.some(inputValue => inputValue)) || 'At least one input must be turned on';
                }
            },
            ...rules,
        }
    });

    const blankRule = {
        inputs: [...Array(wires.length)].map(_ => false),
        useIgnition: false,
        colors: ['#ff0000', '#0000ff', '#ff0000'],
        animation: animationOptions[0],
    };

    const addRule = (event) => {
        append(blankRule);
    };

    const deleteRule = (index) => (event) => {
        remove(index);
    };

    return (
        <Box>
            {formState.errors[name]?.root && (
                <Box color='error.main' textAlign='center' mb={2}>{formState.errors[name].root.message}</Box>
            )}
            {fields.map((rule, index) => (
                <Paper key={rule.inputRulesKey} sx={{ p: 2, mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item container xs={12} sm={11} spacing={2}>
                            <Grid item container spacing={2}>
                                {rule.inputs.map((input, inputIndex) => (
                                    <Grid item xs={12} sm={6} key={`input-${inputIndex}`}>
                                        <USFTCheckbox
                                            name={`${name}.${index}.inputs.${inputIndex}`}
                                            label={wires[inputIndex].label || `Input ${wires[inputIndex].id}`}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <USFTCheckbox
                                        name={`${name}.${index}.useIgnition`}
                                        label='Use With Ignition'
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <USFTAutocomplete
                                        name={`${name}.${index}.animation`}
                                        label='Animation'
                                        options={animationOptions}
                                        rules={{ required: 'Required' }}
                                        renderOption={(props, option) => (
                                            <AnimationOption
                                                {...props}
                                                option={option}
                                                color1={rule.colors[0]}
                                                color2={rule.colors[1]}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ColorPickerField
                                        name={`${name}.${index}.colors.0`}
                                        label='Color 1'
                                        rules={{ required: 'Required' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ColorPickerField
                                        name={`${name}.${index}.colors.1`}
                                        label='Color 2'
                                        rules={{ required: 'Required' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <ColorPickerField
                                        name={`${name}.${index}.colors.2`}
                                        label='Vapor Trail'
                                        rules={{ required: 'Required' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={1} display='flex' justifyContent='center' alignItems='center'>
                            <IconButton aria-label="delete" color='error' onClick={deleteRule(index)}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Paper>
            ))}
            <Box display='flex' justifyContent='center' mt={2}>
                <Button onClick={addRule}>Add Rule</Button>
            </Box>
        </Box>
    );
};

InputRulesField.propTypes = {
    name: PropTypes.string.isRequired,
    wires: PropTypes.arrayOf(PropTypes.object).isRequired,
    rules: PropTypes.object,
};

InputRulesField.defaultProps = {
    name: 'inputRules'
};

export default InputRulesField;
