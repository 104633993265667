import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './demo.scss';
import authService from '../../../services/auth';
import AppLoading from '../../loading/app/app';
import { setLoading } from '../../../store/slices/app';

function DemoLogin() {
  const dispatch = useDispatch();

  useEffect(() => {
    authService.demoLogin().then(() => {
      return dispatch(setLoading(false));
    });
  }, [dispatch]);

  return <AppLoading />;
}

DemoLogin.propTypes = {};

DemoLogin.defaultProps = {};

export default DemoLogin;
