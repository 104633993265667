import { Box } from '@mui/material';

import './logo.scss';
import logo from '../../static/images/logo/logo.png';

function Logo(props) {

    return (
        <Box display='flex' justifyContent='center' height='100%' width='100%' {...props}>
            <img src={logo} alt='US Fleet Tracking logo' className='usft-logo' />
        </Box>
    );
}

Logo.propTypes = {}

Logo.defaultProps = {}

export default Logo;
