import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './alertDetails.scss';
import { formatHeading, useAddress } from '../../../../../../utilities/helpers';
import Details from '../../../../common/details/details';
import VideoPlaybackAction from '../../../../common/actions/videoPlayback/videoPlayback';

function AlertDetails({ event, devices }) {
  const address = useAddress(event.latitude, event.longitude);
  const device = devices[event.device];

  const details = [
    [
      'Timestamp',
      event.time.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS),
    ],
    ['Serial', device.serial],
    ['Name', device.name],
    ['Address', address],
    ['Speed', event.velocity > 0 ? `${event.velocity} mph` : 'Stopped'],
    ['Heading', formatHeading(event.heading)],
    ['Latitude', event.latitude.toFixed(6)],
    ['Longitude', event.longitude.toFixed(6)],
  ];

  const actions = (
    <>
      {event.urls.length > 0 && (
        <VideoPlaybackAction event={event} device={device} />
      )}
    </>
  );

  return <Details primaryDetails={details} actions={actions} />;
}

AlertDetails.propTypes = {
  event: PropTypes.object.isRequired,
  devices: PropTypes.object.isRequired,
};

AlertDetails.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    devices: state.map.devices,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDetails);
