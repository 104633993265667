import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './addAddressHOC.scss';
import FormDialog from '../../../../crud/formDialog/formDialog';
import AddressForm from '../../../../addresses/form/addressForm';
import { setWebSearch, updateAddress } from '../../../../../store/slices/map';

function AddAddressHOC({ address }) {
  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleSave = (address) => {
    handleClose();
    dispatch(
      updateAddress({
        id: address.id,
        properties: { ...address, visible: true },
      })
    );
    dispatch(setWebSearch(null));
  };

  return (
    <>
      <Button variant='contained' onClick={handleClick}>
        Save
      </Button>
      <FormDialog
        open={showDialog}
        onClose={handleClose}
        onSave={handleSave}
        form={AddressForm}
        model='address'
        formProps={{
          formProps: {
            defaultValues: address,
          },
        }}
      />
    </>
  );
}

AddAddressHOC.propTypes = {
  address: PropTypes.object.isRequired,
};

AddAddressHOC.defaultProps = {};

export default AddAddressHOC;
