import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';

import './streetViewPreview.scss';
import mapAPIService from '../../services/map';
import { useMountedRef } from '../../utilities/helpers';

function StreetViewPreview(props) {
    const [url, setURL] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const isMounted = useMountedRef();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        mapAPIService.getStreetViewPreviewURL({
            latitude: props.latitude,
            longitude: props.longitude,
            heading: props.heading
        })
            .then(streetViewURL => {
                if (isMounted.current)
                    setURL(streetViewURL)
            })
            .catch((e) => {
                console.error(e)
                enqueueSnackbar('Unable to fetch Google Street View. Try again later.', { variant: 'error' })
            })
    }, [props, enqueueSnackbar, isMounted])

    return (
        <Box>
            {url && (
                <img
                    className='streetview'
                    style={{ display: isLoaded ? 'block' : 'none' }}
                    alt='Street view'
                    src={url}
                    onLoad={() => setIsLoaded(true)}
                />
            )}
            {!isLoaded ? (
                <div className='streetview-loading'>
                    <Skeleton variant="rectangular" animation="wave" />
                </div>
            ) : null}
        </Box>
    );
}

StreetViewPreview.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    heading: PropTypes.number.isRequired,
}

StreetViewPreview.defaultProps = {}

export default StreetViewPreview;