import { Box, CircularProgress } from '@mui/material';

import './splitLoading.scss';

function SplitLoading(props) {

    return (
        <Box className='split-loading'>
            <CircularProgress />
        </Box>
    );
};

SplitLoading.propTypes = {};

SplitLoading.defaultProps = {};

export default SplitLoading;