import { memo } from 'react';
import { alpha } from '@mui/material';
import PropTypes from 'prop-types';

import './deviceCluster.scss';
import ClusterMarker from '../clusterMarker';

function DeviceClusterMarker(props) {
  let innerProps = {
    className: undefined,
    bgcolor: (t) => alpha(t.palette.secondary.main, 0.8),
    color: 'secondary.contrastText',
  };

  if (props.cluster.properties.hasAlert)
    innerProps = {
      className: 'hasAlert',
      bgcolor: (t) => alpha(t.palette.error.main, 0.8),
      color: 'error.contrastText',
    };

  return <ClusterMarker innerProps={innerProps} {...props} />;
}

DeviceClusterMarker.propTypes = {
  cluster: PropTypes.object.isRequired,
  supercluster: PropTypes.object.isRequired,
};

DeviceClusterMarker.defaultProps = {};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.cluster.properties.point_count ===
      nextProps.cluster.properties.point_count &&
    prevProps.cluster.properties.hasAlert ===
      nextProps.cluster.properties.hasAlert
  );
};

export default memo(DeviceClusterMarker, arePropsEqual);
