import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './spin.scss';

function SpinAnimation({ color1, color2, ...props }) {

    return (
        <Box
            className='spin-animation animation-root'
            sx={{ borderTopColor: color1, borderBottomColor: color2 }}
            {...props}
        />
    );
}

SpinAnimation.propTypes = {
    color1: PropTypes.string.isRequired,
    color2: PropTypes.string.isRequired,
}

SpinAnimation.defaultProps = {}

export default SpinAnimation;
