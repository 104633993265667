import TextField from '@mui/material/TextField';
import { useController } from "react-hook-form";
import PropTypes from 'prop-types';

import './colorPicker.scss';
import { hexRegex } from '../../../../utilities/helpers';

function ColorPickerField({ name, label, defaultValue, rules, helperText, ...props }) {
    const { field, fieldState: { error } } = useController({
        name,
        rules: {
            pattern: {
                value: hexRegex,
                message: 'Invalid Color'
            },
            ...rules,
        },
        defaultValue,
        shouldUnregister: true
    });

    return (
        <TextField
            {...field}
            value={field.value || ''}
            fullWidth
            variant="outlined"
            label={(label && rules?.required) ? `${label} *` : label}
            error={error !== undefined}
            helperText={error?.message || helperText}
            InputProps={{
                endAdornment: <input type='color' {...field} value={field.value || ''} {...props} />,
            }}
            {...props}
        />
    );
};

ColorPickerField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    rules: PropTypes.object,
    helperText: PropTypes.string,
};

ColorPickerField.defaultProps = {
    defaultValue: '',
};

export default ColorPickerField;
