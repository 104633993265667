import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { LinearProgress, Paper, Typography } from '@mui/material';

import './weatherStatus.scss';
import WeatherLayer from '../../services/weather';
import mapAPIService from '../../services/map';

function WeatherStatus(props) {
  const [index, setIndex] = useState(0);
  const [layer, setLayer] = useState(null);

  useEffect(() => {
    const newLayer = new WeatherLayer();
    let active = true;

    newLayer.onReady().then(() => {
      if (active) {
        newLayer.addTo(mapAPIService.map);
        setLayer(newLayer);
      }
    });

    const updateIndex = (event) => {
      setIndex(event.detail.index);
    };
    newLayer.addEventListener(newLayer.events.frameUpdate, updateIndex);
    return () => {
      active = false;
      newLayer.removeFrom(mapAPIService.map);
      newLayer.removeEventListener(newLayer.events.frameUpdate, updateIndex);
    };
  }, []);

  return (
    <Paper className='weather-status'>
      <LinearProgress
        variant={layer ? 'determinate' : undefined}
        value={(index / layer?.numberOfFrames || 0) * 100}
        className='status-progress'
      />
      <Typography className='status-text'>
        {layer
          ? DateTime.fromISO(layer.frames[index].timestamp).toLocaleString(
              DateTime.DATETIME_SHORT_WITH_SECONDS
            )
          : '--'}
      </Typography>
    </Paper>
  );
}

WeatherStatus.propTypes = {};

WeatherStatus.defaultProps = {};

export default WeatherStatus;
