import { useCallback, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useSupercluster from 'use-supercluster';
import { useMap, useMapEvent } from 'react-leaflet';
import { Marker, MarkerLayer } from 'react-leaflet-marker';

import mapAPIService from '../../../services/map';
import DeviceCluster from '../../markers/cluster/device/deviceCluster';
import DeviceMarker from '../../markers/device/deviceMarker';
import { clusterOptions } from './common';
import {
  selectMapActiveDetailPopUp,
  selectMapSettings,
  selectVisibleDevices,
} from '../../../store/selectors';
// import { useTraceUpdate } from '../../../utilities/helpers';

function DeviceLayer() {
  const map = useMap();

  const [bounds, setBounds] = useState(map.getBounds());
  const [zoom, setZoom] = useState(map.getZoom());

  const devices = useSelector(selectVisibleDevices, shallowEqual);
  const { groupMarkers: useGroups } = useSelector(selectMapSettings);
  const activeDetailPopUp = useSelector(selectMapActiveDetailPopUp);

  useMapEvent('moveend', (event) => {
    setBounds(event.target.getBounds());
    setZoom(event.target.getZoom());
  });

  const devicePoints = Object.values(devices).map((device) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      device,
    },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(device.currentLocation.longitude),
        parseFloat(device.currentLocation.latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: devicePoints,
    bounds: [
      bounds._southWest.lng,
      bounds._southWest.lat,
      bounds._northEast.lng,
      bounds._northEast.lat,
    ],
    zoom: zoom,
    options: {
      ...clusterOptions,
      maxZoom: useGroups ? mapAPIService.maxClusterZoom : 0,
    },
  });

  const renderClusterMarker = useCallback(
    (cluster) => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      return (
        <Marker key={`cluster-${cluster.id}`} position={[latitude, longitude]}>
          <DeviceCluster cluster={cluster} supercluster={supercluster} />
        </Marker>
      );
    },
    [supercluster]
  );

  const renderDeviceMarker = (device) => (
    <Marker
      key={device.id}
      position={[
        device.currentLocation.latitude,
        device.currentLocation.longitude,
      ]}
      zIndexOffset={activeDetailPopUp === device.id ? 9999999 : undefined}
    >
      <DeviceMarker device={device} />
    </Marker>
  );

  const deviceMarkers = [],
    clusterMarkers = [];

  clusters.forEach((cluster) => {
    if (cluster.properties.cluster)
      clusterMarkers.push(renderClusterMarker(cluster));
    else if (cluster.properties.device)
      deviceMarkers.push(renderDeviceMarker(cluster.properties.device));
    else console.warn('Invalid Cluster marker');
  });

  // useTraceUpdate({
  //   devices,
  //   bounds,
  //   zoom,
  //   activeDetailPopUp,
  //   useGroups,
  //   map,
  //   supercluster,
  // });
  // console.debug('Render Device Layer');

  return (
    <MarkerLayer pane='markerPane'>
      {clusterMarkers}
      {deviceMarkers}
    </MarkerLayer>
  );
}

DeviceLayer.propTypes = {};

DeviceLayer.defaultProps = {};

export default DeviceLayer;
