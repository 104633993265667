import { useState } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';
import PropTypes from 'prop-types';

import './friendlyMessage.scss';
import USFTDialogTitle from '../dialog/dialogTitle/dialogTitle';

function FriendlyMessage({ message }) {
    const [showFriendlyMessage, setShowFriendlyMessage] = useState(true);

    const handleCloseFriendlyMessage = () => {
        setShowFriendlyMessage(false);
    };

    return (
        <Dialog
            open={showFriendlyMessage}
            onClose={handleCloseFriendlyMessage}
            fullWidth
            maxWidth='sm'
        >
            <USFTDialogTitle title='Alert' onClose={handleCloseFriendlyMessage} />
            <DialogContent dividers>
                {message}
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='success' onClick={handleCloseFriendlyMessage}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

FriendlyMessage.propTypes = {
    message: PropTypes.string.isRequired,
};

FriendlyMessage.defaultProps = {};

export default FriendlyMessage;
