import { memo } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import './clusterMarker.scss';
import mapAPIService from '../../../services/map';

function ClusterMarker({ cluster, supercluster, innerProps, ...props }) {
  const onClick = (event) => {
    mapAPIService.center({
      latitude: cluster.geometry.coordinates[1],
      longitude: cluster.geometry.coordinates[0],
      zoomLevel: Math.min(
        supercluster.getClusterExpansionZoom(cluster.id),
        mapAPIService.maxZoom
      ),
    });
  };

  const size = mapAPIService.clusterRadius * 0.6;

  return (
    <Box
      className='cluster-marker'
      onClick={onClick}
      width={size}
      height={size}
      {...props}
    >
      <Box {...innerProps}>{cluster.properties.point_count}</Box>
    </Box>
  );
}

ClusterMarker.propTypes = {
  cluster: PropTypes.object.isRequired,
  supercluster: PropTypes.object.isRequired,
};

ClusterMarker.defaultProps = {};

const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.cluster.geometry.coordinates[1] ===
      nextProps.cluster.geometry.coordinates[1] &&
    prevProps.cluster.geometry.coordinates[0] ===
      nextProps.cluster.geometry.coordinates[0] &&
    prevProps.cluster.properties.point_count ===
      nextProps.cluster.properties.point_count
  );
};

export default memo(ClusterMarker, arePropsEqual);
