import { useMemo } from 'react';
import { connect } from 'react-redux';
import { Box, Grid, IconButton, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ContactsIcon from '@mui/icons-material/Contacts';
import BarChartIcon from '@mui/icons-material/BarChart';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import LayersIcon from '@mui/icons-material/Layers';
import MapIcon from '@mui/icons-material/Map';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TuneIcon from '@mui/icons-material/Tune';
import ArticleIcon from '@mui/icons-material/Article';
import HistoryIcon from '@mui/icons-material/History';
import BuildIcon from '@mui/icons-material/Build';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import PropTypes from 'prop-types';

import './sidebar.scss';
import SidebarSection from '../../common/sidebarSection/sidebarSection';
import SidebarItem from '../../common/sidebarItem/sidebarItem';
import Copyright from '../../../components/copyright/copyright';
import Logo from '../../../components/logo/logo';
import ThemeModeField from '../../../components/forms/fields/themeMode/themeMode';
import { getDealer } from '../../../utilities/helpers';

function Sidebar({ user, onClose, ...props }) {
  const dealer = useMemo(getDealer, []);

  const handleClick = (event) => {
    if (typeof onClose === 'function') onClose();
  };

  const menuSections = [
    {
      title: 'General',
      permission: true,
      options: [
        {
          label: 'Service Module',
          icon: HomeRepairServiceIcon,
          to: '/service/calls',
          hide: !user.serviceModule,
        },
        { label: 'Dashboard', icon: DashboardIcon, to: '/manage/dashboard', disabled: true },
        {
          label: 'Data Management',
          icon: NoteAltIcon,
          to: '/manage/data',
          hide: dealer,
          disabled: !user.admin,
        },
        {
          label: 'Alerts',
          icon: NotificationImportantIcon,
          to: '/manage/alerts',
          hide: !user.permissions.alerts.manage,
        },
        {
          label: 'KMLs',
          icon: LayersIcon,
          to: '/manage/KMLs',
          hide: dealer,
          disabled: !user.admin,
        },
        {
          label: 'Video Requests',
          icon: VideoLibraryIcon,
          to: '/manage/video-requests',
          hide: !user.permissions.videos,
        },
      ],
    },
    {
      title: 'Devices',
      permission: user.permissions.devices.manage,
      options: [
        { label: 'Devices', icon: DevicesOtherIcon, to: '/manage/devices' },
        { label: 'Groups', icon: ClearAllIcon, to: '/manage/devices/groups' },
        {
          label: 'Maintenance',
          icon: BuildIcon,
          to: '/manage/devices/maintenance',
        },
      ],
    },
    {
      title: 'Addresses',
      permission: user.permissions.addresses.manage,
      options: [
        { label: 'Addresses', icon: MapsHomeWorkIcon, to: '/manage/addresses' },
        { label: 'Groups', icon: ClearAllIcon, to: '/manage/addresses/groups' },
      ],
    },
    {
      title: 'Contacts',
      permission: user.permissions.contacts.manage,
      options: [
        { label: 'Contacts', icon: ContactsIcon, to: '/manage/contacts' },
        {
          label: 'FOBs',
          icon: FingerprintIcon,
          to: '/manage/fobs',
          disabled: !user.admin,
        },
      ],
    },
    {
      title: 'Reports',
      permission: user.permissions.reports.manage,
      options: [
        { label: 'Run Report', icon: BarChartIcon, to: '/manage/reports/run' },
        { label: 'History', icon: HistoryIcon, to: '/manage/reports' },
        {
          label: 'Schedules',
          icon: ScheduleIcon,
          to: '/manage/reports/schedules',
        },
        {
          label: 'Driver Scorecards',
          icon: ArticleIcon,
          to: '/manage/scorecards',
          hide: dealer,
        },
        {
          label: 'Flex Reports',
          icon: TuneIcon,
          to: '/manage/reports/flex',
          disabled: true,
        },
      ],
    },
    {
      title: 'Dispatch',
      permission: user.permissions.routes.manage,
      options: [{ label: 'Routing', icon: AltRouteIcon, to: '/manage/routes' }],
    },
  ]
    .filter((section) => section.permission)
    .map((section) => {
      const filteredOptions = section.options.filter(
        (option) => option.hide === undefined || !option.hide
      );
      return (
        <SidebarSection
          key={section.title}
          title={section.title}
          options={filteredOptions}
          onClick={handleClick}
        />
      );
    });

  return (
    <Box component='nav' className='sidebar'>
      <Box>
        {!dealer && (
          <Grid container p={2}>
            <Grid item xs={9} md={12}>
              <Logo />
            </Grid>
            <Grid
              item
              xs
              sx={{ display: { sm: 'block', md: 'none' }, flexGrow: 1 }}
            />
            <Grid item xs sx={{ display: { sm: 'block', md: 'none' } }}>
              <IconButton aria-label='close' onClick={handleClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <List>
          <SidebarItem
            label='Return To Map'
            icon={MapIcon}
            to='/'
            onClick={handleClick}
          />
          {menuSections}
        </List>
      </Box>
      <Box>
        <Grid container p={2} spacing={2}>
          <Grid item xs={12} className='theme-mode-container'>
            <ThemeModeField />
          </Grid>
          <Grid item xs={12}>
            <Copyright />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

Sidebar.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
