import { Box, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import './checkbox.scss';

function USFTCheckbox({ name, label, defaultValue, tooltip, rules, ...props }) {
  const {
    field: { value, ref, ...field },
  } = useController({ name, rules, defaultValue, shouldUnregister: true });

  return (
    <Box className='usft-checkbox'>
      <FormControlLabel
        control={
          <Checkbox {...field} checked={value} inputRef={ref} {...props} />
        }
        label={label}
        sx={{ ml: 0 }} // TODO remove probably
      />
      {tooltip && (
        <Tooltip title={tooltip} placement='top'>
          <HelpIcon fontSize='small' tabIndex={0} />
        </Tooltip>
      )}
    </Box>
  );
}

USFTCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  rules: PropTypes.object,
  defaultValue: PropTypes.bool,
};

USFTCheckbox.defaultProps = {
  defaultValue: false,
};

export default USFTCheckbox;
