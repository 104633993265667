import { connect } from 'react-redux';
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';

import './themeManager.scss';
import { themeModes } from '../../utilities/helpers';
import { lightTheme, darkTheme } from '../../utilities/colors';

function ThemeManager({ children, themePreferences, ...props }) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true,
  });

  let mode;
  if (themePreferences.mode === themeModes.auto)
    mode = prefersDarkMode ? themeModes.dark : themeModes.light;
  else mode = themePreferences.mode;

  const defaults = mode === themeModes.light ? lightTheme : darkTheme;
  const themeOptions = {
    ...defaults,
    palette: {
      ...defaults.palette,
    },
    components: {
      MuiAppBar: {
        defaultProps: {
          enableColorOnDark: true,
        },
      },
      MuiChip: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            borderRadius: '4px',
          },
        },
      },
    },
  };

  if (themePreferences.colors[mode].primary)
    themeOptions.palette.primary = {
      main: themePreferences.colors[mode].primary,
    };
  if (themePreferences.colors[mode].secondary)
    themeOptions.palette.secondary = {
      main: themePreferences.colors[mode].secondary,
    };

  const theme = responsiveFontSizes(createTheme(themeOptions));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

ThemeManager.propTypes = {
  themePreferences: PropTypes.object.isRequired,
};

ThemeManager.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    themePreferences: state.app.preferences.theme,
  };
};

export default connect(mapStateToProps)(ThemeManager);
