import { useState } from 'react';
import { Grid, IconButton, InputBase, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import PropTypes from 'prop-types';

import './chatInput.scss';

function LiveChatInput({ onSubmit, ...props }) {
  const [submitting, setSubmitting] = useState(false);
  const [chatText, setChatText] = useState('');

  const handleChange = (event) => {
    if (submitting) setSubmitting(false);
    else setChatText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (!event.shiftKey && event.key === 'Enter') handleSubmit();
  };

  const handleSubmit = () => {
    setSubmitting(true);
    if (chatText.trim().length) onSubmit(chatText.trim());
    setChatText('');
  };

  return (
    <Grid container p={1} className='chat-input'>
      <Grid item xs>
        <InputBase
          value={chatText}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder='Write a reply...'
          fullWidth
          multiline
          maxRows={3}
          size='small'
          inputProps={{
            'data-lpignore': true,
          }}
        />
      </Grid>
      <Grid item xs='auto' className='chat-buttons'>
        <Tooltip
          title='Attach File (Coming Soon)'
          placement='top-end'
          enterDelay={500}
        >
          <span>
            <IconButton className='attach-button' size='small' disabled>
              <AttachFileIcon />
            </IconButton>
          </span>
        </Tooltip>
        <IconButton
          className='send-button'
          size='small'
          onClick={handleSubmit}
          disabled={chatText.length === 0}
        >
          <SendIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

LiveChatInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

LiveChatInput.defaultProps = {};

export default LiveChatInput;
