import { TextField, Typography } from '@mui/material';
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import './text.scss';

function USFTTextField({
  name,
  label,
  overrideLabel,
  defaultValue,
  rules,
  helperText,
  ...props
}) {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue, shouldUnregister: true });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') event.stopPropagation();
  };

  let helperTextContent = error?.message || helperText;
  if (props.multiline && typeof rules?.maxLength?.value === 'number')
    helperTextContent = (
      <>
        <Typography component='span'>{helperTextContent}</Typography>
        <Typography component='span'>
          {field.value.length} / {rules.maxLength.value}
        </Typography>
      </>
    );

  return (
    <TextField
      {...field}
      value={field.value || ''}
      fullWidth
      variant='outlined'
      label={label && !overrideLabel && rules?.required ? `${label} *` : label}
      error={error !== undefined}
      helperText={helperTextContent}
      className='usft-text-field'
      onKeyPress={props.multiline ? handleKeyPress : undefined}
      {...props}
    />
  );
}

USFTTextField.propTypes = {
  name: PropTypes.string.isRequired,
  overrideLabel: PropTypes.bool.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  rules: PropTypes.object,
  helperText: PropTypes.string,
};

USFTTextField.defaultProps = {
  defaultValue: '',
  overrideLabel: false,
};

export default USFTTextField;
