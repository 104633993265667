import DefaultAnimation from '../../markers/animations/default/default';
import DefaultAltAnimation from '../../markers/animations/defaultAlt/defaultAlt';
import PulseAnimation from '../../markers/animations/pulse/pulse';
import DoublePulseAnimation from '../../markers/animations/pulseDouble/pulseDouble';
import SpinAnimation from '../../markers/animations/spin/spin';
import DoubleSpinAnimation from '../../markers/animations/spinDouble/spinDouble';
import WaveAnimation from '../../markers/animations/wave/wave';
import DoubleWaveAnimation from '../../markers/animations/waveDouble/waveDouble';

export const allDevicesOption = { id: 0, name: 'All Devices' };
export const allDriversOption = { id: 0, name: 'All Drivers' };
export const allAddressesOption = { id: 0, name: 'All Addresses' };

export const statusAllDevices = 'all';
export const statusMoving = 'moving';
export const statusIdle = 'idle';
export const statusStopped = 'stopped';
export const statusIgnitionOn = 'ignitionOn';
export const statusIgnitionOff = 'ignitionOff';
export const statusOffline = 'offline';

export const animationOptions = [
  { id: 0, name: 'Default', animation: DefaultAnimation },
  { id: 1, name: 'Default Alt', animation: DefaultAltAnimation },
  { id: 2, name: 'Pulse', animation: PulseAnimation },
  { id: 3, name: 'Double Pulse', animation: DoublePulseAnimation },
  { id: 4, name: 'Spin', animation: SpinAnimation },
  { id: 5, name: 'Double spin', animation: DoubleSpinAnimation },
  { id: 6, name: 'Wave', animation: WaveAnimation },
  { id: 7, name: 'Double Wave', animation: DoubleWaveAnimation },
];

// React Hook Form Rules
export const latitudeRules = {
  min: { value: -90, message: 'Invalid coordinate' },
  max: { value: 90, message: 'Invalid coordinate' },
};
export const longitudeRules = {
  min: { value: -180, message: 'Invalid coordinate' },
  max: { value: 180, message: 'Invalid coordinate' },
};
export const wholeNumberRules = {
  validate: {
    wholeNumber: (v) => v % 1 === 0 || 'Must be a whole number only',
  },
};
export const nonNegativeWholeNumberRules = {
  ...wholeNumberRules,
  min: { value: 0, message: 'Must be a non-negative value' },
};
export const positiveWholeNumberRules = {
  ...wholeNumberRules,
  min: { value: 1, message: 'Must be a positive value' },
};
export const profileNameRules = {
  minLength: {
    value: 2,
    message: 'Name must have a minimum length of 2',
  },
  maxLength: {
    value: 50,
    message: 'Name is too long',
  },
};
