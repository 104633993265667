import { useState } from 'react';
import { Box, Grow, IconButton, Slider, useMediaQuery, useTheme } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PropTypes from 'prop-types';

import './volumeControl.scss';

function VolumeControl({ onMuteToggle, onChange, volume, disabled, ...props }) {
    const [showSlider, setShowSlider] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const toggleSlider = (show) => (event) => {
        setShowSlider(show);
    };

    return (
        <Box
            className='volume-control'
            onMouseEnter={disabled ? undefined : toggleSlider(true)}
            onMouseLeave={disabled ? undefined : toggleSlider(false)}
        >
            <Grow in={(isMobile || showSlider) && !disabled} unmountOnExit>
                <Box className='volume-slider'>
                    <Slider
                        min={0}
                        max={1}
                        step={0.01}
                        getAriaLabel={() => 'Volume Slider'}
                        value={volume}
                        onChange={onChange}
                    />
                </Box>
            </Grow>
            <IconButton onClick={onMuteToggle} disabled={disabled}>
                {volume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </IconButton>
        </Box>
    );
}

VolumeControl.propTypes = {
    disabled: PropTypes.bool.isRequired,
    onMuteToggle: PropTypes.func,
    onChange: PropTypes.func,
    volume: PropTypes.number,
};

VolumeControl.defaultProps = {};

export default VolumeControl;
