import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Badge,
  Box,
  Drawer,
  Fab,
  IconButton,
  Popover,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PropTypes from 'prop-types';

import './notifications.scss';
import NotificationCenter from './center/notificationCenter';
import APIService from '../../services/api';
import titleService from '../../services/title';
import { socketManager } from '../../services/socket';
import { useMountedRef } from '../../utilities/helpers';

function Notifications({ token, useFAB }) {
  const [socket, setSocket] = useState(null);
  const [unreadNotificationsSession, setUnreadNotificationsSession] =
    useState(0);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);

  const isMounted = useMountedRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const open = Boolean(notificationAnchorEl);

  useEffect(() => {
    APIService.get('/notifications/unread')
      .then((response) => {
        if (isMounted.current) setUnreadNotifications(response.data.count);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [token, isMounted]);

  useEffect(() => {
    const _socket = socketManager.socket('/notifications', { auth: { token } });
    const handleNotificationMessage = (data) => {
      setUnreadNotifications((prevState) => prevState + 1);
      setUnreadNotificationsSession((prevState) => prevState + 1);
    };
    _socket.on('notifications', handleNotificationMessage);
    _socket.connect();
    setSocket(_socket);

    return () => {
      _socket.off('notifications', handleNotificationMessage);
      _socket.disconnect();
    };
  }, [token]);

  useEffect(() => {
    const handleFocus = () => {
      setUnreadNotificationsSession(0);
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  useEffect(() => {
    if (unreadNotificationsSession > 0 && !document.hasFocus()) {
      let title;
      if (unreadNotificationsSession === 1) title = 'NEW ALERT';
      else title = `${unreadNotificationsSession} NEW ALERTS`;
      titleService.cycle(title);
      return () => {
        titleService.reset();
      };
    }
  }, [unreadNotificationsSession]);

  const showNotifications = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => {
    setNotificationAnchorEl(null);
  };

  const notifications = (
    <NotificationCenter
      socket={socket}
      unreadNotifications={unreadNotifications}
      setUnreadNotifications={setUnreadNotifications}
      onClose={closeNotifications}
    />
  );

  const icon = (
    <Badge badgeContent={unreadNotifications} max={99} color='error'>
      <NotificationsIcon />
    </Badge>
  );

  let button;
  if (useFAB)
    button = (
      <Fab
        onClick={showNotifications}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        color='secondary'
      >
        {icon}
      </Fab>
    );
  else
    button = (
      <IconButton
        onClick={showNotifications}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        color='inherit'
      >
        {icon}
      </IconButton>
    );

  return (
    <>
      <Tooltip title='Notifications'>{button}</Tooltip>
      {isMobile ? (
        <Drawer anchor='bottom' open={open} onClose={closeNotifications}>
          <Box className='notifications-drawer'>{notifications}</Box>
        </Drawer>
      ) : (
        <Popover
          open={open}
          onClose={closeNotifications}
          anchorEl={notificationAnchorEl}
          anchorOrigin={{
            vertical: useFAB ? 70 : 60,
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box className='notifications-popper'>{notifications}</Box>
        </Popover>
      )}
    </>
  );
}

Notifications.propTypes = {
  unreadNotifications: PropTypes.number.isRequired,
  useFAB: PropTypes.bool.isRequired,
};

Notifications.defaultProps = {
  unreadNotifications: 0,
  useFAB: false,
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(Notifications);
