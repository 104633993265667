import { useCallback, useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import './customAddress.scss';
import { GlobalAPIService } from '../../../../services/api';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useMountedRef } from '../../../../utilities/helpers';
import { DateTime } from 'luxon';

function USFTCustomAddressField({
  name,
  label,
  defaultValue,
  rules,
  helperText,
  onPlaceChange,
  ...props
}) {
  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [options, setOptions] = useState([]);
  const [locations, setLocations] = useState({});
  const [loading, setLoading] = useState(false);

  const isMounted = useMountedRef();
  const timeout = useRef(null);
  const latestFetch = useRef(null);
  const {
    field: { onChange, ...field },
    fieldState: { error },
  } = useController({
    name,
    defaultValue: defaultValue || null,
    rules,
    shouldUnregister: true,
  });

  const fetch = useCallback(
    (input) => {
      const fetchID = uuidv4();
      console.debug('fetching', fetchID);
      latestFetch.current = fetchID;
      const start = DateTime.now();
      GlobalAPIService.get(
        `${process.env.REACT_APP_GEOCODE_API_URL}/autocomplete`,
        {
          headers: {
            Authorization: process.env.REACT_APP_GEOCODE_API_KEY,
          },
          params: { search: input },
        }
      )
        .then((response) => {
          console.debug('returned', fetchID, DateTime.now() - start);
          if (isMounted.current && latestFetch.current === fetchID) {
            console.debug('results', response.data);
            setOptions(response.data.map((option) => option.address));
            setLocations(
              response.data.reduce(
                (prev, option) => ({ ...prev, [option.address]: option }),
                {}
              )
            );
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [isMounted]
  );

  const handleInputChange = useCallback(
    (event, newInputValue) => {
      //   console.debug('handleInputChange', newInputValue, event);
      setInputValue(newInputValue);
      if (newInputValue === '' || !event || event.type === 'click') return;
      setLoading(true);
      //   setOptions([]);
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => fetch(newInputValue), 250);
    },
    [fetch, timeout]
  );

  const handleChange = useCallback(
    (event, newValue) => {
      console.log('onChange', newValue);
      onChange(newValue);
      setOptions([]);
      if (newValue && typeof onPlaceChange === 'function')
        onPlaceChange(locations[newValue]);
      setLocations({});
    },
    [locations, onChange, onPlaceChange]
  );

  return (
    <Autocomplete
      {...field}
      filterOptions={(x) => x}
      inputValue={inputValue}
      options={options}
      open={options.length > 0}
      autoComplete
      includeInputInList
      onBlur={() => setOptions([])}
      onChange={handleChange}
      onInputChange={handleInputChange}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant='outlined'
          error={error !== undefined}
          helperText={error?.message || helperText}
          label={label && rules?.required ? `${label} *` : label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {/* {params.InputProps.endAdornment} */}
              </>
            ),
          }}
        />
      )}
      {...props}
      //   rules={{
      //     ...props.rules,
      //     validate: {
      //       ...props.validate,
      //       //   validAddress: validateAddress,
      //     },
      //   }}
      //   inputRef={addressField}
    />
  );
}

USFTCustomAddressField.propTypes = {
  onPlaceChange: PropTypes.func,
};

USFTCustomAddressField.defaultProps = {};

export default USFTCustomAddressField;
