import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import './requestVideoForm.scss';
import FormSection from '../../forms/section/section';
import USFTDateTimeField from '../../forms/fields/dateTime/dateTime';
import DeviceField from '../../forms/fields/device/device';
import CRUDForm from '../../crud/form/form';
import CRUDField from '../../crud/field/field';

const fields = {
  device: 'device',
  time: 'time',
};

function RequestVideoForm({ device, time, ...props }) {
  const form = useForm({
    defaultValues: {
      [fields.device]: device ? { id: device.id, name: device.name } : null,
      [fields.time]: time ? DateTime.fromISO(time) : DateTime.now(),
    },
  });

  const formatSubmitData = (data) => ({
    device: data.device.id,
    time: data.time.toISO(),
  });

  return (
    <CRUDForm
      form={form}
      updateURL='/history/videos/request'
      submitDataFormatter={formatSubmitData}
      successMessage='Video has been requested and may take 5-10min to appear'
      {...props}
    >
      <FormSection>
        <CRUDField xs={12}>
          <DeviceField
            name={fields.device}
            label='Device'
            rules={{ required: 'Required' }}
            filterOptions={{ camera: true }}
            disabled={typeof device === 'object'}
          />
        </CRUDField>
        <CRUDField xs={12}>
          <USFTDateTimeField
            name={fields.time}
            label='Time'
            rules={{ required: 'Required' }}
            views={['year', 'day', 'hours', 'minutes', 'seconds']}
            helperText='This will request & pull video around this time (~30s before/after)'
          />
        </CRUDField>
        <CRUDField xs={12}>
          <Typography>
            <b>NOTE:</b> Vehicle must be turned on for the video to upload. You
            can still request a video and it will be uploaded when the vehicle
            is turned on.
          </Typography>
        </CRUDField>
      </FormSection>
    </CRUDForm>
  );
}

RequestVideoForm.propTypes = {
  device: PropTypes.object,
  formID: PropTypes.string,
  onSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func,
};

RequestVideoForm.defaultProps = {};

export default RequestVideoForm;
