import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './resetPassword.scss';
import AppLoading from '../../loading/app/app';
import APIService from '../../../services/api';
import { authFail } from '../../../store/slices/auth';
import { setLoading } from '../../../store/slices/app';

function ResetPasswordLogin() {
  const dispatch = useDispatch();

  let { token } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    APIService.post('/auth/password/reset/verify', { token: token })
      .then((response) => {
        if (response.data.valid === false) {
          dispatch(authFail('Reset token has expired'));
          dispatch(setLoading(false));
          navigate('/login');
        } else setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        navigate('/login');
      });
  }, [token, navigate, dispatch]);

  return <AppLoading />;
}

ResetPasswordLogin.propTypes = {};

ResetPasswordLogin.defaultProps = {};

export default ResetPasswordLogin;
