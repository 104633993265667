import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import './devices.scss';
import DeviceLegend from './legend/legend';
import DeviceGroupField from '../../forms/fields/deviceGroup/deviceGroup';
import DeviceStatusField from '../../forms/fields/deviceStatus/deviceStatus';
import mapAPIService from '../../../services/map';
import SidebarListView from '../../sidebar/listView/listView';
import { getDeviceRingColor } from '../../markers/common';
import {
  selectAuthUserPermissions,
  selectFilteredDevices,
  selectMapActiveDetailPopUp,
  selectMapDeviceFilters,
  selectMapFollowedMarker,
} from '../../../store/selectors';
import {
  setActiveDetailPopUp,
  setDeviceFilters,
  toggleShowAll,
  updateDevice,
} from '../../../store/slices/map';

const devicesEqual = (prevDevices, devices) => {
  if (Object.keys(prevDevices).length !== Object.keys(devices).length)
    return false;

  return Object.values(devices).every((device) => {
    if (
      device.name !== prevDevices[device.id]?.name ||
      device.serial !== prevDevices[device.id]?.serial ||
      device.visible !== prevDevices[device.id]?.visible ||
      device.ignition !== prevDevices[device.id]?.ignition ||
      (device.location.velocity === 0 &&
        prevDevices[device.id]?.location.velocity !== 0) ||
      (device.location.velocity !== 0 &&
        prevDevices[device.id]?.location.velocity === 0)
    )
      return false;
    else return true;
  });
};

const deviceFiltersEqual = (prevFilters, nextFilters) => {
  return (
    prevFilters.group.id === nextFilters.group.id &&
    prevFilters.status === nextFilters.status
  );
};

const fields = {
  deviceGroup: 'deviceGroup',
  query: 'query',
  status: 'status',
};

function DevicesSidebar() {
  const dispatch = useDispatch();
  const devices = useSelector(selectFilteredDevices, devicesEqual);
  const activeDetailPopUp = useSelector(selectMapActiveDetailPopUp);
  const followedMarker = useSelector(selectMapFollowedMarker);
  const permissions = useSelector(selectAuthUserPermissions);
  const filters = useSelector(selectMapDeviceFilters, deviceFiltersEqual);
  const isInitialMount = useRef(true);
  const form = useForm({
    defaultValues: {
      [fields.deviceGroup]: filters.deviceGroup,
      [fields.status]: filters.status,
    },
  });

  const deviceArray = Object.values(devices);

  useEffect(() => {
    console.log('here', followedMarker, filters.group, filters.status);
    if (isInitialMount.current) isInitialMount.current = false;
    else {
      if (!followedMarker) mapAPIService.resetView();
    }
  }, [followedMarker, filters.group, filters.status]);

  const filterRowsFunction = (query, device) =>
    (device.serial + device.name)
      .toLocaleLowerCase()
      .indexOf(query.toLocaleLowerCase()) >= 0;

  const handleGroupChange = async (event, data) => {
    form.setValue(fields.deviceGroup, data);
    form.setValue(fields.query, '');
    if (data)
      return dispatch(
        setDeviceFilters({ filters: { group: data }, updateVisibility: true })
      );
  };

  const handleFilterChange = async (event) => {
    form.setValue(fields.status, event.target.value);
    if (event.target.value)
      return dispatch(
        setDeviceFilters({
          filters: { status: event.target.value },
          updateVisibility: true,
        })
      );
  };

  const handleChange = (deviceID) => async (event) => {
    const isVisible = !devices[deviceID].visible;
    dispatch(
      updateDevice({ id: deviceID, properties: { visible: isVisible } })
    );
    if (isVisible && !followedMarker) return mapAPIService.resetView();
  };

  const handleAllChange = async (event) => {
    const deviceIDs = deviceArray.map((device) => device.id);
    dispatch(
      toggleShowAll({
        type: 'devices',
        show: event.target.checked,
        ids: deviceIDs,
      })
    );
    if (event.target.checked && !followedMarker)
      return mapAPIService.resetView();

    if (!event.target.checked) mapAPIService.unfollow();
    if (!event.target.checked && deviceIDs.includes(activeDetailPopUp))
      dispatch(setActiveDetailPopUp(null));
  };

  const getRowExtra = (device) => {
    return (
      <FiberManualRecordIcon
        sx={{ ml: 1, color: getDeviceRingColor(device) }}
      />
    );
  };

  const formFields = (
    <>
      <Grid item xs={12}>
        <DeviceLegend />
      </Grid>
      <Grid item xs={12}>
        <DeviceGroupField
          name={fields.deviceGroup}
          label='Show Group'
          onChange={handleGroupChange}
          includeAll
        />
      </Grid>
      <Grid item xs={12}>
        <DeviceStatusField
          name={fields.status}
          label='Status'
          onChange={handleFilterChange}
        />
      </Grid>
    </>
  );

  return (
    <FormProvider {...form}>
      <SidebarListView
        rows={deviceArray}
        getRowPrimaryText={(device) => device.name}
        getRowSecondaryText={(device) => device.serial}
        getRowExtra={getRowExtra}
        filterRows={filterRowsFunction}
        sortRows={(a, b) => a.name.localeCompare(b.name)}
        emptyMessage='No Devices Found'
        canManage={permissions.devices.manage}
        manageURL={'manage/devices'}
        onChange={handleChange}
        onAllChange={handleAllChange}
        formFields={formFields}
      />
    </FormProvider>
  );
}

DevicesSidebar.propTypes = {};

DevicesSidebar.defaultProps = {};

export default DevicesSidebar;
