import { useEffect, useState } from 'react';
import { Button, Typography, useTheme, useMediaQuery, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './notificationCTA.scss';
import { isPushNotificationsSupported } from '../../../utilities/helpers';

// TODO maybe add device (size) specific messages?
function NotificationCTA({ onClick, ...props }) {
    const [show, setShow] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (isPushNotificationsSupported() && Notification.permission !== 'denied') {
            navigator.serviceWorker.ready
                .then(registration => registration.pushManager.getSubscription())
                .then(subscription => {
                    if (!subscription)
                        setShow(true);
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }, []);

    if (show)
        return (
            <Grid container className='notification-cta' bgcolor='secondary.main' p={2}>
                <Grid item xs={6}>
                    <Typography color='secondary.contrastText'>
                        {isMobile ? 'Device' : 'Desktop'} notifications are not enabled.
                    </Typography>
                </Grid>
                <Grid item xs={6} className='button-container'>
                    <Button
                        component={Link}
                        to='/account/settings'
                        variant="contained"
                        color='primary'
                        onClick={onClick}
                    >
                        Enable
                    </Button>
                </Grid>
            </Grid>
        );
    else
        return null;
};

NotificationCTA.propTypes = {
    onClick: PropTypes.func,
};

NotificationCTA.defaultProps = {};

export default NotificationCTA;
