import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import './sharedView.scss';
import authService from '../../../services/auth';
import AppLoading from '../../loading/app/app';
import { setLoading } from '../../../store/slices/app';

function SharedViewLogin() {
  const dispatch = useDispatch();

  let { token } = useParams();

  useEffect(() => {
    authService.sharedViewLogin({ token }).then(() => {
      return dispatch(setLoading(false));
    });
  }, [token, dispatch]);

  return <AppLoading />;
}

SharedViewLogin.propTypes = {};

SharedViewLogin.defaultProps = {};

export default SharedViewLogin;
