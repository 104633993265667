import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import {
  Grid,
  Box,
  Button,
  Collapse,
  Paper,
  Typography,
  Skeleton,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PropTypes from 'prop-types';

import './deviceDetail.scss';
import mapAPIService from '../../../../services/map';
import APIService from '../../../../services/api';
import DeviceControlHOC from './deviceControlHOC/deviceControlHOC';
import CameraHOC from './cameraHOC/cameraHOC';
import EditDeviceHOC from './editDeviceHOC/editDeviceHOC';
import DispatchHOC from './dispatchHOC/dispatchHOC';
import { formatHeading, useMountedRef } from '../../../../utilities/helpers';
import RequestVideoButton from '../../../requestVideos/button/requestVideoButton';
import { formatTemperature } from '../../../../utilities/_algorithms';
import { updateDeviceLocationCache } from '../../../../store/slices/map';
import DirectionsAction from '../../common/actions/directions/directions';
import {
  selectAuth,
  selectMapDrivers,
  selectMapFollowedMarker,
  selectMapMode,
} from '../../../../store/selectors';
import defaultDevicePhoto from '../../../../static/images/common/default_device_photo.png';

// TODO optimize state and update Smartwitness
// TODO use Details like Historical Data and Alert Events
function DeviceDetail({ device, ...props }) {
  const { user, viewOnly } = useSelector(selectAuth);
  const [isLoading, setIsLoading] = useState(!viewOnly);
  const [details, setDetails] = useState({
    earliestRecording: null,
    previewURLs: [],
    previewIndex: 0,
    showSecondaryDetails: false,
    cameraOnline: null,
    tags: [],
    commands: [],
  });
  const [deviceImageLoaded, setDeviceImageLoaded] = useState(false);

  const isMounted = useMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const mapMode = useSelector(selectMapMode);
  const drivers = useSelector(selectMapDrivers);
  const followedMarker = useSelector(selectMapFollowedMarker);

  const deviceImage = details.photo || defaultDevicePhoto;
  const isFollowing = device.id === followedMarker;
  const previews = [deviceImage, ...details.previewURLs];
  let cameraStatus;

  const videoPermission = useMemo(
    () => !viewOnly && device.camera && user.permissions.videos,
    [viewOnly, device.camera, user]
  );

  useEffect(() => {
    if (videoPermission) {
      let active = true;
      let fetchInterval, cycleInterval, visibilityTimeout, isFetchingPreviews;
      const cyclePreviews = () => {
        console.debug('Cycling preview');
        setDetails((prevDetails) => {
          let index = prevDetails.previewIndex + 1;
          if (index > prevDetails.previewURLs.length) index = 0;
          return {
            ...prevDetails,
            previewIndex: index,
          };
        });
      };

      const fetchPreviews = async () => {
        console.debug('Fetching SmartWitness previews');
        isFetchingPreviews = true;
        return APIService.get('cameras/previews/', {
          params: { deviceID: device.camera.esn },
        })
          .then((response) => {
            if (active) {
              isFetchingPreviews = false;
              if (response.status === 200) {
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  previewURLs: response.data.previews.map(
                    (preview) => preview.url
                  ),
                  cameraOnline: true,
                }));
                return true;
              } else if (response.status === 202) {
                setDetails((prevDetails) => ({
                  ...prevDetails,
                  cameraOnline: false,
                }));
              }
            }
            return false;
          })
          .catch((e) => {
            console.error(e);
            if (active) {
              enqueueSnackbar('Unable to reach SmartWitness', {
                variant: 'error',
              });
              setDetails((prevDetails) => ({
                ...prevDetails,
                cameraOnline: false,
              }));
            }
            return false;
          });
      };

      const handleVisbilityChange = () => {
        if (document.visibilityState === 'hidden') {
          console.debug('Hidden');
          visibilityTimeout = setTimeout(() => {
            console.debug('Clearing intervals');
            clearInterval(fetchInterval);
            clearInterval(cycleInterval);
            fetchInterval = cycleInterval = null;
          }, 5000);
        } else {
          console.debug('Visible');
          clearTimeout(visibilityTimeout);
          if (
            (fetchInterval === null) & (cycleInterval === null) &&
            !isFetchingPreviews
          )
            setupIntervals();
        }
      };

      const setupIntervals = async () =>
        fetchPreviews().then((successful) => {
          if (active && document.visibilityState === 'visible') {
            if (successful) {
              cyclePreviews();
              cycleInterval = setInterval(cyclePreviews, 5000); // 5 seconds
            }
            fetchInterval = setInterval(fetchPreviews, 90000); // 3 minutes
          }
        });

      document.addEventListener('visibilitychange', handleVisbilityChange);
      setupIntervals();

      return () => {
        active = false;
        document.removeEventListener('visibilitychange', handleVisbilityChange);
        clearInterval(fetchInterval);
        clearInterval(cycleInterval);
      };
    }
  }, [mapMode, device.camera, videoPermission, enqueueSnackbar]);

  useEffect(() => {
    if (device.camera) {
      APIService.get('cameras/details/', {
        params: { deviceID: device.camera.esn },
      })
        .then((response) => {
          if (isMounted.current) {
            setDetails((prevDetails) => ({
              ...prevDetails,
              earliestRecording: response.data.EarliestAvailableRecording
                ? DateTime.fromISO(response.data.EarliestAvailableRecording)
                : 'Unavailable',
            }));
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Unable to fetch earliest video time', {
            variant: 'error',
          });
          if (isMounted.current) {
            setDetails((prevDetails) => ({
              ...prevDetails,
              earliestRecording: 'Unavailable',
            }));
          }
        });
    }
  }, [isMounted, device.camera, enqueueSnackbar]);

  // TODO Cache result in Redux on device
  useEffect(() => {
    if (!device.location.address) {
      mapAPIService
        .getLocation({
          latitude: device.location.latitude,
          longitude: device.location.longitude,
        })
        .then((location) => {
          console.debug('location', location);
          if (isMounted.current)
            dispatch(
              updateDeviceLocationCache({
                id: device.id,
                address: location ? location.formatted : 'Not Available',
              })
            );
        });
    }
  }, [isMounted, device.id, device.location, dispatch]);

  useEffect(() => {
    if (!viewOnly)
      APIService.get(`devices/${device.id}`)
        .then((response) => {
          if (isMounted.current) {
            setDetails((prevDetails) => ({
              ...prevDetails,
              ...response.data,
            }));
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`Unable to get device info. Try again later.`, {
            variant: 'error',
          });
        });
  }, [isMounted, device.id, viewOnly, enqueueSnackbar]);

  const handleScroll = (event) => {
    event.stopPropagation();
  };

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  const handleOnClick = (event) => {
    event.stopPropagation();
  };

  const handleOnFollow = (event) => {
    event.stopPropagation();
    if (isFollowing) mapAPIService.unfollow();
    else mapAPIService.follow(device.id);
  };

  const handleToggleSecondaryDetails = async (event) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      showSecondaryDetails: !prevDetails.showSecondaryDetails,
    }));
  };

  const handleDeviceSave = (device) => {
    setDetails((prevState) => ({
      ...prevState,
      photo: device.photo,
    }));
  };

  const renderDetail = (detail) => (
    <Grid item xs={12} key={detail[0]} className='detail'>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4} className='detail-key'>
          <Typography noWrap variant='subtitle2'>
            {detail[0]}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} className='detail-value'>
          {detail[1] ? detail[1] : '--'}
        </Grid>
      </Grid>
    </Grid>
  );

  const getEarliestValue = () => {
    if (!details.earliestRecording) return 'Loading...';
    else if (typeof details.earliestRecording === 'object')
      return details.earliestRecording.toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS
      );
    else return details.earliestRecording;
  };

  const primaryDetails = [
    ['Serial', device.serial, true],
    ['Name', device.name, true],
    ['VIN', device.vin, !viewOnly],
    ['Address', device.location.address || 'Loading...', true],
    ['Driver', device.driverID ? drivers[device.driverID]?.name : null, true],
    [
      'Speed',
      device.currentLocation.velocity > 0
        ? `${device.currentLocation.velocity} mph`
        : 'Stopped',
      true,
    ],
    [
      'Idling',
      device.lastMoved
        ? `${Math.round(
            DateTime.fromISO(device.lastMoved).diffNow('minutes').negate()
              .minutes
          )} min`
        : null,
      device.ignition && device.location.velocity === 0,
    ],
    ['Heading', formatHeading(device.currentLocation.heading), true],
    ['Latitude', device.location.latitude.toFixed(6), true],
    ['Longitude', device.location.longitude.toFixed(6), true],
    ['Satellites', device.currentLocation.satellites, !viewOnly],
    [
      'Ignition',
      device.ignition ? 'ON' : 'OFF',
      typeof device.ignition === 'boolean',
    ],
    [
      'Power',
      typeof device.power === 'number' ? `${device.power}%` : null,
      true,
    ],
    [
      'Last Updated',
      DateTime.fromISO(device.location.lastUpdated).toLocaleString(
        DateTime.DATETIME_MED_WITH_SECONDS
      ),
      true,
    ],
    [
      'Last Moved',
      device.lastMoved
        ? DateTime.fromISO(device.lastMoved).toLocaleString(
            DateTime.DATETIME_MED_WITH_SECONDS
          )
        : null,
      true,
    ],
    [
      'Mileage',
      device.mileage ? device.mileage.toLocaleString() : null,
      typeof device.mileage === 'number',
    ],
  ].filter((detail) => detail.at(-1));

  let secondaryDetails = [
    ['Earliest Video', getEarliestValue(), videoPermission],
    [
      'Temperature',
      formatTemperature(device.temp1),
      typeof device.temp1 === 'number',
    ],
    ['Fuel Card', device.fuelCardNumber, true],
  ].filter((detail) => detail.at(-1));

  secondaryDetails = secondaryDetails.concat(
    details.tags.map((tag) => [tag.name, tag.value])
  );

  if (videoPermission) {
    if (details.cameraOnline === null)
      cameraStatus = <Typography>Loading...</Typography>;
    else if (details.cameraOnline === true)
      cameraStatus = (
        <Box color={'success.main'} display='flex'>
          <FiberManualRecordIcon />
          <Typography>Camera online</Typography>
        </Box>
      );
    else
      cameraStatus = (
        <Box color={'error.main'} display='flex'>
          <FiberManualRecordIcon />
          <Typography>Camera offline</Typography>
        </Box>
      );
  }

  return (
    <Box
      className='device-detail'
      onMouseDown={handleMouseDown}
      onClick={handleOnClick}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Paper className='details' elevation={3} onWheel={handleScroll}>
            <Grid container>
              {primaryDetails.map(renderDetail)}
              {!viewOnly && secondaryDetails.length > 0 && (
                <>
                  <Collapse
                    in={details.showSecondaryDetails}
                    className='secondary-details'
                  >
                    {isLoading ? (
                      <Box className='secondary-details-loading'>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Grid container>
                        {secondaryDetails.map(renderDetail)}
                      </Grid>
                    )}
                  </Collapse>
                  <Grid item xs={12}>
                    <Button onClick={handleToggleSecondaryDetails} fullWidth>
                      {details.showSecondaryDetails ? 'Show less' : 'Show More'}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className='actions'>
            <div className='preview-container'>
              {!isLoading && (
                <img
                  className='preview'
                  style={{ display: deviceImageLoaded ? 'block' : 'none' }}
                  alt='User device'
                  src={previews[details.previewIndex]}
                  onLoad={() => setDeviceImageLoaded(true)}
                />
              )}
              {!deviceImageLoaded ? (
                <div className='preview-loading'>
                  <Skeleton variant='rectangular' animation='wave' />
                </div>
              ) : null}
            </div>
            {mapMode === mapAPIService.modes.live && (
              <Stack width='100%' spacing={1} mt={1} alignItems='center'>
                {videoPermission && (
                  <>
                    {cameraStatus}
                    <CameraHOC
                      device={device}
                      offline={!details.cameraOnline}
                    />
                    <RequestVideoButton device={device} />
                  </>
                )}
                {viewOnly || user.permissions.followDevice ? (
                  <Button variant='contained' onClick={handleOnFollow}>
                    {isFollowing ? 'Following' : 'Follow'}
                  </Button>
                ) : null}
                <DirectionsAction location={device.location} />
                {!viewOnly && user.permissions.devices.manage && (
                  <EditDeviceHOC id={device.id} onSave={handleDeviceSave} />
                )}
                {!viewOnly && user.permissions.dispatch.manage && (
                  <DispatchHOC device={device} />
                )}
                {!viewOnly &&
                  user.permissions.devices.control &&
                  details.commands.length > 0 && (
                    <DeviceControlHOC
                      deviceID={device.id}
                      commands={details.commands}
                    />
                  )}
              </Stack>
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

DeviceDetail.propTypes = {
  device: PropTypes.object.isRequired,
};

DeviceDetail.defaultProps = {};

export default DeviceDetail;
