import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
// import invert from 'invert-color';
import { findNearest } from 'geolib';
import L from 'leaflet';

import store from '../store';
import {
  finishDeviceAnimation,
  resetMap,
  setActiveDetailPopUp,
  setAddressFilters,
  setAddressGroups,
  setAddresses,
  setDeviceFilters,
  setDeviceGroups,
  setDeviceHistory,
  setDevices,
  setDrivers,
  setGeofences,
  setKMLs,
  setRoutes,
  setServiceModuleAppointments,
  setfollowedMarker,
  updateDevice,
  updateDeviceCurrentLocation,
  updateDeviceHistory,
  updateDeviceLocation,
  updateGeofence,
  updateKML,
  updateLiveVaporSettings,
  updateRoute,
  updateSettings,
} from '../store/slices/map';
import APIService, { GlobalAPIService } from './api';
// import WeatherService from './weather';
import { themeModes } from '../utilities/helpers';
import { appModes } from '../store/utility';
import { socketManager } from './socket';
import {
  selectVisibleAddresses,
  selectVisibleDevices,
} from '../store/selectors';
import '../utilities/layers';
import JSZip from 'jszip';
import { loadCDNPackage } from '../utilities/_algorithms';

// function getPolygonBounds() {
//   let bounds = new mapAPIService.maps.LatLngBounds();
//   this.getPath().forEach((element) => bounds.extend(element));
//   return bounds;
// }

/** Google Maps API Layer (GMAL) */
// TODO move marker into dedicated class
// TODO handle orphaned eventListeners (geofence, counties, live vapor)
// TODO split InfoWindow handlers
// TODO get web workers setup for location updates https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Using_web_workers
// TODO setup https://developers.google.com/maps/documentation/javascript/overview#js_api_loader_package
export class MapAPIService {
  mapOptions = {
    doubleClickZoom: false,
    zoomControl: false,
    wheelPxPerZoomLevel: 120,
  };
  defaults = {
    center: { lat: 39.5, lng: -98.35 },
    zoom: 5,
  };
  modes = {
    live: 'live',
    historyPlayback: 'history',
  };
  localStorageKey = 'mapSettings';
  clusterRadius = 150;
  minClusterPoints = 5;
  minZoom = 4;
  maxZoom = 22;
  maxClusterZoom = 17;
  boundsPadding = 32; // https://stackoverflow.com/a/44476181
  sureFitPadding = 0.002;
  animationZoomThreshold = 12;
  // Used to assign indexes to future custom layers
  layerIndexes = {
    weather: 0,
  };
  polylineIcons;
  routeEndIcon = `M175.9,99.8c-1.4-40.2-34.5-72.6-74.7-73.2C58.7,25.8,24,60.1,24,102.5c0,8.7,1.5,17.1,4.2,24.9l1.6,4.1
    c1,2.5,2.2,4.9,3.5,7.3l64.9,136.5c0.7,1.5,2.9,1.5,3.6,0l64.1-134.8c2.1-3.6,3.9-7.3,5.3-11.2l0.7-2
    C174.8,118.7,176.3,109.5,175.9,99.8z M100,132.6c-16.4,0-29.6-13.3-29.6-29.6S83.6,73.4,100,73.4s29.6,13.3,29.6,29.6
    S116.4,132.6,100,132.6z`;
  /**
   * Zoom level to FPS map for marker animations
   * {
   *  {Zoom level}: {FPS}
   * }
   */
  framesToZoomMap = {
    12: 2,
    13: 15,
    14: 15,
    15: 15,
    16: 30,
    17: 30,
    18: 60,
    19: 60,
    20: 60,
  };

  panes = {
    event: {
      name: 'event',
      zIndex: 601,
    },
    poi: {
      name: 'poi',
      zIndex: 602,
    },
  };

  map;
  maps;
  geocoder;
  places;
  bounds;
  isLoaded;

  socket;
  locationTopic = 'location:update';
  deviceTopic = 'device:update';
  trafficLayer;
  vaporTrails = {};
  routes = {};
  kmls = {};
  geofences = {};
  history = {};
  countyPolygons = [];
  infoWindow;
  autoZoomState = {
    timeout: null,
    lastZoomed: null,
    buffer: 5, // seconds
  };
  markedViews = {};
  prevMapState = null;
  liveVaporUnsubscribe;

  baseGoogleMapsURI = 'https://maps.googleapis.com'; // TODO move to ENV
  streetviewPath = '/maps/api/streetview'; // TODO move to ENV

  constructor() {
    console.debug('Initializing Maps Service');
    loadCDNPackage(
      'https://api.mqcdn.com/sdk/mapquest-js/v1.3.2/mapquest.js'
    ).then(() => {
      window.L.mapquest.key = process.env.REACT_APP_MAPQUEST_KEY;
    });
    // const loader = new Loader(this.mapOptions);
    // loader.load().then((google) => {
    //   this.maps = google.maps;
    //   this.geocoder = new google.maps.Geocoder();
    //   this.infoWindow = new google.maps.InfoWindow({ disableAutoPan: true });
    //   this.bounds = new google.maps.LatLngBounds(
    //     new google.maps.LatLng(-85, -180),
    //     new google.maps.LatLng(85, 180)
    //   );
    //   this.maps.Polygon.prototype.getBounds = getPolygonBounds;
    //   this.maps.Polyline.prototype.getBounds = getPolygonBounds;
    //   this.polylineIcons = [
    //     {
    //       icon: {
    //         path: this.maps.SymbolPath.FORWARD_OPEN_ARROW,
    //       },
    //       offset: '100px',
    //       repeat: '300px',
    //     },
    //   ];
    // });
  }

  /**
   * Helper function used to get styling.
   * @param {String} mode - Theme Mode to use
   * @returns {Object} Styling object
   */
  _getMapStyles = (mode) => {
    let themeMode = mode;

    if (typeof themeMode !== 'string')
      themeMode = store.getState().app.preferences.theme.mode;

    if (themeMode === themeModes.auto) {
      const query = window.matchMedia('(prefers-color-scheme: dark)');
      themeMode = query.matches ? themeModes.dark : themeModes.light;
    }

    return themeMode === themeModes.dark
      ? this.styling.default.concat(this.styling.dark)
      : this.styling.default;
  };

  /**
   * Helper function used to build map options for Google Maps.
   * @param {*} maps - Google Maps class
   * @param {Object} overrides - Option overrides
   * @returns {google.maps.MapOptions} Map options for Google Maps
   */
  createMapOptions = (maps, overrides = {}) => {
    return {
      gestureHandling: this.defaults.gestureHandling,
      zoomControl: false,
      clickableIcons: false,
      disableDoubleClickZoom: true,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      disableDefaultUI: true,
      streetViewControl: true,
      styles: this._getMapStyles(),
      fullscreenControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      panControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      rotateControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      scaleControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      streetViewControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      ...overrides,
    };
  };

  /**
   * Refreshes Google Maps' theme.
   * @param {String} maps - Theme Mode to use
   */
  updateMapTheme = (mode) => {
    console.debug('Updating Map theme');
    // if (!this.map) {
    //   console.info('Map is not initialized');
    //   return;
    // }
    // this.map.setOptions({ styles: this._getMapStyles(mode) });
  };

  /**
   * Sets a map for the service
   * @param {google.maps.Map} map - The Google Maps instance
   * @returns {Promise}
   */
  setMap = async (map) => {
    console.debug('Attaching to Map', map, this.kmls);
    this.map = map;
    Object.values(this.panes).forEach((pane) => {
      this.map.createPane(pane.name);
      this.map.getPane(pane.name).style.zIndex = pane.zIndex;
    });
    // this.places = new this.maps.places.PlacesService(this.map);
    // const mapState = store.getState().map;
    // Object.entries(this.routes)
    //   .filter(([id, _]) => mapState.routes[id].visible)
    //   .forEach(([_, route]) => {
    //     route.polyline.setMap(this.map);
    //     route.markers.forEach((marker) => marker.setMap(this.map));
    //   });
    // Object.entries(this.kmls)
    //   .filter(([id, _]) => mapState.kmls[id].visible)
    //   .forEach(([_, kml]) => kml.setMap(this.map));
    // Object.entries(this.geofences)
    //   .filter(([id, _]) => mapState.geofences[id].visible)
    //   .forEach(([_, fence]) => fence.setMap(this.map));
  };

  /** Builds a socketIO connection. */
  createSocket = async () => {
    this.socket = socketManager.socket('/map', {
      auth: {
        token: store.getState().auth.token,
      },
    });
  };

  // TODO Add error handling for all promises
  /** Updates the map with the latest device and driver information. */
  _loadMapData = async () => {
    const authState = store.getState().auth;
    let promises = [];
    if (authState.viewOnly) promises = [this.getDevices(), this.getKMLs()];
    else
      promises = [
        {
          promise: async () =>
            this.getDevices().then(() => {
              const mapData = store.getState().map;
              const followedDevice = mapData.devices[mapData.followedMarker];
              if (followedDevice)
                return this.center({
                  latitude: followedDevice.location.latitude,
                  longitude: followedDevice.location.longitude,
                });
            }),
          permission: authState.user.permissions.devices.view,
        },
        {
          promise: this.getDeviceGroups,
          permission: authState.user.permissions.devices.view,
        },
        {
          promise: this.getDrivers,
          permission: authState.user.permissions.contacts.view,
        },
        {
          promise: this.getAddresses,
          permission: authState.user.permissions.addresses.view,
        },
        {
          promise: this.getAddressGroups,
          permission: authState.user.permissions.addresses.view,
        },
        {
          promise: this.getRoutes,
          permission: authState.user.permissions.routes.view,
        },
        {
          promise: this.getGeofences,
          permission: authState.user.permissions.geofences.view,
        },
        {
          promise: this.getVaporTrails,
          permission: authState.user.permissions.devices.view,
        },
        { promise: this.getKMLs, permission: true },
        {
          promise: this.getAppointments,
          permission: authState.user.serviceModule,
        },
      ]
        .filter((call) => call.permission)
        .map((call) => call.promise());
    return Promise.all(promises);
  };

  /**
   * Updates the map with the latest device and driver information.
   * This will be used for updates to map such as tab timeout
   */
  updateMap = async () => {
    console.info('Updating Map');
    return this._loadMapData();
  };

  /**
   * Loads the map with the latest device and driver information.
   * This will be used for initial loading such as loading the page initially or coming back from management side
   */
  loadMap = async () => {
    console.info('Loading Map');
    let promises = [];
    if (localStorage[this.localStorageKey]) {
      this.prevMapState = JSON.parse(
        localStorage.getItem(this.localStorageKey)
      );
      if (this.prevMapState.settings) {
        const settings = {};
        if (this.prevMapState.settings.smallIcons !== undefined)
          settings.smallIcons = this.prevMapState.settings.smallIcons;
        if (this.prevMapState.settings.addressLabels !== undefined)
          settings.addressLabels = this.prevMapState.settings.addressLabels;
        if (this.prevMapState.settings.deviceLabels !== undefined)
          settings.deviceLabels = this.prevMapState.settings.deviceLabels;
        if (this.prevMapState.settings.groupMarkers !== undefined)
          settings.groupMarkers = this.prevMapState.settings.groupMarkers;
        promises.push(store.dispatch(updateSettings(settings)));
        // if (this.prevMapState.settings.type !== undefined)
        //   promises.push(
        //     mapAPIService.setMapType(this.prevMapState.settings?.type)
        //   );
      }
      if (this.prevMapState.deviceFilters)
        promises.push(
          store.dispatch(
            setDeviceFilters({
              filters: this.prevMapState.deviceFilters,
              updateVisibility: false,
            })
          )
        );
      if (this.prevMapState.addressFilters) {
        promises.push(
          store.dispatch(
            setAddressFilters({
              filters: this.prevMapState.addressFilters,
              updateVisibility: false,
            })
          )
        );
      }
    }
    promises.push(this._loadMapData());
    // Check Layers
    if (this.prevMapState?.settings) {
      if (this.prevMapState.settings.traffic)
        promises.push(this.enableTrafficLayer());
      if (this.prevMapState.settings.weather)
        promises.push(this.enableWeatherLayer());
      if (this.prevMapState.settings.counties)
        promises.push(this.enableCountyLayer());
    }
    return Promise.all(promises).then(() => (this.prevMapState = null));
  };

  /**
   * Handler for when the map bounds change.
   * @param {Number} zoom - Current zoom level
   * @param {Object} bounds - Current bounds of viewport
   */
  handleBoundsChanged = async (zoom, bounds) => {
    console.debug('Handling bounds changed', zoom, bounds);
    const currentState = store.getState();
    const focusedDevice =
      currentState.map.devices[currentState.map.activeDetailPopUp];
    if (focusedDevice && !this.isMarkerVisible(focusedDevice)) {
      store.dispatch(setActiveDetailPopUp(null));
    }

    if (currentState.map.followedMarker) return;
    else if (zoom >= this.animationZoomThreshold)
      this.getVisibleMarkers().then((markers) => {
        markers.forEach((marker) => {
          if (
            marker.prevLocation &&
            (marker.prevLocation.velocity !== 0 ||
              marker.location.velocity !== 0)
          )
            this.animateMoveDevice(marker.id);
        });
      });
    // Maybe stop all animations?
  };

  /** Fetches the visible markers within the current viewport. */
  getVisibleMarkers = async () => {
    console.debug('Getting visible markers');
    const bounds = this.map.getBounds();
    return Object.values(store.getState().map.devices).filter(
      (marker) =>
        marker.visible &&
        marker.location?.latitude &&
        bounds.contains({
          lat: marker.location.latitude,
          lng: marker.location.longitude,
        })
    );
  };

  /**
   * Checks if coordinates are valid.
   * @param {Object} latitude - The latitude coordinate
   * @param {Object} longitude - The longitude coordinate
   * @returns {Boolean}
   */
  areValidCoordinates = (latitude, longitude) =>
    typeof latitude === 'number' &&
    typeof longitude === 'number' &&
    !(latitude === 0 && longitude === 0) &&
    latitude >= -85 &&
    latitude <= 85 &&
    longitude >= -180 &&
    longitude <= 180;

  /**
   * Checks if marker is within current visible viewport.
   * @param {Object} marker - The marker to check
   * @param {Object} marker.visible - Is Marker not hidden
   * @param {Object} marker.location - The location object where the Marker is currently at
   * @param {Object} marker.prevLocation - The location object where the Marker was previously at
   * @returns {Boolean}
   */
  isMarkerVisible = (marker) => {
    const bounds = this.map.getBounds();
    return (
      marker.visible &&
      ((marker.prevLocation
        ? bounds.contains({
            lat: marker.prevLocation.latitude,
            lng: marker.prevLocation.longitude,
          })
        : false) ||
        bounds.contains({
          lat: marker.location.latitude,
          lng: marker.location.longitude,
        }))
    );
  };

  /**
   * Gets adjusted heading to help with animating markers.
   *
   * Example:
   * prevHeading = 1
   * nextHeading = 359
   * Since the next heading is more than 180 degrees difference,
   * the animation would show the car spinning in a circle clockwise to get there.
   * We instead set the nextHeading to -1 so that the car rotates naturally to Humans.
   *
   * @param {Number} prevHeading - The previous heading
   * @param {Number} nextHeading - The next Heading
   * @returns {Number} The adjusted heading
   */
  getAdjustedHeading = (prevHeading, nextHeading) => {
    console.debug('Getting adjusted heading');
    const diff = nextHeading - prevHeading;
    if (diff > 180) return nextHeading - 360;
    else if (diff < -180) return nextHeading + 360;
    else return nextHeading;
  };

  /**
   * Abstract fetch helper
   * @param {String} url - URL to fetch from
   * @param {Object} params - Params to pass through
   * @param {Function} action - Redux action to call
   * @returns {null}
   */
  _fetchObjects = async (url, params, action) => {
    return APIService.get(url, params).then(async (response) => {
      const state = store.getState();
      if (state.app.mode === appModes.map && state.map.mode === 'live')
        return store.dispatch(action(response.data, state.auth.viewOnly));
      else throw new Error('Map Unmounted');
    });
  };

  /**
   * Abstract build helper
   * @param {String} objectName - Name of object type
   * @param {Function} buildFunc - Function to build map object
   * @param {Function} hideFunc - Function to hide map object
   * @returns {null}
   */
  _buildObjects = async (objectName, buildFunc, hideFunc) => {
    const objects = store.getState().map[objectName];
    const newMapObjects = {};
    let promises = Object.values(objects).map(async (object) => {
      if (object.visible)
        newMapObjects[object.id] = await buildFunc(object, true);
    });
    await Promise.all(promises);
    Object.entries(this[objectName]).forEach(hideFunc);
    this[objectName] = newMapObjects;
  };

  _buildRoute = async (routeData, setMap = false) => {
    console.debug('Building Route');
    return {
      polyline: L.polyline(routeData.points, {
        color: routeData.color || '#ff8080',
        opacity: 1,
        weight: 6,
      }),
      markers: routeData.points.map((point, index) => {
        // TODO remove invert package
        const textColor = '#fff';
        // const textColor = invert(routeData.color, true);
        const icon =
          index < routeData.points.length - 1
            ? {
                path: this.maps.SymbolPath.CIRCLE,
                strokeColor: textColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillOpacity: 1.0,
                fillColor: routeData.color,
                scale: 10,
              }
            : {
                path: this.routeEndIcon,
                strokeColor: textColor,
                strokeOpacity: 1.0,
                strokeWeight: 2,
                fillOpacity: 1.0,
                fillColor: routeData.color,
                scale: 0.15,
                anchor: new this.maps.Point(100, 275),
              };
        return new this.maps.Marker({
          map: setMap ? this.map : null,
          position: new this.maps.LatLng(point.location),
          icon: icon,
          title: point.name,
          label:
            index > 0 && index < routeData.points.length - 1
              ? { text: index.toString(), color: textColor }
              : undefined,
          draggable: false,
        });
      }),
    };
  };

  // This will accept both KML and KMZs
  _buildKML = async (kmlData, setMap = false) => {
    console.debug('Building KML', kmlData, setMap);
    const isKMZ = kmlData.url.split('.').pop() === 'kmz';

    const loadKML = (kmltext) => {
      const parser = new DOMParser();
      const kmlText = parser.parseFromString(kmltext, 'text/xml');
      const kml = new L.KML(kmlText);
      if (setMap) kml.addTo(this.map);
      return kml;
    };

    return GlobalAPIService.get(kmlData.url, {
      responseType: isKMZ ? 'blob' : undefined,
    }).then((response) => {
      console.log('response', response);
      if (isKMZ) {
        return JSZip.loadAsync(response.data).then(async (zip) => {
          // console.log('usftMapService.showKMZLayer zip', zip)
          let promises = Object.values(zip.files).map((file) =>
            file.async('string').then(loadKML)
          );
          return Promise.all(promises);
        });
      } else {
        return [loadKML(response.data)];
      }
    });
  };

  _buildGeofence = async (fenceData, setMap = false) => {
    console.debug('Building Geofence', fenceData.id);
    let geofence;
    const color = `#${fenceData.color}`;
    if (fenceData.fence)
      geofence = L.polygon(fenceData.fence, {
        color: color,
        opacity: 1,
        weight: 2,
        fillColor: color,
        fillOpacity: 0.5,
      });
    else
      geofence = L.circle(fenceData.center, {
        radius: fenceData.radius,
        color: color,
        opacity: 1,
        weight: 2,
        fillColor: color,
        fillOpacity: 0.5,
      });

    geofence.bindPopup(
      `<div class="geofence-tooltip">${fenceData.name}</div>`,
      { autoPan: false }
    );
    geofence.on('mouseover', () => geofence.openPopup());
    geofence.on('mouseout', geofence.closePopup);

    if (setMap) geofence.addTo(this.map);
    return geofence;
  };

  _renderTrailInfo = (details) =>
    '<div class="live-vapor-tooltip">' +
    Object.entries(details)
      .map(
        ([label, value]) =>
          `<div class="tooltip-row">
                <div class="label">${label}:</div>
                <div>${value}</div>
            </div>`
      )
      .join('') +
    '</div>';

  _buildVaporTrail = (history, deviceID, direction = false) => {
    const device = store.getState().map.devices[deviceID];
    const trail = new this.maps.Polyline({
      map: this.map,
      path: history.map((point) => new this.maps.LatLng(...point.location)),
      strokeColor: device.map.vaporTrailColor || '#ff8080',
      strokeOpacity: 0.5,
      strokeWeight: 6,
      geodesic: true,
      icons: direction ? this.polylineIcons : null,
    });
    trail.addListener('click', async (event) => {
      const points = store.getState().map.history[deviceID].map((point) => ({
        latitude: point.location[0],
        longitude: point.location[1],
        timestamp: point.timestamp,
      }));
      const nearestPoint = findNearest(
        { latitude: event.latLng.lat(), longitude: event.latLng.lng() },
        points
      );
      const details = {
        Device: device.name,
        Serial: device.serial,
        Time: DateTime.fromISO(nearestPoint.timestamp).toLocaleString(
          DateTime.DATETIME_FULL
        ),
        Address: 'Loading...',
        Latitude: nearestPoint.latitude.toFixed(6),
        Longitude: nearestPoint.longitude.toFixed(6),
      };
      // this.infoWindow.setContent(this._renderTrailInfo(details));
      // this.infoWindow.setPosition({
      //   lat: nearestPoint.latitude,
      //   lng: nearestPoint.longitude,
      // });
      // this.infoWindow.open({
      //   map: this.map,
      //   shouldFocus: false,
      // });
      this.getLocation({
        latitude: nearestPoint.latitude,
        longitude: nearestPoint.longitude,
      })
        .then((location) => {
          details.Address = location.formatted_address;
          // this.infoWindow.setContent(this._renderTrailInfo(details));
        })
        .catch((error) => {
          console.error(error);
          details.Address = 'Unknown';
          // this.infoWindow.setContent(this._renderTrailInfo(details));
        });
    });
    return trail;
  };

  /** Fetches Devices and sends them off to Redux */
  getDevices = async () => {
    console.debug('Getting Devices');
    return this._fetchObjects('/map/devices', {}, setDevices);
  };

  /** Fetches Device Groups and sends them off to Redux */
  getDeviceGroups = async () => {
    console.debug('Getting Device Groups');
    return this._fetchObjects('/map/devices/groups', {}, setDeviceGroups);
  };

  /** Fetches Drivers and sends them off to Redux */
  getDrivers = async () => {
    console.debug('Getting Drivers');
    return this._fetchObjects('/drivers', {}, setDrivers);
  };

  /** Fetches Addresses and sends them off to Redux */
  getAddresses = async () => {
    console.debug('Getting Addresses');
    return this._fetchObjects('/map/addresses', {}, setAddresses);
  };

  /** Fetches Addresses Groups and sends them off to Redux */
  getAddressGroups = async () => {
    console.debug('Getting Address Groups');
    return this._fetchObjects('/map/addresses/groups', {}, setAddressGroups);
  };

  /** Fetches Routes and sends them off to Redux */
  getRoutes = async () => {
    console.debug('Getting Routes');
    return this._fetchObjects('/map/routes', {}, setRoutes)
      .then(() =>
        this._buildObjects('routes', this._buildRoute, ([id, route]) => {
          route.polyline.setMap(null);
          route.markers.forEach((marker) => marker.setMap(null));
        })
      )
      .catch((error) => {
        console.error(error);
      });
  };

  /** Fetches KMLs/KMZs and sends them off to Redux */
  getKMLs = async () => {
    console.debug('Getting KMLs');
    return this._fetchObjects('/map/kmls', {}, setKMLs)
      .then(() =>
        this._buildObjects('kmls', this._buildKML, ([id, layers]) => {
          layers.forEach((layer) => layer.remove());
        })
      )
      .catch((error) => {
        console.error(error);
      });
  };

  /** Fetches Geofences and sends them off to Redux */
  getGeofences = async () => {
    console.debug('Getting Geofences');
    return this._fetchObjects('/map/geofences', {}, setGeofences)
      .then(() =>
        this._buildObjects(
          'geofences',
          this._buildGeofence,
          ([id, geofence]) => {
            geofence.remove();
          }
        )
      )
      .catch((error) => {
        console.error(error);
      });
  };

  /** Fetches Live Vapor Trails and sends them off to Redux */
  getVaporTrails = async () => {
    const mapState = store.getState().map;
    if (!mapState.settings.liveVapor) return;
    console.debug('Getting Live Vapor Trails');
    return this._fetchObjects(
      '/map/devices/history',
      { params: mapState.liveVaporSettings },
      setDeviceHistory
    )
      .then(async () => {
        const history = store.getState().map.history;
        Object.entries(this.vaporTrails).forEach(([id, trail]) => {
          if (!Object.keys(history).includes(id)) {
            console.debug(`Removing stale Vapor Trail ${id}`);
            trail.setMap(null);
          }
        });
        const devices = store.getState().map.devices;
        const vaporTrails = {};
        Object.entries(history).forEach(([deviceID, points]) => {
          const device = devices[deviceID];
          if (device.visible) {
            let trail = this.vaporTrails[deviceID];
            if (trail)
              trail.setPath(
                points.map((point) => new this.maps.LatLng(...point.location))
              );
            else
              trail = this._buildVaporTrail(
                points,
                deviceID,
                mapState.liveVaporSettings.direction
              );
            vaporTrails[deviceID] = trail;
          }
        });
        this.vaporTrails = vaporTrails;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };

  /** Fetches Service Module Appointments and sends them off to Redux */
  getAppointments = async () => {
    console.debug('Getting Appointments');
    const date = DateTime.fromISO(
      store.getState().map.appointmentFilters.date
    ).toISODate();
    return this._fetchObjects(
      '/map/service/appointments',
      { params: { date } },
      setServiceModuleAppointments
    );
  };

  _defaultShowFunction = (object) => {
    object.addTo(this.map);
  };

  /**
   * Abstract show helper
   * @param {String} objectName - Name of object type
   * @param {Number} id - ID of object to show
   * @param {Function} buildFunc - Function to build map object
   * @param {Function} action - Redux action to call
   * @param {Function} showFunc - Function to show map object
   * @returns {Object} Map object (Geofence, KML, Route)
   */
  _showObject = async (
    objectName,
    id,
    buildFunc,
    action,
    showFunc = this._defaultShowFunction
  ) => {
    if (!this[objectName][id])
      this[objectName][id] = await buildFunc(
        store.getState().map[objectName][id]
      );
    const mapObject = this[objectName][id];
    showFunc(mapObject);
    store.dispatch(action({ id, properties: { visible: true } }));
    return mapObject;
  };

  /** Shows Route on Map */
  showRoute = async (routeID, zoom = false) => {
    console.debug(`Showing Route ${routeID}`);
    return this._showObject(
      'routes',
      routeID,
      this._buildRoute,
      updateRoute,
      (route) => {
        this.routes[routeID].polyline.setMap(this.map);
        this.routes[routeID].markers.forEach((marker) =>
          marker.setMap(this.map)
        );
      }
    ).then((route) => {
      if (zoom) {
        const bounds = route.polyline.getBounds();
        this.map.panTo(bounds.getCenter());
        this.map.fitBounds(bounds);
      }
    });
  };

  /** Hides Route on Map */
  hideRoute = async (routeID) => {
    console.debug(`Hiding Route ${routeID}`);
    this.routes[routeID].polyline.setMap(null);
    this.routes[routeID].markers.forEach((marker) => marker.setMap(null));
    return store.dispatch(updateRoute(routeID, { visible: false }));
  };

  /** Shows KML on Map */
  showKML = async (kmlID) => {
    console.debug(`Showing KML ${kmlID}`);
    return this._showObject(
      'kmls',
      kmlID,
      this._buildKML,
      updateKML,
      (layers) => layers.forEach((layer) => layer.addTo(this.map))
    );
  };

  /** Hides KML on Map */
  hideKML = async (kmlID) => {
    console.debug(`Hiding KML ${kmlID}`);
    const layers = this.kmls[kmlID];
    console.debug(`layers`, layers);
    layers.forEach((layer) => layer.remove());
    return store.dispatch(
      updateKML({ id: kmlID, properties: { visible: false } })
    );
  };

  /** Shows Geofence on Map */
  showGeofence = async (fenceID, zoom = false) => {
    console.debug(`Showing Geofence ${fenceID}`);
    return this._showObject(
      'geofences',
      fenceID,
      this._buildGeofence,
      updateGeofence
    ).then((geofence) => {
      if (zoom) {
        const bounds = geofence.getBounds();
        this.map.panTo(geofence.center || bounds.getCenter());
        this.map.fitBounds(bounds);
      }
    });
  };

  /** Hides Geofence on Map */
  hideGeofence = async (id) => {
    console.debug(`Hiding Geofence ${id}`);
    this.geofences[id].remove();
    // this.infoWindow.close();
    return store.dispatch(
      updateGeofence({ id, properties: { visible: false } })
    );
  };

  /** Shows Vapor Trail on Map */
  showVaporTrail = async (vaporTrailID) => {
    console.debug(`Showing Vapor Trail ${vaporTrailID}`);
    if (this.vaporTrails[vaporTrailID]) return this.vaporTrails[vaporTrailID];
    const trail = this._buildVaporTrail(
      store.getState().map.history[vaporTrailID],
      vaporTrailID
    );
    this.vaporTrails[vaporTrailID] = trail;
    return trail;
  };

  /** Hides Vapor Trail on Map */
  hideVaporTrail = async (vaporTrailID) => {
    console.debug(`Hiding Vapor Trail ${vaporTrailID}`);
    this.vaporTrails[vaporTrailID].setMap(null);
    delete this.vaporTrails[vaporTrailID];
  };

  _updateVaporTrail = async (trailID, location) => {
    console.debug('Updating Vapor Trail', trailID);

    const mapState = store.getState().map;
    let deviceHistory = mapState.history[trailID] || [];
    let sliceIndex = 0;

    deviceHistory.every((point, index) => {
      const timestamp = DateTime.fromISO(point.timestamp);
      if (
        timestamp.diffNow('hours').as('hours') >
        -mapState.liveVaporSettings.range
      ) {
        deviceHistory = deviceHistory.slice(index);
        sliceIndex = index;
        return false;
      } else return true;
    });
    deviceHistory.push({
      location: [location.latitude, location.longitude],
      timestamp: location.lastUpdated,
    });

    const vaporTrail = this.vaporTrails[trailID];
    if (vaporTrail) {
      let path = vaporTrail.getPath().getArray().slice(sliceIndex);
      path.push(new this.maps.LatLng(location.latitude, location.longitude));
      vaporTrail.setPath(path);
    }

    return store.dispatch(updateDeviceHistory(trailID, deviceHistory));
  };

  /**
   * Animates the movement of a marker
   * @param {String} markerID - ID of marker to animate
   */
  animateMoveDevice = async (markerID) => {
    console.debug('Animating marker', markerID);

    const animationLoopID = uuidv4();
    store.dispatch(
      updateDevice({
        id: markerID,
        properties: {
          animationLoop: animationLoopID,
        },
      })
    );

    const mapState = store.getState().map;
    const device = mapState.devices[markerID];
    const animationDuration = device.pingInterval + 0.5; // buffer 1/2 second
    const animationFrames =
      this.framesToZoomMap[this.map.getZoom()] * animationDuration;
    const animationDelay = (animationDuration / animationFrames) * 1000; // in ms

    console.debug('Estimated FPS:', animationFrames / animationDuration);

    // crossing the 180° meridian and going the long way around the earth?
    if (
      Math.abs(device.location.longitude - device.prevLocation.longitude) > 180
    )
      device.location.longitude +=
        device.location.longitude < device.prevLocation.longitude ? 360 : -360;

    const headingDiff =
      this.getAdjustedHeading(
        device.prevLocation.heading,
        device.location.heading
      ) - device.prevLocation.heading;
    const latitudeDiff =
      device.location.latitude - device.prevLocation.latitude;
    const longitudeDiff =
      device.location.longitude - device.prevLocation.longitude;
    const velocityDiff =
      device.location.velocity - device.prevLocation.velocity;
    const satelliteDiff =
      device.location.satellites - device.prevLocation.satellites;

    /** Generates the current "frame" for the marker  */
    const animateFrame = async () => {
      if (
        animationLoopID !==
        store.getState().map.devices[markerID]?.animationLoop
      )
        return;

      const durationRatio =
        DateTime.now().diff(startTime, 'milliseconds').as('milliseconds') /
        (animationDuration * 1000); // 0 - 1

      if (durationRatio < 1) {
        store.dispatch(
          updateDeviceCurrentLocation({
            id: device.id,
            location: {
              heading: (
                device.prevLocation.heading +
                headingDiff * durationRatio
              ).toFixed(1),
              lastUpdated: device.location.lastUpdated,
              latitude:
                device.prevLocation.latitude + latitudeDiff * durationRatio,
              longitude:
                device.prevLocation.longitude + longitudeDiff * durationRatio,
              velocity: Math.round(
                device.prevLocation.velocity + velocityDiff * durationRatio
              ),
              satellites: Math.round(
                device.prevLocation.satellites + satelliteDiff * durationRatio
              ),
              ignition: device.ignition,
            },
          })
        );
        setTimeout(animateFrame, animationDelay);
      } else {
        store.dispatch(finishDeviceAnimation(device.id));
      }
    };
    const startTime = DateTime.fromISO(device.location.lastUpdated);
    animateFrame();
  };

  /**
   * Handles location changes for devices.
   * @param {Object} data - Change data from SocketIO
   * @param {String} data.id - ID of marker that has changed
   * @param {Object} data.location - Location data for device
   * @param {String} data.location.lastUpdated - ISO date of when change occurred
   */
  handleLocationChange = async (data) => {
    // console.debug('Handling location change', data);
    const mapData = store.getState().map;
    const marker = mapData.devices[data.id];
    if (!marker) {
      // Address/Location changes will be caught here
      // TODO handle when a new device is added to account
      console.warn('No device found for ID', data.id);
      return;
    }
    const currentLastUpdated = DateTime.fromISO(marker.location.lastUpdated);
    const newLastUpdated = DateTime.fromISO(data.location.lastUpdated);
    if (newLastUpdated > currentLastUpdated) {
      let promises = [];
      if (mapData.settings.liveVapor)
        promises.push(this._updateVaporTrail(data.id, marker.location));
      if (
        this.map.getZoom() >= this.animationZoomThreshold &&
        this.isMarkerVisible({
          ...marker,
          prevLocation: marker.location,
          location: data.location,
        }) &&
        newLastUpdated.diff(currentLastUpdated).as('seconds') < 15 &&
        (data.location.velocity !== 0 || marker.location.velocity !== 0)
      ) {
        store.dispatch(
          updateDeviceLocation({
            id: data.id,
            location: data.location,
            moveDirect: false,
          })
        );
        this.animateMoveDevice(data.id);
      } else
        store.dispatch(
          updateDeviceLocation({
            id: data.id,
            location: data.location,
            moveDirect: true,
          })
        );
      return Promise.all(promises).then(() => {
        if (mapData.settings.autoZoom) return this.autoZoom();
      });
    } else {
      console.warn('Invalid Location', data);
    }
  };

  /**
   * Handles changes for devices.
   * @param {Object} data - Change data from SocketIO
   * @param {String} data.id - ID of marker that has changed
   * @param {Object} data.location - Location data for device
   * @param {String} data.location.lastUpdated - ISO date of when change occurred
   */
  handleDeviceChange = async (data) => {
    // console.debug('Handling device change');
    store.dispatch(updateDevice(data.id, data.device));
  };

  /** Opens socket connection to Hades */
  subscribe = async () => {
    console.debug('Subscribing to live data');
    if (this.socket && this.socket.connected) {
      console.warn('Socket is already connected');
      return;
    } else if (!this.socket) await this.createSocket();
    this.socket.connect();
    this.socket.on(this.locationTopic, this.handleLocationChange);
    this.socket.on(this.deviceTopic, this.handleDeviceChange);
  };

  /** Closes socket connection to Hades */
  unsubscribe = async () => {
    console.debug('Unsubscribing to live data');
    if (!this.socket) {
      console.warn('No socket available to disconnect');
      return;
    }
    this.socket.off(this.locationTopic, this.handleLocationChange);
    this.socket.off(this.deviceTopic, this.handleDeviceChange);
    this.socket.disconnect();
  };

  /** Clears map of markers and remove active detail popup */
  cleanUp = async (full = false) => {
    console.debug('Cleaning up map');
    Object.values(this.routes).forEach((route) => {
      route.polyline.setMap(null);
      route.markers.forEach((marker) => marker.setMap(null));
    });
    Object.values(this.kmls).forEach((layers) =>
      layers.forEach((layer) => layer.remove())
    );
    Object.values(this.geofences).forEach((fence) => fence.remove());

    // this.infoWindow.close();

    const promises = [
      this.disableCountyLayer(),
      this.disableAutoZoom(),
      this.disableLiveVapor(),
      this.disableTrafficLayer(),
      this.disableWeatherLayer(),
      full ? store.dispatch(resetMap()) : null,
    ];

    return Promise.all(promises);
  };

  /**
   * Gets a Google maps location object.
   * @private
   * @param {String} address - Street address
   * @param {Number} latitude - Latitude coordinate
   * @param {Number} longitude - Longitude coordinate
   * @param {Number} timeout - Retry timeout if service is unavailable
   * @returns {Promise<google.maps.LatLng>} Google Maps location object
   */
  _getLocation = async ({ address, latitude, longitude, timeout = 500 }) => {
    let route, params;
    if (address) {
      route = 'geocode';
      params = { search: address };
    } else if (this.areValidCoordinates(latitude, longitude)) {
      route = 'reverse';
      params = { lat: latitude, lng: longitude };
    } else throw new Error('Missing parameters');
    return GlobalAPIService.get(
      `${process.env.REACT_APP_GEOCODE_API_URL}/${route}`,
      {
        headers: { Authorization: process.env.REACT_APP_GEOCODE_API_KEY },
        params,
      }
    )
      .then((response) => {
        // console.log('Location result', response);
        return response.data;
      })
      .catch((e) => {
        if (e.code === 'OVER_QUERY_LIMIT') {
          console.warn(
            'The webpage has gone over the requests limit in too short a period of time'
          );
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve(
                this._getLocation({
                  address,
                  latitude,
                  longitude,
                  timeout: timeout * 2,
                })
              );
            }, timeout);
          });
        } else throw e;
      });
  };

  /**
   * Gets a Google maps location object.
   * @public
   * @param {String} address - Street address
   * @param {Number} latitude - Latitude coordinate
   * @param {Number} longitude - Longitude coordinate
   * @returns {Promise<google.maps.LatLng>} Google Maps location object
   */
  getLocation = async ({ address, latitude, longitude }) => {
    console.debug('Getting location for', address, latitude, longitude);
    return this._getLocation({ address, latitude, longitude });
  };

  /**
   * Searches for Google Maps Places.
   * @public
   * @param {String} query - Query to search
   * @returns {Promise<Array>} Array of <PlaceResult>
   */
  searchPlaces = async (query) => {
    console.debug('Searching Places for', query);
    return new Promise((resolve, reject) => {
      this.places.findPlaceFromQuery(
        {
          query,
          fields: ['place_id', 'name', 'formatted_address', 'geometry'],
          locationBias: 'IP_BIAS',
        },
        (results, status) => {
          console.debug('Google Places Results', results, status);
          switch (status) {
            case this.maps.places.PlacesServiceStatus.OK:
            case this.maps.places.PlacesServiceStatus.ZERO_RESULTS:
              resolve(results || []);
              break;
            default:
              reject(status);
          }
        }
      );
    });
  };

  /**
   * Sets the map type on Google Maps and Redux.
   * @param {String} mapTypeId - Google ENUM value for map type
   * @returns {Promise<Array>}
   */
  setMapType = async (mapTypeId) => {
    console.debug('Changing map type to', mapTypeId);
    const map = this.map.setMapTypeId(mapTypeId);
    const redux = store.dispatch(updateSettings({ type: mapTypeId }));
    return Promise.all([map, redux]);
  };

  /**
   * Creates a marked view.
   * A marked view is like a bookmark of a view that you
   * would like to return to at a later time.
   * @returns {String} UUID V4 for marked view
   */
  markView = () => {
    console.debug('Marking view');
    const uuid = uuidv4();
    this.markedViews[uuid] = {
      location: this.map.getCenter(),
      zoomLevel: this.map.getZoom(),
    };
    return uuid;
  };

  /** Deletes a marked view */
  unmarkView = async (viewID) => {
    console.debug('Unmarking view');
    delete this.markedViews[viewID];
  };

  /**
   * Centers the map to a location.
   * @param {String} address - Street address
   * @param {Number} latitude - Latitude coordinate
   * @param {Number} longitude - Longitude coordinate
   * @param {Number} zoomLevel - Zoom level
   * @param {String} markedViewID - ID of marked view to center on
   * @param {Number} animate - Indicates if the map should animate the centering
   * @returns {Promise<Array>}
   */
  // TODO split this into overloaded function?
  center = async ({
    address,
    latitude,
    longitude,
    zoomLevel,
    markedViewID,
    animate = true,
  }) => {
    console.debug(
      'Centering map on',
      address,
      latitude,
      longitude,
      zoomLevel,
      animate
    );
    let location, zoom;
    if (this.areValidCoordinates(latitude, longitude)) {
      location = { lat: latitude, lng: longitude };
      zoom = zoomLevel;
    } else if (markedViewID) {
      location = this.markedViews[markedViewID].location;
      zoom = this.markedViews[markedViewID].zoomLevel;
    } else if (address) {
      await this._getLocation({ address }).then((response) => {
        if (response) {
          location = response.geometry.location;
          zoom = zoomLevel;
        } else throw new Error('Invalid address');
      });
    } else throw new Error('Invalid location');
    return this.map.flyTo(location, zoom, { animate });
  };

  /**
   * Sets a marker to be followed.
   * @param {String} markerID - The ID of the marker to follow
   * @returns {Promise<Array>}
   */
  follow = async (markerID) => {
    console.debug('Following marker', markerID);
    const marker = store.getState().map.devices[markerID];
    const promises = [
      this.disableAutoZoom(),
      this.center({
        latitude: marker.currentLocation.latitude,
        longitude: marker.currentLocation.longitude,
        zoomLevel: this.maxClusterZoom + 1,
      }),
      store.dispatch(setfollowedMarker(markerID)),
    ];
    return Promise.all(promises);
  };

  /** Unsets the currently followed marker. */
  unfollow = async () => {
    console.debug('Unfollowing device');
    return store.dispatch(setfollowedMarker(null));
  };

  /**
   * Zooms the map to a specific level.
   * @param {Number} level - The level to zoom to
   * @returns {Promise}
   */
  zoom = async (level) => {
    if (level < this.minZoom || level > this.maxZoom)
      throw new Error('Invalid zoom level');
    console.debug('Zooming map to level', level);
    return this.map.setZoom(level);
  };

  /**
   * Pans the map by a specific x,y pair.
   * @param {Number} x - Number of pixels to move the map in the x direction
   * @param {Number} y - Number of pixels to move the map in the y direction
   * @returns {Promise}
   */
  pan = async (x, y) => {
    console.debug('Panning map by', x, y);
    return this.map.panBy(x, y);
  };

  /**
   * Rotates the map to face the heading.
   * @param {Number} heading - The heading to rotate to
   * @returns {Promise}
   */
  rotate = async (heading) => {
    if (heading < 0 || heading > 360) throw new Error('Invalid heading value');
    else if (this.map.getTilt() === 0) throw new Error('Unable to rotate');
    console.debug('Rotating map to heading', heading);
    return this.map.setHeading(heading);
  };

  /** Enables the Google Maps traffic layer. */
  enableTrafficLayer = async () => {
    console.debug('Enabling traffic layer');
    return store.dispatch(updateSettings({ traffic: true }));
  };

  /** Disables the Google Maps traffic layer. */
  disableTrafficLayer = async () => {
    console.debug('Disabling traffic layer');
    return store.dispatch(updateSettings({ traffic: false }));
  };

  /** Toggles the Google Maps traffic layer. */
  toggleTrafficLayer = async () => {
    console.debug('Toggling traffic layer');
    return store.getState().map.settings.traffic
      ? this.disableTrafficLayer()
      : this.enableTrafficLayer();
  };

  /** Enables Live Vapor. */
  enableLiveVapor = async (mode, range, direction = false) => {
    console.debug('Enabling Live Vapor');
    return store
      .dispatch(updateLiveVaporSettings({ mode, range, direction }))
      .then(this.getVaporTrails)
      .catch((error) => {
        this.disableLiveVapor();
        throw error;
      })
      .then(() => {
        let currentValue;
        // TODO reapproach this
        this.liveVaporUnsubscribe = store.subscribe(() => {
          let previousValue = currentValue;
          currentValue = store.getState().map.devices;

          if (previousValue !== currentValue) {
            Object.entries(this.vaporTrails).forEach(([deviceID, trail]) => {
              trail.setMap(currentValue[deviceID].visible ? this.map : null);
            });
          }
        });
      });
  };

  /** Disables Live Vapor. */
  disableLiveVapor = async () => {
    console.debug('Disabling Live Vapor');
    if (typeof this.liveVaporUnsubscribe === 'function') {
      this.liveVaporUnsubscribe();
      delete this.liveVaporUnsubscribe;
    }
    Object.values(this.vaporTrails).forEach((trail) => trail.setMap(null));
    this.vaporTrails = {};
    // this.infoWindow.close();
    return Promise.all([
      store.dispatch(updateSettings({ liveVapor: false })),
      store.dispatch(setDeviceHistory({})),
    ]);
  };

  /** Toggles Live Vapor. */
  toggleLiveVapor = async () => {
    console.debug('Toggling Live Vapor');
    return store.getState().map.settings.liveVapor
      ? this.disableLiveVapor()
      : this.enableLiveVapor();
  };

  /** Enables the weather layer for the map. */
  enableWeatherLayer = async (service) => {
    console.debug('Adding weather layer');
    // return WeatherService.enable(this.map)
    //   .then(() => {
    //     if (store.getState().app.mode === appModes.map) {
    //       console.debug('Weather layer enabled');
    return store.dispatch(updateSettings({ weather: true }));
    //   }
    // })
    // .catch((error) => {
    //   console.error(error);
    //   alert(`Unable to get Weather layer`); // TODO look into making work with snackbar
    // });
  };

  /** Disables the weather layer for the map. */
  disableWeatherLayer = async () => {
    console.debug('Removing weather layer');
    // return WeatherService.disable().then(() => {
    //   console.debug('Weather layer disabled');
    return store.dispatch(updateSettings({ weather: false }));
    // });
  };

  /** Toggles the weather layer for the map. */
  toggleWeatherLayer = async () => {
    console.debug('Toggling weather layer');
    return store.getState().map.settings.weather
      ? this.disableWeatherLayer()
      : this.enableWeatherLayer();
  };

  /**
   * Generates and shows a county information window on the map.
   * @param {Object} event - The click event
   * @param {google.maps.LatLng} event.latLng - Google Maps location object
   * @param {Object} county - The county object
   * @param {String} county.name - The county's name
   * @param {String} county.state - The county's state's name
   */
  showCountyInfo = async (event, county) => {
    // const contentString = `<div class="infoWindowVapor">
    //             <div class="deviceName">
    //                 <strong>County:</strong> ${county.name}<br>
    //                 <strong>State Abbreviation:</strong> ${county.state}<br>
    //             </div>
    //         </div>`;
    // this.infoWindow.setContent(contentString);
    // this.infoWindow.setPosition(event.latLng);
    // this.infoWindow.open(this.map);
  };

  /**
   * Creates County Polygons for the County layer
   * @param {Array} counties - Array of county data
   */
  // TODO look into better caching
  createCountyPolygons = async (counties) => {
    console.debug('Creating county Polygons');
    this.countyPolygons = counties.map((county) => {
      let polygon = L.polygon(county.points, {
        color: '#fff',
        opacity: 0.7,
        weight: 2,
      });
      polygon.bindPopup(
        `<div class="infoWindowVapor">
                <div class="deviceName">
                    <strong>County:</strong> ${county.name}<br>
                    <strong>State Abbreviation:</strong> ${county.state}<br>
                </div>
            </div>`,
        { autoPan: false }
      );
      return polygon;
    });
  };

  /**
   * Helper function for enabling the county layer.
   * @private
   */
  _enableCountyLayer = async () => {
    this.countyPolygons.map((polygon) => polygon.addTo(this.map));
    return store.dispatch(updateSettings({ counties: true }));
  };

  /**
   * Enables the county layer for the map.
   * @public
   */
  enableCountyLayer = async () => {
    console.debug('Enabling county layer');
    if (!this.countyPolygons.length) {
      console.debug('Fetching counties');
      return APIService.get('/map/counties')
        .then((response) => response.data)
        .then(this.createCountyPolygons)
        .then(() => {
          const state = store.getState();
          if (state.app.mode === appModes.map && state.map.mode === 'live')
            return this._enableCountyLayer();
        });
      // .catch((error) => {
      //   console.error(error);
      //   alert(`Unable to get County data`); // TODO look into making work with snackbar
      // });
    } else {
      return this._enableCountyLayer();
    }
  };

  /** Disables the county layer for the map. */
  disableCountyLayer = async () => {
    console.debug('Disabling county layer');
    this.countyPolygons.map((polygon) => polygon.remove());
    return store.dispatch(updateSettings({ counties: false }));
  };

  // TODO look into making this a layer due to covering weather
  /** Toggles the county layer for the map. */
  toggleCountyLayer = async () => {
    console.debug('Toggling county layer');
    return store.getState().map.settings.counties
      ? this.disableCountyLayer()
      : this.enableCountyLayer();
  };

  /** Enables Auto Zoom on location update. */
  enableAutoZoom = async () => {
    console.debug('Enabling Auto Zoom');
    clearTimeout(this.autoZoomState.timeout);
    const promises = [
      this.resetView(),
      this.unfollow(),
      store.dispatch(updateSettings({ autoZoom: true })),
    ];
    return Promise.all(promises);
  };

  /** Disables Auto Zoom on location update. */
  disableAutoZoom = async () => {
    console.debug('Disabling Auto Zoom');
    clearTimeout(this.autoZoomState.timeout);
    return store.dispatch(updateSettings({ autoZoom: false }));
  };

  /** Toggles Auto Zoom on location update. */
  toggleAutoZoom = async () => {
    console.debug('Toggling Auto Zoom');
    return store.getState().map.settings.autoZoom
      ? this.disableAutoZoom()
      : this.enableAutoZoom();
  };

  autoZoom = async () => {
    if (
      !this.autoZoomState.lastZoomed ||
      -this.autoZoomState.lastZoomed.diffNow('seconds').as('seconds') >=
        this.autoZoomState.buffer
    ) {
      console.debug('Auto Zooming');
      this.autoZoomState.lastZoomed = DateTime.now();
      return this.resetView();
    }
  };

  /** Resets the viewport to the default center/zoom */
  resetView = async () => {
    console.debug('Resetting view');
    const state = store.getState();

    if (state.map.followedMarker) {
      const marker = state.map.devices[state.map.followedMarker];
      return this.center({
        latitude: marker.currentLocation.latitude,
        longitude: marker.currentLocation.longitude,
        zoomLevel: this.maxClusterZoom + 1,
      });
    }
    const bounds = L.latLngBounds();
    Object.values(selectVisibleDevices(state)).forEach((device) => {
      if (
        this.areValidCoordinates(
          device.location?.latitude,
          device.location?.longitude
        )
      )
        bounds.extend(
          L.latLng(device.location.latitude, device.location.longitude)
        );
      else console.warn('Device missing coordinates', device);
    });
    Object.values(selectVisibleAddresses(state)).forEach((address) => {
      if (address.latitude && address.longitude)
        bounds.extend(L.latLng(address.latitude, address.longitude));
      else console.warn('Address missing coordinates', address);
    });
    Object.values(state.map.serviceCalls)
      .filter((call) => call.visible)
      .forEach((call) => {
        if (call.location.latitude && call.location.longitude)
          bounds.extend(
            L.latLng(call.location.latitude, call.location.longitude)
          );
        else console.warn('Service Call missing coordinates', call);
      });
    // Service Module
    Object.values(state.map.appointments)
      .filter((appointment) => appointment.visible)
      .forEach((appointment) => {
        if (appointment.latitude && appointment.longitude)
          bounds.extend(
            new this.maps.LatLng(appointment.latitude, appointment.longitude)
          );
        else
          console.warn('Service Appointment missing coordinates', appointment);
      });
    await Promise.all(
      Object.values(state.map.kmls)
        .filter((kml) => kml.visible)
        .map((kml) => {
          return new Promise((resolve, reject) => {
            this.kmls[kml.id].forEach((layer) => {
              bounds.extend(layer.getBounds());
            });
            resolve();
          });
        })
    ).catch((error) => {
      console.error(error);
    });
    if (bounds.isValid())
      this.map.fitBounds(bounds, {
        maxZoom: this.maxZoom,
        padding: L.point(this.boundsPadding, this.boundsPadding),
      });
    else
      return this.center({
        latitude: this.defaults.center.lat,
        longitude: this.defaults.center.lng,
        zoomLevel: this.defaults.zoom,
      });
  };

  /**
   * Generates an URL for Google Street View for the specified location
   * @param {Number} latitude - Latitude coordinate
   * @param {Number} longitude - Longitude coordinate
   * @param {Number} heading - Heading
   * @param {Object} size - Size options for street view
   * @param {Number} size.width - Width of desired preview
   * @param {Number} size.height - Height of desired preview
   * @returns
   */
  getStreetViewPreviewURL = async ({
    latitude,
    longitude,
    heading,
    size = { width: 180, height: 180 },
  }) => {
    return `${this.baseGoogleMapsURI}${this.streetviewPath}?size=${size.width}x${size.height}&location=${latitude},${longitude}&heading=${heading}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  };

  /**
   * Saves the current map Redux state to local storage
   */
  saveMapState = () => {
    console.debug('Saving Map State', store.getState().map);
    const state = store.getState();
    let data = {};
    ['devices', 'addresses', 'geofences', 'routes', 'kmls'].forEach((key) => {
      const ids = [];
      Object.values(state.map[key]).forEach(
        (object) => object.visible && ids.push(object.id)
      );
      data[key] = ids;
    });
    const mapState = {
      settings: state.map.settings,
      ...data,
      deviceFilters: state.map.deviceFilters,
      addressFilters: state.map.addressFilters,
    };
    localStorage.setItem(this.localStorageKey, JSON.stringify(mapState));
  };

  /**
   * Clears localStorage cache
   */
  clearMapState = async () => {
    console.debug('Clearing Map State');
    localStorage.removeItem(this.localStorageKey);
  };
}

const mapAPIService = new MapAPIService();

export default mapAPIService;
