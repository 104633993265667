import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { FormProvider } from 'react-hook-form';

import './form.scss';
import APIService from '../../../services/api';
import { filterFormKeyPress, useMountedRef } from '../../../utilities/helpers';
import { LoadingContext } from '../common/context';
import { capitalize } from '../../../utilities/_algorithms';

function CRUDForm({
  form,
  fetchURL,
  updateURL,
  fetchDataFormatter,
  submitDataFormatter,
  successMessage,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(props.id ? true : false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const isMounted = useMountedRef();

  useEffect(() => {
    if (props.id)
      APIService.get(fetchURL, { params: { id: props.id } })
        .then((response) => {
          if (isMounted.current) {
            let data = response.data;
            if (typeof fetchDataFormatter === 'function')
              data = fetchDataFormatter(response.data);
            form.reset(data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.isAxiosError && error.response?.status === 400)
            enqueueSnackbar(error.response.data, { variant: 'error' });
          else
            enqueueSnackbar(
              `Unable to get ${
                props.model ? capitalize(props.model) : 'data'
              }. Try again later.`,
              { variant: 'error' }
            );
        });
    // eslint-disable-next-line
  }, [fetchURL, props.id, props.model, enqueueSnackbar, form, isMounted]);

  const onSubmit = async (data) => {
    if (!props.useCustomButton) setIsSubmitting(true);
    if (typeof props.onSubmit === 'function') await props.onSubmit();
    let submitData = data;
    if (typeof submitDataFormatter === 'function')
      submitData = await submitDataFormatter(data);
    return APIService.post(updateURL, submitData)
      .then((response) => {
        enqueueSnackbar(
          successMessage ||
            `Successfully saved ${
              props.model ? capitalize(props.model) : 'data'
            }`,
          { variant: 'success' }
        );
        if (typeof props.onSave === 'function') props.onSave(response.data);
      })
      .catch((error) => {
        if (error.isAxiosError && error.response?.status === 400)
          enqueueSnackbar(error.response.data, { variant: 'error' });
        else
          enqueueSnackbar(
            `Unable to save ${
              props.model ? capitalize(props.model) : 'data'
            }. Try again later.`,
            { variant: 'error' }
          );
        if (typeof props.onError === 'function') props.onError(error);
      })
      .finally(() => {
        if (!props.useCustomButton) setIsSubmitting(false);
      });
  };

  const stopPropagation = (callback) => (event) => {
    event.stopPropagation();
    callback(event);
  };

  return (
    <LoadingContext.Provider value={isLoading}>
      <FormProvider {...form}>
        <Box
          component='form'
          className={props.className}
          id={props.formID}
          onSubmit={stopPropagation(form.handleSubmit(onSubmit))}
          onKeyPress={filterFormKeyPress}
        >
          {props.children}
          {!props.useCustomButton && (
            <Box display='flex' flexDirection='row-reverse'>
              <LoadingButton
                loading={isSubmitting || isLoading}
                variant='contained'
                color='success'
                type='submit'
              >
                Save
              </LoadingButton>
            </Box>
          )}
        </Box>
      </FormProvider>
    </LoadingContext.Provider>
  );
}

CRUDForm.propTypes = {
  form: PropTypes.object.isRequired,
  useCustomButton: PropTypes.bool.isRequired,
  formID: PropTypes.string,
  model: PropTypes.string,
  fetchURL: PropTypes.string,
  updateURL: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSubmit: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func,
  submitDataFormatter: PropTypes.func,
  fetchDataFormatter: PropTypes.func, // IMPORTANT use useCallback
  successMessage: PropTypes.string,
};

CRUDForm.defaultProps = {
  useCustomButton: false,
};

export default CRUDForm;
