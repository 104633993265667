import { Typography, Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';

import './section.scss';

function FormSection({ children, title, ...props }) {
  return (
    <>
      {title && (
        <>
          <Typography variant='h6'>{title}</Typography>
          <Divider />
        </>
      )}
      <Grid container spacing={2} mt={title ? 1 : undefined} mb={3} {...props}>
        {children}
      </Grid>
    </>
  );
}

FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

FormSection.defaultProps = {};

export default FormSection;
