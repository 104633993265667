import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import './videoRequestDetails.scss';
import { formatHeading, useAddress } from '../../../../../../utilities/helpers';
import Details from '../../../../common/details/details';

function VideoRequestDetails({ event, ...props }) {
  const address = useAddress(event.latitude, event.longitude);

  const details = [
    [
      'Timestamp',
      event.time.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    ],
    ['Status', event.status],
    ['Address', address],
    ['Speed', event.velocity > 0 ? `${event.velocity} mph` : 'Stopped'],
    ['Heading', formatHeading(event.heading)],
    ['Latitude', event.latitude],
    ['Longitude', event.longitude],
  ];

  return <Details primaryDetails={details} />;
}

VideoRequestDetails.propTypes = {
  event: PropTypes.object.isRequired,
};

VideoRequestDetails.defaultProps = {};

export default VideoRequestDetails;
