import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import './loading.scss';
import { dealers, getDealer } from '../../utilities/helpers';

// TODO Redo
function Loading({ loadingMessage }) {
  const dealer = getDealer();

  let message = 'Loading US Fleet Tracking';
  if (dealer)
    switch (dealer.id) {
      case dealers.LiveViewGPS:
        message = 'Loading LiveViewGPS Live Tracking';
        break;
      default:
        break;
    }

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex + 1 }} open>
      <Box flexDirection='column' alignItems='center' sx={{ display: 'flex' }}>
        <CircularProgress color='inherit' />
        <Typography color='inherit' sx={{ mt: 2 }}>
          {loadingMessage && message}
        </Typography>
      </Box>
    </Backdrop>
  );
}

Loading.propTypes = {
  loadingMessage: PropTypes.bool.isRequired,
};

Loading.defaultProps = {
  loadingMessage: true,
};

export default Loading;
