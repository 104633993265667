// TODO REGISTER IF WE WANT TILES FROM A LOCKED PROVIDER
// https://github.com/leaflet-extras/leaflet-providers#providers-requiring-registration
const tileSets = {
  usft: {
    id: 'usft',
    name: 'USFT (network or VPN only)',
    maxNativeZoom: 19,
    url: 'https://test.maptiler.usft.com/styles/basic-preview/{z}/{x}/{y}.png',
  },
  osm: {
    id: 'osm',
    name: 'Open Street Maps',
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    maxNativeZoom: 19,
    attribution:
      '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  },
  stadiaLight: {
    id: 'stadiaLight',
    name: 'Stadia - Alidade Light (localhost Only)',
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
  },
  stadiaDark: {
    id: 'stadiaDark',
    name: 'Stadia - Alidade Dark (localhost Only)',
    url: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
  },
  tfSpinalMap: {
    id: 'tfSpinalMap',
    name: 'Thunderforest - Spinal Map',
    url: 'https://{s}.tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=db5ae1f5778a448ca662554581f283c5',
  },
  jawgSunny: {
    id: 'jawgSunny',
    name: 'Jawg - Sunny (localhost Only)',
    url: 'https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=PyTJUlEU1OPJwCJlW1k0NC8JIt2CALpyuj7uc066O7XbdZCjWEL3WYJIk6dnXtps',
  },
  jawgDark: {
    id: 'jawgDark',
    name: 'Jawg - Dark (localhost Only)',
    url: 'https://{s}.tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=PyTJUlEU1OPJwCJlW1k0NC8JIt2CALpyuj7uc066O7XbdZCjWEL3WYJIk6dnXtps',
  },
  stamenToner: {
    id: 'stamenToner',
    name: 'Stamen - Toner',
    url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png',
  },
  cartoDBLight: {
    id: 'cartoDBLight',
    name: 'CartoDB - Positron',
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  },
  cartoDBDark: {
    id: 'cartoDBDark',
    name: 'CartoDB - Dark Matter',
    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  },
};
export default tileSets;
