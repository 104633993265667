import { FormProvider, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './geofences.scss';
import mapAPIService from '../../../services/map';
import SidebarListView from '../../sidebar/listView/listView';

function GeofencesSidebar({ geofences, followedMarker, canManage }) {
    const form = useForm();

    const filterRowsFunction = (query, geofence) => (
        geofence.name.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) >= 0
    );

    const handleChange = (fenceID) => async (event) => {
        if (!geofences[fenceID].visible)
            return mapAPIService.showGeofence(fenceID, !followedMarker);
        else
            return mapAPIService.hideGeofence(fenceID);
    };

    const handleAllChange = async (event) => {
        if (event.target.checked)
            Object.values(geofences).forEach(geofence => mapAPIService.showGeofence(geofence.id));
        else
            Object.values(geofences).forEach(geofence => mapAPIService.hideGeofence(geofence.id));
    };

    return (
        <FormProvider {...form} >
            <SidebarListView
                rows={Object.values(geofences)}
                getRowPrimaryText={(geofence) => geofence.name}
                filterRows={filterRowsFunction}
                sortRows={(a, b) => a.name.localeCompare(b.name)}
                emptyMessage='No Geofences Found'
                canManage={canManage}
                manageURL={'manage/alerts'}
                onChange={handleChange}
                onAllChange={handleAllChange}
            />
        </FormProvider>
    );
};

GeofencesSidebar.propTypes = {
    geofences: PropTypes.object.isRequired,
    canManage: PropTypes.bool.isRequired,
    followedMarker: PropTypes.number,
};

GeofencesSidebar.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        geofences: state.map.geofences,
        followedMarker: state.map.followedMarker,
        canManage: state.auth.user.permissions.geofences.manage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GeofencesSidebar);