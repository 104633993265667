import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import './editDeviceHOC.scss';
import DeviceForm from '../../../../devices/form/deviceForm';
import FormDialog from '../../../../crud/formDialog/formDialog';
import { updateDevice } from '../../../../../store/slices/map';

function EditDeviceHOC({ id, onSave }) {
  const [showDialog, setShowDialog] = useState(false);

  const dispatch = useDispatch();

  const handleClick = (e) => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleSave = (device) => {
    if (typeof onSave === 'function') onSave(device);
    dispatch(updateDevice({ id, properties: device }));
    handleClose();
  };

  return (
    <>
      <Button variant='contained' onClick={handleClick}>
        Edit
      </Button>
      <FormDialog
        open={showDialog}
        onClose={handleClose}
        onSave={handleSave}
        form={DeviceForm}
        model='device'
        formProps={{ id }}
      />
    </>
  );
}

EditDeviceHOC.propTypes = {
  id: PropTypes.number.isRequired,
};

EditDeviceHOC.defaultProps = {};

export default memo(EditDeviceHOC);
